import { memo } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "Components/Routing/Router";
import { Slide, ThemeContext, Themes, ToastContainer } from "@clintonelec/react-storybook";
import TitleBar from "Components/TitleBar";

const App = () => {
	return (
		<BrowserRouter>
			<ThemeContext.Provider value={ Themes.LIGHT }>
				<div className="app-window">
					<TitleBar />
					<Router />
				</div>
				<ToastContainer
					position="top-right"
					autoClose={ 4500 }
					hideProgressBar
					newestOnTop={ false }
					closeOnClick
					rtl={ false }
					pauseOnFocusLoss
					draggable
					pauseOnHover
					transition={ Slide }
					className="cec-toast"
					limit={ 10 }
					style={ { width: 350 } }
					theme={ Themes.LIGHT }
				/>
			</ThemeContext.Provider>
		</BrowserRouter>
	);
};

export default memo(App);
