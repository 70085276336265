import { DatePicker } from "@clintonelec/react-storybook";
import "Components/PlaybackLayout/DateTimePicker/DateTimePicker.less";
import { setDateAction } from "Data/Redux/Slices/Playback";
import { useAppDispatch } from "Data/Redux/Store";
import { memo } from "react";

function DateTimePicker() {
	const dispatch = useAppDispatch();
	const changeDate = (value: Date) => dispatch(setDateAction(+value));

	return (
		<div className="date-time-picker">
			<DatePicker onChange={ changeDate } />
		</div>
	);
}

export default memo(DateTimePicker);
