import storage from "redux-persist/lib/storage";
import { buildStore, store } from "Data/Redux/Store";
import {
	PersistConfig, persistStore, persistReducer,
	createTransform
} from "redux-persist";
import { AppState, rootReducer } from "Data/Redux/Reducers";
import { produce } from "immer";
import { IPlaybackState } from "Interfaces";

const persistTransform = createTransform(
	(inboundState, key) => {
		switch (key) {
			case "playback":
				return produce(inboundState as IPlaybackState, (draft) => {
					draft.date = null;
				});

			default:
				return inboundState;
		}
	}
);

const persistConfig: PersistConfig<AppState> = {
	key: "ip-camera-web",
	storage,
	transforms: [ persistTransform ]
};

buildStore(
	persistReducer(persistConfig, rootReducer),
	{},
	[]
);

export const persistor = persistStore(store);
