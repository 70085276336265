import { memo, MouseEvent, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { produce } from "immer";
import { IPoint } from "Interfaces";
import { pointInsidePolygon } from "Data/Utils/Point";
import { useResizeCanvas } from "Data/Utils/Effects";

const pointColor = "rgb(51, 184, 137)";
const hoveredPointColor = "#68D5AE";
const areaColor = "rgba(51, 184, 137, 0.2)";
const hoveredAreaColor = "rgba(51, 184, 137, 0.3)";
const pointColor2 = "rgb(244, 206, 52)";
const hoveredPointColor2 = "#F7DC70";
const areaColor2 = "rgb(244, 206, 52, 0.2)";
const hoveredAreaColor2 = "rgb(244, 206, 52, 0.3)";
const pointRadius = 8;

interface IInteractiveRectangleProps {
	points?: IPoint[];
	points2?: IPoint[];
	onUpdate?: (newPoints: IPoint[]) => void;
	onUpdate2?: (newPoints: IPoint[]) => void;
}

const InteractiveRectangle = (props: IInteractiveRectangleProps) => {
	const { points: statePoints, onUpdate: setPoints, points2: statePoints2, onUpdate2: setPoints2 } = props;
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [ isDraggingRectangle, setIsDraggingRectangle ] = useState(false);
	const [ currentMousePos, setCurrentMousePos ] = useState<IPoint>(null);
	const [ hoveredPointIndex, setHoveredPointIndex ] = useState<number>(null);
	const [ draggedPointIndex, setDraggedPointIndex ] = useState<number>(null);
	const [ hoveredPointIndex2, setHoveredPointIndex2 ] = useState<number>(null);
	const [ draggedPointIndex2, setDraggedPointIndex2 ] = useState<number>(null);
	const [ isDraggingRectangle2, setIsDraggingRectangle2 ] = useState(false);

	useResizeCanvas(canvasRef);

	const points = useMemo(() => {
		if (statePoints?.length > 0) {
			return statePoints;
		}

		const newPoints: IPoint[] = [
			{ x: 125, y: 100 },
			{ x: 225, y: 100 },
			{ x: 225, y: 200 },
			{ x: 125, y: 200 }
		];

		return newPoints;
	}, [ statePoints ]);

	const points2 = useMemo(() => {
		if (statePoints2?.length > 0) {
			return statePoints2;
		}

		const newPoints: IPoint[] = [
			{ x: 295, y: 100 },
			{ x: 395, y: 100 },
			{ x: 395, y: 200 },
			{ x: 295, y: 200 }
		];

		return newPoints;
	}, [ statePoints2 ]);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");

		// Prevents having to put user-select: none; on literally everything.
		canvas.onselectstart = () => false;

		context.strokeStyle = pointColor;
		context.lineWidth = 2;
		context.clearRect(0, 0, canvas.width, canvas.height);

		if (statePoints && points.length > 1) {
			const mouseWithin = pointInsidePolygon(currentMousePos, points) && hoveredPointIndex === null;

			context.beginPath();
			context.moveTo(points[0].x, points[0].y);
			points.forEach(point => context.lineTo(point.x, point.y));
			context.closePath();
			context.stroke();
			context.fillStyle = !mouseWithin ? areaColor : hoveredAreaColor;
			context.fill();
		}

		if (statePoints) {
			points.forEach((point, index) => {
				context.beginPath();
				context.fillStyle = index === hoveredPointIndex ? hoveredPointColor : pointColor;
				context.arc(point.x, point.y, pointRadius, 0, 2 * Math.PI);
				context.fill();
			});
		}

		context.strokeStyle = pointColor2;

		if (statePoints2 && points2.length > 1) {
			const mouseWithin = pointInsidePolygon(currentMousePos, points2) && hoveredPointIndex2 === null;

			context.beginPath();
			context.moveTo(points2[ 0 ].x, points2[ 0 ].y);
			points2.forEach(point => context.lineTo(point.x, point.y));
			context.closePath();
			context.stroke();
			context.fillStyle = !mouseWithin ? areaColor2 : hoveredAreaColor2;
			context.fill();
		}

		if (statePoints2) {
			points2.forEach((point, index) => {
				context.beginPath();
				context.fillStyle = index === hoveredPointIndex2 ? hoveredPointColor2 : pointColor2;
				context.arc(point.x, point.y, pointRadius, 0, 2 * Math.PI);
				context.fill();
			});
		}
	}, [ points, hoveredPointIndex, currentMousePos, points2, hoveredPointIndex2, statePoints, statePoints2 ]);

	const handleMouseDown = (event: MouseEvent) => {
		if (event.buttons & 1) {
			const rect = canvasRef.current.getBoundingClientRect();
			const clickPoint: IPoint = {
				x: event.clientX - rect.left,
				y: event.clientY - rect.top
			};

			const pointIndex = points.findIndex(point =>
				Math.hypot(point.x - clickPoint.x, point.y - clickPoint.y) <= pointRadius
			);

			const pointIndex2 = points2.findIndex(point =>
				Math.hypot(point.x - clickPoint.x, point.y - clickPoint.y) <= pointRadius
			);

			if (pointIndex !== -1) {
				setDraggedPointIndex(pointIndex);
			} else if (pointInsidePolygon(clickPoint, points)) {
				setIsDraggingRectangle(true);
				setCurrentMousePos(clickPoint);
			} else if (pointIndex2 !== -1) {
				setDraggedPointIndex2(pointIndex2);
			} else if (pointInsidePolygon(clickPoint, points2)) {
				setIsDraggingRectangle2(true);
				setCurrentMousePos(clickPoint);
			}
		}
	};

	const handleRectangleMove = (
		event: MouseEvent,
		rectangleEnabled: boolean,
		draggedIndex: number,
		draggingRectangle: boolean,
		rectanglePoints: IPoint[],
		updateHoveredPointIndex: (value: SetStateAction<number>) => void,
		updatePoints: (newPoints: IPoint[]) => void
	) => {
		if (!rectangleEnabled) {
			return;
		}

		const rect = canvasRef.current.getBoundingClientRect();
		const mousePos: IPoint = {
			x: event.clientX - rect.left,
			y: event.clientY - rect.top
		};

		setCurrentMousePos(mousePos);

		if (draggedIndex === null && !draggingRectangle) {
			const foundPoint = rectanglePoints.findIndex(point =>
				Math.hypot(point.x - mousePos.x, point.y - mousePos.y) <= pointRadius
			);

			updateHoveredPointIndex(foundPoint !== -1 ? foundPoint : null);
		} else if (draggedIndex !== null) {
			const newPoints = produce(rectanglePoints, draft => {
				draft[ draggedIndex ] = mousePos;

				const length = rectanglePoints.length;
				const leftIndex = ((draggedIndex - 1) % length + length) % length;
				const rightIndex = ((draggedIndex + 1) % length + length) % length;

				if (draggedIndex % 2 === 0) {
					draft[ leftIndex ].x = mousePos.x;
					draft[ rightIndex ].y = mousePos.y;
				} else {
					draft[ leftIndex ].y = mousePos.y;
					draft[ rightIndex ].x = mousePos.x;
				}
			});

			updatePoints(newPoints);
		} else if (draggingRectangle) {
			const deltaX = mousePos.x - currentMousePos.x;
			const deltaY = mousePos.y - currentMousePos.y;
			const newPoints = rectanglePoints.map(point => ({
				x: point.x + deltaX,
				y: point.y + deltaY
			}));

			const isOutsideCanvas = newPoints.some(point => {
				return point.x > canvasRef.current.width || point.x < 0
					|| point.y < 0 || point.y > canvasRef.current.height;
			});

			if (!isOutsideCanvas) {
				updatePoints(newPoints);
			}
		}
	};

	const handleMouseMove = (event: MouseEvent) => {
		handleRectangleMove(
			event, !!statePoints, draggedPointIndex, isDraggingRectangle, points, setHoveredPointIndex, setPoints
		);

		handleRectangleMove(
			event, !!statePoints2, draggedPointIndex2, isDraggingRectangle2, points2, setHoveredPointIndex2, setPoints2
		);
	};

	const handleMouseUp = () => {
		setDraggedPointIndex(null);
		setIsDraggingRectangle(false);
		setDraggedPointIndex2(null);
		setIsDraggingRectangle2(false);
	};

	return (
		<canvas
			className="interactive-canvas"
			ref={ canvasRef }
			onMouseDown={ handleMouseDown }
			onMouseMove={ handleMouseMove }
			onMouseUp={ handleMouseUp }
		/>
	);
};

export default memo(InteractiveRectangle);
