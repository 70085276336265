import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IAnalyticsEventState } from "Interfaces";

const initialState: IAnalyticsEventState = {
	email: false,
	alarmOut: false,
	interval: 0,
	recording: false,
	snapshot: false,
	whiteLight: false,
	audioAlarm: false,
	redBlueLight: false,
	ftp: false
};

const setAnalyticsEventReducer = (state: IAnalyticsEventState, action: PayloadAction<IAnalyticsEventState>) => {
	return action.payload;
};

const getAnalyticsEvent = (state: IAnalyticsEventState) => {
	return state;
};

const analyticsEventSlice = createAppSlice({
	initialState,
	name: "analyticsEvent",
	reducers: {
		setAnalyticsEventAction: setAnalyticsEventReducer
	},
	selectors: {
		selectAnalyticsEvent: getAnalyticsEvent
	}
});

export default analyticsEventSlice;

export const { setAnalyticsEventAction } = analyticsEventSlice.actions;
export const { selectAnalyticsEvent } = analyticsEventSlice.getSelectors<AppState>(
	state => state.settings.event.analyticsEvent
);
