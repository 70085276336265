import { Input, Select, Switch } from "@clintonelec/react-storybook";
import { IStream } from "Interfaces";
import { Fragment } from "react/jsx-runtime";
import {
	bitrateControlOptions, codecOptions, frameRateOptions, gopSizeOptions, imageQualityOptions, profileOptions,
	resolutionOptions
} from "Data/Objects/CodecSettingsOptions";
import { BitrateControl, Codec } from "Data/Objects/Camera";
import { Dispatch, memo, SetStateAction } from "react";

interface ITabContentProps {
	setUserStreams: Dispatch<SetStateAction<IStream[]>>;
	streamIndex: number;
	userStreams: IStream[];
}

function TabContent(props: ITabContentProps) {
	const { setUserStreams, streamIndex, userStreams } = props;
	const streamName = streamIndex === 1 ? "Second" : "Third";
	const {
		enableStream, codec, profile, resolution, frameRate, gopSize, bitrateControl, bitrate,
		imageQuality, mjpgQuality, dynamicOptions, dynamicFps, dynamicBitrate, dynamicGopSize
	} = userStreams[ streamIndex ];

	const handleUpdate = (key: string) => (value: string | boolean) => {
		const newStreams = userStreams.map((stream, index) => {
			if (index !== streamIndex) {
				return stream;
			}

			if ([ "codec", "profile", "resolution", "imageQuality", "dynamicOptions" ].includes(key)) {
				return { ...stream, [ key ]: value };
			}

			if ([
				"dynamicFps", "dynamicBitrate", "dynamicGopSize", "gopSize", "bitrate", "mjpgQuality"
			].includes(key)) {
				return { ...stream, [ key ]: +value };
			}
		});

		setUserStreams(newStreams);
	};

	const changeEnableStream = (value: boolean) => {
		const newStreams = userStreams.map((stream, index) => {
			if (index !== streamIndex) {
				return stream;
			}

			if (!value) {
				return {
					...stream,
					enableStream: value,
					dynamicOptions: false
				};
			}

			return { ...stream, enableStream: value };
		});

		setUserStreams(newStreams);
	};

	const changeFrameRate = (value: string) => {
		const newStreams = userStreams.map((stream, index) => {
			if (index === streamIndex) {
				return { ...stream, frameRate: +value };
			}

			if (index === 3) {
				if (stream.useStreamSettings === "mainStream" && streamIndex === 0) {
					return { ...stream, frameRate: +value * stream.frameRateLabel };
				}

				if (stream.useStreamSettings === "secondStream" && streamIndex === 1) {
					return { ...stream, frameRate: +value * stream.frameRateLabel };
				}
			}

			return stream;
		});

		setUserStreams(newStreams);
	};

	const changeBitrateControl = (value: BitrateControl) => {
		const newStreams = userStreams.map((stream, index) => {
			if (index !== streamIndex) {
				return stream;
			}

			if (value === BitrateControl.VBRPLUS) {
				return {
					...stream,
					bitrateControl: value,
					dynamicOptions: false
				};
			}

			return { ...stream, bitrateControl: value };
		});

		setUserStreams(newStreams);
	};

	const renderDynamicOptionsArea = () => {
		if (!dynamicOptions) {
			return;
		}

		return (
			<Fragment>
				<div className="form-row">
					<span>Dynamic FPS</span>
					<div className="codec-input-box">
						<Input
							hideCharMessage={ true }
							max={ 30 }
							min={ 1 }
							onSave={ handleUpdate("dynamicFps") }
							type="number"
							value={ dynamicFps }
						/>
						<span>fps (1 ~ 30)</span>
					</div>
				</div>
				<div className="form-row">
					<span>Dynamic Bitrate</span>
					<div className="codec-input-box">
						<Input
							hideCharMessage={ true }
							max={ 8000 }
							min={ 512 }
							onSave={ handleUpdate("dynamicBitrate") }
							type="number"
							value={ dynamicBitrate }
						/>
						<span>kbps (512 ~ 8000)</span>
					</div>
				</div>
				<div className="form-row">
					<span>Dynamic GOP Size</span>
					<div className="codec-input-box">
						<Input
							hideCharMessage={ true }
							max={ 60 }
							min={ 1 }
							onSave={ handleUpdate("dynamicGopSize") }
							type="number"
							value={ dynamicGopSize }
						/>
						<span>frames (1 ~ 60)</span>
					</div>
				</div>
			</Fragment>
		);
	};

	const renderEnableStreamSwitch = () => {
		if (streamIndex === 0) {
			return;
		}

		return (
			<div className="form-row">
				<Switch
					checked={ enableStream }
					className="codec-switch"
					onChange={ changeEnableStream }
				/>
				<span>Enable { streamName } Stream</span>
			</div>
		);
	};

	return (
		<Fragment>
			{ renderEnableStreamSwitch() }
			<div className="form-row">
				<span>Codec</span>
				<Select
					allowClear={ false }
					className="codec-select"
					disabled={ !enableStream }
					onSelect={ handleUpdate("codec") }
					options={ codecOptions }
					value={ codec }
				/>
			</div>
			<div className="form-row">
				<span>Profile</span>
				<Select
					allowClear={ false }
					className="codec-select"
					disabled={ !enableStream }
					onSelect={ handleUpdate("profile") }
					options={ profileOptions }
					value={ profile }
				/>
			</div>
			<div className="form-row">
				<span>Resolution</span>
				<Select
					allowClear={ false }
					className="codec-select"
					disabled={ !enableStream }
					onSelect={ handleUpdate("resolution") }
					options={ resolutionOptions }
					value={ resolution }
				/>
			</div>
			<div className="form-row">
				<span>Frame Rate</span>
				<Select
					allowClear={ false }
					className="codec-select"
					disabled={ !enableStream }
					onSelect={ changeFrameRate }
					options={ frameRateOptions }
					value={ frameRate }
				/>
			</div>
			<div className="form-row">
				<span>GOP Size</span>
				<Select
					allowClear={ false }
					className="codec-select"
					disabled={ !enableStream }
					onSelect={ handleUpdate("gopSize") }
					options={ gopSizeOptions }
					value={ gopSize }
				/>
			</div>
			<div className="form-row">
				<span>Bitrate Control</span>
				<Select
					allowClear={ false }
					className="codec-select"
					disabled={ !enableStream }
					onSelect={ changeBitrateControl }
					options={ bitrateControlOptions }
					value={ bitrateControl }
				/>
			</div>
			<div className="form-row">
				<span>Bitrate</span>
				<div className="codec-input-box">
					<Input
						disabled={ !enableStream }
						hideCharMessage={ true }
						max={ 8000 }
						min={ 512 }
						onSave={ handleUpdate("bitrate") }
						type="number"
						value={ bitrate }
					/>
					<span>kbps (512 ~ 8000)</span>
				</div>
			</div>
			<div className="form-row">
				<span>Image Quality</span>
				<Select
					allowClear={ false }
					className="codec-select"
					disabled={ bitrateControl === BitrateControl.CBR || !enableStream }
					onSelect={ handleUpdate("imageQuality") }
					options={ imageQualityOptions }
					value={ imageQuality }
				/>
			</div>
			<div className="form-row">
				<span>MJPG Quality</span>
				<div className="codec-input-box">
					<Input
						disabled={ codec !== Codec.MJPEG || !enableStream }
						hideCharMessage={ true }
						max={ 100 }
						min={ 0 }
						onSave={ handleUpdate("mjpgQuality") }
						type="number"
						value={ mjpgQuality }
					/>
					<span>(0 ~ 100)</span>
				</div>
			</div>
			<div className="form-row">
				<span>Dynamic Options</span>
				<div className="codec-switch-container">
					<Switch
						checked={ dynamicOptions }
						className="codec-switch"
						disabled={ bitrateControl === BitrateControl.VBRPLUS || !enableStream }
						onChange={ handleUpdate("dynamicOptions") }
					/>
				</div>
			</div>
			{ renderDynamicOptionsArea() }
		</Fragment>
	);
}

export default memo(TabContent);
