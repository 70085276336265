import { AspectMaintainer, Input, RangeSlider, Select } from "@clintonelec/react-storybook";
import {
	ManualWbMode, manualWbModeOptions, MirrorImage, mirrorImageOptions, WhiteBalanceMode, whiteBalanceModeOptions
} from "Data/Objects/Camera";
import { produce } from "immer";
import { IImageSettingsState } from "Interfaces";
import { Dispatch, Fragment, memo, SetStateAction } from "react";

interface IAppearanceProps {
	localSettings: IImageSettingsState;
	setLocalSettings: Dispatch<SetStateAction<IImageSettingsState>>;
}

function Appearance(props: IAppearanceProps) {
	const { localSettings, setLocalSettings } = props;
	const {
		mirrorImage, brightness, contrast, color, sharpness, whiteBalanceMode, manualWbMode
	} = localSettings.appearance;

	const selectMirrorImage = (value: MirrorImage) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.mirrorImage = value;
		}));
	};

	const changeBrightness = (value: number) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.brightness = value;
		}));
	};

	const updateBrightness = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.brightness = +value;
		}));
	};

	const changeContrast = (value: number) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.contrast = value;
		}));
	};

	const updateContrast = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.contrast = +value;
		}));
	};

	const changeColor = (value: number) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.color = value;
		}));
	};

	const updateColor = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.color = +value;
		}));
	};

	const changeSharpness = (value: number) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.sharpness = value;
		}));
	};

	const updateSharpness = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.sharpness = +value;
		}));
	};

	const selectWhiteBalanceMode = (value: WhiteBalanceMode) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.whiteBalanceMode = value;
		}));
	};

	const selectManualWbMode = (value: ManualWbMode) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.appearance.manualWbMode = value;
		}));
	};

	return (
		<Fragment>
			<div className="content-left">
				<AspectMaintainer>
					<div className="camera-snapshot"></div>
				</AspectMaintainer>
				<div className="form-row">&nbsp;</div>
				<div className="form-row">
					<span>Mirror Image</span>
					<Select
						allowClear={ false }
						className="form-select"
						onSelect={ selectMirrorImage }
						options={ mirrorImageOptions }
						value={ mirrorImage }
					/>
				</div>
			</div>
			<div className="content-right">
				<div className="form-row">
					<span>Brightness</span>
					<div className="form-slider-wrapper">
						<RangeSlider
							max={ 30 }
							min={ 0 }
							onAfterChange={ changeBrightness }
							value={ brightness }
						/>
						<Input
							hideCharMessage={ true }
							max={ 30 }
							min={ 0 }
							onUpdate={ updateBrightness }
							type="number"
							value={ brightness }
						/>
					</div>
				</div>
				<div className="form-row">
					<span>Contrast</span>
					<div className="form-slider-wrapper">
						<RangeSlider
							max={ 30 }
							min={ 0 }
							onAfterChange={ changeContrast }
							value={ contrast }
						/>
						<Input
							hideCharMessage={ true }
							max={ 30 }
							min={ 0 }
							onUpdate={ updateContrast }
							type="number"
							value={ contrast }
						/>
					</div>
				</div>
				<div className="form-row">
					<span>Color</span>
					<div className="form-slider-wrapper">
						<RangeSlider
							max={ 30 }
							min={ 0 }
							onAfterChange={ changeColor }
							value={ color }
						/>
						<Input
							hideCharMessage={ true }
							max={ 30 }
							min={ 0 }
							onUpdate={ updateColor }
							type="number"
							value={ color }
						/>
					</div>
				</div>
				<div className="form-row">
					<span>Sharpness</span>
					<div className="form-slider-wrapper">
						<RangeSlider
							max={ 15 }
							min={ 0 }
							onAfterChange={ changeSharpness }
							value={ sharpness }
						/>
						<Input
							hideCharMessage={ true }
							max={ 15 }
							min={ 0 }
							onUpdate={ updateSharpness }
							type="number"
							value={ sharpness }
						/>
					</div>
				</div>
				<div className="form-row">&nbsp;</div>
				<div className="form-row">
					<span>White Balance Mode</span>
					<Select
						allowClear={ false }
						className="form-select"
						onSelect={ selectWhiteBalanceMode }
						options={ whiteBalanceModeOptions }
						value={ whiteBalanceMode }
					/>
				</div>
				<div className="form-row">
					<span>Manual WB Mode</span>
					<Select
						allowClear={ false }
						className="form-select"
						disabled={ whiteBalanceMode !== WhiteBalanceMode.MANUAL }
						onSelect={ selectManualWbMode }
						options={ manualWbModeOptions }
						value={ manualWbMode }
					/>
				</div>
			</div>
		</Fragment>
	);
}

export default memo(Appearance);
