import { Button, ButtonTypes, Input, ISelectOption, Select } from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import "Components/ConfigLayout/Network/EmailSettings/EmailSettings.less";
import { selectEmail, setEmailAction } from "Data/Redux/Slices/Settings/Network/Email";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { produce } from "immer";
import { IEmailSettingsState } from "Interfaces";
import { isEqual, merge } from "lodash";
import { FormEvent, memo, useState } from "react";

const securityOptions: ISelectOption[] = [
	{
		label: "On",
		value: "ON"
	},
	{
		label: "Off",
		value: "OFF"
	}
];

function EmailSettings() {
	const dispatch = useAppDispatch();
	const emailSettings = useAppSelector(selectEmail);
	const [ emailSettingsDiff, setEmailSettingsDiff ] = useState<Partial<IEmailSettingsState>>({});

	const setEmailSettings = (newSettings: IEmailSettingsState) => {
		dispatch(setEmailAction(newSettings));
	};

	const localEmailSettings = produce(emailSettings, draft => {
		merge(draft, emailSettingsDiff);
	});

	const { server, port, security, user, testEmailAddress } = localEmailSettings;
	const [ password, setPassword ] = useState<string>(null);
	const disabled = isEqual(localEmailSettings, emailSettings) && !password;

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setEmailSettingsDiff({});
		setPassword(null);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setEmailSettings(localEmailSettings);
		setEmailSettingsDiff({});
		setPassword(null);
	};

	const updateServer = (value: string) => {
		setEmailSettingsDiff(produce(localEmailSettings, draft => {
			draft.server = value;
		}));
	};

	const updatePort = (value: string) => {
		setEmailSettingsDiff(produce(localEmailSettings, draft => {
			draft.port = +value;
		}));
	};

	const selectSecurity = (value: string) => {
		setEmailSettingsDiff(produce(localEmailSettings, draft => {
			draft.security = value === "ON";
		}));
	};

	const updateUser = (value: string) => {
		setEmailSettingsDiff(produce(localEmailSettings, draft => {
			draft.user = value;
		}));
	};

	const updateTestEmailAddress = (value: string) => {
		setEmailSettingsDiff(produce(localEmailSettings, draft => {
			draft.testEmailAddress = value;
		}));
	};

	return (
		<form className="network-email-form" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="container">
				<div className="card">
					<div className="header">
						<h4>E-mail Setup</h4>
					</div>
					<div className="form-row">
						<span>Server</span>
						<Input
							onUpdate={ updateServer }
							value={ server }
							wrapClassName="email-input"
						/>
					</div>
					<div className="form-row">
						<span>Port</span>
						<Input
							hideCharMessage={ true }
							max={ 65535 }
							min={ 1 }
							onUpdate={ updatePort }
							type="number"
							value={ port }
							wrapClassName="email-input"
						/>
					</div>
					<div className="form-row">
						<span>Security</span>
						<Select
							allowClear={ false }
							className="email-select"
							onSelect={ selectSecurity }
							options={ securityOptions }
							value={ security ? "ON" : "OFF" }
						/>
					</div>
					<div className="form-row">
						<span>User</span>
						<Input
							onUpdate={ updateUser }
							type="email"
							value={ user }
							wrapClassName="email-input"
						/>
					</div>
					<div className="form-row">
						<span>Password</span>
						<Input
							onUpdate={ setPassword }
							password={ true }
							placeholder="********"
							value={ password }
							wrapClassName="email-input"
						/>
					</div>
					<div className="form-row">
						<span>Test E-mail Address</span>
						<Input
							onUpdate={ updateTestEmailAddress }
							type="email"
							value={ testEmailAddress }
							wrapClassName="email-input"
						/>
					</div>
					<div className="form-row">
						<div className="email-button">
							<Button htmlType="button" type={ ButtonTypes.TERTIARY }>Test E-mail</Button>
						</div>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(EmailSettings);
