import { memo } from "react";
import "Components/PlaybackLayout/PlaybackLayout.less";
import VideoContainer from "Components/PlaybackLayout/VideoContainer";
import PlaybackController from "Components/PlaybackLayout/PlaybackController";
import DateTimePicker from "Components/PlaybackLayout/DateTimePicker";

function PlaybackLayout() {
	return (
		<div className="playback-layout">
			<VideoContainer />
			<div className="playback-main">
				<DateTimePicker />
				<PlaybackController />
			</div>
		</div>
	);
}

export default memo(PlaybackLayout);
