import { memo } from "react";
import "Components/ConfigLayout/ActionButtons/ActionButtons.less";
import { Button, ButtonTypes } from "@clintonelec/react-storybook";

interface IActionButtonsProps {
	disabled?: boolean;
}

function ActionButtons(props: IActionButtonsProps) {
	const { disabled } = props;

	return (
		<div className="action-buttons">
			<Button
				disabled={ disabled }
				fill
				htmlType="reset"
				type={ ButtonTypes.SECONDARY }
			>
				Cancel
			</Button>
			<Button
				disabled={ disabled }
				fill
				type={ ButtonTypes.TERTIARY }
			>
				Accept
			</Button>
		</div>
	);
}

export default memo(ActionButtons);
