// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dhcp-form-row .dhcp-checkbox-wrapper .cec-checkbox-container {
  flex: 2;
  margin-bottom: 0;
  padding-left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Network/IpSetupSettings/Ipv4SetupSettings/Ipv4SetupSettings.less"],"names":[],"mappings":"AAEA;EAEE,OAAA;EACA,gBAAA;EACA,eAAA;AAFF","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.dhcp-form-row .dhcp-checkbox-wrapper {\n\t.cec-checkbox-container {\n\t\tflex: 2;\n\t\tmargin-bottom: 0;\n\t\tpadding-left: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
