import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/ActionSettings/ActionSettings.less";
import { Tabs } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";
import { selectActionSettings, setActionSettingsAction } from "Data/Redux/Slices/Settings/Event/Action";
import AlarmOut from "Components/ConfigLayout/Event/ActionSettings/AlarmOut";
import Snapshot from "Components/ConfigLayout/Event/ActionSettings/Snapshot";
import ActionLightSettings from "Components/ConfigLayout/Event/ActionSettings/ActionLightSettings";
import Audio from "Components/ConfigLayout/Event/ActionSettings/Audio";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

function ActionSettings() {
	const dispatch = useAppDispatch();
	const actionSettings = useAppSelector(selectActionSettings);
	const localActionState = useState(actionSettings);
	const [ localActionSettings, setLocalActionSettings ] = localActionState;
	const setActionSettings = () => dispatch(setActionSettingsAction(localActionSettings));

	const handleFormSubmit = (event: FormEvent) => {
		event.preventDefault();

		setActionSettings();
	};

	const handleFormReset = (event: FormEvent) => {
		event.preventDefault();

		setLocalActionSettings(actionSettings);
	};

	const panes = [
		{
			title: "Alarm Out",
			content: <AlarmOut localActionState={ localActionState } />
		},
		{
			title: "Snapshot",
			content: <Snapshot localActionState={ localActionState } />
		},
		{
			title: "White Light",
			content: <ActionLightSettings localActionState={ localActionState } whiteLight />
		},
		{
			title: "Red & Blue Light",
			content: <ActionLightSettings localActionState={ localActionState } />
		},
		{
			title: "Audio",
			content: <Audio localActionState={ localActionState } />
		}
	];

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="action-settings settings-content">
						<SettingsCard size="large" title="Action Settings">
							<Tabs panes={ panes } />
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localActionSettings, actionSettings) } />
		</form>
	);
}

export default memo(ActionSettings);
