import { combineSlices } from "@reduxjs/toolkit";
import userSettingsSlice from "Data/Redux/Slices/Settings/User";
import systemSettingsSlice from "Data/Redux/Slices/Settings/System";
import cameraSettingsSlice from "Data/Redux/Slices/Settings/Camera";
import networkSettingsSlice from "Data/Redux/Slices/Settings/Network";
import audioSettingsSlice from "Data/Redux/Slices/Settings/Audio";

export default combineSlices({
	audio: audioSettingsSlice,
	camera: cameraSettingsSlice,
	network: networkSettingsSlice,
	system: systemSettingsSlice,
	user: userSettingsSlice
});
