import { ISelectOption } from "@clintonelec/react-storybook";

export enum Codec {
	H264 = "H264",
	H265 = "H265",
	MJPEG = "MJPEG"
}

export enum Profile {
	BASELINE = "BASELINE",
	MAIN = "MAIN",
	HIGH = "HIGH"
}

export enum Resolution {
	R1920X1080 = "1920x1080",
	R1280X1024 = "1280x1024",
	R1024X768 = "1024x768"
}

export enum BitrateControl {
	CBR = "CBR",
	VBR = "VBR",
	VBRPLUS = "VBR+"
}

export enum ImageQuality {
	LOW = "LOW",
	MID = "MID",
	HIGH = "HIGH"
}

export enum VideoFormat {
	NTSC = 60,
	PAL = 50
}

export type UseStreamSettings = "mainStream" | "secondStream";

export enum TamperDetection {
	OFF = "OFF",
	LOW = "LOW",
	MID = "MID",
	HIGH = "HIGH"
}

export enum RoiQuality {
	LOW = "LOW",
	MEDIUM = "MEDIUM",
	HIGH = "HIGH"
}

export enum Colors {
	RED = "RED",
	ORANGE = "ORANGE",
	YELLOW = "YELLOW",
	GREEN = "GREEN",
	CYAN = "CYAN",
	BLUE = "BLUE",
	MAGENTA = "MAGENTA",
	BLACK = "BLACK",
	WHITE = "WHITE"
}

export enum DeviceNamePosition {
	OFF = "OFF",
	UPPER_LEFT = "UPPER_LEFT",
	UPPER_RIGHT = "UPPER_RIGHT",
	LOWER_LEFT = "LOWER_LEFT",
	LOWER_RIGHT = "LOWER_RIGHT"
}

export enum MirrorImage {
	NONE = "NONE",
	HORIZONTAL = "HORIZONTAL",
	VERTICAL = "VERTICAL",
	HORIZONTAL_VERTICAL = "HORIZONTAL & VERTICAL"
}

export enum WhiteBalanceMode {
	MANUAL = "MANUAL",
	AUTO = "AUTO",
	AUTO_WIDE = "AUTO WIDE"
}

export enum ManualWbMode {
	INDOOR = "INDOOR",
	FLUORESCENT = "FLUORESCENT",
	OUTDOOR = "OUTDOOR"
}

export enum ExposureMode {
	MANUAL = "MANUAL",
	AUTO = "AUTO",
	AUTO_MOTION_PRIORITY = "AUTO(MOTION PRIORITY)"
}

export enum MaxAgc {
	LOW = "LOW",
	LOW_MID = "LOW - MID",
	HIGH_MID = "HIGH - MID",
	HIGH = "HIGH",
	MAX = "MAX"
}

export enum ShutterSpeed {
	S_30 = "1/30",
	S_60 = "1/60",
	S_120 = "1/120",
	S_180 = "1/180",
	S_240 = "1/240",
	S_300 = "1/300",
	S_360 = "1/360",
	S_500 = "1/500",
	S_800 = "1/800",
	S_1000 = "1/1000",
	S_1500 = "1/1500",
	S_2000 = "1/2000",
	S_2500 = "1/2500",
	S_3000 = "1/3000",
	S_4000 = "1/4000",
	S_5000 = "1/5000"
}

export enum SlowShutter {
	OFF = "OFF",
	X2 = "X2",
	X4 = "X4",
	X8 = "X8"
}

export enum AntiFlickerMode {
	A_50 = "50Hz",
	A_60 = "60Hz",
	OFF = "OFF"
}

export enum ShutterSpeed50Hz {
	S_25 = "1/25",
	S_50 = "1/50",
	S_100 = "1/100"
}

export enum PIrisControl {
	AUTO = "AUTO",
	MANUAL = "MANUAL",
	BEST_QUALITY = "BEST QUALITY",
	DEPTH_OF_FIELD = "DEPTH OF FIELD"
}

export enum BacklightCompensation {
	OFF = "OFF",
	ADAPTIVE = "ADAPTIVE",
	ZONE_LOWER = "ZONE LOWER",
	ZONE_MIDDLE = "ZONE MIDDLE",
	ZONE_UPPER = "ZONE UPPER",
	ZONE_LEFT = "ZONE LEFT",
	ZONE_RIGHT = "ZONE RIGHT"
}

export enum HighlightCompensation {
	OFF = "OFF",
	LOW = "LOW",
	MID = "MID",
	HIGH = "HIGH"
}

export enum DigitalNoiseReduction {
	OFF = "OFF",
	AUTO = "AUTO",
	MANUAL = "MANUAL"
}

export enum DefogControl {
	OFF = "OFF",
	LOW = "LOW",
	MID = "MID",
	HIGH = "HIGH"
}

export enum EnhancementActivation {
	DAY = "DAY",
	NIGHT = "NIGHT",
	DAY_NIGHT = "DAY NIGHT"
}

export enum DayNightMode {
	AUTO = "AUTO",
	DAY = "DAY",
	NIGHT = "NIGHT",
	SCHEDULE = "SCHEDULE"
}

export enum DwellTime {
	D0 = 0,
	D5 = 5,
	D10 = 10,
	D15 = 15,
	D30 = 30,
	D60 = 60
}

export enum IlluminationMode {
	IR = "IR",
	WHITE = "WHITE"
}

export enum IrLed {
	OFF = "OFF",
	AUTO = "AUTO"
}

export enum WhiteLedControl {
	AUTO = "AUTO",
	MANUAL = "MANUAL"
}

// Image Settings
export const onOffOptions: ISelectOption[] = [
	{
		label: "Off",
		value: "OFF"
	},
	{
		label: "On",
		value: "ON"
	}
];

// #region Appearance options

export const mirrorImageOptions: ISelectOption[] = [
	{
		label: "None",
		value: MirrorImage.NONE
	},
	{
		label: "Horizontal",
		value: MirrorImage.HORIZONTAL
	},
	{
		label: "Vertical",
		value: MirrorImage.VERTICAL
	},
	{
		label: "Horizontal & Vertical",
		value: MirrorImage.HORIZONTAL_VERTICAL
	}
];

export const whiteBalanceModeOptions: ISelectOption[] = [
	{
		label: "Manual",
		value: WhiteBalanceMode.MANUAL
	},
	{
		label: "Auto",
		value: WhiteBalanceMode.AUTO
	},
	{
		label: "Auto Wide",
		value: WhiteBalanceMode.AUTO_WIDE
	}
];

export const manualWbModeOptions: ISelectOption[] = [
	{
		label: "Indoor",
		value: ManualWbMode.INDOOR
	},
	{
		label: "Fluorescent",
		value: ManualWbMode.FLUORESCENT
	},
	{
		label: "Outdoor",
		value: ManualWbMode.OUTDOOR
	}
];

// #endregion

// #region Exposure options

export const exposureModeOptions: ISelectOption[] = [
	{
		label: "Manual",
		value: ExposureMode.MANUAL
	},
	{
		label: "Auto",
		value: ExposureMode.AUTO
	},
	{
		label: "Auto(Motion Priority)",
		value: ExposureMode.AUTO_MOTION_PRIORITY
	}
];

export const agcGainOptions: ISelectOption[] = new Array(37).fill(0).map((current, index) => {
	return {
		label: index.toString(),
		value: index
	};
});

export const maxAgcOptions: ISelectOption[] = [
	{
		label: "Low",
		value: MaxAgc.LOW
	},
	{
		label: "Low Mid",
		value: MaxAgc.LOW_MID
	},
	{
		label: "High Mid",
		value: MaxAgc.HIGH_MID
	},
	{
		label: "High",
		value: MaxAgc.HIGH
	},
	{
		label: "Max",
		value: MaxAgc.MAX
	}
];

export const shutterSpeedOptions: ISelectOption[] = Object.values(ShutterSpeed).map(value => {
	return {
		label: value,
		value
	};
});

export const slowShutterOptions: ISelectOption[] = Object.values(SlowShutter).map(value => {
	return {
		label: value,
		value
	};
});

export const antiFlickerModeOptions: ISelectOption[] = Object.values(AntiFlickerMode).map(value => {
	return {
		label: value,
		value
	};
});

export const shutterSpeedAntiFlickerMode50HzOptions: ISelectOption[] = Object.values(ShutterSpeed50Hz).map(value => {
	return {
		label: value,
		value
	};
});

export const shutterSpeedAntiFlickerMode60HzOptions: ISelectOption[] = shutterSpeedOptions.filter((option, index) => {
	return index <= 2;
});

// #endregion

// #region Enhancement options

export const backlightCompensationOptions: ISelectOption[] = [
	{
		label: "Off",
		value: BacklightCompensation.OFF
	},
	{
		label: "Adaptive",
		value: BacklightCompensation.ADAPTIVE
	},
	{
		label: "Zone - Lower",
		value: BacklightCompensation.ZONE_LOWER
	},
	{
		label: "Zone - Middle",
		value: BacklightCompensation.ZONE_MIDDLE
	},
	{
		label: "Zone - Upper",
		value: BacklightCompensation.ZONE_UPPER
	},
	{
		label: "Zone - Left",
		value: BacklightCompensation.ZONE_LEFT
	},
	{
		label: "Zone - Right",
		value: BacklightCompensation.ZONE_RIGHT
	}
];

export const highlightCompensationOptions: ISelectOption[] = [
	{
		label: "Off",
		value: HighlightCompensation.OFF
	},
	{
		label: "Low",
		value: HighlightCompensation.LOW
	},
	{
		label: "Mid",
		value: HighlightCompensation.MID
	},
	{
		label: "High",
		value: HighlightCompensation.HIGH
	}
];

export const digitalNoiseReductionOptions: ISelectOption[] = [
	{
		label: "Off",
		value: DigitalNoiseReduction.OFF
	},
	{
		label: "Auto",
		value: DigitalNoiseReduction.AUTO
	},
	{
		label: "Manual",
		value: DigitalNoiseReduction.MANUAL
	}
];

export const defogControlOptions: ISelectOption[] = [
	{
		label: "Off",
		value: DefogControl.OFF
	},
	{
		label: "Low",
		value: DefogControl.LOW
	},
	{
		label: "Mid",
		value: DefogControl.MID
	},
	{
		label: "High",
		value: DefogControl.HIGH
	}
];

export const enhancementActivationOptions: ISelectOption[] = [
	{
		label: "Day Mode Only",
		value: EnhancementActivation.DAY
	},
	{
		label: "Night Mode Only",
		value: EnhancementActivation.NIGHT
	},
	{
		label: "Day & Night",
		value: EnhancementActivation.DAY_NIGHT
	}
];

// #endregion

// #region Day & Night options

export const dayNightOptions: ISelectOption[] = [
	{
		label: "Auto",
		value: DayNightMode.AUTO
	},
	{
		label: "Day",
		value: DayNightMode.DAY
	},
	{
		label: "Night",
		value: DayNightMode.NIGHT
	},
	{
		label: "Schedule",
		value: DayNightMode.SCHEDULE
	}
];

export const dwellTimeOptions: ISelectOption[] = [
	{
		label: "0 Sec",
		value: DwellTime.D0.toString()
	},
	{
		label: "5 Sec",
		value: DwellTime.D5.toString()
	},
	{
		label: "10 Sec",
		value: DwellTime.D10.toString()
	},
	{
		label: "15 Sec",
		value: DwellTime.D15.toString()
	},
	{
		label: "30 Sec",
		value: DwellTime.D30.toString()
	},
	{
		label: "60 Sec",
		value: DwellTime.D60.toString()
	}
];

export const dayToNightSensitivityOptions: ISelectOption[] = new Array(8).fill(0).map((current, index) => {
	return {
		label: index === 0 ? "Off" : index.toString(),
		value: index.toString()
	};
});

export const nightToDaySensitivityOptions: ISelectOption[] = new Array(8).fill(0).map((current, index) => {
	return {
		label: index === 0 ? "Off" : (index + 3).toString(),
		value: (index + 3).toString()
	};
});

export const illuminationModeOptions: ISelectOption[] = [
	{
		label: "IR LEDs",
		value: IlluminationMode.IR
	},
	{
		label: "White LEDs",
		value: IlluminationMode.WHITE
	}
];

export const irLedOptions: ISelectOption[] = [
	{
		label: "Off",
		value: IrLed.OFF
	},
	{
		label: "Auto",
		value: IrLed.AUTO
	}
];

export const whiteLedControlOptions: ISelectOption[] = [
	{
		label: "Auto",
		value: WhiteLedControl.AUTO
	},
	{
		label: "Manual",
		value: WhiteLedControl.MANUAL
	}
];

// #endregion

// #region Zoom & Focus options

export const irisOptions: ISelectOption[] = [
	{
		label: "Auto Mode",
		value: "Auto Mode"
	}
];

// #endregion
export enum MotionMode {
	VIDEO = "VIDEO",
	SMART = "SMART"
}

export enum MotionObject {
	PERSON = "PERSON",
	VEHICLE = "VEHICLE",
	BIKE = "BIKE"
}

export enum AiRuleType {
	LINE = "Line",
	ZONE = "Zone"
}

export enum AiRuleDirection {
	BOTH = "Both",
	A = "A",
	B = "B"
}

export enum StaticObjectFilter {
	OFF = "Off",
	LOW = "Low",
	MEDIUM = "Medium",
	HIGH = "High"
}

export enum TrackReferencePoint {
	CENTER = "Center",
	TOP = "Top",
	BOTTOM = "Bottom"
}
