import { ISelectOption, Select, SelectComponent } from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import SettingsCard from "Components/ConfigLayout/SettingsCard";
import { Languages } from "Data/Objects/Languages";
import { selectLanguage, setLanguageAction } from "Data/Redux/Slices/Settings/System/Language";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { FormEvent, memo, useRef, useState } from "react";

const languageOptions: ISelectOption[] = [
	{
		label: Languages.ENGLISH,
		value: Languages.ENGLISH
	},
	{
		label: Languages.KOREAN,
		value: Languages.KOREAN
	}
];

interface ISystemLanguageForm extends HTMLFormElement {
	languageOptions: SelectComponent;
}

function LanguageSettings() {
	const selectRef = useRef<SelectComponent>(null);
	const language = useAppSelector(selectLanguage);
	const dispatch = useAppDispatch();
	const [ localLanguage, setLocalLanguage ] = useState(language);
	const setSystemLanguage = (lang: Languages) => dispatch(setLanguageAction(lang));

	const handleSubmit = (event: FormEvent<ISystemLanguageForm>) => {
		event.preventDefault();

		const { languageOptions: { value: systemLanguage } } = event.currentTarget;

		setSystemLanguage(systemLanguage);
	};

	const handleReset = (event: FormEvent<ISystemLanguageForm>) => {
		event.preventDefault();
		selectRef.current?.setValue(
			languageOptions.find(option => option.value === language)
		);
	};

	const handleSelect = (value: string) => setLocalLanguage(value as Languages);

	return (
		<form className="settings-form" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="settings-content">
						<SettingsCard size="small" title="Language">
							<div className="form-row">
								<span>Language</span>
								<Select
									allowClear={ false }
									className="form-select"
									forwardRef={ selectRef }
									name="languageOptions"
									onSelect={ handleSelect }
									options={ languageOptions }
									value={ language }
								/>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ localLanguage === language } />
		</form>
	);
}

export default memo(LanguageSettings);
