import { Button, ButtonTypes, Input, ISelectOption, Select } from "@clintonelec/react-storybook";
import { Dispatch, memo, SetStateAction } from "react";
import { IEmailFtpSettingsState } from "Interfaces";
import { produce } from "immer";
import "Components/ConfigLayout/Event/EmailFtpSettings/EmailServerSettings/EmailServerSettings.less";

const securityOptions: ISelectOption[] = [
	{
		label: "Off",
		value: "OFF"
	},
	{
		label: "SSL",
		value: "SSL"
	},
	{
		label: "TLS/Start TLS",
		value: "TLS"
	}
];

interface IEmailServerSettingsProps {
	localEmailFtpState: [ IEmailFtpSettingsState, Dispatch<SetStateAction<IEmailFtpSettingsState>> ];
}

function EmailServerSettings(props: IEmailServerSettingsProps) {
	const { localEmailFtpState: [ localEmailFtpSettings, setLocalEmailFtpSettings ] } = props;
	const { server, password, port, security, user, testEmailAddress } = localEmailFtpSettings.emailServerSettings;

	const updateServer = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailServerSettings.server = value;
		}));
	};

	const updatePort = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailServerSettings.port = +value;
		}));
	};

	const selectSecurity = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailServerSettings.security = value === "ON";
		}));
	};

	const updateUser = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailServerSettings.user = value;
		}));
	};

	const updateTestEmailAddress = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailServerSettings.testEmailAddress = value;
		}));
	};

	const updatePassword = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.emailServerSettings.password = value;
		}));
	};

	return (
		<div className="email-server-settings">
			<div className="header">
				<h4>Email Setup</h4>
			</div>
			<div className="form-row">
				<span>Server</span>
				<Input
					onUpdate={ updateServer }
					value={ server }
					wrapClassName="form-input"
				/>
			</div>
			<div className="form-row">
				<span>Port</span>
				<Input
					hideCharMessage={ true }
					max={ 65535 }
					min={ 1 }
					onUpdate={ updatePort }
					type="number"
					value={ port }
					wrapClassName="form-input"
				/>
			</div>
			<div className="form-row">
				<span>Security</span>
				<Select
					allowClear={ false }
					className="form-select"
					onSelect={ selectSecurity }
					options={ securityOptions }
					value={ security ? "ON" : "OFF" }
				/>
			</div>
			<div className="form-row">
				<span>User</span>
				<Input
					onUpdate={ updateUser }
					type="email"
					value={ user }
					wrapClassName="form-input"
				/>
			</div>
			<div className="form-row">
				<span>Password</span>
				<Input
					onUpdate={ updatePassword }
					password={ true }
					placeholder="********"
					value={ password }
					wrapClassName="form-input"
				/>
			</div>
			<div className="form-row">
				<span>Test E-mail Address</span>
				<Input
					onUpdate={ updateTestEmailAddress }
					type="email"
					value={ testEmailAddress }
					wrapClassName="form-input"
				/>
			</div>
			<div className="form-row">
				<div className="form-button">
					<Button htmlType="button" type={ ButtonTypes.TERTIARY }>Test E-mail</Button>
				</div>
			</div>
		</div>
	);
}

export default memo(EmailServerSettings);
