// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.management-settings {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.management-settings .scrollable-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 30px;
}
.management-settings .scrollable-container .card {
  background-color: white;
  border-radius: 8px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px 20px;
  width: 500px;
}
.management-settings .scrollable-container .card .page-title {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}
.management-settings .scrollable-container .card .page-title h4 {
  margin-block-end: 5px;
}
.management-settings .scrollable-container .card .divider {
  border-top: 1px solid #eaeaea;
  margin-block: 20px;
}
.management-settings .scrollable-container .card .form-row {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
}
.management-settings .scrollable-container .card .form-row > span {
  color: #7e7e7e;
  margin-right: auto;
}
.management-settings .scrollable-container .card .form-row .system-select,
.management-settings .scrollable-container .card .form-row .cec-input-wrapper {
  width: 50%;
}
.management-settings .scrollable-container .card .form-row .cec-button:not(:first-child) {
  margin-left: 10px;
  width: 20%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/System/ManagementSettings/ManagementSettings.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;EACA,uBAAA;AADD;AAHA;EAOE,mBAAA;EACA,aAAA;EACA,OAAA;EACA,sBAAA;EACA,cAAA;EACA,aAAA;AADF;AAXA;EAeG,uBAAA;EACA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,oBAAA;EACA,YAAA;AADH;AAlBA;EAsBI,gCAAA;EACA,mBAAA;AADJ;AAtBA;EA0BK,qBAAA;AADL;AAzBA;EA+BI,6BAAA;EACA,kBAAA;AAHJ;AA7BA;EAoCI,mBAAA;EACA,aAAA;EACA,mBAAA;AAJJ;AAlCA;EAyCK,cAAA;EACA,kBAAA;AAJL;AAtCA;;EA+CK,UAAA;AALL;AA1CA;EAmDK,iBAAA;EACA,UAAA;AANL","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.management-settings {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\tjustify-content: center;\n\n\t.scrollable-container {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tflex-direction: column;\n\t\toverflow: auto;\n\t\tpadding: 30px;\n\n\t\t.card {\n\t\t\tbackground-color: white;\n\t\t\tborder-radius: 8px;\n\t\t\theight: fit-content;\n\t\t\tpadding: 0 20px 20px;\n\t\t\twidth: 500px;\n\n\t\t\t.page-title {\n\t\t\t\tborder-bottom: 1px solid @light-gray-1;\n\t\t\t\tmargin-bottom: 20px;\n\n\t\t\t\th4 {\n\t\t\t\t\tmargin-block-end: 5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.divider {\n\t\t\t\tborder-top: 1px solid @light-gray-1;\n\t\t\t\tmargin-block: 20px;\n\t\t\t}\n\n\t\t\t.form-row {\n\t\t\t\talign-items: center;\n\t\t\t\tdisplay: flex;\n\t\t\t\tmargin-bottom: 12px;\n\n\t\t\t\t> span {\n\t\t\t\t\tcolor: @light-gray-2;\n\t\t\t\t\tmargin-right: auto;\n\t\t\t\t}\n\n\t\t\t\t.system-select,\n\t\t\t\t.cec-input-wrapper {\n\t\t\t\t\twidth: 50%;\n\t\t\t\t}\n\n\t\t\t\t.cec-button:not(:first-child) {\n\t\t\t\t\tmargin-left: 10px;\n\t\t\t\t\twidth: 20%;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
