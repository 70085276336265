import { MutableRefObject, ReactEventHandler, memo } from "react";
import "Components/Global/VideoPlayer/VideoPlayer.less";
import AspectMaintainer from "Components/Global/AspectMaintainer";

interface IVideoPlayerProps {
	onPlaying?: ReactEventHandler<HTMLVideoElement>;
	onTimeUpdate?: ReactEventHandler<HTMLVideoElement>;
	onWaiting?: ReactEventHandler<HTMLVideoElement>;
	canvasRef?: MutableRefObject<HTMLCanvasElement>;
}

function VideoPlayer(props: IVideoPlayerProps) {
	const { canvasRef } = props;

	return (
		<AspectMaintainer>
			<canvas
				ref={ canvasRef }
				className="video-canvas"
			/>
		</AspectMaintainer>
	);
}

export default memo(VideoPlayer);
