// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-buttons {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
}
.action-buttons .cec-button:not(:first-child) {
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/ActionButtons/ActionButtons.less"],"names":[],"mappings":"AAEA;EACC,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;AADD;AALA;EASE,iBAAA;AADF","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.action-buttons {\n\talign-items: center;\n\tbackground-color: white;\n\tdisplay: flex;\n\tjustify-content: center;\n\tpadding: 10px 0;\n\twidth: 100%;\n\n\t.cec-button:not(:first-child) {\n\t\tmargin-left: 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
