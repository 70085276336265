export const ipNetworkSettingsWarningMessage = "If for some reason the IP camera fails to acquire an IP address," +
	" you will need to use the admin tool to find this unit.";

export const invalidIpValidityMessage = "Invalid IP Address";

export const invalidPortValueMessage = "Invalid Port Value";

export const cidrRegex = "^(12[0-8]|1[01][0-9]|[1-9]?[0-9])$";

export enum IIpv6RadioSettings {
	OFF = "OFF",
	MANUAL = "MANUAL",
	AUTO = "AUTO"
}
