import { memo, ReactNode } from "react";
import "Components/ConfigLayout/SettingsCard/SettingsCard.less";

interface ISettingsCardProps {
	children?: ReactNode;
	size: "small" | "medium" | "large";
	title: string;
}

function SettingsCard(props: ISettingsCardProps) {
	const { size, title, children } = props;

	return (
		<div className={ `settings-card settings-card-${ size }` }>
			<h4 className="settings-card-title">{ title }</h4>
			{ children }
		</div>
	);
}

export default memo(SettingsCard);
