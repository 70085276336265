import { memo } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "Components/Routing/Router";
import { ThemeContext, Themes } from "@clintonelec/react-storybook";
import TitleBar from "Components/TitleBar";

const App = () => {
	return (
		<BrowserRouter>
			<ThemeContext.Provider value={ Themes.LIGHT }>
				<div className="app-window">
					<TitleBar />
					<Router />
				</div>
			</ThemeContext.Provider>
		</BrowserRouter>
	);
};

export default memo(App);
