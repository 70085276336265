import { PayloadAction } from "@reduxjs/toolkit";
import { DUMMY_ADMIN_USER } from "Data/Dummy/User";
import { createAppSlice } from "Data/Redux/Store";
import { ILoginPayload, ILoginResponse, IUserState } from "Interfaces";

const initialState: IUserState = {
	token: "",
	user: null
};

// #region Thunk Functions
export const loginUserThunk = async (payload: ILoginPayload) => {
	const { username, password } = payload ?? {};

	if (!username || !password) {
		return;
	}

	return {
		token: "authToken",
		user: DUMMY_ADMIN_USER
	};
};
// #endregion

// #region User Reducers
export const loginReducer = (state: IUserState, action: PayloadAction<ILoginResponse>) => {
	const { user, token } = action.payload;

	if (!user || !token) {
		return;
	}

	state.token = token;
	state.user = user;
};

export const logoutReducer = (state: IUserState) => {
	state.token = "";
	state.user = null;
};
// #endregion

// #region User Selectors
export const getToken = (state: IUserState) => {
	return state.token;
};

export const getUser = (state: IUserState) => {
	return state.user;
};
// #endregion

const userSlice = createAppSlice({
	name: "user",
	initialState,
	reducers: (create) => ({
		loginUserAction: create.asyncThunk(
			loginUserThunk,
			{
				fulfilled: loginReducer
			}
		),
		logoutUserAction: create.reducer(logoutReducer)
	}),
	selectors: {
		selectCurrentUser: getUser,
		selectToken: getToken
	}
});

export default userSlice;

export const { loginUserAction, logoutUserAction } = userSlice.actions;
export const { selectCurrentUser, selectToken } = userSlice.selectors;
