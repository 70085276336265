import { Select } from "@clintonelec/react-storybook";
import { ICodecSettingsState } from "Interfaces";
import { fourthStreamFrameRateOptions, useStreamSettingsOptions } from "Data/Objects/CodecSettingsOptions";
import { Dispatch, Fragment, memo, SetStateAction } from "react";
import { UseStreamSettings } from "Data/Objects/Camera";
import { produce } from "immer";

interface IFourthTabContentProps {
	codecSettingsState: [ ICodecSettingsState, Dispatch<SetStateAction<ICodecSettingsState>> ];
	streamIndex: number;
}

function FourthTabContent(props: IFourthTabContentProps) {
	const { codecSettingsState, streamIndex } = props;
	const [ localCodecSettings, setLocalCodecSettings ] = codecSettingsState;
	const { useStreamSettings, frameRateLabel } = localCodecSettings.streams[ streamIndex ];

	const selectUseStreamSettings = (stream: UseStreamSettings) => {
		const newState = produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].useStreamSettings = stream;

			switch (stream) {
				case "mainStream": {
					const mainStream = draft.streams[ 0 ];

					draft.streams[ streamIndex ].frameRate = mainStream.frameRate * frameRateLabel;

					break;
				}

				case "secondStream": {
					const secondStream = draft.streams[ 1 ];

					draft.streams[ streamIndex ].frameRate = secondStream.frameRate * frameRateLabel;

					break;
				}
			}
		});

		setLocalCodecSettings(newState);
	};

	const selectFrameRateLabel = (newFrameRateLabel: string) => {
		const newState = produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].frameRateLabel = +newFrameRateLabel;

			switch (useStreamSettings) {
				case "mainStream": {
					const mainStream = draft.streams[ 0 ];

					draft.streams[ streamIndex ].frameRate = mainStream.frameRate * +newFrameRateLabel;

					break;
				}

				case "secondStream": {
					const secondStream = draft.streams[ 1 ];

					draft.streams[ streamIndex ].frameRate = secondStream.frameRate * +newFrameRateLabel;

					break;
				}
			}
		});

		setLocalCodecSettings(newState);
	};

	return (
		<Fragment>
			<div className="form-row">
				<span>Use Stream Settings</span>
				<Select
					allowClear={ false }
					className="form-select"
					onSelect={ selectUseStreamSettings }
					options={ useStreamSettingsOptions }
					value={ useStreamSettings }
				/>
			</div>
			<div className="form-row">
				<span>Frame Rate</span>
				<Select
					allowClear={ false }
					className="form-select"
					onSelect={ selectFrameRateLabel }
					options={ fourthStreamFrameRateOptions }
					value={ frameRateLabel }
				/>
			</div>
		</Fragment>
	);
}

export default memo(FourthTabContent);
