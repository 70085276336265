import { IEmailFtpSettingsState, ScheduleUpdater } from "Interfaces";
import { ChangeEvent, Dispatch, memo, SetStateAction } from "react";
import { produce } from "immer";
import {
	Button, ButtonTypes, Checkbox, ConfirmModal, IconSize, Popover, Select, Table
} from "@clintonelec/react-storybook";
import { Link } from "react-router-dom";
import { emailFrequencyOptions } from "Data/Objects/Event";
import EmailEntryModal from "Components/ConfigLayout/Event/EmailFtpSettings/EmailListSettings/EmailEntryModal";
import "Components/ConfigLayout/Event/EmailFtpSettings/EmailListSettings/EmailListSettings.less";
import Icon, { IconWeight } from "Components/Global/Icon";

const tableColumns = [
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
		sortable: false
	},
	{
		title: "Actions",
		dataIndex: "actions",
		key: "actions",
		sortable: false
	}
];

interface IEmailListSettingsProps {
	localEmailFtpState: [ IEmailFtpSettingsState, Dispatch<SetStateAction<IEmailFtpSettingsState>> ];
}

function EmailListSettings(props: IEmailListSettingsProps) {
	const { localEmailFtpState: [ localEmailFtpSettings, setLocalEmailFtpSettings ] } = props;
	const link = (
		<div>
			Go to <Link to="/setup/system/schedule">Schedule Preset</Link> to create a new preset.
		</div>
	);

	const emailEnabled = (email: string) => {
		return Object.keys(localEmailFtpSettings.emailListSettings.emailList).every(entry => {
			return localEmailFtpSettings.emailListSettings.emailList[entry] !== email;
		});
	};

	const getEmailList = () => {
		return localEmailFtpSettings.emailListSettings.emailList.map((email, index) => ({
			key:`${ index }`,
			email: email,
			actions: actionButtons(email)
		}));
	};

	const handleAddEmailEntry = (email: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailListSettings.emailList.push(email);
		}));
	};

	const handleDeleteEmailEntry = (email: string) => () => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailListSettings.emailList =
				localEmailFtpSettings.emailListSettings.emailList.filter(entry => entry !== email);
		}));
	};

	const handleUpdateEmailEntry = (email: string, prevEmail: string) => {
		const indexMatch = localEmailFtpSettings.emailListSettings.emailList.findIndex(entry => entry === prevEmail);

		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailListSettings.emailList[ indexMatch ] = email;
		}));
	};

	const handleSelectEmailFrequency = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailListSettings.minimumEmailFrequency = +value;
		}));
	};

	const handleUpdateScheduleChecked = (scheduleName: string) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailListSettings.presetSchedule[ scheduleName ] = event.currentTarget.checked;
		}));
	};

	const handleUpdateSnapshot = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.emailListSettings.includeSnapshotOption = event.currentTarget.checked;
		}));
	};

	const actionButtons = (email: string) => (
		<div className="email-list-actions">
			<EmailEntryModal
				emailEnabled={ emailEnabled }
				handleEmailEntryCallback={ handleUpdateEmailEntry }
				oldEmail={ email }
			>
				<Button
					className="edit-button"
					ghost
					htmlType="button"
					icon={ { name: "pencil" } }
					type={ ButtonTypes.SECONDARY }
				/>
			</EmailEntryModal>
			<ConfirmModal
				cancelButton={ { text: "Cancel", type: ButtonTypes.DANGER } }
				okButton={ { text: "Confirm", onClick: handleDeleteEmailEntry(email), type: ButtonTypes.TERTIARY } }
				title="Delete Email"
				modalContent="Are you sure you want to remove this Email?"
			>
				<Button
					ghost
					htmlType="button"
					icon={ { name: "trash" } }
					type={ ButtonTypes.DANGER }
				/>
			</ConfirmModal>
		</div>
	);

	const renderSchedule = (updater: ScheduleUpdater) => {
		return function Schedule(schedule: [ string, boolean ]) {
			const [ scheduleName, checked ] = schedule;

			return (
				<div className="checkbox-wrapper" key={ scheduleName }>
					<Checkbox
						checked={ checked }
						onChange={ updater(scheduleName) }
					/>
					<label>{ scheduleName }</label>
				</div>
			);
		};
	};

	const emailListSchedule = Object
		.entries(localEmailFtpSettings.emailListSettings.presetSchedule)
		.map(renderSchedule(handleUpdateScheduleChecked));

	return (
		<div className="email-list-settings">
			<div className="email-list-content">
				<div className="column">
					<div className="header">
						<h4>Email List</h4>
					</div>
					<EmailEntryModal
						handleEmailEntryCallback={ handleAddEmailEntry }
						emailEnabled={ emailEnabled }
					>
						<Button
							className="add-email-button"
							htmlType="button"
							icon={ { name: "plus" } }
							type={ ButtonTypes.TERTIARY }
						>
							Add Email
						</Button>
					</EmailEntryModal>
					<Table
						data={ getEmailList() }
						columns={ tableColumns }
					/>
				</div>
				<div className="column">
					<div className="header">
						<h4>Options</h4>
					</div>
					<div className="form-row">
						<span>Minimum Email Frequency</span>
						<Select
							allowClear={ false }
							disabled={ false }
							onSelect={ handleSelectEmailFrequency }
							options={ emailFrequencyOptions }
							value={ localEmailFtpSettings.emailListSettings.minimumEmailFrequency }
						/>
					</div>
					<div className="form-row">
						<span>Include Snapshot</span>
						<div className="snapshot-checkbox-wrapper">
							<Checkbox
								checked={ localEmailFtpSettings.emailListSettings.includeSnapshotOption }
								onChange={ handleUpdateSnapshot }
							/>
						</div>
					</div>
				</div>
				<div className="column">
					<div className="schedule-title">
						<h4>Schedule</h4>
						<Popover content={ link }>
							<Icon
								name="circle-info"
								iconWeight={ IconWeight.LIGHT }
								size={ IconSize.SMALLEST }
							/>
						</Popover>
					</div>
					<div className="schedule-checkbox-wrapper">
						{ emailListSchedule }
					</div>
				</div>
			</div>
		</div>
	);
}

export default memo(EmailListSettings);
