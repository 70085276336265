import { memo } from "react";
import { useAppSelector } from "Data/Redux/Store";
import "Components/ConfigLayout/System/SystemInformation/SystemInformation.less";
import { selectSystemInformation } from "Data/Redux/Slices/Settings/System/Information";

function SystemInformation() {
	const systemInformation = useAppSelector(selectSystemInformation);
	const cardContent = Object.entries(systemInformation)
		.map(([ key, value ]) => {
			const words = key.split(/(?=[A-Z0-9])/);

			return (
				<div className="row" key={ key }>
					<span>{ words.join(" ") }:&nbsp;</span>
					<p>{ value }</p>
				</div>
			);
		});

	return (
		<div className="system-information">
			<div className="scrollable-container">
				<div className="card">
					<div className="title">
						<h4>System Information</h4>
					</div>
					{ cardContent }
				</div>
			</div>
		</div>
	);
}

export default memo(SystemInformation);
