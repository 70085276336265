// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Images/Clinton_Logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
  align-items: center;
  background-color: #f0f0f0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.login-page .login-card {
  align-items: center;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  width: 375px;
}
.login-page .login-card .header {
  background: no-repeat center url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 75px;
  margin-bottom: 28px;
  width: 85%;
}
.login-page .login-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 90%;
}
.login-page .login-form .login-button {
  margin-top: 18px;
  width: 50%;
}
.login-page .login-form .username-input {
  margin-bottom: 7px;
}
.login-page .login-form label {
  align-self: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/Components/LoginPage/LoginPage.less"],"names":[],"mappings":"AAEA;EACC,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,OAAA;EACA,sBAAA;EACA,uBAAA;AADD;AALA;EASE,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;AADF;AAfA;EAmBG,oEAAA;EACA,YAAA;EACA,mBAAA;EACA,UAAA;AADH;AArBA;EA2BE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;AAHF;AA3BA;EAiCG,gBAAA;EACA,UAAA;AAHH;AA/BA;EAsCG,kBAAA;AAJH;AAlCA;EA0CG,sBAAA;AALH","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.login-page {\n\talign-items: center;\n\tbackground-color: @light-gray-0;\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\tjustify-content: center;\n\n\t.login-card {\n\t\talign-items: center;\n\t\tbackground-color: white;\n\t\tborder-radius: 6px;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\theight: 300px;\n\t\tjustify-content: center;\n\t\twidth: 375px;\n\n\t\t.header {\n\t\t\tbackground: no-repeat center url(\"../../Images/Clinton_Logo.svg\");\n\t\t\theight: 75px;\n\t\t\tmargin-bottom: 28px;\n\t\t\twidth: 85%;\n\t\t}\n\t}\n\n\t.login-form {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\twidth: 90%;\n\n\t\t.login-button {\n\t\t\tmargin-top: 18px;\n\t\t\twidth: 50%;\n\t\t}\n\n\t\t.username-input {\n\t\t\tmargin-bottom: 7px;\n\t\t}\n\n\t\tlabel {\n\t\t\talign-self: flex-start;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
