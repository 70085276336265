import { PayloadAction } from "@reduxjs/toolkit";
import { Languages } from "Data/Objects/Languages";
import { createAppSlice } from "Data/Redux/Store";
import { IUserSettingsState } from "Interfaces";

const initialState: IUserSettingsState = {
	selectedLanguage: Languages.ENGLISH
};

export const changeLanguageReducer = (state: IUserSettingsState, action: PayloadAction<Languages>) => {
	state.selectedLanguage = action.payload;
};

export const getSelectedLanguage = (state: IUserSettingsState) => {
	return state.selectedLanguage;
};

const userSettingsSlice = createAppSlice({
	name: "userSettings",
	initialState,
	reducers: (create) => ({
		changeLanguageAction: create.reducer(changeLanguageReducer)
	}),
	selectors: {
		selectSelectedLanguage: getSelectedLanguage
	}
});

export default userSettingsSlice;

export const { changeLanguageAction } = userSettingsSlice.actions;
export const { selectSelectedLanguage } = userSettingsSlice.selectors;
