import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/System/ManagementSettings/ManagementSettings.less";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { ISystemManagementState } from "Interfaces";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import {
	selectSystemManagementSettings, setSystemManagementAction
} from "Data/Redux/Slices/Settings/System/Management";
import {
	Button, ButtonTypes, ConfirmModal, IModalButtonProps, Input, ISelectOption, Select, SelectComponent
} from "@clintonelec/react-storybook";
import { produce } from "immer";
import SystemDataModal from "Components/ConfigLayout/System/ManagementSettings/SystemDataModal";
import { isEqual } from "lodash";
import FirmwareUpgradeModal from "Components/ConfigLayout/System/ManagementSettings/FirmwareUpgradeModal";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const passwordExpirationOptions: ISelectOption[] =
	[ 0, 1, 2, 4, 6 ].map(value => {
		if (value === 0) {
			return {
				label: "Never",
				value
			};
		}

		return {
			label: `${ value } Month(s)`,
			value
		};
	});

interface IManagementSettingsForm extends HTMLFormElement {
	systemId: HTMLInputElement;
	deviceName: HTMLInputElement;
	passwordExpiration: SelectComponent;
}

const confirmButton: IModalButtonProps = {
	text: "Ok",
	type: ButtonTypes.TERTIARY
};

const cancelButton: IModalButtonProps = {
	text: "Cancel",
	type: ButtonTypes.DANGER
};

const deviceFieldRegex = "^[a-zA-Z0-9!@#$%^&*()_+-=]{1,20}$";

function ManagementSettings() {
	const dispatch = useAppDispatch();
	const [ submitted, setSubmitted ] = useState(false);
	const systemManagement = useAppSelector(selectSystemManagementSettings);
	const [ localSystemManagement, setLocalSystemManagement ] = useState<ISystemManagementState>(systemManagement);
	const setSystemManagement = () => dispatch(setSystemManagementAction(localSystemManagement));

	const handleSubmit = (event: FormEvent<IManagementSettingsForm>) => {
		event.preventDefault();
		setSubmitted(true);

		if (event.currentTarget?.checkValidity()) {
			setSystemManagement();
		}
	};

	const handleReset = (event: FormEvent<IManagementSettingsForm>) => {
		event.preventDefault();

		setLocalSystemManagement(systemManagement);
	};

	const handleSelect = (value: string) => setLocalSystemManagement(
		produce(localSystemManagement, draft => {
			draft.paswordExpiryTerm = +value;
		})
	);

	const handleInputUpdate = (key: string) => (value: string) => {
		const newState = produce(localSystemManagement, (draft) => {
			draft[ key ] = value;
		});

		setLocalSystemManagement(newState);
	};

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleReset }
			onSubmit={ handleSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="management-settings settings-content">
						<SettingsCard size="small" title="System Management">
							<div className="form-row">
								<span>System ID</span>
								<Input
									value={ localSystemManagement.systemId }
									name="systemId"
									noValidate={ !submitted }
									onUpdate={ handleInputUpdate("systemId") }
									required
									validator={ deviceFieldRegex }
									validityMessage="Invalid System ID"
									wrapClassName="form-input"
								/>
							</div>
							<div className="form-row">
								<span>Device Name</span>
								<Input
									value={ localSystemManagement.deviceName }
									name="deviceName"
									noValidate={ !submitted }
									onUpdate={ handleInputUpdate("deviceName") }
									required
									validator={ deviceFieldRegex }
									validityMessage="Invalid Device Name"
									wrapClassName="form-input"
								/>
							</div>
							<div className="form-row">
								<span>Expired Term Of Password</span>
								<Select
									allowClear={ false }
									className="form-select"
									name="passwordExpiration"
									onSelect={ handleSelect }
									options={ passwordExpirationOptions }
									value={ localSystemManagement.paswordExpiryTerm }
								/>
							</div>
							<div className="divider" />
							<div className="form-row">
								<span>Firmware Upgrade</span>
								<FirmwareUpgradeModal />
							</div>
							<div className="form-row">
								<span>Factory Default</span>
								<ConfirmModal
									okButton={ confirmButton }
									cancelButton={ cancelButton }
									title="Factory Default"
									modalContent="This will load the factory default settings."
								>
									<Button type={ ButtonTypes.TERTIARY } htmlType="button">
										Load
									</Button>
								</ConfirmModal>
							</div>
							<div className="form-row">
								<span>System Data</span>
								<Button type={ ButtonTypes.TERTIARY } htmlType="button">
									Save
								</Button>
								<SystemDataModal />
							</div>
							<div className="form-row">
								<span>System Reboot</span>
								<ConfirmModal
									okButton={ confirmButton }
									cancelButton={ cancelButton }
									title="Reboot"
									modalContent={
										`The system will reboot. In some cases,
										if DHCP is enabled it may not pull a new IP address.`
									}
								>
									<Button type={ ButtonTypes.TERTIARY } htmlType="button">
										Reboot
									</Button>
								</ConfirmModal>
							</div>
							<div className="form-row">
								<span>Event Log</span>
								<Button type={ ButtonTypes.TERTIARY } htmlType="button">
									Save
								</Button>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localSystemManagement, systemManagement) } />
		</form>
	);
}

export default memo(ManagementSettings);
