import { BitrateControl, Codec, ImageQuality, Profile, Resolution, VideoFormat } from "Data/Objects/Camera";
import { ICodecSettingsState } from "Interfaces";

export const DUMMY_CODEC_SETTINGS: ICodecSettingsState = {
	streams: [
		{
			enableStream: true,
			codec: Codec.H264,
			profile: Profile.BASELINE,
			resolution: Resolution.R1920X1080,
			frameRate: 30,
			gopSize: 1,
			bitrateControl: BitrateControl.CBR,
			bitrate: 512,
			imageQuality: ImageQuality.LOW,
			mjpgQuality: 0,
			dynamicOptions: false,
			dynamicFps: 1,
			dynamicBitrate: 512,
			dynamicGopSize: 1
		},
		{
			enableStream: false,
			codec: Codec.H264,
			profile: Profile.MAIN,
			resolution: Resolution.R1280X1024,
			frameRate: 2,
			gopSize: 1,
			bitrateControl: BitrateControl.CBR,
			bitrate: 512,
			imageQuality: ImageQuality.LOW,
			mjpgQuality: 0,
			dynamicOptions: false,
			dynamicFps: 1,
			dynamicBitrate: 512,
			dynamicGopSize: 1
		},
		{
			enableStream: false,
			codec: Codec.H264,
			profile: Profile.MAIN,
			resolution: Resolution.R1280X1024,
			frameRate: 2,
			gopSize: 1,
			bitrateControl: BitrateControl.CBR,
			bitrate: 512,
			imageQuality: ImageQuality.LOW,
			mjpgQuality: 0,
			dynamicOptions: false,
			dynamicFps: 1,
			dynamicBitrate: 512,
			dynamicGopSize: 1
		},
		{
			enableStream: false,
			useStreamSettings: "mainStream",
			frameRateLabel: 0.5,
			frameRate: 15
		}
	],
	videoFormat: VideoFormat.NTSC
};
