import { memo, useState } from "react";
import { Button, ButtonTypes, Modal } from "@clintonelec/react-storybook";
import "Components/ConfigLayout/Network/SecuritySettings/CertificateInfoModal/CertificateInfoModal.less";
import { ISecurityCertificate } from "Interfaces";
import { formatDate } from "@clintonelec/typescriptutils";

interface ICertificateInfoModalProps {
	ieeeEnabled?: boolean;
	certificate?: ISecurityCertificate;
}

function CertificateInfoModal(props: ICertificateInfoModalProps) {
	const { ieeeEnabled, certificate: { name, issuer, subject, validEnd, validStart } } = props;
	const [ visible, setVisible ] = useState(false);

	const handleVisibilityChanged = (newVisibility: boolean) => {
		setVisible(newVisibility);
	};

	const modalContent = (
		<div className="certificate-info-modal-content">
			<div className="row">
				<span>Issuer:</span> { issuer ?? "Unknown" }
			</div>
			<div className="row">
				<span>Subject:</span> { subject ?? "Unknown" }
			</div>
			<div className="row">
				<span>Valid Through:</span>
				{ formatDate(validEnd, "MM/DD/YYYY") }
				-
				{ formatDate(validStart, "MM/DD/YYYY") }
			</div>
		</div>
	);

	return (
		<Modal
			title="Certificate Information"
			modalContent={ modalContent }
			onVisibilityChange={ handleVisibilityChanged }
			visible={ visible }
			width={ 400 }
		>
			<Button
				className="cert-button"
				disabled={ !ieeeEnabled || !name }
				htmlType="button"
				type={ ButtonTypes.TERTIARY }
			>
				Info
			</Button>
		</Modal>
	);
}

export default memo(CertificateInfoModal);
