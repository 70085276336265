export enum DateFormat {
	YYYY_MM_DD = "YYYY/MM/DD",
	MM_DD_YYYY = "MM/DD/YYYY",
	DD_MM_YYYY = "DD/MM/YYYY"
}

export enum TimeFormat {
	TWENTYFOUR_HOUR = "24 HOUR",
	TWELVE_HOUR = "12 HOUR"
}

export enum TimezoneDst {
	T0 = "GMT-12:00",
	T1 = "GMT-11:00 Pacific/Midway",
	T2 = "GMT-10:00 Pacific/Hawaii",
	T3 = "GMT-09:00 America/Anchorage",
	T4 = "GMT-08:00 America/LA",
	T5 = "GMT-07:00 America/Phoenix",
	T6 = "GMT-06:00 America/CST",
	T7 = "GMT-05:00 America/EST",
	T8 = "GMT-04:00 America/Halifax",
	T9 = "GMT-03:30 America/St Johns",
	T10 = "GMT-03:00 America/Sao Paulo",
	T11 = "GMT-02:00 Mid Atlantic",
	T12 = "GMT-01:00 Atlantic/Azores",
	T13 = "GMT+00:00 Europe/London",
	T14 = "GMT+01:00 Europe/Berlin",
	T15 = "GMT+02:00 Europe/Istanbul",
	T16 = "GMT+02:00 Africa/Cairo",
	T17 = "GMT+03:00 Europe/Moscow",
	T18 = "GMT+03:30 Asia/Tehran",
	T19 = "GMT+04:00 Asia/Muscat",
	T20 = "GMT+04:30 Asia/Kabul",
	T21 = "GMT+05:00 Asia/Karachi",
	T22 = "GMT+05:30 Asia/Calcutta",
	T23 = "GMT+05:45 Asia/Katmandu",
	T24 = "GMT+06:00 Asia/Dhaka",
	T25 = "GMT+06:30 Asia/Rangoon",
	T26 = "GMT+07:00 Asia/Bangkok",
	T27 = "GMT+08:00 Asia/Beijing",
	T28 = "GMT+09:00 Asia/Tokyo",
	T29 = "GMT+09:00 Asia/Seoul",
	T30 = "GMT+09:30 Australia/Darwin",
	T31 = "GMT+09:30 Australia/Adelaide",
	T32 = "GMT+10:00 Australia/Brisbane",
	T33 = "GMT+11:00 Pacific/Noumea",
	T34 = "GMT+12:00 Newzealand",
	T35 = "GMT+08:00 Australia/Perth",
	T36 = "GMT+02:00 Middle East/Jordan",
	T37 = "GMT+02:00 Middle East/Lebanon",
	T38 = "GMT+02:00 Middle East/Syria",
	T39 = "GMT+03:00 Middle East/SaudiArabia",
	T40 = "GMT+03:00 Middle East/Iraq",
	T41 = "GMT+03:30 Middle East/Iran",
	T42 = "GMT+04:00 Middle East/UAE",
	T43 = "GMT+10:00 Australia/Sydney",
	T44 = "GMT-07:00 America/Edmonton"
}

export enum ScheduleType {
	WEEKLY = "weekly",
	DAILY = "daily"
}
