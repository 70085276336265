import TitleBar from "Components/TitleBar";
import { memo } from "react";
import { Outlet } from "react-router-dom";

const PageLayout = () => {
	return (
		<div className="app-window">
			<TitleBar />
			<Outlet />
		</div>
	);
};

export default memo(PageLayout);
