import "Components/TitleBar/TitleBar.less";
import { selectSelectedLanguage, changeLanguageAction } from "Data/Redux/Slices/UserSettings";
import { logoutUserAction, selectCurrentUser, selectToken } from "Data/Redux/Slices/User";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { memo } from "react";
import { Link, NavLink } from "react-router-dom";
import { Popover, PopoverTrigger } from "@clintonelec/react-storybook";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";
import { Languages } from "Data/Objects/Languages";

const TitleBar = () => {
	const dispatch = useAppDispatch();
	const authToken = useAppSelector(selectToken);
	const currentUser = useAppSelector(selectCurrentUser);
	const currentLanguage = useAppSelector(selectSelectedLanguage);
	const changeLanguage = (selectedLanguage: Languages) => dispatch(changeLanguageAction(selectedLanguage));
	const logout = () => dispatch(logoutUserAction());

	const handleChangeLanguage = (event) => {
		changeLanguage(event.target.dataset.value);
	};

	const renderCenterControls = () => {
		if (!authToken || !currentUser) {
			return;
		}

		return (
			<div className="title-bar-center">
				<NavLink to="/">
					<Icon iconWeight={ IconWeight.REGULAR } name="tv" size={ IconSize.SMALL } />
				</NavLink>
				<NavLink to="/search">
					<Icon iconWeight={ IconWeight.REGULAR } name="clapperboard-play" size={ IconSize.SMALL } />
				</NavLink>
				<NavLink to="/setup/camera/codec">
					<Icon iconWeight={ IconWeight.REGULAR } name="sliders" size={ IconSize.SMALL } />
				</NavLink>
			</div>
		);
	};

	const renderLogoutButton = () => {
		if (!authToken || !currentUser) {
			return;
		}

		return (
			<Icon
				className="icon right-icon"
				iconWeight={ IconWeight.REGULAR }
				name="arrow-right-from-bracket"
				onClick={ logout }
				size={ IconSize.SMALLER }
			/>
		);
	};

	const renderLanguageOption = (language: string) => {
		const className = currentLanguage === language ? "current-language" : "";

		return (
			<li className={ className } data-value={ language } key={ language }>
				{ language }
			</li>
		);
	};

	const languagePopoverContent = (
		<ul className="language-list" onClick={ handleChangeLanguage }>
			{ Object.values(Languages).map(renderLanguageOption) }
		</ul>
	);

	return (
		<div className="title-bar">
			<div className="title-bar-left">
				<Link to="/">
					<div className="logo" />
				</Link>
			</div>
			{ renderCenterControls() }
			<div className="title-bar-right">
				<span className="camera-model">{ `VSD-TEST-1234` }</span>
				<a href="https://support.clintonelectronics.com" rel="noreferrer" target="_blank">
					<Icon
						className="right-icon"
						iconWeight={ IconWeight.REGULAR }
						name="circle-question"
						size={ IconSize.SMALLER }
					/>
				</a>
				<Popover content={ languagePopoverContent } trigger={ PopoverTrigger.CLICK }>
					<Icon
						className="icon right-icon"
						iconWeight={ IconWeight.REGULAR }
						name="language"
						size={ IconSize.SMALLER }
					/>
				</Popover>
				{ renderLogoutButton() }
			</div>
		</div>
	);
};

export default memo(TitleBar);
