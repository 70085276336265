// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certificate-select-modal-content {
  flex: 1;
}
.certificate-select-modal-content .file-selector {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.certificate-select-modal-content .file-selector .cec-input-wrapper {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Network/SecuritySettings/CertificateSelectModal/CertificateSelectModal.less"],"names":[],"mappings":"AAEA;EACC,OAAA;AADD;AAAA;EAIE,mBAAA;EACA,aAAA;EACA,mBAAA;AADF;AALA;EASG,kBAAA;AADH","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.certificate-select-modal-content {\n\tflex: 1;\n\n\t.file-selector {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tmargin-bottom: 20px;\n\n\t\t.cec-input-wrapper {\n\t\t\tmargin-right: 10px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
