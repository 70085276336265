import { Input, ISelectOption, Select, Switch } from "@clintonelec/react-storybook";
import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/NetworkLossSettings/NetworkLossSettings.less";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectNetworkLossEvent, setNetworkLossEventAction } from "Data/Redux/Slices/Settings/Event/NetworkLoss";
import { produce } from "immer";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

interface INetworkLossSettingsForm extends HTMLFormElement {
	detectedObject: HTMLInputElement;
	ignoringInterval: HTMLInputElement;
	hostIpAddress: HTMLInputElement;
	pingInterval: HTMLInputElement;
	alarmOut: HTMLInputElement;
	snapshot: HTMLInputElement;
	recording: HTMLInputElement;
	email: HTMLInputElement;
	ftp: HTMLInputElement;
}

function NetworkLossSettings() {
	const dispatch = useAppDispatch();
	const networkLossSettings = useAppSelector(selectNetworkLossEvent);
	const [ localNetworkLossEvent, setLocalNetworkLossEvent ] = useState(networkLossSettings);
	const setNetworkLossSettings = () => dispatch(setNetworkLossEventAction(localNetworkLossEvent));
	const disabled = isEqual(localNetworkLossEvent, networkLossSettings);
	const {
		alarmOut, detectedObject, ignoringInterval, hostIpAddress, pingInterval, snapshot, recording, email, ftp
	} = localNetworkLossEvent;

	const handleFormSubmit = (event: FormEvent<INetworkLossSettingsForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setNetworkLossSettings();
		}
	};

	const handleFormReset = (event: FormEvent<INetworkLossSettingsForm>) => {
		event.preventDefault();

		setLocalNetworkLossEvent(networkLossSettings);
	};

	const handleDetectedObjectSelect = (newDetectedObject: string) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.detectedObject = newDetectedObject;

			if (newDetectedObject === "Ethernet") {
				draft.email = false;
			}
		}));
	};

	const handleAlarmOutChange = (newEnabled: boolean) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.alarmOut = newEnabled;
		}));
	};

	const handleSnapshotChange = (newEnabled: boolean) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.snapshot = newEnabled;
		}));
	};

	const handleRecordingChange = (newEnabled: boolean) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.recording = newEnabled;
		}));
	};

	const handleEmailChange = (newEnabled: boolean) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.email = newEnabled;
		}));
	};

	const handleFtpChange = (newEnabled: boolean) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.ftp = newEnabled;
		}));
	};

	const handlePingIntervalChange = (newInterval: string) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.pingInterval = +newInterval;
		}));
	};

	const handleHostIpAddressChange = (newAddresss: string) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.hostIpAddress = newAddresss;
		}));
	};

	const handleIgnoringIntervalChange = (newInterval: string) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.ignoringInterval = +newInterval;
		}));
	};

	const detectedObjectSelectOptions: ISelectOption[] = [
		{
			label: "Ethernet",
			value: "Ethernet"
		},
		{
			label: "Ethernet and Destination Host IP",
			value: "Ethernet and Destination Host IP"
		}
	];

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="network-loss-event-settings settings-content">
						<SettingsCard size="medium" title="Network Loss Settings">
							<div className="note-banner event">
								<span>Notification</span>
								<span>&nbsp;-&nbsp;</span>
								<span>
									Actions and notifications require detailed settings on each setting
									page to work.&nbsp;
								</span>
								<span>
									FTP image transfer and email notification are possible only when there is a network
									connection to the server.
								</span>
							</div>
							<div className="network-loss-event-content">
								<div className="form-row">
									<span>Detected object</span>
									<div className="form-select">
										<Select
											allowClear={ false }
											className="detected-object-select"
											onSelect={ handleDetectedObjectSelect }
											options={ detectedObjectSelectOptions }
											value={ detectedObject }
										/>
									</div>
								</div>
								<div className="form-row">
									<span>Ignoring Interval</span>
									<div className="form-input">
										<div className="ignoring-interval-input">
											<Input
												hideCharMessage
												max={ 60 }
												min={ 0 }
												name="ignoringInterval"
												onUpdate={ handleIgnoringIntervalChange }
												type="number"
												value={ ignoringInterval }
											/>
											<span>Sec</span>
										</div>
									</div>
								</div>
								<div className="form-row">
									<span>Destination Host IP</span>
									<div className="form-input">
										<Input
											wrapClassName="host-ip-address-input"
											disabled={ detectedObject === "Ethernet" }
											name="hostIpAddress"
											onUpdate={ handleHostIpAddressChange }
											value={ hostIpAddress }
										/>
									</div>
								</div>
								<div className="form-row">
									<span>PING interval</span>
									<div className="form-input">
										<div className="ping-interval-table-data">
											<Input
												disabled={ detectedObject === "Ethernet" }
												hideCharMessage
												max={ 60 }
												min={ 0 }
												name="pingInterval"
												onUpdate={ handlePingIntervalChange }
												type="number"
												value={ pingInterval }
											/>
											<span>Sec</span>
										</div>
									</div>
								</div>
								<div className="form-row">
									<span>Alarm Out</span>
									<div className="form-switch-wrapper">
										<Switch
											checked={ alarmOut }
											onChange={ handleAlarmOutChange }
										/>
									</div>
								</div>
								<div className="form-row">
									<span>Snapshot</span>
									<div className="form-switch-wrapper">
										<Switch
											checked={ snapshot }
											onChange={ handleSnapshotChange }
										/>
									</div>
								</div>
								<div className="form-row">
									<span>Recording</span>
									<div className="form-switch-wrapper">
										<Switch
											checked={ recording }
											onChange={ handleRecordingChange }
										/>
									</div>
								</div>
								<div className="form-row">
									<span>Email</span>
									<div className="form-switch-wrapper">
										<Switch
											checked={ email }
											disabled={ detectedObject === "Ethernet" }
											onChange={ handleEmailChange }
										/>
									</div>
								</div>
								<div className="form-row">
									<span>FTP</span>
									<div className="form-switch-wrapper">
										<Switch
											checked={ ftp }
											onChange={ handleFtpChange }
										/>
									</div>
								</div>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(NetworkLossSettings);
