import { Input, Radio } from "@clintonelec/react-storybook";
import {
	cidrRegex, IIpv6RadioSettings, invalidIpValidityMessage, ipNetworkSettingsWarningMessage
} from "Data/Objects/Network";
import { Dispatch, memo, SetStateAction } from "react";
import "Components/ConfigLayout/Network/IpSetupSettings/Ipv6SetupSettings/Ipv6SetupSettings.less";
import { INetworkIpSettings } from "Interfaces";
import { ipv6Regex } from "@clintonelec/typescriptutils";
import { NotificationType, notify } from "src/Notifications";
import { produce } from "immer";

interface IIpv6SetupSettingsProps {
	ipSetupSettings: INetworkIpSettings;
	updateIpv6Radio: Dispatch<SetStateAction<IIpv6RadioSettings>>;
	updateLinkLocalAddress: Dispatch<SetStateAction<string>>;
	updateIpv6AddressInfo: (value: [ number, string ]) => void;
	updateIpv6CidrInfo: (value: [ number, number ]) => void;
	updateIpv6Gateway: Dispatch<SetStateAction<string>>;
	updateDnsIpv6: (value: string[]) => void;
}

function Ipv6SetupSettings(props: IIpv6SetupSettingsProps) {
	const {
		ipSetupSettings: { ipv6Settings, ipv6Settings: { ipv6Radio: radioSelection } },
		updateIpv6Radio, updateLinkLocalAddress, updateIpv6AddressInfo, updateIpv6CidrInfo, updateIpv6Gateway,
		updateDnsIpv6
	} = props;

	const handleRadioOnChange = (newRadioSelection: IIpv6RadioSettings) => () => {
		if (newRadioSelection === IIpv6RadioSettings.AUTO) {
			notify(
				NotificationType.WARNING,
				"WARNING",
				ipNetworkSettingsWarningMessage,
				null,
				{ toastId: "ipv6RadioToast" }
			);
		}

		updateIpv6Radio(newRadioSelection);
	};

	const handleUpdateIpv6AddressInfo = (index: number) => (value: string) => {
		updateIpv6AddressInfo([ index, value ]);
	};

	const handleUpdateCidr = (index: number) => (value: string) => {
		updateIpv6CidrInfo([ index, +value ]);
	};

	const handleUpdateDnsIpv6 = (index: number) => (value: string) => {
		updateDnsIpv6(produce(ipv6Settings.dnsIpv6, draft => {
			draft[ index ] = value;
		}));
	};

	const disabledInput = radioSelection !== IIpv6RadioSettings.MANUAL;
	const disabledLinkLocalAddress = true;
	const ipFields = (
		ipv6Settings.ipv6AddressInfo.map((ipv6Info, index) => {
			return (
				// eslint-disable-next-line react/no-array-index-key
				<div className="ipv6-form-row form-row" key={ `ipv6Info-${ index }` }>
					<div className="ip-address-inputs">
						<label>IP Address { index + 1 }</label>
						<div className="ipv6-address-wrapper">
							<Input
								disabled={ disabledInput }
								name="ipv6Address"
								onUpdate={ handleUpdateIpv6AddressInfo(index) }
								validator={ ipv6Regex }
								value={ ipv6Info.address }
								wrapClassName="ipv6-address"
								validityMessage={ invalidIpValidityMessage }
							/>
							<div> / </div>
							<Input
								disabled={ disabledInput }
								max={ 128 }
								min={ 0 }
								hideCharMessage
								name="ipv6Cidr"
								onUpdate={ handleUpdateCidr(index) }
								type="number"
								validator={ cidrRegex }
								value={ ipv6Info.cidr }
								wrapClassName="ipv6-cidr"
							/>
						</div>
					</div>
				</div>
			);
		})
	);

	const dnsFields = (
		ipv6Settings.dnsIpv6.map((dns, index) => {
			return (
				// eslint-disable-next-line react/no-array-index-key
				<div className="ipv6-form-row form-row" key={ `dns-ipv6-${ index }` }>
					<label>DNS Address { index + 1 }</label>
					<Input
						disabled={ disabledInput }
						name="dnsIpv6"
						onUpdate={ handleUpdateDnsIpv6(index) }
						value={ dns }
						validator={ ipv6Regex }
						validityMessage={ invalidIpValidityMessage }
					/>
				</div>
			);
		})
	);

	return (
		<div className="ipv6-setup-content">
			<div className="setup-inputs">
				<div className="ipv6-radio ipv6-form-row form-row" key={ radioSelection }>
					<label>IPv6</label>
					<div className="radio-wrapper">
						<Radio
							defaultChecked={ true }
							checked={ radioSelection === IIpv6RadioSettings.OFF }
							id="ipv6RadioOff"
							label="OFF"
							name="ipv6Radio"
							onChange={ handleRadioOnChange(IIpv6RadioSettings.OFF) }
							value={ IIpv6RadioSettings.OFF }
						/>
						<Radio
							checked={ radioSelection === IIpv6RadioSettings.MANUAL }
							id="ipv6RadioManual"
							label="ON (MANUAL)"
							name="ipv6Radio"
							onChange={ handleRadioOnChange(IIpv6RadioSettings.MANUAL) }
							value={ IIpv6RadioSettings.MANUAL }
						/>
						<Radio
							checked={ radioSelection === IIpv6RadioSettings.AUTO }
							id="ipv6RadioAuto"
							label="ON (AUTO)"
							name="ipv6Radio"
							onChange={ handleRadioOnChange(IIpv6RadioSettings.AUTO) }
							value={ IIpv6RadioSettings.AUTO }
						/>
					</div>
				</div>
				<div className="ipv6-form-row form-row">
					<label>Link-local Address</label>
					<Input
						disabled={ disabledLinkLocalAddress }
						name="linkLocalAddress"
						onUpdate={ updateLinkLocalAddress }
						validator={ ipv6Regex }
						value={ ipv6Settings.linkLocalAddress }
						validityMessage={ invalidIpValidityMessage }
					/>
				</div>
				{ ipFields }
				<div className="ipv6-form-row form-row">
					<label>Gateway</label>
					<Input
						disabled={ disabledInput }
						name="ipv6Gateway"
						onUpdate={ updateIpv6Gateway }
						validator={ ipv6Regex }
						value={ ipv6Settings.ipv6Gateway }
						validityMessage={ invalidIpValidityMessage }
					/>
				</div>
				{ dnsFields }
			</div>
		</div>
	);
}

export default memo(Ipv6SetupSettings);
