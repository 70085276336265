import { FormEvent, Fragment, memo, useState } from "react";
import Icon from "Components/Global/Icon";
import { IIpFilter, IpFilterType, IWithChildren } from "Interfaces";
import "Components/ConfigLayout/Network/SecuritySettings/FilterManagementModal/FilterManagementModal.less";
import {
	Button, ButtonTypes, Input, ISelectOption, Modal, Select, SelectComponent
} from "@clintonelec/react-storybook";
import { ipv4Regex } from "@clintonelec/typescriptutils";
import { NotificationType, notify } from "src/Notifications";

interface IFilterManagementModalProps extends IWithChildren {
	allFilters?: IIpFilter[];
	filter?: IIpFilter;
	filterActionCallback: (filter: IIpFilter | string, oldFilterAddress?: string) => void;
}

interface IFilterManagementForm extends HTMLFormElement {
	address: HTMLInputElement;
	ipType: SelectComponent;
}

const ipTypeOptions: ISelectOption[] = [
	{
		label: "IP Address",
		value: IpFilterType.IP_ADDRESS
	},
	{
		label: "Network (A Class)",
		value: IpFilterType.A_NETWORK
	},
	{
		label: "Network (B Class)",
		value: IpFilterType.B_NETWORK
	},
	{
		label: "Network (C Class)",
		value: IpFilterType.C_NETWORK
	}
];

function FilterManagementModal(props: IFilterManagementModalProps) {
	const { allFilters, children, filter, filterActionCallback } = props;
	const [ visible, setVisible ] = useState(false);
	const [ submitted, setSubmitted ] = useState(false);

	const handleVisibilityChanged = (newVisibility: boolean) => {
		setVisible(newVisibility);
		setSubmitted(false);
	};

	const handleFormSubmit = (event: FormEvent<IFilterManagementForm>) => {
		setSubmitted(true);
		event.preventDefault();
		event.stopPropagation();

		if (event.currentTarget?.checkValidity()) {
			const { address: { value: newFitlerAddress }, ipType: { value: newIpType } } = event.currentTarget;
			const formFilter: IIpFilter = {
				address: newFitlerAddress,
				type: newIpType
			};

			const foundIndex = allFilters?.findIndex(current => current.address === newFitlerAddress);

			if (foundIndex >= 0) {
				notify(NotificationType.ERROR, "That filter already exists.");

				return;
			}

			if (filter) {
				if (filter !== formFilter) {
					filterActionCallback(formFilter, filter.address);
				}
			} else {
				filterActionCallback(formFilter);
			}

			setVisible(false);
		}
	};

	const validateAddress = (value: string) => {
		return ipv4Regex.test(value);
	};

	const modalContent = (
		<form
			className="filter-management-content"
			noValidate
			onSubmit={ handleFormSubmit }
		>
			<label>Type</label>
			<Select
				allowClear={ false }
				defaultValue={ filter?.type ?? IpFilterType.IP_ADDRESS }
				name="ipType"
				options={ ipTypeOptions }
			/>
			<label>Address</label>
			<Input
				defaultValue={ filter?.address }
				name="address"
				noValidate={ !submitted }
				required
				validator={ validateAddress }
				validityMessage="A valid IP Address is required."
			/>
			<Button icon={ { name: "pencil" } } type={ ButtonTypes.TERTIARY }>
				Save
			</Button>
		</form>
	);

	const renderTitle = () => {
		const titleText = filter ? "Edit Filter" : "Create Filter";

		return (
			<Fragment>
				<Icon name="filter" />
				{ titleText }
			</Fragment>
		);
	};

	return (
		<Modal
			modalContent={ modalContent }
			onVisibilityChange={ handleVisibilityChanged }
			title={ renderTitle() }
			visible={ visible }
		>
			{ children }
		</Modal>
	);
}

export default memo(FilterManagementModal);
