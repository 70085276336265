import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/MotionEventSettings/MotionEventSettings.less";
import { Checkbox, IColumn, ISelectOption, Select, Table } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectMotionEvent, setMotionEventAction } from "Data/Redux/Slices/Settings/Event/Motion";
import { produce } from "immer";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";

const motionEventColumns: IColumn[] = [
	{
		dataIndex: "interval",
		title: "Ignoring Interval",
		key: "interval",
		sortable: false
	},
	{
		dataIndex: "alarmOut",
		title: "Alarm Out",
		key: "alarmOut",
		sortable: false
	},
	{
		dataIndex: "sendImage",
		title: "Send Image",
		key: "sendImage",
		sortable: false
	},
	{
		dataIndex: "whiteLight",
		title: "White Light",
		key: "whiteLight",
		sortable: false
	},
	{
		dataIndex: "redBlueLight",
		title: "Red & Blue Light",
		key: "redBlueLight",
		sortable: false
	},
	{
		dataIndex: "audioAlarm",
		title: "Audio Alarm",
		key: "audioAlarm",
		sortable: false
	},
	{
		dataIndex: "recording",
		title: "Recording",
		key: "recording",
		sortable: false
	},
	{
		dataIndex: "email",
		title: "Email",
		key: "email",
		sortable: false
	}
];

const intervalSelectOptions: ISelectOption[] = [ 0, 5, 10, 15, 20, 25, 30 ].map(value => ({
	label: value === 0 ? "Off" : `${ value }`,
	value
}));

interface IMotionEventForm extends HTMLFormElement {
	eventName: HTMLInputElement;
	alarmOut: HTMLInputElement;
	sendImage: HTMLInputElement;
	whiteLight: HTMLInputElement;
	redBlueLight: HTMLInputElement;
	audioAlarm: HTMLInputElement;
	recording: HTMLInputElement;
	email: HTMLInputElement;
}

function MotionEventSettings() {
	const dispatch = useAppDispatch();
	const motionEventSettings = useAppSelector(selectMotionEvent);
	const [ localMotionEventSettings, setLocalMotionEventSettings ] = useState(motionEventSettings);
	const setMotionEvent = () => dispatch(setMotionEventAction(localMotionEventSettings));

	const handleFormChange = (event: FormEvent<IMotionEventForm>) => {
		const {
			alarmOut: { checked: newAlarmOut },
			sendImage: { checked: newSendImage },
			whiteLight: { checked: newWhiteLight },
			redBlueLight: { checked: newRedBlueLight },
			audioAlarm: { checked: newAudioAlarm },
			recording: { checked: newRecording },
			email: { checked: newEmail }
		} = event.currentTarget;

		const newLocalSettings = produce(localMotionEventSettings, draft => {
			draft.alarmOut = newAlarmOut;
			draft.sendImage = newSendImage;
			draft.whiteLight = newWhiteLight;
			draft.redBlueLight = newRedBlueLight;
			draft.audioAlarm = newAudioAlarm;
			draft.recording = newRecording;
			draft.email = newEmail;
		});

		setLocalMotionEventSettings(newLocalSettings);
	};

	const handleFormSubmit = (event: FormEvent<IMotionEventForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setMotionEvent();
		}
	};

	const handleFormReset = (event: FormEvent<IMotionEventForm>) => {
		event.preventDefault();

		setLocalMotionEventSettings(motionEventSettings);
	};

	const handleIntervalSelect = (interval: string) => {
		const newLocalState = produce(localMotionEventSettings, draft => {
			draft.interval = +interval;
		});

		setLocalMotionEventSettings(newLocalState);
	};

	const tableData = [
		{
			key: "motionEventName",
			interval: (
				<Select
					allowClear={ false }
					className="interval-select"
					onSelect={ handleIntervalSelect }
					options={ intervalSelectOptions }
					value={ localMotionEventSettings.interval }
				/>
			),
			alarmOut: <Checkbox name="alarmOut" checked={ localMotionEventSettings.alarmOut } />,
			sendImage: <Checkbox name="sendImage" checked={ localMotionEventSettings.sendImage } />,
			whiteLight: <Checkbox name="whiteLight" checked={ localMotionEventSettings.whiteLight } />,
			redBlueLight: <Checkbox name="redBlueLight" checked={ localMotionEventSettings.redBlueLight } />,
			audioAlarm: <Checkbox name="audioAlarm" checked={ localMotionEventSettings.audioAlarm } />,
			recording: <Checkbox name="recording" checked={ localMotionEventSettings.recording } />,
			email: <Checkbox name="email" checked={ localMotionEventSettings.email } />
		}
	];

	return (
		<form
			className="motion-event-settings-form"
			noValidate
			onChange={ handleFormChange }
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-content">
				<div className="card">
					<div className="page-title">
						<h4>Motion Event Settings</h4>
					</div>
					<Table
						columns={ motionEventColumns }
						data={ tableData }
					/>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localMotionEventSettings, motionEventSettings) } />
		</form>
	);
}

export default memo(MotionEventSettings);
