// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/Components/LiveLayout/VideoContainer/VideoContainer.less"],"names":[],"mappings":"AAEA;EACC,OAAA;AADD","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.video-container {\n\tflex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
