import { combineSlices } from "@reduxjs/toolkit";
import codecSlice from "Data/Redux/Slices/Settings/Camera/Codec";
import tamperSlice from "Data/Redux/Slices/Settings/Camera/Tamper";
import embeddedOsdSlice from "Data/Redux/Slices/Settings/Camera/EmbeddedOsd";
import maskSlice from "Data/Redux/Slices/Settings/Camera/Mask";
import imageSlice from "Data/Redux/Slices/Settings/Camera/Image";
import aiAnalyticsSlice from "Data/Redux/Slices/Settings/Camera/AiAnalytics";
import motionSlice from "Data/Redux/Slices/Settings/Camera/Motion";
import roiSlice from "Data/Redux/Slices/Settings/Camera/Roi";

export default combineSlices(
	codecSlice, tamperSlice, embeddedOsdSlice, maskSlice, imageSlice, aiAnalyticsSlice, motionSlice, roiSlice
);
