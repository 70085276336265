import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/AnalyticsEventSettings/AnalyticsEventSettings.less";
import { ISelectOption, Select, Switch } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectAnalyticsEvent, setAnalyticsEventAction } from "Data/Redux/Slices/Settings/Event/Analytics";
import { produce } from "immer";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const intervalSelectOptions: ISelectOption[] = [ 0, 5, 10, 15, 20, 25, 30 ].map(value => ({
	label: value === 0 ? "Off" : `${ value }`,
	value
}));

interface IAnalyticsEventForm extends HTMLFormElement {
	eventName: HTMLInputElement;
	alarmOut: HTMLInputElement;
	snapshot: HTMLInputElement;
	whiteLight: HTMLInputElement;
	recording: HTMLInputElement;
	email: HTMLInputElement;
	ftp: HTMLInputElement;
}

function AnalyticsEventSettings() {
	const dispatch = useAppDispatch();
	const analyticsEventSettings = useAppSelector(selectAnalyticsEvent);
	const [ localAnalyticsEventSettings, setLocalAnalyticsEventSettings ] = useState(analyticsEventSettings);
	const setAnalyticsEvent = () => dispatch(setAnalyticsEventAction(localAnalyticsEventSettings));
	const disabled = isEqual(localAnalyticsEventSettings, analyticsEventSettings);
	const {
		interval, alarmOut, snapshot, whiteLight, redBlueLight, audioAlarm, recording, email, ftp
	} = localAnalyticsEventSettings;

	const handleAlarmOutChange = (newEnabled: boolean) => {
		setLocalAnalyticsEventSettings(produce(localAnalyticsEventSettings, draft => {
			draft.alarmOut = newEnabled;
		}));
	};

	const handleSnapshotChange = (newEnabled: boolean) => {
		setLocalAnalyticsEventSettings(produce(localAnalyticsEventSettings, draft => {
			draft.snapshot = newEnabled;
		}));
	};

	const handleWhiteLightChange = (newEnabled: boolean) => {
		setLocalAnalyticsEventSettings(produce(localAnalyticsEventSettings, draft => {
			draft.whiteLight = newEnabled;
		}));
	};

	const handleRedBlueLightChange = (newEnabled: boolean) => {
		setLocalAnalyticsEventSettings(produce(localAnalyticsEventSettings, draft => {
			draft.redBlueLight = newEnabled;
		}));
	};

	const handleAudioAlarmChange = (newEnabled: boolean) => {
		setLocalAnalyticsEventSettings(produce(localAnalyticsEventSettings, draft => {
			draft.audioAlarm = newEnabled;
		}));
	};

	const handleRecordingChange = (newEnabled: boolean) => {
		setLocalAnalyticsEventSettings(produce(localAnalyticsEventSettings, draft => {
			draft.recording = newEnabled;
		}));
	};

	const handleEmailChange = (newEnabled: boolean) => {
		setLocalAnalyticsEventSettings(produce(localAnalyticsEventSettings, draft => {
			draft.email = newEnabled;
		}));
	};

	const handleFtpChange = (newEnabled: boolean) => {
		setLocalAnalyticsEventSettings(produce(localAnalyticsEventSettings, draft => {
			draft.ftp = newEnabled;
		}));
	};

	const handleFormSubmit = (event: FormEvent<IAnalyticsEventForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setAnalyticsEvent();
		}
	};

	const handleFormReset = (event: FormEvent<IAnalyticsEventForm>) => {
		event.preventDefault();

		setLocalAnalyticsEventSettings(analyticsEventSettings);
	};

	const handleIntervalSelect = (newInterval: string) => {
		const newLocalState = produce(localAnalyticsEventSettings, draft => {
			draft.interval = +newInterval;
		});

		setLocalAnalyticsEventSettings(newLocalState);
	};

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="analytics-event-settings settings-content">
						<SettingsCard size="medium" title="AI Analytics Event">
							<div className="note-banner event">
								<span>Notification</span>
								<span>&nbsp;-&nbsp;</span>
								<span>
									Actions and notifications require detailed settings on each settings
									page to work.
								</span>
							</div>
							<div className="analytics-content">
								<div className="form-row">
									<span>Ignoring Interval</span>
									<Select
										allowClear={ false }
										className="form-select"
										onSelect={ handleIntervalSelect }
										options={ intervalSelectOptions }
										value={ interval }
									/>
								</div>
								<div className="form-row">
									<span>Alarm Out</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleAlarmOutChange } checked={ alarmOut } />
									</div>
								</div>
								<div className="form-row">
									<span>Snapshot</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleSnapshotChange } checked={ snapshot } />
									</div>
								</div>
								<div className="form-row">
									<span>White Light</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleWhiteLightChange }checked={ whiteLight } />
									</div>
								</div>
								<div className="form-row">
									<span>Red & Blue Light</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleRedBlueLightChange } checked={ redBlueLight } />
									</div>
								</div>
								<div className="form-row">
									<span>Audio Alarm</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleAudioAlarmChange } checked={ audioAlarm } />
									</div>
								</div>
								<div className="form-row">
									<span>Recording</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleRecordingChange } checked={ recording } />
									</div>
								</div>
								<div className="form-row">
									<span>E-Mail</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleEmailChange } checked={ email } />
									</div>
								</div>
								<div className="form-row">
									<span>FTP</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleFtpChange } checked={ ftp } />
									</div>
								</div>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(AnalyticsEventSettings);
