import { PayloadAction } from "@reduxjs/toolkit";
import { IUpnpSettingsState } from "Interfaces";
import { createAppSlice } from "Data/Redux/Store";
import { AppState } from "Data/Redux/Reducers";

const initialState: IUpnpSettingsState = {
	upnp: false,
	friendlyName: ""
};

export const setUpnpReducer = (state: IUpnpSettingsState, action: PayloadAction<IUpnpSettingsState>) => {
	return action.payload;
};

export const getUpnp = (state: IUpnpSettingsState) => {
	return state;
};

const upnpSlice = createAppSlice({
	name: "upnp",
	initialState,
	reducers: {
		setUpnpAction: setUpnpReducer
	},
	selectors: {
		selectUpnp: getUpnp
	}
});

export default upnpSlice;

export const { setUpnpAction } = upnpSlice.actions;
export const { selectUpnp } = upnpSlice.getSelectors<AppState>(state => state.settings.network.upnp);
