import "Components/ConfigLayout/System/ScheduleSettings/SchedulePopover/SchedulePopover.less";
import { Button, ButtonTypes, Checkbox, Popover, PopoverTrigger } from "@clintonelec/react-storybook";
import { IScheduledDay } from "Interfaces";
import { ChangeEvent, memo } from "react";
import { ScheduleType } from "Data/Objects/System";

interface ISchedulePopoverProps {
	checkScheduledDay: (id: number) => (value: IScheduledDay) => void;
	days: string[];
	index: number;
	scheduledDay: IScheduledDay;
	scheduleType: ScheduleType;
	changeCheckbox: (day: string, id: number) => (event: ChangeEvent<HTMLInputElement>) => void;
}

function SchedulePopover(props: ISchedulePopoverProps) {
	const { checkScheduledDay, days, index, scheduledDay, scheduleType, changeCheckbox } = props;

	const selectAllDay = (all: boolean) => () => {
		checkScheduledDay(index)({
			MON: all,
			TUE: all,
			WED: all,
			THU: all,
			FRI: all,
			SAT: all,
			SUN: all
		});
	};

	const selectWeekday = (all: boolean) => () => {
		checkScheduledDay(index)({
			MON: all,
			TUE: all,
			WED: all,
			THU: all,
			FRI: all,
			SAT: !all,
			SUN: !all
		});
	};

	const renderCheckbox = days.map((day: string) => {
		return (
			<Checkbox
				checked={ scheduledDay[ day ] }
				key={ day }
				onChange={ changeCheckbox(day, index) }
			>
				{ day }.
			</Checkbox>
		);
	});

	const popoverContent = (
		<div className="schedule-popover-content">
			<div className="schedule-popover-buttons">
				<Button ghost htmlType="button" onClick={ selectAllDay(true) }>Select All</Button>
				<Button ghost htmlType="button" onClick={ selectAllDay(false) }>Unselect All</Button>
				<Button ghost htmlType="button" onClick={ selectWeekday(true) }>Weekday</Button>
				<Button ghost htmlType="button" onClick={ selectWeekday(false) }>Weekend</Button>
			</div>
			<div className="schedule-popover-checkbox">
				{ renderCheckbox }
			</div>
		</div>
	);

	return (
		<Popover
			content={ popoverContent }
			trigger={ PopoverTrigger.CLICK }
			wrapClassName={ scheduleType === "weekly" && "hidden" }
		>
			<Button
				ghost
				htmlType="button"
				icon={ { name: "pencil" } }
				type={ ButtonTypes.WARNING }
			/>
		</Popover>
	);
}

export default memo(SchedulePopover);
