import { memo } from "react";
import "Components/PlaybackLayout/PlaybackController/PlaybackController.less";

function PlaybackController() {
	return (
		<div className="playback-controller">
			Timeline/Video Playback Controls
		</div>
	);
}

export default memo(PlaybackController);
