import { ISystemSettingsState } from "Interfaces";
import { Languages } from "Data/Objects/Languages";
import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "Data/Redux/Store";
import { AppState } from "Data/Redux/Reducers";

const initialState: ISystemSettingsState = {
	language: Languages.ENGLISH
};

export const setLanguageReducer = (state: ISystemSettingsState, action: PayloadAction<Languages>) => {
	state.language = action.payload;
};

export const getLanguage = (state: ISystemSettingsState) => {
	return state.language;
};

const languageSlice = createAppSlice({
	name: "language",
	initialState,
	reducers: {
		setLanguageAction: setLanguageReducer
	},
	selectors: {
		selectLanguage: getLanguage
	}
});

export default languageSlice;

export const { setLanguageAction } = languageSlice.actions;
export const { selectLanguage } = languageSlice.getSelectors<AppState>(state => state.settings.system.language);
