import { memo } from "react";
import "Components/LiveLayout/LiveToolbar/LiveToolbar.less";

function LiveToolbar() {
	return (
		<div className="live-toolbar">
			Toolbar
		</div>
	);
}

export default memo(LiveToolbar);
