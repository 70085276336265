import { ChangeEvent, FormEvent, memo, useRef, useState } from "react";
import { Button, ButtonTypes, ConfirmModal, IModalButtonProps, Input, Modal } from "@clintonelec/react-storybook";
import "Components/ConfigLayout/System/ManagementSettings/FirmwareUpgradeModal/FirmwareUpgradeModal.less";
import { useAppSelector } from "Data/Redux/Store";
import { selectSystemInformation } from "Data/Redux/Slices/Settings/System/Information";

const confirmButton: IModalButtonProps = {
	text: "Ok",
	type: ButtonTypes.TERTIARY
};

const cancelButton: IModalButtonProps = {
	text: "Cancel",
	type: ButtonTypes.DANGER
};

function SystemDataModal() {
	const systemInfo = useAppSelector(selectSystemInformation);
	const [ visible, setVisible ] = useState(false);
	const fileSelectRef = useRef<HTMLInputElement>(null);
	const [ selectedFile, setSelectedFile ] = useState<File>(null);

	const handleVisibilityChanged = (newVisibility: boolean) => {
		setSelectedFile(null);
		setVisible(newVisibility);
	};

	const handleSelectedFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target?.files?.length === 0) {
			return;
		}

		setSelectedFile(event.target.files[0]);
	};

	const handleFirmwareUpload = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setVisible(false);
	};

	const renderWarning = () => {
		if (!systemInfo.model.startsWith("CE-PV")) {
			return;
		}

		return (
			<div className="note-banner alert">
				<span>Note</span>
				<span>&nbsp;-&nbsp;</span>
				<span>
					If the camera is installed in a Connect PVM, the Connect PVM should be set to Maintenance Mode
					before updating the camera firmware to prevent possible firmware update interruption. For more
					information please visit our support page&nbsp;
					<a target="_blank" href="https://support.clintonelectronics.com/" rel="noreferrer noopener">here</a>
					.
				</span>
			</div>
		);
	};

	const modalContent = (
		<div className="firmware-upgrade-modal-content">
			{ renderWarning() }
			<p>Please select file for Firmware Upgrade</p>
			<div className="file-selector">
				<input
					accept=".bin"
					hidden
					onChange={ handleSelectedFileChange }
					ref={ fileSelectRef }
					type="file"
				/>
				<Input
					className="file-label"
					disabled
					value={ selectedFile?.name }
				/>
				<Button
					onClick={ () => fileSelectRef.current?.click() }
					htmlType="button"
					type={ ButtonTypes.TERTIARY }
				>
					Choose File
				</Button>
			</div>
			<ConfirmModal
				okButton={ { ...confirmButton, onClick: handleFirmwareUpload } }
				cancelButton={ cancelButton }
				title="Firmware Upgrade"
				modalContent="Video recording will be stopped automatically."
			>
				<Button type={ ButtonTypes.TERTIARY } disabled={ !selectedFile } htmlType="button">
					Upload
				</Button>
			</ConfirmModal>
		</div>
	);

	return (
		<Modal
			title="Firmware Upgrade"
			modalContent={ modalContent }
			onVisibilityChange={ handleVisibilityChanged }
			visible={ visible }
			width={ 550 }
		>
			<Button
				htmlType="button"
				type={ ButtonTypes.TERTIARY }
			>
				Load
			</Button>
		</Modal>
	);
}

export default memo(SystemDataModal);
