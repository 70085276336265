// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-tamper-form {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.event-tamper-form .scrollable-container {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-y: auto;
  padding: 30px;
}
.event-tamper-form .scrollable-container .card {
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px 20px;
  width: -moz-fit-content;
  width: fit-content;
}
.event-tamper-form .scrollable-container .card .page-title {
  border-bottom: 1px solid #7e7e7e;
  font-weight: bold;
  margin-bottom: 15px;
  width: 100%;
}
.event-tamper-form .scrollable-container .card .page-title > h4 {
  margin-block-end: 5px;
}
.event-tamper-form .scrollable-container .card .event-tamper-settings .cec-checkbox-container {
  margin-bottom: 0;
}
.event-tamper-form .scrollable-container .card .event-tamper-settings .interval-select {
  width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Event/TamperEventSettings/TamperEventSettings.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;AADD;AAFA;EAME,aAAA;EACA,OAAA;EACA,uBAAA;EACA,gBAAA;EACA,aAAA;AADF;AATA;EAaG,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,oBAAA;EACA,uBAAA;EAAA,kBAAA;AADH;AAlBA;EAsBI,gCAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AADJ;AAxBA;EA4BK,qBAAA;AADL;AA3BA;EAkCK,gBAAA;AAJL;AA9BA;EAsCK,YAAA;AALL","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.event-tamper-form {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\n\t.scrollable-container {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tjustify-content: center;\n\t\toverflow-y: auto;\n\t\tpadding: 30px;\n\n\t\t.card {\n\t\t\tbackground-color: white;\n\t\t\tborder-radius: 8px;\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\theight: fit-content;\n\t\t\tpadding: 0 20px 20px;\n\t\t\twidth: fit-content;\n\n\t\t\t.page-title{\n\t\t\t\tborder-bottom: 1px solid @light-gray-2;\n\t\t\t\tfont-weight: bold;\n\t\t\t\tmargin-bottom: 15px;\n\t\t\t\twidth: 100%;\n\n\t\t\t\t> h4 {\n\t\t\t\t\tmargin-block-end: 5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.event-tamper-settings {\n\t\t\t\t.cec-checkbox-container {\n\t\t\t\t\tmargin-bottom: 0;\n\t\t\t\t}\n\n\t\t\t\t.interval-select {\n\t\t\t\t\twidth: 200px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
