import { memo } from "react";
import "Components/PlaybackLayout/PlaybackLayout.less";
import VideoContainer from "Components/PlaybackLayout/VideoContainer";
import DateTimePicker from "Components/PlaybackLayout/DateTimePicker";
import HistoricalTimelineToolbar from "Components/PlaybackLayout/HistoricalTimelineToolbar";
import HistoricalTimeline from "Components/PlaybackLayout/HistoricalTimeline";

function PlaybackLayout() {
	return (
		<div className="playback-layout">
			<VideoContainer />
			<div className="playback-main">
				<DateTimePicker />
				<HistoricalTimeline />
				<div className="horizontal" />
				<HistoricalTimelineToolbar />
			</div>
		</div>
	);
}

export default memo(PlaybackLayout);
