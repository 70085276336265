import { Checkbox, Input } from "@clintonelec/react-storybook";
import { Dispatch, memo, SetStateAction } from "react";
import "Components/ConfigLayout/Network/IpSetupSettings/Ipv4SetupSettings/Ipv4SetupSettings.less";
import { INetworkIpSettings } from "Interfaces";
import { ipv4Regex } from "@clintonelec/typescriptutils";
import { NotificationType, notify } from "src/Notifications";
import { invalidIpValidityMessage, ipNetworkSettingsWarningMessage } from "Data/Objects/Network";
import { produce } from "immer";

interface IIpv4SetupSettingsProps {
	ipSetupSettings: INetworkIpSettings;
	updateDhcp: Dispatch<SetStateAction<boolean>>;
	updateIpv4Address: Dispatch<SetStateAction<string>>;
	updateSubnetMask: Dispatch<SetStateAction<string>>;
	updateIpv4Gateway: Dispatch<SetStateAction<string>>;
	updateDnsIpv4: (value: string[]) => void;
}

function Ipv4SetupSettings(props: IIpv4SetupSettingsProps) {
	const {
		ipSetupSettings: { ipv4Settings, ipv4Settings: { dhcp: dhcpSelection } }, updateDhcp,
		updateIpv4Address, updateSubnetMask, updateIpv4Gateway, updateDnsIpv4
	} = props;

	const handleCheckboxOnChange = () => {
		if (!dhcpSelection) {
			notify(
				NotificationType.WARNING,
				"WARNING",
				ipNetworkSettingsWarningMessage,
				null,
				{ toastId: "ipv4CheckboxToast" }
			);
		}

		updateDhcp(!dhcpSelection);
	};

	const handleUpdateDnsIpv4 = (index: number) => (value: string) => {
		updateDnsIpv4(produce(ipv4Settings.dnsIpv4, draft => {
			draft[ index ] = value;
		}));
	};

	const dhcpKey = ipv4Settings.dhcp.toString();
	const dnsFields = (
		ipv4Settings.dnsIpv4.map((dns, index) => {
			return (
				// eslint-disable-next-line react/no-array-index-key
				<div className="form-row" key={ `dns-ipv4-${ index }` }>
					<label>DNS Address { index + 1 }</label>
					<Input
						disabled={ dhcpSelection }
						name="dnsIpv4"
						onUpdate={ handleUpdateDnsIpv4(index) }
						value={ dns }
						validator={ ipv4Regex }
						validityMessage={ invalidIpValidityMessage }
					/>
				</div>
			);
		})
	);

	return (
		<div className="ipv4-setup-content">
			<div className="setup-inputs">
				<div className="dhcp-form-row form-row" key={ dhcpKey }>
					<div className="dhcp-checkbox-wrapper">
						<label>DHCP</label>
						<Checkbox
							checked={ dhcpSelection }
							id="dhcp"
							name="dhcp"
							onChange={ handleCheckboxOnChange }
						/>
					</div>
				</div>
				<div className="form-row">
					<label>IP Address</label>
					<Input
						disabled={ dhcpSelection }
						name="ipv4Address"
						onUpdate={ updateIpv4Address }
						value={ ipv4Settings.ipv4Address }
						validator={ ipv4Regex }
						validityMessage={ invalidIpValidityMessage }
					/>
				</div>
				<div className="form-row">
					<label>Subnet Mask</label>
					<Input
						disabled={ dhcpSelection }
						name="subnetMask"
						onUpdate={ updateSubnetMask }
						value={ ipv4Settings.subnetMask }
						validator={ ipv4Regex }
						validityMessage={ invalidIpValidityMessage }
					/>
				</div>
				<div className="form-row">
					<label>Default Gateway</label>
					<Input
						disabled={ dhcpSelection }
						name="ipv4Gateway"
						onUpdate={ updateIpv4Gateway }
						value={ ipv4Settings.ipv4Gateway }
						validator={ ipv4Regex }
						validityMessage={ invalidIpValidityMessage }
					/>
				</div>
				{ dnsFields }
			</div>
		</div>
	);
}

export default memo(Ipv4SetupSettings);
