// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.live-layout .main-content {
  display: flex;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/Components/LiveLayout/LiveLayout.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;AADD;AAFA;EAME,aAAA;EACA,OAAA;AADF","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.live-layout {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\n\t.main-content {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
