import { FormEvent, memo, useRef, useState } from "react";
import { ISelectOption, Select, SelectComponent } from "@clintonelec/react-storybook";
import { useAppSelector, useAppDispatch } from "Data/Redux/Store";
import { TamperDetection } from "Data/Objects/Camera";
import { selectTamperDetection, setTamperDetectionAction } from "Data/Redux/Slices/Settings/Camera/Tamper";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const tamperOptions: ISelectOption[] = [
	{
		label: "Off",
		value: TamperDetection.OFF
	},
	{
		label: "Low",
		value: TamperDetection.LOW
	},
	{
		label: "Mid",
		value: TamperDetection.MID
	},
	{
		label: "High",
		value: TamperDetection.HIGH
	}
];

interface ITamperDetectionForm extends HTMLFormElement {
	tamperSelect: SelectComponent;
}

function TamperSettings() {
	const dispatch = useAppDispatch();
	const selectRef = useRef<SelectComponent>(null);
	const tamperDetection = useAppSelector(selectTamperDetection);
	const [ localTamper, setLocalTamper ] = useState(tamperDetection);
	const setTamperDetection = (newDetection: TamperDetection) => dispatch(setTamperDetectionAction(newDetection));

	const handleFormSubmit = (event: FormEvent<ITamperDetectionForm>) => {
		event.preventDefault();

		const { tamperSelect: { value: newDetection } } = event.currentTarget;

		setTamperDetection(newDetection);
	};

	const handleReset = (event: FormEvent<ITamperDetectionForm>) => {
		event.preventDefault();

		selectRef.current?.setValue(
			tamperOptions.find(option => option.value === tamperDetection)
		);
	};

	const handleSelect = (value: string) => setLocalTamper(value as TamperDetection);

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="settings-content">
						<SettingsCard size="small" title="Tamper Detection">
							<div className="form-row">
								<span>Level</span>
								<Select
									name="tamperSelect"
									allowClear={ false }
									className="form-select"
									options={ tamperOptions }
									defaultValue={ tamperDetection }
									forwardRef={ selectRef }
									onSelect={ handleSelect }
								/>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons
				disabled={ localTamper === tamperDetection }
			/>
		</form>
	);
}

export default memo(TamperSettings);
