import { AspectMaintainer, Button, ButtonTypes, ISelectOption, Select, Switch } from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import InteractivePolygon from "Components/Global/InteractivePolygon";
import { RoiQuality } from "Data/Objects/Camera";
import { selectRoiSettings, setRoiSettingsAction } from "Data/Redux/Slices/Settings/Camera/Roi";
import { useAppSelector } from "Data/Redux/Store";
import { produce } from "immer";
import { IPoint } from "Interfaces";
import { isEqual } from "lodash";
import { FormEvent, memo, useState } from "react";
import { useDispatch } from "react-redux";
import "Components/ConfigLayout/Camera/RoiSettings/RoiSettings.less";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const qualityOptions: ISelectOption[] = [
	{
		label: "Low",
		value: RoiQuality.LOW
	},
	{
		label: "Medium",
		value: RoiQuality.MEDIUM
	},
	{
		label: "High",
		value: RoiQuality.HIGH
	}
];

function RoiSettings() {
	const dispatch = useDispatch();
	const roiSettings = useAppSelector(selectRoiSettings);
	const [ localRoiSettings, setLocalRoiSettings ] = useState(roiSettings);
	const [ selectedZone, setSelectedZone ] = useState<number>(null);
	const setRoiSettings = () => dispatch(setRoiSettingsAction(localRoiSettings));
	const allZonesEmpty = localRoiSettings.zones.every(zone => zone.length === 0);

	const handleRoiQualitySelect = (value: string) => {
		const newLocalState = produce(localRoiSettings, draft => {
			draft.quality = value;
		});

		setLocalRoiSettings(newLocalState);
	};

	const handleZoneButtonClick = (zoneIndex: number) => () => {
		setSelectedZone(selectedZone === zoneIndex ? null : zoneIndex);
	};

	const handlePointsUpdate = (newPoints: IPoint[]) => {
		const newLocalState = produce(localRoiSettings, draft => {
			draft.zones[ selectedZone ] = newPoints;
		});

		setLocalRoiSettings(newLocalState);
	};

	const handleClearActiveZone = () => {
		const newLocalState = produce(localRoiSettings, draft => {
			draft.zones[ selectedZone ] = [];
		});

		setLocalRoiSettings(newLocalState);
		setSelectedZone(null);
	};

	const handleClearAllZones = () => {
		const newLocalState = produce(localRoiSettings, draft => {
			draft.zones = [ [], [], [], [], [], [], [], [] ];
		});

		setLocalRoiSettings(newLocalState);
		setSelectedZone(null);
	};

	const handleFormReset = (event: FormEvent) => {
		event.preventDefault();

		setLocalRoiSettings(roiSettings);
		setSelectedZone(null);
	};

	const handleFormSubmit = (event: FormEvent) => {
		event.preventDefault();

		setRoiSettings();
		setSelectedZone(null);
	};

	const handlePrivacyRoiEnabledChange = (newEnabled: boolean) => {
		const newLocalState = produce(localRoiSettings, draft => {
			draft.roiEnabled = newEnabled;
		});

		setLocalRoiSettings(newLocalState);
		setSelectedZone(null);
	};

	const renderRoiButtons = () => Array.from(Array(8)).map((current, index) => {
		const isButtonSelected = selectedZone === index;
		const filled = localRoiSettings.zones[ index ]?.length > 0;

		return (
			<Button
				// eslint-disable-next-line react/no-array-index-key
				key={ `zone-button-${ index }` }
				className="zone-button"
				disabled={ !localRoiSettings.roiEnabled }
				ghost={ !isButtonSelected && !filled }
				htmlType="button"
				onClick={ handleZoneButtonClick(index) }
				type={ isButtonSelected ? ButtonTypes.TERTIARY : ButtonTypes.SECONDARY }
			>
				Zone { index + 1 }
			</Button>
		);
	});

	const renderInteractivePolygon = () => {
		if (selectedZone === null) {
			return;
		}

		return (
			<InteractivePolygon
				points={ localRoiSettings.zones[ selectedZone ] }
				onUpdate={ handlePointsUpdate }
				isBox
			/>
		);
	};

	return (
		<form
			className="settings-form"
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="roi-settings settings-content">
						<SettingsCard size="large" title="Region of Interest" >
							<div className="content">
								<div className="left-content">
									<div className="form-row">
										<Switch
											checked={ localRoiSettings.roiEnabled }
											className="roi-switch"
											onChange={ handlePrivacyRoiEnabledChange }
										/>
										<span>Enable ROI Mode</span>
									</div>
									<div className="camera-container">
										<AspectMaintainer>
											<div className="camera-snapshot">
												{ renderInteractivePolygon() }
											</div>
										</AspectMaintainer>
									</div>
									<div className="controls-container">
										<div className="roi-buttons">
											{ renderRoiButtons() }
										</div>
										<Button
											className="zone-action-button"
											disabled={ !localRoiSettings.roiEnabled || selectedZone === null }
											ghost
											htmlType="button"
											onClick={ handleClearActiveZone }
											type={ ButtonTypes.SECONDARY }
										>
											Clear Active Zone
										</Button>
										<Button
											className="zone-action-button"
											disabled={ !localRoiSettings.roiEnabled || allZonesEmpty }
											type={ ButtonTypes.SECONDARY }
											ghost
											htmlType="button"
											onClick={ handleClearAllZones }
										>
											Clear All Zones
										</Button>
									</div>
								</div>
								<div className="right-content">
									<div className="form-row roi-quality">
										<span>ROI Quality</span>
										<Select
											allowClear={ false }
											className="form-select"
											onSelect={ handleRoiQualitySelect }
											options={ qualityOptions }
											value={ localRoiSettings.quality }
										/>
									</div>
								</div>
							</div>
						</SettingsCard>

					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(roiSettings, localRoiSettings) } />
		</form>
	);
}

export default memo(RoiSettings);
