import { ISelectOption } from "@clintonelec/react-storybook";

export enum RecordRadioSettings {
	CONTINUOUS = "CONTINUOUS",
	EVENT = "EVENT",
	DISABLE = "DISABLE"
}

export const storageOptions: ISelectOption[] = [
	{
		label: "SD Card",
		value: "SDCard"
	}
];

export const recordingOptions: ISelectOption[] = [
	{
		label: "1st Stream",
		value: "firstStream"
	},
	{
		label: "2nd Stream",
		value: "secondStream"
	}
];

export const preRecordingTimeOptions: ISelectOption[] = [
	{
		label: "Off",
		value: 0
	},
	{
		label: "5 Sec",
		value: 5
	}
];

export const postRecordingTimeOptions: ISelectOption[] =
	[ 5, 10, 15, 20, 30, 60, 120, 180 ].map(value => {
		return {
			label: `${ value } Sec`,
			value
		};
	});
