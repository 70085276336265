import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { INetworkLossSettingsState } from "Interfaces";

const initialState: INetworkLossSettingsState = {
	detectedObject: "Ethernet",
	ignoringInterval: 0,
	hostIpAddress: "192.158.1.38",
	pingInterval: 5,
	alarmOut: false,
	sendImage: false,
	recording: false,
	email: false
};

const setNetworkLossEventReducer = (
	state: INetworkLossSettingsState,
	action: PayloadAction<INetworkLossSettingsState>
) => {
	return action.payload;
};

const getNetworkLossEvent = (state: INetworkLossSettingsState) => {
	return state;
};

const networkLossEventSlice = createAppSlice({
	initialState,
	name: "networkLossEvent",
	reducers: {
		setNetworkLossEventAction: setNetworkLossEventReducer
	},
	selectors: {
		selectNetworkLossEvent: getNetworkLossEvent
	}
});

export default networkLossEventSlice;

export const { setNetworkLossEventAction } = networkLossEventSlice.actions;
export const { selectNetworkLossEvent } = networkLossEventSlice.getSelectors<AppState>(state =>
	state.settings.event.networkLossEvent
);
