import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/AlarmEventSettings/AlarmEventSettings.less";
import { Input, ISelectOption, Select, Switch } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectAlarmEvent, setAlarmEventAction } from "Data/Redux/Slices/Settings/Event/Alarm";
import { isEqual } from "lodash";
import { produce } from "immer";
import { Operation } from "Data/Objects/Event";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const operationOptions: ISelectOption[] = [
	{
		label: Operation.NORMALLY_OPEN,
		value: Operation.NORMALLY_OPEN
	},
	{
		label: Operation.NORMALLY_CLOSED,
		value: Operation.NORMALLY_CLOSED
	}
];

interface IAlarmEventForm extends HTMLFormElement {
	alarmName: HTMLInputElement;
	alarmOut: HTMLInputElement;
	snapshot: HTMLInputElement;
	whiteLight: HTMLInputElement;
	redBlueLight: HTMLInputElement;
	audioAlarm: HTMLInputElement;
	recording: HTMLInputElement;
	email: HTMLInputElement;
	ftp: HTMLInputElement;
}

function AlarmEventSettings() {
	const dispatch = useAppDispatch();
	const alarmEventSettings = useAppSelector(selectAlarmEvent);
	const [ localAlarmEventSettings, setLocalAlarmEventSettings ] = useState(alarmEventSettings);
	const {
		name, operation, alarmOut, snapshot, whiteLight, redBlueLight, audioAlarm, recording, email, ftp
	} = localAlarmEventSettings;

	const setAlarmEvent = () => dispatch(setAlarmEventAction(localAlarmEventSettings));
	const disabled = isEqual(localAlarmEventSettings, alarmEventSettings);

	const handleAlarmOutOnChange = (newEnabled: boolean) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.alarmOut = newEnabled;
		}));
	};

	const handleSnapshotOnChange = (newEnabled: boolean) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.snapshot = newEnabled;
		}));
	};

	const handleWhiteLightOnChange = (newEnabled: boolean) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.whiteLight = newEnabled;
		}));
	};

	const handleRedBlueLightOnChange = (newEnabled: boolean) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.redBlueLight = newEnabled;
		}));
	};

	const handleAudioAlarmOnChange = (newEnabled: boolean) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.audioAlarm = newEnabled;
		}));
	};

	const handleRecordingOnChange = (newEnabled: boolean) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.recording = newEnabled;
		}));
	};

	const handleEmailOnChange = (newEnabled: boolean) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.email = newEnabled;
		}));
	};

	const handleFtpChange = (newEnabled: boolean) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.ftp = newEnabled;
		}));
	};

	const handleFormReset = (event: FormEvent<IAlarmEventForm>) => {
		event.preventDefault();

		setLocalAlarmEventSettings(alarmEventSettings);
	};

	const handleFormSubmit = (event: FormEvent<IAlarmEventForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setAlarmEvent();
		}
	};

	const selectOperation = (value: Operation) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.operation = value;
		}));
	};

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="settings-content alarm-settings">
						<SettingsCard size="medium" title="Alarm Event Settings">
							<div className="note-banner event">
								<span>Notification</span>
								<span>&nbsp;-&nbsp;</span>
								<span>
									Actions and notifications require detailed settings on each setting page to work.
								</span>
							</div>
							<div className="alarm-settings-content">
								<div className="form-row">
									<span>Name</span>
									<Input wrapClassName="form-input" name="alarmName" value={ name } />
								</div>
								<div className="form-row">
									<span>Operation</span>
									<Select
										allowClear={ false }
										className="form-select"
										onSelect={ selectOperation }
										options={ operationOptions }
										value={ operation }
									/>
								</div>
								<div className="form-row">
									<span>Alarm Out</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleAlarmOutOnChange } checked={ alarmOut } />
									</div>
								</div>
								<div className="form-row">
									<span>Snapshot</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleSnapshotOnChange } checked={ snapshot } />
									</div>
								</div>
								<div className="form-row">
									<span>White Light</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleWhiteLightOnChange }checked={ whiteLight } />
									</div>
								</div>
								<div className="form-row">
									<span>Red & Blue Light</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleRedBlueLightOnChange } checked={ redBlueLight } />
									</div>
								</div>
								<div className="form-row">
									<span>Audio Alarm</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleAudioAlarmOnChange } checked={ audioAlarm } />
									</div>
								</div>
								<div className="form-row">
									<span>Recording</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleRecordingOnChange } checked={ recording } />
									</div>
								</div>
								<div className="form-row">
									<span>E-Mail</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleEmailOnChange } checked={ email } />
									</div>
								</div>
								<div className="form-row">
									<span>FTP</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleFtpChange } checked={ ftp } />
									</div>
								</div>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(AlarmEventSettings);
