import { createRoot } from "react-dom/client";
import App from "Components/App";
import "Styles/index.less";
import initFontAwesome from "./FontAwesome";
import { Provider } from "react-redux";
import { store } from "Data/Redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "Data/Redux/Persistor";

const appComponent = (
	<Provider store={ store }>
		<PersistGate loading={ null } persistor={ persistor } >
			<App />
		</PersistGate>
	</Provider>
);

initFontAwesome();

createRoot(document.getElementById("app"))
	.render(appComponent);
