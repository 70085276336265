import { memo, useMemo } from "react";
import { IWithChildren } from "Interfaces";
import "Components/Global/AspectMaintainer/AspectMaintainer.less";

interface IAspectMaintainerProps extends IWithChildren {
	aspectRatio?: string;
}

function AspectMaintainer({ aspectRatio = "16:9", children }: IAspectMaintainerProps) {
	const [ width, height ] = useMemo(() => aspectRatio.split(":"), [ aspectRatio ]);

	return (
		<div className="aspect-wrapper-1">
			<div className="aspect-wrapper-2">
				<div className="responsive-container">
					{ children }
				</div>
				<svg
					className="aspect-maintainer"
					viewBox={ `0 0 ${ width } ${ height }` }
				/>
			</div>
			<svg
				className="aspect-maintainer"
				viewBox={ `0 0 ${ width } ${ height }` }
			/>
		</div>
	);
}

export default memo(AspectMaintainer);
