import { memo, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { produce } from "immer";
import { IPoint } from "Interfaces";
import { useResizeCanvas } from "Data/Utils/Effects";
import { pointInsidePolygon } from "Data/Utils/Point";

const pointColor = "rgb(55, 161, 234)";
const hoveredPointColor = "#87c7f2";
const pointRadius = 8;
const aArrowColor = "#33B889";
const bArrowColor = "#F4CE34";
const counterColor = "rgba(204, 204, 204, 0.5)";

interface IInteractiveLineProps {
	arrowA?: boolean;
	arrowB?: boolean;
	count?: number;
	counterEnabled?: boolean;
	counterPoints?: IPoint[];
	points: IPoint[];
	onUpdate: (newPoints: IPoint[]) => void;
	updateCounterPoints?: (newPoints: IPoint[]) => void;
}

const InteractiveLine = (props: IInteractiveLineProps) => {
	const {
		points: statePoints, counterPoints: stateCounterPoints, onUpdate: setPoints, arrowA, arrowB, count,
		updateCounterPoints, counterEnabled
	} = props;

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [ currentMousePos, setCurrentMousePos ] = useState<IPoint>(null);
	const [ hoveredPointIndex, setHoveredPointIndex ] = useState<number>(null);
	const [ draggedPointIndex, setDraggedPointIndex ] = useState<number>(null);
	const [ isDraggingCounter, setIsDraggingCounter ] = useState(false);

	useResizeCanvas(canvasRef);

	const counterPoints = useMemo(() => {
		if (stateCounterPoints?.length > 0) {
			return stateCounterPoints;
		}

		const newPoints: IPoint[] = [
			{ x: 150, y: 150 },
			{ x: 225, y: 150 },
			{ x: 225, y: 170 },
			{ x: 150, y: 170 }
		];

		return newPoints;
	}, [ stateCounterPoints ]);

	const points = useMemo(() => {
		if (statePoints.length > 0) {
			return statePoints;
		}

		const newPoints: IPoint[] = [
			{ x: 150, y: 150 },
			{ x: 300, y: 150 }
		];

		return newPoints;
	}, [ statePoints ]);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");
		const midpoint: IPoint = {
			x: (points[ 0 ].x + points[ 1 ].x) / 2, y: (points[ 0 ].y + points[ 1 ].y) / 2
		};

		const aArrowAngle = Math.atan2(midpoint.y - points[ 0 ].y, midpoint.x - points[ 0 ].x);
		const bArrowAngle = aArrowAngle + Math.PI;
		const aArrowStartingPoint: IPoint = {
			x: midpoint.x, y: midpoint.y
		};

		const arrowStartingPoint: IPoint = {
			x: 15, y: 30
		};

		const aArrowTranslationVector: IPoint = {
			x: aArrowStartingPoint.x - arrowStartingPoint.x, y: aArrowStartingPoint.y - arrowStartingPoint.y
		};

		const arrowPoints: IPoint[] = [
			{ x: 10, y: 30 },
			{ x: 10, y: 15 },
			{ x: 5, y: 15 },
			{ x: 15, y: 5 },
			{ x: 25, y: 15 },
			{ x: 20, y: 15 },
			{ x: 20, y: 30 }
		];

		const aArrowRotationPoint: IPoint = {
			x: arrowStartingPoint.x + aArrowTranslationVector.x,
			y: arrowStartingPoint.y + aArrowTranslationVector.y
		};

		const aArrowPoints = arrowPoints.map((point) => ({
			x: point.x + aArrowTranslationVector.x, y: point.y + aArrowTranslationVector.y
		}));

		// Prevents having to put user-select: none; on literally everything.
		canvas.onselectstart = () => false;

		context.strokeStyle = pointColor;
		context.lineWidth = 2;
		context.clearRect(0, 0, canvas.width, canvas.height);

		if (points.length > 1) {
			context.beginPath();
			context.moveTo(points[0].x, points[0].y);
			points.forEach(point => context.lineTo(point.x, point.y));
			context.closePath();
			context.stroke();
		}

		points.forEach((point, index) => {
			context.beginPath();
			context.fillStyle = index === hoveredPointIndex ? hoveredPointColor : pointColor;
			context.arc(point.x, point.y, pointRadius, 0 ,2 * Math.PI);
			context.fill();
		});

		if (arrowA) {
			context.beginPath();
			context.fillStyle = aArrowColor;
			context.moveTo(aArrowStartingPoint.x, aArrowStartingPoint.y);
			context.translate(aArrowRotationPoint.x, aArrowRotationPoint.y);
			context.rotate(aArrowAngle);
			context.translate(-aArrowRotationPoint.x, -aArrowRotationPoint.y);
			aArrowPoints.forEach(point => context.lineTo(point.x, point.y));
			context.translate(aArrowRotationPoint.x, aArrowRotationPoint.y);
			context.rotate(-aArrowAngle);
			context.translate(-aArrowRotationPoint.x, -aArrowRotationPoint.y);
			context.fill();
		}

		if (arrowB) {
			context.beginPath();
			context.fillStyle = bArrowColor;
			context.moveTo(aArrowStartingPoint.x, aArrowStartingPoint.y);
			context.translate(aArrowRotationPoint.x, aArrowRotationPoint.y);
			context.rotate(bArrowAngle);
			context.translate(-aArrowRotationPoint.x, -aArrowRotationPoint.y);
			aArrowPoints.forEach(point => context.lineTo(point.x, point.y));
			context.translate(aArrowRotationPoint.x, aArrowRotationPoint.y);
			context.rotate(-bArrowAngle);
			context.translate(-aArrowRotationPoint.x, -aArrowRotationPoint.y);
			context.fill();
		}

		if (counterPoints.length > 1 && counterEnabled) {
			context.beginPath();
			context.moveTo(counterPoints[0].x, counterPoints[0].y);
			counterPoints.forEach(point => context.lineTo(point.x, point.y));
			context.closePath();
			context.fillStyle = counterColor;
			context.fill();

			context.beginPath();
			context.fillStyle = "#000000";
			context.font = "20px sans-serif";
			context.fillText(`${ count }`, counterPoints[ 3 ].x + 3, counterPoints[ 3 ].y - 3 );
		}
	}, [ points, hoveredPointIndex, currentMousePos, arrowA, arrowB, counterPoints, count, counterEnabled ]);

	const handleMouseDown = (event: MouseEvent) => {
		if (event.buttons & 1) {
			const rect = canvasRef.current.getBoundingClientRect();
			const clickPoint: IPoint = {
				x: event.clientX - rect.left,
				y: event.clientY - rect.top
			};

			const pointIndex = points.findIndex(point =>
				Math.hypot(point.x - clickPoint.x, point.y - clickPoint.y) <= pointRadius
			);

			if (pointIndex !== -1) {
				setDraggedPointIndex(pointIndex);
			}

			if (pointInsidePolygon(clickPoint, counterPoints)) {
				setIsDraggingCounter(true);
				setCurrentMousePos(clickPoint);
			}
		}
	};

	const handleMouseMove = (event: MouseEvent) => {
		const rect = canvasRef.current.getBoundingClientRect();
		const mousePos: IPoint = {
			x: event.clientX - rect.left,
			y: event.clientY - rect.top
		};

		setCurrentMousePos(mousePos);

		if (draggedPointIndex === null) {
			const foundPoint = points.findIndex(point =>
				Math.hypot(point.x - mousePos.x, point.y - mousePos.y) <= pointRadius
			);

			setHoveredPointIndex(foundPoint !== -1 ? foundPoint : null);
		} else if (draggedPointIndex !== null) {
			const newPoints = produce(points, draft => {
				draft[ draggedPointIndex ] = mousePos;
			});

			setPoints(newPoints);
		}

		if (isDraggingCounter) {
			const deltaX = mousePos.x - currentMousePos.x;
			const deltaY = mousePos.y - currentMousePos.y;
			const newPoints = counterPoints.map(point => ({
				x: point.x + deltaX,
				y: point.y + deltaY
			}));

			const isOutsideCanvas = newPoints.some(point => {
				return point.x > canvasRef.current.width || point.x < 0
					|| point.y < 0 || point.y > canvasRef.current.height;
			});

			if (!isOutsideCanvas) {
				updateCounterPoints?.(newPoints);
			}
		}
	};

	const handleMouseUp = () => {
		setDraggedPointIndex(null);
		setIsDraggingCounter(false);
	};

	return (
		<canvas
			className="interactive-canvas"
			ref={ canvasRef }
			onMouseDown={ handleMouseDown }
			onMouseMove={ handleMouseMove }
			onMouseUp={ handleMouseUp }
		/>
	);
};

export default memo(InteractiveLine);
