import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IEmailSettingsState } from "Interfaces";

const initialState: IEmailSettingsState = {
	server: "smtp-mail.outlook.com",
	port: 25,
	security: false,
	user: "username842@outlook.com",
	testEmailAddress: "testname@clintonelectronics.com"
};

export const setEmailReducer = (state: IEmailSettingsState, action: PayloadAction<IEmailSettingsState>) => {
	return action.payload;
};

export const getEmail = (state: IEmailSettingsState) => {
	return state;
};

const emailSlice = createAppSlice({
	name: "email",
	initialState,
	reducers: {
		setEmailAction: setEmailReducer
	},
	selectors: {
		selectEmail: getEmail
	}
});

export default emailSlice;

export const { setEmailAction } = emailSlice.actions;
export const { selectEmail } = emailSlice.getSelectors<AppState>(state => state.settings.network.email);
