import { createDraftSafeSelector, PayloadAction } from "@reduxjs/toolkit";
import { DUMMY_FILTER } from "Data/Dummy/Filters";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { produce } from "immer";
import {
	CertificateType, EapType, HttpAuthenticationType, INetworkSecurityState, IpFilterRule,
	RtspAuthenticationType
} from "Interfaces";

const initialState: INetworkSecurityState = {
	securityOptions: {
		rtspAuthType: RtspAuthenticationType.OFF,
		httpsEnabled: false,
		httpAuthType: HttpAuthenticationType.BASIC
	},
	ipFiltering: {
		ipFilterEnabled: false,
		ipFilterRule: IpFilterRule.ALLOW,
		filters: [ DUMMY_FILTER ]
	},
	ieeeOptions: {
		eapolVersion: 1,
		eapType: EapType.TLS,
		enabled: true,
		id: ""
	},
	certificates:[
		{
			type: CertificateType.CA,
			name: ""
		},
		{
			type: CertificateType.CLIENT,
			name: ""
		},
		{
			type: CertificateType.KEY,
			name: ""
		}
	]
};

// #region Reducers

export const setNetworkSecuritySettingsReducer = (
	state: INetworkSecurityState, action: PayloadAction<INetworkSecurityState>
) => {
	if (!action.payload) {
		return;
	}

	return produce(action.payload, draft => {
		delete draft.ieeeOptions.password;
		delete draft.ieeeOptions.clientKeyPassword;
	});
};

// #endregion

// #region Selectors

const getNetworkSecuritySettings = (state: INetworkSecurityState): INetworkSecurityState => {
	return produce(state, draft => {
		draft.ieeeOptions.password = "";
		draft.ieeeOptions.clientKeyPassword = "";
	});
};

// #endregion

const networkSecuritySlice = createAppSlice({
	name: "security",
	initialState,
	reducers: {
		setNetworkSecuritySettingsAction: setNetworkSecuritySettingsReducer
	},
	selectors: {
		selectNetworkSecuritySettings: createDraftSafeSelector(
			(state) => state,
			getNetworkSecuritySettings
		)
	}
});

export default networkSecuritySlice;

export const { setNetworkSecuritySettingsAction } = networkSecuritySlice.actions;

export const {
	selectNetworkSecuritySettings
} = networkSecuritySlice.getSelectors<AppState>(state => state.settings.network.security);
