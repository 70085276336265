// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-time-picker {
  align-self: center;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/PlaybackLayout/DateTimePicker/DateTimePicker.less"],"names":[],"mappings":"AAEA;EACC,kBAAA;EACA,gBAAA;AADD","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.date-time-picker {\n\talign-self: center;\n\tmargin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
