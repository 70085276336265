import {
	configureStore, createAsyncThunk as createAsyncThunkBase, DevToolsEnhancerOptions,
	EnhancedStore, Reducer, StoreEnhancer, Tuple, UnknownAction, ThunkDispatch,
	buildCreateSlice, asyncThunkCreator
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector as reduxUseSelector, useDispatch as reduxUseDispatch } from "react-redux";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { AppState } from "Data/Redux/Reducers";

export type ThunkApiConfig = { state: AppState };

export let store: EnhancedStore<AppState, UnknownAction, Tuple<[StoreEnhancer<{
	dispatch: ThunkDispatch<AppState, undefined, UnknownAction>; }>, StoreEnhancer]>>;

export const buildStore = (reducer: Reducer, devTools: DevToolsEnhancerOptions, middleware) => {
	store = configureStore({
		reducer: reducer,
		devTools,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ]
			}
		})
			.prepend(middleware)
	});
};

export const createAsyncThunk = createAsyncThunkBase.withTypes<ThunkApiConfig>();
export const useAppSelector: TypedUseSelectorHook<AppState> = reduxUseSelector;
export const useAppDispatch: () => typeof store.dispatch = reduxUseDispatch;
export const createAppSlice = buildCreateSlice({
	creators: { asyncThunk: asyncThunkCreator }
});
