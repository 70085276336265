// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-time-form {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.date-time-form .container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding: 30px;
}
.date-time-form .container .card {
  background-color: white;
  border-radius: 8px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px 20px;
  width: 500px;
}
.date-time-form .container .card .header {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}
.date-time-form .container .card .header h4 {
  margin-block-end: 5px;
}
.date-time-form .container .card .form-row {
  align-items: center;
  display: flex;
}
.date-time-form .container .card .form-row:not(:last-child) {
  margin-bottom: 8px;
}
.date-time-form .container .card .form-row > span {
  color: #7e7e7e;
}
.date-time-form .container .card .form-row .date-time-setup-picker,
.date-time-form .container .card .form-row .date-time-button,
.date-time-form .container .card .form-row .date-time-input,
.date-time-form .container .card .form-row .date-time-select {
  margin-left: auto;
  width: 50%;
}
.date-time-form .container .card .form-row .date-time-setup-picker > .cec-date-picker-wrapper {
  width: 100%;
}
.date-time-form .container .card .form-row .date-time-setup-picker > .cec-date-picker-wrapper .rs-input-group {
  border-color: #b6b8bc;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/System/DateTimeSettings/DateTimeSettings.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;AADD;AAFA;EAME,mBAAA;EACA,aAAA;EACA,OAAA;EACA,sBAAA;EACA,SAAA;EACA,cAAA;EACA,aAAA;AADF;AAXA;EAeG,uBAAA;EACA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,oBAAA;EACA,YAAA;AADH;AAlBA;EAsBI,gCAAA;EACA,mBAAA;AADJ;AAtBA;EA0BK,qBAAA;AADL;AAzBA;EA+BI,mBAAA;EACA,aAAA;AAHJ;AAKI;EACC,kBAAA;AAHL;AAhCA;EAuCK,cAAA;AAJL;AAnCA;;;;EA8CK,iBAAA;EACA,UAAA;AALL;AA1CA;EAoDM,WAAA;AAPN;AA7CA;EAuDO,qBAAA;AAPP","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.date-time-form {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\n\t.container {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tflex-direction: column;\n\t\tgap: 30px;\n\t\toverflow: auto;\n\t\tpadding: 30px;\n\n\t\t.card {\n\t\t\tbackground-color: white;\n\t\t\tborder-radius: 8px;\n\t\t\theight: fit-content;\n\t\t\tpadding: 0 20px 20px;\n\t\t\twidth: 500px;\n\n\t\t\t.header {\n\t\t\t\tborder-bottom: 1px solid @light-gray-1;\n\t\t\t\tmargin-bottom: 20px;\n\n\t\t\t\th4 {\n\t\t\t\t\tmargin-block-end: 5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.form-row {\n\t\t\t\talign-items: center;\n\t\t\t\tdisplay: flex;\n\n\t\t\t\t&:not(:last-child) {\n\t\t\t\t\tmargin-bottom: 8px;\n\t\t\t\t}\n\n\t\t\t\t> span {\n\t\t\t\t\tcolor: @light-gray-2;\n\t\t\t\t}\n\n\t\t\t\t.date-time-setup-picker,\n\t\t\t\t.date-time-button,\n\t\t\t\t.date-time-input,\n\t\t\t\t.date-time-select {\n\t\t\t\t\tmargin-left: auto;\n\t\t\t\t\twidth: 50%;\n\t\t\t\t}\n\n\t\t\t\t.date-time-setup-picker {\n\t\t\t\t\t> .cec-date-picker-wrapper {\n\t\t\t\t\t\twidth: 100%;\n\n\t\t\t\t\t\t.rs-input-group {\n\t\t\t\t\t\t\tborder-color: @gray-0\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
