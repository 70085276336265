import { Button, ButtonTypes, Input, Modal } from "@clintonelec/react-storybook";
import { emailRegexObj } from "Data/Objects/Event";
import { FormEvent, memo, ReactNode, useState } from "react";

interface IEmailEntryModalProps {
	children: ReactNode;
	emailEnabled: (email: string) => boolean;
	handleEmailEntryCallback: (email: string, oldEmail?: string) => void;
	oldEmail?: string;
}

function EmailEntryModal(props: IEmailEntryModalProps) {
	const { children, handleEmailEntryCallback, emailEnabled, oldEmail } = props;
	const [ email, setEmail ] = useState<string>("");
	const [ visible, setVisible ] = useState(false);
	const isEnabled = emailRegexObj.test(email) && emailEnabled(email);
	const modalTitle = oldEmail ? "Edit Email" : "Add Email";

	const handleVisibilityChanged = (newVisibility: boolean) => {
		setEmail(null);
		setVisible(newVisibility);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (event.currentTarget?.checkValidity()) {
			handleEmailEntryCallback(email, oldEmail);
			setEmail("");
			setVisible(false);
		}
	};

	const modalContent = (
		<form
			className="event-notification-modal"
			noValidate
			onSubmit={ handleSubmit }
		>
			<Input
				onUpdate={ setEmail }
				validator={ emailRegexObj }
				validityMessage="Incorrect E-Mail format."
				value={ email ?? oldEmail }
			/>
			<div className="modal-footer">
				<Button
					disabled={ !isEnabled }
					icon={ { name: "pencil" } }
					type={ ButtonTypes.TERTIARY }
				>
					Submit
				</Button>
			</div>
		</form>
	);

	return (
		<Modal
			modalContent={ modalContent }
			onVisibilityChange={ handleVisibilityChanged }
			title={ modalTitle }
			visible={ visible }
		>
			{ children }
		</Modal>
	);
}

export default memo(EmailEntryModal);
