import { createAppSlice } from "Data/Redux/Store";
import { PayloadAction } from "@reduxjs/toolkit";
import { IAiAnalyticsState } from "Interfaces";
import { AppState } from "Data/Redux/Reducers";
import { StaticObjectFilter, TrackReferencePoint } from "Data/Objects/Camera";

const initialState: IAiAnalyticsState = {
	enabled: false,
	rules: Array.from(Array(8)),
	selectedIndex: 1,
	staticObjectFilter: StaticObjectFilter.OFF,
	trackReferencePoint: TrackReferencePoint.CENTER,
	minSizeFilterEnabled: false,
	maxSizeFilterEnabled: false,
	minSizeFilter: [],
	maxSizeFilter: []
};

export const setAiAnalyticsReducer = (state: IAiAnalyticsState, action: PayloadAction<IAiAnalyticsState>) => {
	return action.payload;
};

export const getAiAnalytics = (state: IAiAnalyticsState) => {
	return state;
};

const aiAnalyticsSlice = createAppSlice({
	name: "aiAnalytics",
	initialState,
	reducers: {
		setAiAnalyticsAction: setAiAnalyticsReducer
	},
	selectors: {
		selectAiAnalytics: getAiAnalytics
	}
});

export default aiAnalyticsSlice;

export const { setAiAnalyticsAction } = aiAnalyticsSlice.actions;
export const { selectAiAnalytics } = aiAnalyticsSlice
	.getSelectors<AppState>(state => state.settings.camera.aiAnalytics);
