import { PayloadAction } from "@reduxjs/toolkit";
import { IIpv6RadioSettings } from "Data/Objects/Network";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import {
	INetworkIpSettingsState, INetworkIpv4Settings, INetworkIpv6Settings, IServicePortSettings
} from "Interfaces";

const placeholderIpv4Settings: INetworkIpv4Settings = {
	dhcp: true,
	ipv4Address: "10.1.6.120",
	subnetMask: "255.255.255.0",
	ipv4Gateway: "10.1.6.1",
	dnsIpv4: [
		"10.1.3.23",
		"0.0.0.0"
	]
};

const placeholderIpv6Settings: INetworkIpv6Settings = {
	ipv6Radio: IIpv6RadioSettings.OFF,
	linkLocalAddress: "",
	ipv6AddressInfo: [
		{ address: "", cidr: 0 },
		{ address: "", cidr: 0 },
		{ address: "", cidr: 0 },
		{ address: "", cidr: 0 }
	],
	ipv6Gateway: "",
	dnsIpv6: [ "", "" ]
};

const placeholderServicePortSettings: IServicePortSettings = {
	httpPort: 80,
	rtspPort: 554,
	httpsPort: 443
};

const initialState: INetworkIpSettingsState = {
	ipv4Settings: placeholderIpv4Settings,
	ipv6Settings: placeholderIpv6Settings,
	servicePortSettings: placeholderServicePortSettings
};

// #region reducers
export const setIpSettingsReducer = (
	state: INetworkIpSettingsState,
	action: PayloadAction<INetworkIpSettingsState>
) => {
	if (!action.payload) {
		return;
	}

	return action.payload;
};

// #endregion

// #region selectors
const getIpSettings = (state: INetworkIpSettingsState) => {
	return state;
};

// #endregion

const ipSettingsSlice = createAppSlice({
	name: "ipSettings",
	initialState,
	reducers: {
		setIpSettingsAction: setIpSettingsReducer
	},
	selectors: {
		selectIpSettings: getIpSettings
	}
});

export default ipSettingsSlice;

export const { setIpSettingsAction } = ipSettingsSlice.actions;
export const { selectIpSettings } = ipSettingsSlice.getSelectors<AppState>(state =>
	state.settings.network.ipSettings
);
