import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { ISystemManagementState } from "Interfaces";

const initialState: ISystemManagementState = {
	deviceName: "",
	paswordExpiryTerm: 1,
	systemId: ""
};

// #region Reducers

const setSystemManagementReducer = (state: ISystemManagementState, action: PayloadAction<ISystemManagementState>) => {
	if (!action.payload) {
		return;
	}

	return action.payload;
};

// #endregion

// #region Selectors

const getSystemManagementSettings = (state: ISystemManagementState) => {
	return state;
};

// #endregion

const systemManagementSlice = createAppSlice({
	name: "systemManagement",
	initialState,
	reducers: {
		setSystemManagementAction: setSystemManagementReducer
	},
	selectors: {
		selectSystemManagementSettings: getSystemManagementSettings
	}
});

export default systemManagementSlice;

export const { setSystemManagementAction } = systemManagementSlice.actions;
export const { selectSystemManagementSettings } = systemManagementSlice.getSelectors<AppState>(
	state => state.settings.system.systemManagement
);
