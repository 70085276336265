export enum Categories {
	CAMERA = "CAMERA",
	AUDIO = "AUDIO",
	USER = "USER",
	NETWORK = "NETWORK",
	SYSTEM = "SYSTEM",
	STORAGE = "STORAGE",
	EVENT = "EVENT",
	RECORD = "RECORD"
}

export enum CameraSubCategories {
	CODEC = "Codec",
	IMAGE = "Image",
	ROI = "ROI",
	MOTION = "Motion",
	MASK = "Mask",
	EMBEDDED_OSD = "Embedded OSD",
	TAMPER = "Tamper",
	ANALYTICS = "Analytics"
}

export enum AudioSubCategories {
	AUDIO = "Audio"
}

export enum UserSubCategories {
	MANAGEMENT = "Management",
	AUTHORITY = "Authority"
}

export enum NetworkSubCategories {
	IP_SETUP = "IP Setup",
	UPNP = "UPnP",
	MULTICAST = "Multicast",
	SECURITY = "Security",
	SNMP = "SNMP"
}

export enum SystemSubCategories {
	DATETIME = "Date / Time",
	MANAGEMENT = "Management",
	INFORMATION = "Information",
	LANGUAGE = "Language",
	SECURITY = "Security",
	SCHEDULE = "Schedule"
}

export enum StorageSubCategories {
	SD_CARD = "SD Card"
}

export enum EventSubCategories {
	ACTION = "Action",
	EMAILFTP = "Email/FTP",
	ALARM = "Alarm",
	MOTION = "Motion",
	TAMPER = "Tamper",
	NETWORK_LOSS = "Network Loss",
	SYSTEM = "System",
	AI_ANALYTICS = "AI Analytics"
}

export enum RecordSubCategories {
	SETUP = "Setup"
}

export const Menus = {
	[ Categories.CAMERA ]: CameraSubCategories,
	[ Categories.AUDIO ]: AudioSubCategories,
	[ Categories.USER ]: UserSubCategories,
	[ Categories.NETWORK ]: NetworkSubCategories,
	[ Categories.SYSTEM ]: SystemSubCategories,
	[ Categories.STORAGE ]: StorageSubCategories,
	[ Categories.EVENT ]: EventSubCategories,
	[ Categories.RECORD ]: RecordSubCategories
};
