import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Camera/MotionSettings/MotionSettings.less";
import {
	AspectMaintainer, Button, ButtonTypes, ISelectOption, Select
} from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectMotionSettings, setMotionSettingsAction } from "Data/Redux/Slices/Settings/Camera/Motion";
import InteractivePolygon from "Components/Global/InteractivePolygon";
import { produce } from "immer";
import { IPoint } from "Interfaces";
import { compact, isEqual } from "lodash";
import VideoMotionControls from "Components/ConfigLayout/Camera/MotionSettings/VideoMotionControls";
import SmartMotionControls from "Components/ConfigLayout/Camera/MotionSettings/SmartMotionControls";
import { MotionMode, MotionObject } from "Data/Objects/Camera";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

interface IMotionSettingsForm extends HTMLFormElement {
	bikeConfidence: HTMLInputElement;
	bikeConfidenceLevel: HTMLInputElement;
	daySensitivityLevel: HTMLInputElement;
	motionMode: HTMLInputElement;
	nightSensitivityLevel: HTMLInputElement;
	personConfidence: HTMLInputElement;
	personConfidenceLevel: HTMLInputElement;
	vehicleConfidence: HTMLInputElement;
	vehicleConfidenceLevel: HTMLInputElement;
	zones: HTMLInputElement[][];
}

const motionModeOptions: ISelectOption[] = [
	{
		label: "Video Motion",
		value: MotionMode.VIDEO
	},
	{
		label: "Smart Motion",
		value: MotionMode.SMART
	}
];

function MotionSettings() {
	const dispatch = useAppDispatch();
	const motionSettings = useAppSelector(selectMotionSettings);
	const [ localMotionSettings, setLocalMotionSettings ] = useState(motionSettings);
	const [ selectedZone, setSelectedZone ] = useState<number>(null);
	const setMotionSettings = () => dispatch(setMotionSettingsAction(localMotionSettings));
	const actionButtonDisabled = (
		isEqual(motionSettings, localMotionSettings)
		|| localMotionSettings.selectedMotionObjects.length === 0
	);

	const allZonesEmpty = localMotionSettings.zones.every(zone => zone.length === 0);

	const handleFormReset = (event: FormEvent<IMotionSettingsForm>) => {
		event.preventDefault();

		setLocalMotionSettings(motionSettings);
	};

	const handleFormSubmit = (event: FormEvent<IMotionSettingsForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setMotionSettings();
		}
	};

	const handleFormChange = (event: FormEvent<IMotionSettingsForm>) => {
		const { personConfidence, vehicleConfidence, bikeConfidence } = event.currentTarget;
		const newLocalSettings = produce(localMotionSettings, draft => {
			draft.selectedMotionObjects = compact([
				personConfidence.checked ? MotionObject.PERSON : null,
				vehicleConfidence.checked ? MotionObject.VEHICLE : null,
				bikeConfidence.checked ? MotionObject.BIKE : null
			]);
		});

		setLocalMotionSettings(newLocalSettings);
	};

	const handleClearActiveZone = () => {
		if (selectedZone === null) {
			return;
		}

		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.zones[ selectedZone ] = [];
		}));

		setSelectedZone(null);
	};

	const handleClearAllZones = () => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.zones = [ [], [], [], [] ];
		}));

		setSelectedZone(null);
	};

	const handleMotionModeSelect = (value: MotionMode) => {
		const newLocalState = produce(localMotionSettings, draft => {
			draft.motionMode = value;
		});

		setLocalMotionSettings(newLocalState);
	};

	const handlePointsUpdate = (newPoints: IPoint[]) => {
		const newLocalState = produce(localMotionSettings, draft => {
			draft.zones[ selectedZone ] = newPoints;
		});

		setLocalMotionSettings(newLocalState);
	};

	const handleZoneButtonClick = (zoneIndex: number) => () => {
		setSelectedZone(selectedZone === zoneIndex ? null : zoneIndex);
	};

	const renderInteractivePolygon = () => {
		if (selectedZone === null) {
			return;
		}

		return (
			<InteractivePolygon
				points={ localMotionSettings.zones[ selectedZone ] }
				onUpdate={ handlePointsUpdate }
			/>
		);
	};

	const renderZoneButtons = () => Array.from(Array(4)).map((current, index) => {
		const selectedButton = selectedZone === index;
		const filled = !selectedButton && localMotionSettings.zones[ index ]?.length > 0;

		return (
			<Button
				// eslint-disable-next-line react/no-array-index-key
				key={ `zone-button-${ index }` }
				className="zone-button"
				ghost={ selectedZone !== index && !filled }
				htmlType="button"
				onClick={ handleZoneButtonClick(index) }
				type={ selectedButton ? ButtonTypes.TERTIARY : ButtonTypes.SECONDARY }
			>
				Zone { index + 1 }
			</Button>
		);
	});

	return (
		<form
			className="settings-form"
			onChange={ handleFormChange }
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="motion-settings settings-content">
						<SettingsCard size="large" title="Motion Settings">
							<div className="card-content-container">
								<div className="left-content">
									<div className="camera-container">
										<AspectMaintainer>
											<div className="camera-snapshot">
												{ renderInteractivePolygon() }
											</div>
										</AspectMaintainer>
									</div>
									<div className="controls-container">
										<div className="zone-buttons">
											{ renderZoneButtons() }
										</div>
										<Button
											className="zone-action-button"
											disabled={ selectedZone == null }
											ghost
											htmlType="button"
											onClick={ handleClearActiveZone }
											type={ ButtonTypes.SECONDARY }
										>
											Clear Active Zone
										</Button>
										<Button
											className="zone-action-button"
											disabled={ allZonesEmpty }
											ghost
											htmlType="button"
											onClick={ handleClearAllZones }
											type={ ButtonTypes.SECONDARY }
										>
											Clear All Zones
										</Button>
									</div>
								</div>
								<div className="right-content">
									<div className="motion-mode-settings">
										<div className="motion-setting-row">
											<span>Motion Mode</span>
											<Select
												allowClear={ false }
												className="motion-mode-select"
												onSelect={ handleMotionModeSelect }
												options={ motionModeOptions }
												value={ localMotionSettings.motionMode }
											/>
										</div>
										<SmartMotionControls
											localMotionSettings={ localMotionSettings }
											setLocalMotionSettings={ setLocalMotionSettings }
										/>
										<VideoMotionControls
											localMotionSettings={ localMotionSettings }
											setLocalMotionSettings={ setLocalMotionSettings }
										/>
									</div>
								</div>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ actionButtonDisabled } />
		</form>
	);
}

export default memo(MotionSettings);
