import { IPlaybackState, ZoomLevel } from "Interfaces";
import { createAppSlice } from "Data/Redux/Store";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState: IPlaybackState = {
	date: null,
	zoomLevel: ZoomLevel.SIXTY_MINUTES
};

export const setDateReducer = (state: IPlaybackState, action: PayloadAction<number>) => {
	state.date = action.payload;
};

const setZoomLevelReducer = (state: IPlaybackState, action: PayloadAction<ZoomLevel>) => {
	state.zoomLevel = action.payload;
};

export const getDate = (state: IPlaybackState) => {
	return state.date ? new Date(state.date) : null;
};

const getZoomLevel = (state: IPlaybackState) => {
	return state.zoomLevel;
};

const playbackSlice = createAppSlice({
	name: "playback",
	initialState,
	reducers: (create) => ({
		setDateAction: create.reducer(setDateReducer),
		setZoomLevelAction: create.reducer(setZoomLevelReducer)
	}),
	selectors: {
		selectDate: getDate,
		selectZoomLevel: getZoomLevel
	}
});

export default playbackSlice;

export const { setDateAction, setZoomLevelAction } = playbackSlice.actions;
export const { selectDate, selectZoomLevel } = playbackSlice.selectors;
