// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-management-content {
  width: 100%;
}
.filter-management-content .cec-input-wrapper,
.filter-management-content .cec-select {
  margin-bottom: 15px;
}
.cec-modal-title > span {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Network/SecuritySettings/FilterManagementModal/FilterManagementModal.less"],"names":[],"mappings":"AAEA;EACC,WAAA;AADD;AAAA;;EAIE,mBAAA;AAAF;AAIA;EACC,iBAAA;AAFD","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.filter-management-content {\n\twidth: 100%;\n\n\t.cec-input-wrapper, .cec-select {\n\t\tmargin-bottom: 15px;\n\t}\n}\n\n.cec-modal-title > span {\n\tmargin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
