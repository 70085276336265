import { ISelectOption, Select } from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import "Components/ConfigLayout/Network/FtpSettings/FtpSettings.less";
import { selectFtp, setFtpAction } from "Data/Redux/Slices/Settings/Network/Ftp";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { produce } from "immer";
import { IFtpSettingsState } from "Interfaces";
import { isEqual, merge } from "lodash";
import { FormEvent, memo, useState } from "react";

const ftpOptions: ISelectOption[] = [
	{
		label: "Off",
		value: "OFF"
	},
	{
		label: "On",
		value: "ON"
	}
];

function FtpSettings() {
	const dispatch = useAppDispatch();
	const ftpSettings = useAppSelector(selectFtp);
	const [ ftpSettingsDiff, setFtpSettingsDiff ] = useState<Partial<IFtpSettingsState>>({});

	const setFtpSettings = (newSettings: IFtpSettingsState) => {
		dispatch(setFtpAction(newSettings));
	};

	const localFtpSettings = produce(ftpSettings, draft => {
		merge(draft, ftpSettingsDiff);
	});

	const disabled = isEqual(localFtpSettings, ftpSettings);

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setFtpSettingsDiff({});
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setFtpSettings(localFtpSettings);
		setFtpSettingsDiff({});
	};

	const selectFtpServer = (value: string) => {
		setFtpSettingsDiff(produce(localFtpSettings, draft => {
			draft.ftp = value === "ON";
		}));
	};

	return (
		<form className="ftp-settings-form" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="container">
				<div className="card">
					<div className="header">
						<h4>Server Configuration</h4>
					</div>
					<div className="form-row">
						<span>FTP Server</span>
						<Select
							allowClear={ false }
							className="ftp-select"
							onSelect={ selectFtpServer }
							options={ ftpOptions }
							value={ localFtpSettings.ftp ? "ON" : "OFF" }
						/>
					</div>
					<span className="message">If you want to access the local storage remotely, enable this.</span>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(FtpSettings);
