export enum Platforms {
	WINDOWS = "WINDOWS",
	MAC = "MAC"
}

export enum Environments {
	PRODUCTION = "PRODUCTION",
	DEVELOPMENT = "DEVELOPMENT"
}

export function currentPlatform() {
	return navigator.platform.toUpperCase().indexOf("MAC") >= 0 ? Platforms.MAC : Platforms.WINDOWS;
}
