import { memo } from "react";
import "Components/ConfigLayout/User/UserValidation/UserValidation.less";
import { passwordConsecutiveRegex, idLengthRegex, passwordCharactersRegex, passwordLengthRegex } from "Data/Utils/User";

interface IUserValidationProps {
	userId: string;
	password: string;
	isDuplicateUserId: boolean;
}

function UserValidator(props: IUserValidationProps) {
	const { userId, password, isDuplicateUserId } = props;

	const getValidityClass = (isValid: boolean) => {
		return isValid ? "valid" : "invalid";
	};

	const getDisabledClass = () => {
		return password.length === 0 ? "disabled" : null;
	};

	const passwordConsecutiveCompositeClass = [
		getValidityClass(passwordConsecutiveRegex.test(password)),
		getDisabledClass()
	].join(" ");

	const passwordLengthCompositeClass = [
		getValidityClass(passwordLengthRegex.test(password)),
		getDisabledClass()
	].join(" ");

	const passwordCharactersCompositeClass = [
		getValidityClass(passwordCharactersRegex.test(password)),
		getDisabledClass()
	].join(" ");

	return (
		<div className="user-validation">
			<div className="instructions">
				The ID and Password must meet the following rules:
			</div>
			<p className={ getValidityClass(idLengthRegex.test(userId)) }>
				1. Only IDs of 5 characters or more and 10 characters or less can be used.
			</p>
			<p className={ passwordConsecutiveCompositeClass }>
				2. Password can not contain more than 3 consecutive numbers or letters.
			</p>
			<p className={ passwordLengthCompositeClass }>
				3. Password must be greater than 8 characters and less than 16 characters long.
			</p>
			<p className={ passwordCharactersCompositeClass }>
				4. Password must include at least one capital letter, lower-case letter, number and special character.
			</p>
			<p className={ getValidityClass(userId !== password) }>
				5. The ID and PW should be different.
			</p>
			<p className={ getValidityClass(isDuplicateUserId && userId.length > 0) }>
				6. The User ID must be unique, duplicate user IDs are not allowed.
			</p>
		</div>
	);
}

export default memo(UserValidator);
