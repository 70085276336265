import { memo, useRef } from "react";
import "Components/PlaybackLayout/VideoContainer/VideoContainer.less";
import VideoPlayer from "Components/Global/VideoPlayer";
import { useAppSelector } from "Data/Redux/Store";
import { selectDate } from "Data/Redux/Slices/Playback";
import AspectMaintainer from "Components/Global/AspectMaintainer";

function VideoContainer() {
	const selectedDate = useAppSelector(selectDate);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	let content = (
		<VideoPlayer
			canvasRef={ canvasRef }
		/>
	);

	if (!selectedDate) {
		content = (
			<AspectMaintainer>
				<div className="no-video">
					<p>Select Date to Load Video.</p>
				</div>
			</AspectMaintainer>
		);
	}

	return (
		<div className="playback-video-container">
			{ content }
		</div>
	);
}

export default memo(VideoContainer);
