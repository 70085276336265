import "Components/ConfigLayout/Network/MulticastSettings/MulticastSettings.less";
import { FormEvent, memo, useState } from "react";
import { Input } from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { IMulticastSettings } from "Interfaces";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import {
	setMulticastSettingsAction, selectMulticastSettings
} from "Data/Redux/Slices/Settings/Network/MulticastSettings";
import { isEqual, merge } from "lodash";
import { ipv4Regex, ipv6Regex } from "@clintonelec/typescriptutils";
import { produce } from "immer";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

export interface IMulticastSettingsFormFields extends HTMLFormElement {
	startPort: HTMLInputElement;
	endPort: HTMLInputElement;
	videoAddressIpv4: HTMLInputElement[];
	audioAddressIpv4: HTMLInputElement[];
	multicastAddressIpv4: HTMLInputElement[];
	videoAddressIpv6: HTMLInputElement[];
	audioAddressIpv6: HTMLInputElement[];
	multicastAddressIpv6: HTMLInputElement[];
	videoPort: HTMLInputElement[];
	audioPort: HTMLInputElement[];
	multicastPort: HTMLInputElement[];
	videoTtl: HTMLInputElement[];
	audioTtl: HTMLInputElement[];
	multicastTtl: HTMLInputElement[];
}

const multicastSetupLabels = [
	"Video address (IPv4)",
	"Audio address (IPv4)",
	"Multicast address (IPv4)",
	"Video address (IPv6)",
	"Audio address (IPv6)",
	"Multicast address (IPv6)",
	"Video Port",
	"Audio Port",
	"Multicast Port",
	"Video TTL",
	"Audio TTL",
	"Multicast TTL"
];

const validateMulticastAddress = (propName: string) => (value: string) => {
	let regExpString;

	if (propName.includes("Ipv4")) {
		regExpString = ipv4Regex;
	}

	if (propName.includes("Ipv6")) {
		regExpString = ipv6Regex;
	}

	return new RegExp(regExpString).test(value);
};

function MulticastSettings() {
	const multicastSettings = useAppSelector(selectMulticastSettings);
	const [ multicastSettingsDiff, setMulticastSettingsDiff ] =
		useState<Partial<IMulticastSettings>>({});

	const localMulticastSettings = produce(multicastSettings, (draft) => {
		merge(draft, multicastSettingsDiff);
	});

	const { portRange: { startPort: startPort, endPort: endPort }, networks } = localMulticastSettings;
	const dispatch = useAppDispatch();

	const setMulticast = () => {
		dispatch(setMulticastSettingsAction(localMulticastSettings));
	};

	const handleFormSubmit = (event: FormEvent<IMulticastSettingsFormFields>) => {
		event?.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setMulticast();
			setMulticastSettingsDiff({});
		}
	};

	const handleFormChange = (event: FormEvent<IMulticastSettingsFormFields>) => {
		const {
			startPort: startPortForm, endPort: endPortForm, videoAddressIpv4, audioAddressIpv4, multicastAddressIpv4,
			videoAddressIpv6, audioAddressIpv6, multicastAddressIpv6, videoPort, audioPort, multicastPort, videoTtl,
			audioTtl, multicastTtl
		} = event.currentTarget;

		setMulticastSettingsDiff(produce(localMulticastSettings, multicastSettingsDraft => {
			multicastSettingsDraft.portRange.startPort = startPortForm.value;
			multicastSettingsDraft.portRange.endPort = endPortForm.value;

			for (let networkIndex = 0; networkIndex < multicastSettingsDraft.networks.length; networkIndex++) {
				multicastSettingsDraft.networks[networkIndex] = {
					videoAddressIpv4: videoAddressIpv4[networkIndex].value,
					audioAddressIpv4: audioAddressIpv4[networkIndex].value,
					multicastAddressIpv4: multicastAddressIpv4[networkIndex].value,
					videoAddressIpv6: videoAddressIpv6[networkIndex].value,
					audioAddressIpv6: audioAddressIpv6[networkIndex].value,
					multicastAddressIpv6: multicastAddressIpv6[networkIndex].value,
					videoPort: videoPort[networkIndex].value,
					audioPort: audioPort[networkIndex].value,
					multicastPort: multicastPort[networkIndex].value,
					videoTtl: videoTtl[networkIndex].value,
					audioTtl: audioTtl[networkIndex].value,
					multicastTtl: multicastTtl[networkIndex].value
				};
			}
		}));
	};

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setMulticastSettingsDiff({});
	};

	const renderSetupLabels = () => {
		return multicastSetupLabels.map((multicastSetupLabel) => {
			return (
				<div key={ multicastSetupLabel }>{ multicastSetupLabel }</div>
			);
		});
	};

	const renderNetworkInputs = () => {
		return networks.map((network, networkIndex) => {
			return (
				<div
					className="network-input-group"
					// eslint-disable-next-line react/no-array-index-key
					key={ `network-input-group-${ networkIndex }` }
				>
					{
						Object.keys(network).map((propName) => {
							return (
								<Input
									// eslint-disable-next-line react/no-array-index-key
									key={ `${ propName }-${ networkIndex }` }
									name={ propName }
									validator={ validateMulticastAddress(propName) }
									value={ network[ propName ] }
								/>
							);
						})
					}
				</div>
			);
		});
	};

	return (
		<form
			className="settings-form multicast-form"
			noValidate
			onReset={ handleReset }
			onSubmit={ handleFormSubmit }
			onChange={ handleFormChange }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="settings-content">
						<SettingsCard size="medium" title="Multicast Port Range">
							<div className="port-range">
								<div className="port-range-row">
									<label>Start Port</label>
									<Input
										value={ startPort }
										name="startPort"
									/>
								</div>
								<div className="port-range-row">
									<label>End Port</label>
									<Input
										value={ endPort }
										name="endPort"
									/>
								</div>
							</div>
						</SettingsCard>
						<SettingsCard size="medium" title="Multicast Port Range">
							<div className="setup">
								<div className="setup-header">
									<span></span>
									<span>1st</span>
									<span>2nd</span>
								</div>
								<div className="setup-content">
									<div className="setup-labels">
										{ renderSetupLabels() }
									</div>
									<div className="setup-inputs">
										{ renderNetworkInputs() }
									</div>
								</div>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localMulticastSettings, multicastSettings) }/>
		</form>
	);
}

export default memo(MulticastSettings);
