import { IColumn, ISelectOption } from "@clintonelec/react-storybook";
import { EapType, HttpAuthenticationType, IpFilterRule, RtspAuthenticationType } from "Interfaces";

export const ipFilterEnabledOptions: ISelectOption[] = [
	{
		label: "On",
		value: "On"
	},
	{
		label: "Off",
		value: "Off"
	}
];

export const ipFilterRuleOptions: ISelectOption[] = [
	{
		label: "Allow List",
		value: IpFilterRule.ALLOW
	},
	{
		label: "Block List",
		value: IpFilterRule.BLOCK
	}
];

export const ipFilterTableColumns: IColumn[] = [
	{
		key: "type",
		title: "Type",
		dataIndex: "type"
	},
	{
		key: "address",
		title: "Address",
		dataIndex: "address"
	},
	{
		key: "actions",
		title: "Actions",
		dataIndex: "actions"
	}
];

export const rtspOptions: ISelectOption[] = [
	{
		label: "Off",
		value: RtspAuthenticationType.OFF
	},
	{
		label: "Basic",
		value: RtspAuthenticationType.BASIC
	},
	{
		label: "Digest",
		value: RtspAuthenticationType.DIGEST
	}
];

export const enabledOptions: ISelectOption[] = [
	{
		label: "On",
		value: "On"
	},
	{
		label: "Off",
		value: "Off"
	}
];

export const httpsAuthTypeOptions: ISelectOption[] = [
	{
		label: "Basic",
		value: HttpAuthenticationType.BASIC
	},
	{
		label: "Digest",
		value: HttpAuthenticationType.DIGEST
	}
];

export const eapTypeOptions: ISelectOption[] = [
	{
		label: "EAP-TLS",
		value: EapType.TLS
	},
	{
		label: "EAP-MD5",
		value: EapType.MD5
	}
];

export const eapolOptions: ISelectOption[] =
	[ 1, 2, 3, 4 ].map(current => ({ label: `${ current }`, value: current }));
