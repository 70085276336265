import { FormEvent, memo, useEffect, useState } from "react";
import { loginUserAction } from "Data/Redux/Slices/User";
import { selectCurrentUser, selectToken } from "Data/Redux/Slices/User";
import "Components/LoginPage/LoginPage.less";
import { useAppSelector, useAppDispatch } from "Data/Redux/Store";
import { useNavigate } from "react-router-dom";
import { ILoginPayload } from "Interfaces";
import { NotificationType, notify } from "src/Notifications";
import { Button, ButtonTypes, Input } from "@clintonelec/react-storybook";

interface ILoginForm extends HTMLFormElement {
	username: HTMLInputElement;
	password: HTMLInputElement;
}

function LoginPage() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const authToken = useAppSelector(selectToken);
	const currentUser = useAppSelector(selectCurrentUser);
	const loginUser = (payload: ILoginPayload) => dispatch(loginUserAction(payload));
	const [ submitted, setSubmitted ] = useState(false);

	useEffect(() => {
		if (authToken && currentUser) {
			navigate("/");
		}
	}, [ authToken, currentUser, navigate ]);

	const handleLoginSubmit = (event: FormEvent<ILoginForm>) => {
		event.preventDefault();
		setSubmitted(true);

		const { username, password } = event.currentTarget;
		const payload: ILoginPayload = {
			password: password.value,
			username: username.value
		};

		loginUser(payload)
			.unwrap()
			.catch(() => {
				notify(NotificationType.ERROR, "There was an error logging you in.");
			});
	};

	return (
		<div className="login-page">
			<div className="login-card">
				<div className="header" />
				<form className="login-form" onSubmit={ handleLoginSubmit }>
					<label>Username</label>
					<Input
						name="username"
						required
						noValidate={ !submitted }
						validityMessage="A username is required"
						wrapClassName="username-input"
					/>
					<label>Password</label>
					<Input
						name="password"
						required
						password
						noValidate={ !submitted }
						validityMessage="Password is required"
					/>
					<Button
						className="login-button"
						type={ ButtonTypes.PRIMARY }
						htmlType="submit"
					>
						Sign In
					</Button>
				</form>
			</div>
		</div>
	);
}

export default memo(LoginPage);
