import { Input, Switch } from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { selectUpnp, setUpnpAction } from "Data/Redux/Slices/Settings/Network/Upnp";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { produce } from "immer";
import { FormEvent, memo, useState } from "react";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

interface IUpnpForm extends HTMLFormElement {
	friendlyName: HTMLFormElement;
}

function UpnpSettings() {
	const upnpFromSlice = useAppSelector(selectUpnp);
	const [ localUpnp, setLocalUpnp ] = useState(upnpFromSlice);
	const dispatch = useAppDispatch();
	const setUpnp = () => dispatch(setUpnpAction(localUpnp));

	const handleCancel = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLocalUpnp(upnpFromSlice);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setUpnp();
	};

	const handleUpnpEnable = (value: boolean) => {
		const newUpnpState = produce(localUpnp, draft => {
			draft.upnp = value;
		});

		setLocalUpnp(newUpnpState);
	};

	const handleFormChange = (event: FormEvent<IUpnpForm>) => {
		event.preventDefault();

		const { friendlyName: { value } } = event.currentTarget;
		const newUpnpState = produce(localUpnp, draft => {
			draft.friendlyName = value;
		});

		setLocalUpnp(newUpnpState);
	};

	return (
		<form
			className="settings-form upnp-settings"
			onChange={ handleFormChange }
			onReset={ handleCancel }
			onSubmit={ handleSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="settings-content">
						<SettingsCard size="medium" title="UPnP">
							<div className="form-row">
								<span>UPnP</span>
								<div className="form-switch-wrapper">
									<Switch
										checked={ localUpnp.upnp }
										onChange={ handleUpnpEnable }
									/>
								</div>
							</div>
							<div className="form-row">
								<span>Friendly Name</span>
								<Input
									disabled={ !localUpnp.upnp }
									name="friendlyName"
									wrapClassName="form-input"
									value={ localUpnp.friendlyName }
								/>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ localUpnp === upnpFromSlice } />
		</form>
	);
}

export default memo(UpnpSettings);
