import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Camera/ImageSettings/ImageSettings.less";
import { Icon, IconWeight, Popover, Tabs } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectImage, setImageAction } from "Data/Redux/Slices/Settings/Camera/Image";
import { isEqual } from "lodash";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import Appearance from "Components/ConfigLayout/Camera/ImageSettings/Appearance";
import Exposure from "Components/ConfigLayout/Camera/ImageSettings/Exposure";
import Enhancement from "Components/ConfigLayout/Camera/ImageSettings/Enhancement";
import DayNight from "Components/ConfigLayout/Camera/ImageSettings/DayNight";
import ZoomFocus from "Components/ConfigLayout/Camera/ImageSettings/ZoomFocus";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const renderPopover = (message: string) => {
	return (
		<Popover wrapClassName="image-popover" content={ message }>
			<Icon iconWeight={ IconWeight.REGULAR } name="circle-info" />
		</Popover>
	);
};

function ImageSettings() {
	const dispatch = useAppDispatch();
	const imageSettings = useAppSelector(selectImage);
	const [ localImageSettings, setLocalImageSettings ] = useState(imageSettings);
	const setImageSettings = () => dispatch(setImageAction(localImageSettings));
	const disabled = isEqual(localImageSettings, imageSettings);

	const handleFormReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLocalImageSettings(imageSettings);
	};

	const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setImageSettings();
		}
	};

	const panes = [
		{
			title: "Appearance",
			content: <Appearance
				localSettings={ localImageSettings }
				setLocalSettings={ setLocalImageSettings }
			/>
		},
		{
			title: "Exposure",
			content: <Exposure
				localSettings={ localImageSettings }
				renderPopover={ renderPopover }
				setLocalSettings={ setLocalImageSettings }
			/>
		},
		{
			title: "Enhancement",
			content: <Enhancement
				localSettings={ localImageSettings }
				renderPopover={ renderPopover }
				setLocalSettings={ setLocalImageSettings }
			/>
		},
		{
			title: "Day & Night",
			content: <DayNight
				localSettings={ localImageSettings }
				setLocalSettings={ setLocalImageSettings }
			/>
		},
		{
			title: "Zoom & Focus",
			content: <ZoomFocus
				localSettings={ localImageSettings }
				setLocalSettings={ setLocalImageSettings }
			/>
		}
	];

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="image-settings settings-content">
						<SettingsCard size="large" title="Image Setup">
							<Tabs panes={ panes } />
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(ImageSettings);
