import { IMulticastNetwork, IMulticastSettingsState, IMulticastSettings, IMulticastPortRange } from "Interfaces";
import { createAppSlice } from "Data/Redux/Store";
import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";

const placeholderPortRange: IMulticastPortRange = {
	startPort: "50000",
	endPort: "50999"
};

const placeholderFirstNetwork: IMulticastNetwork = {
	videoAddressIpv4: "239.176.170.175",
	audioAddressIpv4: "239.176.170.175",
	multicastAddressIpv4: "239.176.170.175",
	videoAddressIpv6: "ff05::c4a1:eb0:aaaf",
	audioAddressIpv6: "ff05::c4a1:eb0:aaaf",
	multicastAddressIpv6: "ff05::c4a1:eb0:aaaf",
	videoPort: "10002",
	audioPort: "10004",
	multicastPort: "10006",
	videoTtl: "5",
	audioTtl: "5",
	multicastTtl: "5"
};

const placeholderSecondNetwork: IMulticastNetwork = {
	videoAddressIpv4: "239.176.170.175",
	audioAddressIpv4: "239.176.170.175",
	multicastAddressIpv4: "239.176.170.175",
	videoAddressIpv6: "ff05::c4a1:eb0:aaaf",
	audioAddressIpv6: "ff05::c4a1:eb0:aaaf",
	multicastAddressIpv6: "ff05::c4a1:eb0:aaaf",
	videoPort: "10012",
	audioPort: "10014",
	multicastPort: "10016",
	videoTtl: "5",
	audioTtl: "5",
	multicastTtl: "5"
};

const initialState: IMulticastSettingsState = {
	portRange: placeholderPortRange,
	networks: [ placeholderFirstNetwork, placeholderSecondNetwork ]
};

// #region reducers
export const setMulticastSettingsReducer = (
	state: IMulticastSettingsState,
	action: PayloadAction<IMulticastSettings>
) => {
	if (!action.payload) {
		return;
	}

	return action.payload;
};

// #endregion

// #region selectors
const getMulticastSettings = ( state: IMulticastSettingsState) => {
	return state;
};

// #endregion

const multicastSettingsSlice = createAppSlice({
	name: "multicastSettings",
	initialState,
	reducers: {
		setMulticastSettingsAction: setMulticastSettingsReducer
	},
	selectors: {
		selectMulticastSettings: getMulticastSettings
	}
});

export default multicastSettingsSlice;

export const { setMulticastSettingsAction } = multicastSettingsSlice.actions;
export const { selectMulticastSettings } = multicastSettingsSlice.getSelectors<AppState>(state =>
	state.settings.network.multicastSettings
);
