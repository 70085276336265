import { PayloadAction } from "@reduxjs/toolkit";
import { Operation } from "Data/Objects/Event";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IAlarmEventState } from "Interfaces";

const initialState: IAlarmEventState = {
	name: "SENSOR1",
	operation: Operation.NORMALLY_OPEN,
	alarmOut: false,
	snapshot: false,
	whiteLight: true,
	redBlueLight: true,
	audioAlarm: true,
	recording: false,
	email: false,
	ftp: false
};

export const setAlarmEventReducer = (state: IAlarmEventState, action: PayloadAction<IAlarmEventState>) => {
	return action.payload;
};

export const getAlarmEvent = (state: IAlarmEventState) => {
	return state;
};

const alarmEventSlice = createAppSlice({
	name: "alarmEvent",
	initialState,
	reducers: {
		setAlarmEventAction: setAlarmEventReducer
	},
	selectors: {
		selectAlarmEvent: getAlarmEvent
	}
});

export default alarmEventSlice;

export const { setAlarmEventAction } = alarmEventSlice.actions;
export const { selectAlarmEvent } = alarmEventSlice.getSelectors<AppState>(state => state.settings.event.alarmEvent);
