import { memo, useRef, useState } from "react";
import "Components/LiveLayout/StreamStatusModal/StreamStatusModal.less";
import {
	Button, ButtonTypes, IconWeight, Modal, Popover, PopoverPlacement
} from "@clintonelec/react-storybook";
import { useAppSelector } from "Data/Redux/Store";
import { selectSystemInformation } from "Data/Redux/Slices/Settings/System/Information";
import { selectStreams } from "Data/Redux/Slices/Settings/Camera/Codec";
import { selectStreamIndex } from "Data/Redux/Slices/LiveView";
import { IWithChildren } from "Interfaces";

function StreamStatusModal(props: IWithChildren) {
	const timeoutRef = useRef(null);
	const [ popoverVisible, setPopoverVisible ] = useState(false);
	const streams = useAppSelector(selectStreams);
	const streamIndex = useAppSelector(selectStreamIndex);
	const systemInformation = useAppSelector(selectSystemInformation);
	const rtspUrl = `rtsp://${ systemInformation.ipAddress }:${ systemInformation.rtspPort }/live/main`;

	const handleCopyClick = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		setPopoverVisible(true);

		navigator.clipboard.writeText(rtspUrl);

		timeoutRef.current = setTimeout(() => {
			setPopoverVisible(false);
		}, 2000);
	};

	const modalContent = (
		<div className="stream-status-modal">
			<div className="status-row">
				<label>Bit Rate</label>
				<span>{ streams[ streamIndex ]?.bitrate } kbps</span>
			</div>
			<div className="status-row">
				<label>RTSP URL</label>
				<span>
					{ rtspUrl }
					<div className="copy-button-wrapper">
						<Popover
							placement={ PopoverPlacement.TOP }
							content="Copied!"
							visible={ popoverVisible }
						>
							<Button
								type={ ButtonTypes.SECONDARY }
								ghost
								icon={ { name: "copy", weight: IconWeight.REGULAR } }
								onClick={ handleCopyClick }
							/>
						</Popover>
					</div>
				</span>
			</div>
		</div>
	);

	return (
		<Modal
			title="Stream Status"
			modalContent={ modalContent }
			width={ 550 }
		>
			{ props.children }
		</Modal>
	);
}

export default memo(StreamStatusModal);
