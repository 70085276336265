import { ISelectOption, RangeSlider, Select, Switch } from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import "Components/ConfigLayout/Audio/AudioSettings/AudioSettings.less";
import SettingsCard from "Components/ConfigLayout/SettingsCard";
import { MicOption } from "Data/Objects/Audio";
import { selectAudio, setAudioAction } from "Data/Redux/Slices/Settings/Audio/Audio";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { produce } from "immer";
import { IAudioSettingsState } from "Interfaces";
import { isEqual, merge } from "lodash";
import { FormEvent, memo, useState } from "react";
import { NotificationType, notify } from "src/Notifications";

const micOptions: ISelectOption[] = [
	{
		label: "Built-In",
		value: MicOption.BUILT_IN
	},
	{
		label: "Line-In",
		value: MicOption.LINE_IN
	}
];

function AudioSettings() {
	const dispatch = useAppDispatch();
	const audioSettings = useAppSelector(selectAudio);
	const [ audioSettingsDiff, setAudioSettingsDiff ] = useState<Partial<IAudioSettingsState>>({});

	const setAudioSettings = (newSettings: IAudioSettingsState) => {
		dispatch(setAudioAction(newSettings));
	};

	const localAudioSettings = produce(audioSettings, draft => {
		merge(draft, audioSettingsDiff);
	});

	const { enableAudio, micOption, micVolume, speakerVolume } = localAudioSettings;
	const disabled = isEqual(localAudioSettings, audioSettings);

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setAudioSettingsDiff({});
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setAudioSettings(localAudioSettings);
		setAudioSettingsDiff({});
	};

	const selectMicOption = (value: MicOption) => {
		setAudioSettingsDiff(produce(localAudioSettings, draft => {
			draft.micOption = value;
		}));
	};

	const updateMicVolume = (value: number) => {
		setAudioSettingsDiff(produce(localAudioSettings, draft => {
			draft.micVolume = value * 5;
		}));
	};

	const updateSpeakerVolume = (value: number) => {
		setAudioSettingsDiff(produce(localAudioSettings, draft => {
			draft.speakerVolume = value * 5;
		}));
	};

	const handleEnableAudio = (checked: boolean) => {
		if (checked) {
			notify(
				NotificationType.WARNING,
				"Attention",
				`Audio monitoring and/or recording may be prohibited in certain areas.
				Always check any local and state laws before enabling audio on the camera.`,
				null,
				{ toastId: "audioEnabledToast" }
			);
		}

		setAudioSettingsDiff(produce(localAudioSettings, draft => {
			draft.enableAudio = checked;
		}));
	};

	return (
		<form className="audio-settings-form settings-form" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="audio-settings settings-content">
						<SettingsCard size="small" title="Audio">
							<div className="form-row switch-container">
								<span>Enable Audio</span>
								<div className="form-switch-wrapper">
									<Switch
										checked={ enableAudio }
										formName="enableAudioSwitch"
										onChange={ handleEnableAudio }
									/>
								</div>
							</div>
							<div className="form-row">
								<span>Mic</span>
								<Select
									allowClear={ false }
									className="form-select"
									disabled={ !enableAudio }
									onSelect={ selectMicOption }
									options={ micOptions }
									value={ micOption }
								/>
							</div>
							<div className="form-row">
								<span>Mic Volume</span>
								<div className="form-select">
									<RangeSlider
										disabled={ !enableAudio }
										max={ 20 }
										min={ 0 }
										onAfterChange={ updateMicVolume }
										value={ micVolume / 5 }
									/>
								</div>
							</div>
							<div className="form-row">
								<span>Speaker Volume</span>
								<div className="form-select">
									<RangeSlider
										disabled={ !enableAudio }
										max={ 20 }
										min={ 0 }
										onAfterChange={ updateSpeakerVolume }
										value={ speakerVolume / 5 }
									/>
								</div>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(AudioSettings);
