import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Storage/SdCardSettings/SdCardSettings.less";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import {
	Button, ButtonTypes, Input, ISelectOption, ProgressBar, Select, Switch, Modal
} from "@clintonelec/react-storybook";
import { DiskWriteMode, FormatType } from "Data/Objects/Storage";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectSdCard, setSdCardAction } from "Data/Redux/Slices/Settings/Storage/SdCard";
import { isEqual, merge } from "lodash";
import { produce } from "immer";
import { ISdCardSettingsState } from "Interfaces";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const diskWriteModeOptions: ISelectOption[] = [
	{
		label: "Once",
		value: DiskWriteMode.ONCE
	},
	{
		label: "Overwrite",
		value: DiskWriteMode.OVERWRITE
	}
];

const formatTypeOptions: ISelectOption[] = [
	{
		label: FormatType.EXFAT,
		value: FormatType.EXFAT
	}
];

function SdCardSettings() {
	const dispatch = useAppDispatch();
	const sdCardSettings = useAppSelector(selectSdCard);
	const [ sdCardSettingsDiff, setSdCardSettingsDiff ] = useState<Partial<ISdCardSettingsState>>({});

	const setSdCardSettings = (newSettings: ISdCardSettingsState) => {
		dispatch(setSdCardAction(newSettings));
	};

	const localSdCardSettings = produce(sdCardSettings, draft => {
		merge(draft, sdCardSettingsDiff);
	});

	const {
		enableSdCardRecording, diskWriteMode, storageName, formatType, totalSize, usedSize, status
	} = localSdCardSettings;

	const disabled = isEqual(localSdCardSettings, sdCardSettings);

	const statusMessage = () => {
		if (!status) {
			return "No SD Card";
		} else if (status && !enableSdCardRecording) {
			return "Not In Use";
		} else if (status && enableSdCardRecording) {
			return "Recording";
		} else {
			return "Error";
		}
	};

	const boxRowClassName = `${ status ? "box-row" : "hidden" }`;
	const progressBarClassName = `${ status ? "" : "hidden" }`;
	const [ formatModalVisible, setFormatModalVisible ] = useState(false);
	const closeFormatModal = () => setFormatModalVisible(false);

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setSdCardSettingsDiff({});
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setSdCardSettings(localSdCardSettings);
		setSdCardSettingsDiff({});
	};

	const changeEnableSdCardRecording = (checked: boolean) => {
		setSdCardSettingsDiff(produce(sdCardSettingsDiff, draft => {
			draft.enableSdCardRecording = checked;
		}));
	};

	const changeStatus = () => {
		setSdCardSettingsDiff(produce(sdCardSettingsDiff, draft => {
			draft.status = !status;
		}));
	};

	const selectDiskWriteMode = (value: DiskWriteMode) => {
		setSdCardSettingsDiff(produce(sdCardSettingsDiff, draft => {
			draft.diskWriteMode = value;
		}));
	};

	const updateStorageName = (value: string) => {
		setSdCardSettingsDiff(produce(sdCardSettingsDiff, draft => {
			draft.storageName = value;
		}));
	};

	const selectFormatType = (value: FormatType) => {
		setSdCardSettingsDiff(produce(sdCardSettingsDiff, draft => {
			draft.formatType = value;
		}));
	};

	const modalContent = (
		<div className="format-modal-content">
			<span>All recorded data will be removed.<br />Do you want to continue?</span>
			<div className="buttons">
				<Button ghost htmlType="button" onClick={ closeFormatModal }>Cancel</Button>
				<Button htmlType="button" onClick={ closeFormatModal }>OK</Button>
			</div>
		</div>
	);

	return (
		<form className="settings-form" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="sd-card-settings settings-content">
						<SettingsCard size="large" title="SD Card">
							<div className="form-row">
								<Switch
									checked={ enableSdCardRecording }
									className="sd-card-switch"
									onChange={ changeEnableSdCardRecording }
								/>
								<span>Enable SD Card Recording</span>
							</div>
							<div className="sd-card-settings-wrapper">
								<div className="sd-card-settings">
									<p>SD Card Info</p>
									<div className="sd-card-info-box">
										<div className={ boxRowClassName }>
											<span>Total Size</span>
											<span>{ totalSize } GB</span>
										</div>
										<div className={ progressBarClassName }>
											<ProgressBar
												showProgress={ false }
												progress={ usedSize / totalSize * 100 }
											/>
										</div>
										<div className={ boxRowClassName }>
											<span>{ usedSize } GB Used</span>
											<span>{ totalSize - usedSize } GB Free</span>
										</div>
										<div className={ boxRowClassName }>
											<span>Format</span>
											<span>{ formatType }</span>
										</div>
										<div className="box-row">
											<span>Status</span>
											<span>{ statusMessage() }</span>
										</div>
									</div>
									<div className="mount-button">
										<Button
											disabled={ !enableSdCardRecording }
											htmlType="button"
											onClick={ changeStatus }
											type={ ButtonTypes.TERTIARY }
										>
											{ status ? "Unmount" : "Mount" }
										</Button>
									</div>
								</div>
								<div className="sd-card-settings">
									<div className="form-row">
										<span>Disk Write Mode</span>
										<Select
											allowClear={ false }
											className="form-select"
											disabled={ !enableSdCardRecording }
											onSelect={ selectDiskWriteMode }
											options={ diskWriteModeOptions }
											value={ diskWriteMode }
										/>
									</div>
									<div className="form-row">&nbsp;</div>
									<div className="form-row">
										<span>Storage Name</span>
										<Input
											disabled={ !enableSdCardRecording }
											onUpdate={ updateStorageName }
											value={ storageName }
											wrapClassName="form-input"
										/>
									</div>
									<div className="form-row">
										<span>Format Type</span>
										<div className="select-button-wrapper form-select">
											<Select
												allowClear={ false }
												disabled={ !status || !enableSdCardRecording }
												onSelect={ selectFormatType }
												options={ formatTypeOptions }
												value={ formatType }
											/>
											<Modal
												modalContent={ modalContent }
												title={ "Confirm SD Card Format" }
												visible={ formatModalVisible }
												onVisibilityChange={ setFormatModalVisible }
											>
												<Button
													disabled={ !status || !enableSdCardRecording }
													htmlType="button"
													type={ ButtonTypes.TERTIARY }
												>
													Format
												</Button>
											</Modal>
										</div>
									</div>
								</div>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(SdCardSettings);
