import { ISelectOption } from "@clintonelec/react-storybook";

export const groupSelectOptions: ISelectOption[] = [
	{
		label: "ADMIN",
		value: "ADMIN"
	},
	{
		label: "MANAGER",
		value: "MANAGER"
	},
	{
		label: "USER",
		value: "USER"
	}
];
