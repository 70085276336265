import { ISelectOption, Select } from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import "Components/ConfigLayout/Network/UpnpSettings/UpnpSettings.less";
import { selectUpnp, setUpnpAction } from "Data/Redux/Slices/Settings/Network/Upnp";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { FormEvent, memo, useState } from "react";

const upnpOptions: ISelectOption[] = [
	{
		label: "Off",
		value: "OFF"
	},
	{
		label: "On",
		value: "ON"
	}
];

function UpnpSettings() {
	const upnpFromSlice = useAppSelector(selectUpnp);
	const [ localUpnp, setLocalUpnp ] = useState(upnpFromSlice);
	const dispatch = useAppDispatch();
	const setUpnp = () => dispatch(setUpnpAction(localUpnp));

	const handleCancel = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLocalUpnp(upnpFromSlice);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setUpnp();
	};

	const handleSelect = (value: string) => setLocalUpnp(value === "ON");

	return (
		<form className="upnp-settings-form" onReset={ handleCancel } onSubmit={ handleSubmit }>
			<div className="container">
				<div className="card">
					<div className="header">
						<h4>UPnP</h4>
					</div>
					<div className="form-row">
						<span>UPnP</span>
						<Select
							allowClear={ false }
							className="upnp-select"
							menuPlacement="auto"
							menuPortalTarget={ document.body }
							onSelect={ handleSelect }
							options={ upnpOptions }
							value={ localUpnp ? "ON" : "OFF" }
						/>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ localUpnp === upnpFromSlice } />
		</form>
	);
}

export default memo(UpnpSettings);
