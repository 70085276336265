// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-toolbar {
  height: 60px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/LiveLayout/LiveToolbar/LiveToolbar.less"],"names":[],"mappings":"AAEA;EACC,YAAA;EACA,WAAA;AADD","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.live-toolbar {\n\theight: 60px;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
