import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/SystemEventSettings/SystemEventSettings.less";
import { Switch } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { produce } from "immer";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";
import { selectSystemEvent, setSystemEventAction } from "Data/Redux/Slices/Settings/Event/System";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

interface ISystemEventForm extends HTMLFormElement {
	alarmOut: HTMLInputElement;
	snapshot: HTMLInputElement;
	email: HTMLInputElement;
	ftp: HTMLInputElement;
}

function SystemEventSettings() {
	const dispatch = useAppDispatch();
	const systemEventSettings = useAppSelector(selectSystemEvent);
	const [ localSystemEventSettings, setLocalSystemEventSettings ] = useState(systemEventSettings);
	const setSystemEvent = () => dispatch(setSystemEventAction(localSystemEventSettings));
	const disabled = isEqual(localSystemEventSettings, systemEventSettings);
	const { alarmOut, snapshot, email, ftp } = localSystemEventSettings;

	const handleAlarmOutChange = (newEnabled: boolean) => {
		setLocalSystemEventSettings(produce(localSystemEventSettings, draft => {
			draft.alarmOut = newEnabled;
		}));
	};

	const handleSnapshotChange = (newEnabled: boolean) => {
		setLocalSystemEventSettings(produce(localSystemEventSettings, draft => {
			draft.snapshot = newEnabled;
		}));
	};

	const handleEmailChange = (newEnabled: boolean) => {
		setLocalSystemEventSettings(produce(localSystemEventSettings, draft => {
			draft.email = newEnabled;
		}));
	};

	const handleFtpChange = (newEnabled: boolean) => {
		setLocalSystemEventSettings(produce(localSystemEventSettings, draft => {
			draft.ftp = newEnabled;
		}));
	};

	const handleFormSubmit = (event: FormEvent<ISystemEventForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setSystemEvent();
		}
	};

	const handleFormReset = (event: FormEvent<ISystemEventForm>) => {
		event.preventDefault();

		setLocalSystemEventSettings(systemEventSettings);
	};

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="system-event-settings settings-content">
						<SettingsCard size="medium" title="System Event Settings">
							<div className="note-banner event">
								<span>Notification</span>
								<span>&nbsp;-&nbsp;</span>
								<span>
									Actions and notifications require detailed settings on each settings
									page to work.
								</span>
							</div>
							<div className="system-event-content">
								<div className="form-row">
									<span>Alarm Out</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleAlarmOutChange } checked={ alarmOut } />
									</div>
								</div>
								<div className="form-row">
									<span>Snapshot</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleSnapshotChange } checked={ snapshot } />
									</div>
								</div>
								<div className="form-row">
									<span>Email</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleEmailChange } checked={ email } />
									</div>
								</div>
								<div className="form-row">
									<span>FTP</span>
									<div className="form-switch-wrapper">
										<Switch onChange={ handleFtpChange } checked={ ftp } />
									</div>
								</div>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(SystemEventSettings);
