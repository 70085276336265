import { combineSlices } from "@reduxjs/toolkit";
import userSlice from "Data/Redux/Slices/User";
import playbackSlice from "Data/Redux/Slices/Playback";
import settingsSlice from "Data/Redux/Slices/Settings";
import userSettingsSlice from "Data/Redux/Slices/UserSettings";
import liveViewSlice from "Data/Redux/Slices/LiveView";

export const rootReducer = combineSlices(userSlice, playbackSlice, userSettingsSlice, liveViewSlice, {
	settings: settingsSlice
});
export type AppState = ReturnType<typeof rootReducer>;
