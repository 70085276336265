import { FormEvent, Fragment, memo, useState } from "react";
import "Components/ConfigLayout/Record/SetupSettings/SetupSettings.less";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { Link } from "react-router-dom";
import { Checkbox, Radio, Select } from "@clintonelec/react-storybook";
import {
	RecordRadioSettings, postRecordingTimeOptions, preRecordingTimeOptions, recordingOptions, storageOptions
} from "Data/Objects/RecordSettings";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectRecordSettings, setRecordSettingsAction } from "Data/Redux/Slices/Settings/Record/Record";
import { IRecordSettingsState } from "Interfaces";
import { isEqual, merge } from "lodash";
import { produce } from "immer";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const recordingModeNote =
	"You must use a 16GB or larger SD card to use recording storage. Older SD cards may not work.";

const eventRecordingNote =
	"Go to Schedule Preset to create a new preset.";

export interface IRecordSettingsFormFields extends HTMLFormElement {
	recordingModeRadio: HTMLInputElement;
	storageOption: HTMLInputElement;
	continuousRecordingOption: HTMLInputElement;
	eventRecordingOption: HTMLInputElement;
	recordingSchedule: RadioNodeList;
	preRecordingTime: HTMLInputElement;
	postRecordingTime: HTMLInputElement;
}

function RecordSetupSettings() {
	const dispatch = useAppDispatch();
	const recordSetupSettings = useAppSelector(selectRecordSettings);
	const [ recordSetupSettingsDiff, setRecordSetupSettingsDiff ] =
		useState<Partial<IRecordSettingsState>>({});

	const localRecordSetupSettings = produce(recordSetupSettings, (draft) => {
		merge(draft, recordSetupSettingsDiff);
	});

	const setRecordSettings = () => {
		dispatch(setRecordSettingsAction(localRecordSetupSettings));
	};

	const handleReset = (event: FormEvent<IRecordSettingsFormFields>) => {
		event.preventDefault();
		setRecordSetupSettingsDiff({});
	};

	const handleFormSubmit = (event: FormEvent<IRecordSettingsFormFields>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setRecordSettings();
			setRecordSetupSettingsDiff({});
		}
	};

	const handleFormChange = (event: FormEvent<IRecordSettingsFormFields>) => {
		const { recordingModeRadio, recordingSchedule } = event.currentTarget;

		setRecordSetupSettingsDiff(produce(localRecordSetupSettings, (draft) => {
			draft.recordingModeRadio = recordingModeRadio?.value as RecordRadioSettings;

			Object.keys(localRecordSetupSettings.recordingSchedule).forEach(schedule => {
				recordingSchedule.forEach((input: HTMLInputElement) => {
					if (input.id === schedule) {
						draft.recordingSchedule[ schedule ] = input.checked;
					}
				});
			});
		}));
	};

	const selectStorageOption = (value: string) => {
		setRecordSetupSettingsDiff(produce(localRecordSetupSettings, draft => {
			draft.storageOption = value;
		}));
	};

	const selectContinuousRecordingOption = (value: string) => {
		setRecordSetupSettingsDiff(produce(localRecordSetupSettings, draft => {
			draft.continuousRecordingOption = value;
		}));
	};

	const selectEventRecordingOption = (value: string) => {
		setRecordSetupSettingsDiff(produce(localRecordSetupSettings, draft => {
			draft.eventRecordingOption = value;
		}));
	};

	const selectPreRecordingTime = (value: string) => {
		setRecordSetupSettingsDiff(produce(localRecordSetupSettings, draft => {
			draft.preRecordingTime = +value;
		}));
	};

	const selectPostRecordingTime = (value: string) => {
		setRecordSetupSettingsDiff(produce(localRecordSetupSettings, draft => {
			draft.postRecordingTime = +value;
		}));
	};

	const renderRecordingSchedule = () => {
		return Object.entries(localRecordSetupSettings.recordingSchedule).map((schedule, index) => {
			const [ scheduleName, checked ] = schedule;

			return (
				// eslint-disable-next-line react/no-array-index-key
				<Fragment key={ `recordingSchedule-${ index }` }>
					<Checkbox
						checked={ checked }
						disabled={ localRecordSetupSettings.recordingModeRadio !== RecordRadioSettings.EVENT }
						id={ scheduleName }
						name="recordingSchedule"
					/>
					<label>{ scheduleName }</label>
				</Fragment>
			);
		});
	};

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleReset }
			onSubmit={ handleFormSubmit }
			onChange={ handleFormChange }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="record-setup-settings settings-content">
						<SettingsCard size="small" title="Recording Mode">
							<div className="note-banner alert">
								<span>Note</span>
								<span>&nbsp;-&nbsp;</span>
								<span>{ recordingModeNote }</span>
							</div>
							<div className="record-setup-radio">
								<Radio
									checked={
										localRecordSetupSettings.recordingModeRadio === RecordRadioSettings.CONTINUOUS
									}
									id="radioModeContinous"
									label={ RecordRadioSettings.CONTINUOUS }
									name="recordingModeRadio"
									value={ RecordRadioSettings.CONTINUOUS }
								/>
								<Radio
									checked={
										localRecordSetupSettings.recordingModeRadio === RecordRadioSettings.EVENT
									}
									id="radioModeEvent"
									label={ RecordRadioSettings.EVENT }
									name="recordingModeRadio"
									value={ RecordRadioSettings.EVENT }
								/>
								<Radio
									checked={
										localRecordSetupSettings.recordingModeRadio === RecordRadioSettings.DISABLE
									}
									id="radioModeDisable"
									label={ RecordRadioSettings.DISABLE }
									name="recordingModeRadio"
									value={ RecordRadioSettings.DISABLE }
								/>
							</div>
						</SettingsCard>
						<SettingsCard size="small" title="Storage Options">
							<div className="form-row">
								<span>Recording Storage</span>
								<Select
									allowClear={ false }
									className="form-select"
									disabled
									name="storageOption"
									onSelect={ selectStorageOption }
									options={ storageOptions }
									value={ localRecordSetupSettings.storageOption }
								/>
							</div>
						</SettingsCard>
						<SettingsCard size="small" title="Continuous Recording Options">
							<div className="form-row">
								<span>Video Stream</span>
								<Select
									allowClear={ false }
									className="form-select"
									disabled={
										localRecordSetupSettings.recordingModeRadio !== RecordRadioSettings.CONTINUOUS
									}
									name="continuousRecordingOption"
									onSelect={ selectContinuousRecordingOption }
									options={ recordingOptions }
									value={ localRecordSetupSettings.continuousRecordingOption }
								/>
							</div>
						</SettingsCard>
						<SettingsCard size="small" title="Event Recording Options">
							<div className="form-row">
								<span>Video Stream</span>
								<Select
									allowClear={ false }
									className="form-select"
									disabled={
										localRecordSetupSettings.recordingModeRadio !== RecordRadioSettings.EVENT
									}
									name="eventRecordingOption"
									onSelect={ selectEventRecordingOption }
									options={ recordingOptions }
									value={ localRecordSetupSettings.eventRecordingOption }
								/>
							</div>
							<div className="form-row">
								<span>Recording Schedule</span>
								<div className="note-banner alert recording-schedule-banner">
									<div>
										<span>Note</span>
										<span>&nbsp;-&nbsp;</span>
										<span>
											<Link to="/setup/system/schedule">{ eventRecordingNote }</Link>
										</span>
									</div>
								</div>
							</div>
							<div className="form-row">
								<div className="checkbox-spacer"></div>
								<div className="record-checkbox-wrapper">
									{ renderRecordingSchedule() }
								</div>
							</div>
						</SettingsCard>
						<SettingsCard size="small" title="Recording Time">
							<div className="form-row">
								<span>Pre Recording Time</span>
								<Select
									allowClear={ false }
									className="form-select"
									name="preRecordingTime"
									onSelect={ selectPreRecordingTime }
									options={ preRecordingTimeOptions }
									value={ localRecordSetupSettings.preRecordingTime }
								/>
							</div>
							<div className="form-row">
								<span>Post Recording Time</span>
								<Select
									allowClear={ false }
									className="form-select"
									name="postRecordingTime"
									menuPlacement="auto"
									onSelect={ selectPostRecordingTime }
									options={ postRecordingTimeOptions }
									value={ localRecordSetupSettings.postRecordingTime }
								/>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localRecordSetupSettings, recordSetupSettings) } />
		</form>
	);
}

export default memo(RecordSetupSettings);
