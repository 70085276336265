import { Fragment, memo, useState } from "react";
import "Components/ConfigLayout/ConfigNav/ConfigNav.less";
import { NavLink, useMatch } from "react-router-dom";
import { Collapse, Panel } from "@clintonelec/react-storybook";
import { Categories, Menus } from "Data/Objects/Category";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";

const icons = {
	[ Categories.CAMERA ]: "camera-cctv",
	[ Categories.AUDIO ]: "volume",
	[ Categories.USER ]: "user",
	[ Categories.NETWORK ]: "globe",
	[ Categories.SYSTEM ]: "gear",
	[ Categories.STORAGE ]: "hard-drive",
	[ Categories.EVENT ]: "siren-on",
	[ Categories.RECORD ]: "film"
};

function ConfigNav() {
	const { params: { currentCategory } } = useMatch("/setup/:currentCategory?/:currentSubCategory?");
	const [ activeCategory, setActiveCategory ] = useState(
		Categories[ currentCategory?.toUpperCase() ] || Categories.CAMERA
	);

	const handleClick = (category: string) => () => {
		setActiveCategory(category);
	};

	const renderSubCategories = (category: string, subCategory: string) => {
		return (
			<li key={ subCategory }>
				<NavLink to={ `/setup/${ category.toLowerCase() }/${ subCategory.toLowerCase()?.replace(" ", "_") }` }>
					{ subCategory }
				</NavLink>
			</li>
		);
	};

	const renderMenus = (category: string) => {
		return (
			<ul>
				{ Object.values(Menus[ category ]).map((subCategory: string) =>
					renderSubCategories(category, subCategory)) }
			</ul>
		);
	};

	const renderPanel = (category: string) => {
		const headerContent = (
			<Fragment>
				<Icon
					className="category-icon"
					iconWeight={ IconWeight.SOLID }
					name={ icons[ category ] }
					size={ IconSize.SMALLER }
				/>
				{ category }
			</Fragment>
		);

		return (
			<div
				className="panel-wrapper"
				key={ category }
				onClick={ handleClick(category) }
			>
				<Panel
					collapsed={ activeCategory !== category }
					header={ headerContent }
					headerClassName="category"
				>
					{ renderMenus(category) }
				</Panel>
			</div>
		);
	};

	return (
		<nav className="config-nav">
			<Collapse>
				{ Object.values(Categories).map(renderPanel) }
			</Collapse>
		</nav>
	);
}

export default memo(ConfigNav);
