import { memo } from "react";
import "Components/LiveLayout/LiveLayout.less";
import LiveToolbar from "Components/LiveLayout/LiveToolbar";
import VideoContainer from "Components/PlaybackLayout/VideoContainer";

function LiveLayout() {
	return (
		<div className="live-layout">
			<div className="main-content">
				<VideoContainer />
			</div>
			<LiveToolbar />
		</div>
	);
}

export default memo(LiveLayout);
