// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playback-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.playback-layout .playback-main {
  border-top: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  height: 160px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/PlaybackLayout/PlaybackLayout.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;AADD;AAFA;EAME,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AADF","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.playback-layout {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\n\t.playback-main {\n\t\tborder-top: 1px solid @light-gray-0;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\theight: 160px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
