import { memo } from "react";
import "Components/ConfigLayout/Breadcrumb/Breadcrumb.less";
import { useMatch } from "react-router-dom";
import { Menus } from "Data/Objects/Category";

function Breadcrumb() {
	let { params: { category, subCategory } } = useMatch("/setup/:category?/:subCategory?");

	category = category?.toUpperCase();
	subCategory = Menus?.[ category ]?.[ subCategory?.toUpperCase() ];

	return (
		<div className="breadcrumb">
			<span>
				{ category } &gt; { subCategory }
			</span>
		</div>
	);
}

export default memo(Breadcrumb);
