import { ReactNode, Fragment } from "react";
import { toast, ToastOptions } from "@clintonelec/react-storybook";

export enum NotificationType {
	SUCCESS = "success",
	ERROR = "error",
	INFO = "info",
	WARNING = "warning"
}

export function notify(
	type: NotificationType, title: string, content?: ReactNode, durationMilliseconds?: number | false,
	toastOptions?: ToastOptions
) {
	let innerContent = null;

	if (content) {
		innerContent = (
			<div className="cec-notification-content">
				{ content }
			</div>
		);
	}

	const complexContent = (
		<Fragment>
			<div className="cec-notification-title">
				{ title }
			</div>
			{ innerContent }
		</Fragment>
	);

	return toast[type](complexContent, { autoClose: durationMilliseconds, ...toastOptions });
}
