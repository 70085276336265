// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-information {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.system-information .scrollable-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 30px;
}
.system-information .scrollable-container .card {
  background-color: white;
  border-radius: 8px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px 20px;
  width: 500px;
}
.system-information .scrollable-container .card .title {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}
.system-information .scrollable-container .card .title h4 {
  margin-block-end: 5px;
}
.system-information .scrollable-container .card .row {
  align-items: center;
  display: flex;
  padding-inline: 15px;
}
.system-information .scrollable-container .card .row > span {
  color: #7e7e7e;
  text-transform: capitalize;
  width: 65%;
}
.system-information .scrollable-container .card .row > p {
  font-weight: bold;
  margin: 0;
  width: 35%;
}
.system-information .scrollable-container .card .row:not(:last-child) {
  margin-bottom: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/System/SystemInformation/SystemInformation.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;AADD;AAJA;EAQE,mBAAA;EACA,aAAA;EACA,OAAA;EACA,sBAAA;EACA,cAAA;EACA,aAAA;AADF;AAZA;EAgBG,uBAAA;EACA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,oBAAA;EACA,YAAA;AADH;AAnBA;EAuBI,gCAAA;EACA,mBAAA;AADJ;AAvBA;EA2BK,qBAAA;AADL;AA1BA;EAgCI,mBAAA;EACA,aAAA;EACA,oBAAA;AAHJ;AA/BA;EAqCK,cAAA;EACA,0BAAA;EACA,UAAA;AAHL;AApCA;EA2CK,iBAAA;EACA,SAAA;EACA,UAAA;AAJL;AAOI;EACC,mBAAA;AALL","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.system-information {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\tjustify-content: center;\n\tposition: relative;\n\n\t.scrollable-container {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tflex-direction: column;\n\t\toverflow: auto;\n\t\tpadding: 30px;\n\n\t\t.card {\n\t\t\tbackground-color: white;\n\t\t\tborder-radius: 8px;\n\t\t\theight: fit-content;\n\t\t\tpadding: 0 20px 20px;\n\t\t\twidth: 500px;\n\n\t\t\t.title {\n\t\t\t\tborder-bottom: 1px solid @light-gray-1;\n\t\t\t\tmargin-bottom: 20px;\n\n\t\t\t\th4 {\n\t\t\t\t\tmargin-block-end: 5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.row {\n\t\t\t\talign-items: center;\n\t\t\t\tdisplay: flex;\n\t\t\t\tpadding-inline: 15px;\n\n\t\t\t\t> span {\n\t\t\t\t\tcolor: @light-gray-2;\n\t\t\t\t\ttext-transform: capitalize;\n\t\t\t\t\twidth: 65%;\n\t\t\t\t}\n\n\t\t\t\t> p {\n\t\t\t\t\tfont-weight: bold;\n\t\t\t\t\tmargin: 0;\n\t\t\t\t\twidth: 35%;\n\t\t\t\t}\n\n\t\t\t\t&:not(:last-child) {\n\t\t\t\t\tmargin-bottom: 18px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
