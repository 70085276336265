// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-player {
  display: flex;
  flex: 1;
  justify-content: center;
}
.video-canvas {
  background-color: black;
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Global/VideoPlayer/VideoPlayer.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,uBAAA;AADD;AAIA;EACC,uBAAA;EACA,YAAA;EACA,WAAA;AAFD","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.video-player {\n\tdisplay: flex;\n\tflex: 1;\n\tjustify-content: center;\n}\n\n.video-canvas {\n\tbackground-color: black;\n\theight: 100%;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
