import { Select, Switch } from "@clintonelec/react-storybook";
import { IStream } from "Interfaces";
import { fourthStreamFrameRateOptions, useStreamSettingsOptions } from "Data/Objects/CodecSettingsOptions";
import { Dispatch, Fragment, memo, SetStateAction } from "react";
import { UseStreamSettings } from "Data/Objects/Camera";

interface IFourthTabContentProps {
	setUserStreams: Dispatch<SetStateAction<IStream[]>>;
	streamIndex: number;
	userStreams: IStream[];
}

function FourthTabContent(props: IFourthTabContentProps) {
	const { setUserStreams, streamIndex, userStreams } = props;
	const { enableStream, useStreamSettings, frameRateLabel } = userStreams[ streamIndex ];

	const changeEnableStream = (value: boolean) => {
		const newStreams = userStreams.map((stream, index) => {
			if (index !== streamIndex) {
				return stream;
			}

			return { ...stream, enableStream: value };
		});

		setUserStreams(newStreams);
	};

	const changeUseStreamSettings = (value: UseStreamSettings) => {
		const newStreams = userStreams.map((stream, index) => {
			if (index === streamIndex) {
				if (value === "mainStream") {
					return {
						...stream,
						frameRate: userStreams[ 0 ].frameRate * frameRateLabel,
						useStreamSettings: value
					};
				}

				if (value === "secondStream") {
					return {
						...stream,
						frameRate: userStreams[ 1 ].frameRate * frameRateLabel,
						useStreamSettings: value
					};
				}
			}

			return stream;
		});

		setUserStreams(newStreams);
	};

	const changeFrameRate = (value: string) => {
		const newStreams = userStreams.map((stream, index) => {
			if (index === streamIndex) {
				if (useStreamSettings === "mainStream") {
					return {
						...stream,
						frameRate: userStreams[ 0 ].frameRate * +value,
						frameRateLabel: +value
					};
				}

				if (useStreamSettings === "secondStream") {
					return {
						...stream,
						frameRate: userStreams[ 1 ].frameRate * +value,
						frameRateLabel: +value
					};
				}
			}

			return stream;
		});

		setUserStreams(newStreams);
	};

	return (
		<Fragment>
			<div className="form-row">
				<Switch
					checked={ enableStream }
					className="codec-switch"
					onChange={ changeEnableStream }
				/>
				<span>Enable Fourth Stream</span>
			</div>
			<div className="form-row">
				<span>Use Stream Settings</span>
				<Select
					allowClear={ false }
					className="codec-select"
					disabled={ !enableStream }
					onSelect={ changeUseStreamSettings }
					options={ useStreamSettingsOptions }
					value={ useStreamSettings }
				/>
			</div>
			<div className="form-row">
				<span>Frame Rate</span>
				<Select
					allowClear={ false }
					className="codec-select"
					disabled={ !enableStream }
					onSelect={ changeFrameRate }
					options={ fourthStreamFrameRateOptions }
					value={ frameRateLabel }
				/>
			</div>
		</Fragment>
	);
}

export default memo(FourthTabContent);
