// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
  border-bottom: 1px solid #f0f0f0;
  color: #127ec0;
  height: 46px;
  line-height: 46px;
  padding-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Breadcrumb/Breadcrumb.less"],"names":[],"mappings":"AAEA;EACC,gCAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AADD","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.breadcrumb {\n\tborder-bottom: 1px solid @light-gray-0;\n\tcolor: @blue-1;\n\theight: 46px;\n\tline-height: 46px;\n\tpadding-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
