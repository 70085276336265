import { FormEvent, memo, useState } from "react";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import "Components/ConfigLayout/Network/SecuritySettings/SecuritySettings.less";
import {
	Button, ButtonTypes, ConfirmModal, Input, Select, SelectComponent, Table
} from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import {
	selectNetworkSecuritySettings, setNetworkSecuritySettingsAction
} from "Data/Redux/Slices/Settings/Network/Security";
import {
	EapType, HttpAuthenticationType, IIpFilter, INetworkSecurityState, IpFilterRule, IpFilterType,
	ISecurityCertificate, RtspAuthenticationType
} from "Interfaces";
import {
	httpsAuthTypeOptions, enabledOptions, ipFilterEnabledOptions, ipFilterRuleOptions, ipFilterTableColumns,
	rtspOptions, eapTypeOptions, eapolOptions
} from "Data/Objects/NetworkSecurity";
import FilterManagementModal from "Components/ConfigLayout/Network/SecuritySettings/FilterManagementModal";
import { produce } from "immer";
import { cloneDeep, isEqual, merge } from "lodash";
import CertificateField from "Components/ConfigLayout/Network/SecuritySettings/CertificateField";

interface ISecuritySettingsForm extends HTMLFormElement {
	httpAuthType: SelectComponent;
	httpEnabled: SelectComponent;
	ipFilterEnabled: SelectComponent;
	ipFilterRule: SelectComponent;
	authSelect: SelectComponent;
	ieeeEnabled: SelectComponent;
	eapType: SelectComponent;
	eapolVersion: SelectComponent;
	ieeeId: HTMLInputElement;
	ieeePassword: HTMLInputElement;
}

interface IFilterActionsProps {
	disabled: boolean;
	filterActionCallback: (filter: IIpFilter | string, oldFilter?: string) => void;
	targetFilter: IIpFilter;
}

const getIpFilterTypeDisplayName = (type: IpFilterType) => {
	switch (type) {
		case IpFilterType.IP_ADDRESS:
			return "IP Address";

		case IpFilterType.A_NETWORK:
			return "Network (A Class)";

		case IpFilterType.B_NETWORK:
			return "Network (B Class)";

		case IpFilterType.C_NETWORK:
			return "Network (C Class)";
	}
};

const FilterActions = memo(function FilterActions(props: IFilterActionsProps) {
	const { disabled, filterActionCallback, targetFilter } = props;
	const deleteFilter = () => filterActionCallback(targetFilter.address);

	return (
		<div className="filter-actions">
			<FilterManagementModal
				filter={ targetFilter }
				filterActionCallback={ filterActionCallback }
			>
				<Button
					type={ ButtonTypes.SECONDARY }
					ghost
					icon={ { name: "pencil" } }
					htmlType="button"
					disabled={ disabled }
				/>
			</FilterManagementModal>
			<ConfirmModal
				cancelButton={ { text: "Cancel" } }
				modalContent="Are you sure you want to do this? It cannot be undone."
				title="Delete Filter"
				okButton={ { text: "Confirm", onClick: deleteFilter } }
			>
				<Button
					type={ ButtonTypes.DANGER }
					ghost
					icon={ { name: "trash" } }
					htmlType="button"
					disabled={ disabled }
				/>
			</ConfirmModal>
		</div>
	);
});

const emptySecuritySettingsState: Partial<INetworkSecurityState> = {
	certificates: [],
	ieeeOptions: {},
	ipFiltering: {},
	securityOptions: {}
};

function SecuritySettings() {
	const dispatch = useAppDispatch();
	const securitySettings = useAppSelector(selectNetworkSecuritySettings);
	const [ securitySettingsDiff , setSecuritySettingsDiff ] = useState<Partial<INetworkSecurityState>>(
		emptySecuritySettingsState
	);

	const setNetworkSecuritySettings = (newOptions: INetworkSecurityState) => dispatch(
		setNetworkSecuritySettingsAction(newOptions)
	);

	const localSecuritySettings = produce(securitySettings, draft => {
		merge(draft, securitySettingsDiff);

		draft.certificates = securitySettingsDiff.certificates.length !== 0
			? securitySettingsDiff.certificates
			: securitySettings.certificates;

		draft.ipFiltering.filters = securitySettingsDiff.ipFiltering.filters ?? securitySettings.ipFiltering.filters;
	});

	const handleFormSubmit = (event: FormEvent<ISecuritySettingsForm>) => {
		event.preventDefault();

		setNetworkSecuritySettings(localSecuritySettings);
		setSecuritySettingsDiff(emptySecuritySettingsState);
	};

	const handleReset = (event: FormEvent) => {
		event.preventDefault();

		setSecuritySettingsDiff(emptySecuritySettingsState);
	};

	const handleFilterEnabledChange = (value: string) => {
		const newState = produce(securitySettingsDiff, (draft) => {
			draft.ipFiltering.ipFilterEnabled = value === "On";
			draft.ipFiltering.ipFilterRule = securitySettings.ipFiltering.ipFilterRule;
		});

		setSecuritySettingsDiff(newState);
	};

	const handleFilterAction = (filter: IIpFilter | string, oldFilterAddress?: string) => {
		const { ipFiltering: { filters } } = securitySettings;
		const newState = produce(securitySettingsDiff, (draft) => {
			if (!draft.ipFiltering.filters) {
				draft.ipFiltering.filters = cloneDeep(filters);
			}

			const { ipFiltering: { filters: draftFilters } } = draft;

			if (typeof filter === "string") {
				draft.ipFiltering.filters = draftFilters.filter(current => current.address !== filter);
			} else {
				const foundIndex = draftFilters.findIndex(current => current.address === oldFilterAddress);

				if (foundIndex < 0) {
					draft.ipFiltering.filters.push(filter);
				} else {
					draft.ipFiltering.filters = draftFilters.map((current, index) => {
						return index === foundIndex ? filter : current;
					});
				}
			}
		});

		setSecuritySettingsDiff(newState);
	};

	const getIpFilterData = () => localSecuritySettings.ipFiltering.filters.map(current => {
		const { address, type } = current;

		return {
			key: address,
			address,
			type: getIpFilterTypeDisplayName(type),
			actions: (
				<FilterActions
					disabled={ !localSecuritySettings.ipFiltering.ipFilterEnabled }
					targetFilter={ current }
					filterActionCallback={ handleFilterAction }
				/>
			)
		};
	});

	const handleRtspAuthSelect = (value: RtspAuthenticationType) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.securityOptions.rtspAuthType = value;
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const handleHttpsEnabledSelect = (value: string) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.securityOptions.httpsEnabled = value === "On";
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const handleHttpAuthTypeSelect = (value: HttpAuthenticationType) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.securityOptions.httpAuthType = value;
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const handleEapolVersionSelect = (value: string) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.ieeeOptions.eapolVersion = +value;
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const handleEapTypeSelect = (value: EapType) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.ieeeOptions.eapType = value;
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const handleIpRuleSelect = (value: IpFilterRule) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.ipFiltering.ipFilterRule = value;
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const handleIeeeEnabledSelect = (value: string) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.ieeeOptions.enabled = value === "On";
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const handleCertificateUpdate = (certificate: ISecurityCertificate) => {
		const newState = produce(securitySettingsDiff, (draft) => {
			if (draft.certificates.length === 0) {
				draft.certificates = cloneDeep(localSecuritySettings.certificates);
			}

			draft.certificates = draft.certificates.map(currentCert => {
				return currentCert.type === certificate.type ? certificate : currentCert;
			});
		});

		setSecuritySettingsDiff(newState);
	};

	const handleIeeeIdUpdate = (value: string) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.ieeeOptions.id = value;
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const handleIeeePasswordUpdate = (value: string) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.ieeeOptions.password = value;
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const handleIeeeClientKeyPasswordUpdate = (value: string) => {
		const newStateDiff = produce(securitySettingsDiff, draft => {
			draft.ieeeOptions.clientKeyPassword = value;
		});

		setSecuritySettingsDiff(newStateDiff);
	};

	const certificateFields = localSecuritySettings.certificates.map((cert) => (
		<CertificateField
			key={ cert.type }
			ieeeEnabled={ localSecuritySettings.ieeeOptions.enabled }
			certificate={ cert }
			handleCertUpdate={ handleCertificateUpdate }
		/>
	));

	return (
		<form
			className="security-settings"
			noValidate
			onReset={ handleReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="card">
					<div className="card-title">
						<h4>Security</h4>
					</div>
					<div className="form-row">
						<span>RTSP Authentication</span>
						<Select
							allowClear={ false }
							className="security-select"
							name="authSelect"
							onSelect={ handleRtspAuthSelect }
							options={ rtspOptions }
							value={ localSecuritySettings.securityOptions.rtspAuthType }
						/>
					</div>
					<div className="form-row">
						<span>HTTPS Enabled</span>
						<Select
							allowClear={ false }
							className="security-select"
							name="httpEnabled"
							onSelect={ handleHttpsEnabledSelect }
							options={ enabledOptions }
							value={ localSecuritySettings.securityOptions.httpsEnabled ? "On" : "Off" }
						/>
					</div>
					<div className="form-row">
						<span>HTTP Authentication Type</span>
						<Select
							allowClear={ false }
							className="security-select"
							name="httpAuthType"
							onSelect={ handleHttpAuthTypeSelect }
							options={ httpsAuthTypeOptions }
							value={ localSecuritySettings.securityOptions.httpAuthType }
						/>
					</div>
				</div>
				<div className="card">
					<div className="card-title">
						<h4>IP Filtering</h4>
					</div>
					<div className="form-row">
						<span>IP Filter Enabled</span>
						<Select
							allowClear={ false }
							className="security-select"
							name="ipFilterEnabled"
							options={ ipFilterEnabledOptions }
							onSelect={ handleFilterEnabledChange }
							value={ localSecuritySettings.ipFiltering.ipFilterEnabled ? "On" : "Off" }
						/>
					</div>
					<div className="form-row">
						<span>IP Filter Rule</span>
						<Select
							allowClear={ false }
							className="security-select"
							disabled={ !localSecuritySettings.ipFiltering.ipFilterEnabled }
							name="ipFilterRule"
							onSelect={ handleIpRuleSelect }
							options={ ipFilterRuleOptions }
							value={ localSecuritySettings.ipFiltering.ipFilterRule }
						/>
					</div>
					<FilterManagementModal
						allFilters={ localSecuritySettings.ipFiltering.filters }
						filterActionCallback={ handleFilterAction }
					>
						<Button
							className="add-filter"
							disabled={ !localSecuritySettings.ipFiltering.ipFilterEnabled }
							htmlType="button"
							icon={ { name: "plus" } }
							type={ ButtonTypes.TERTIARY }
						>
							New Filter
						</Button>
					</FilterManagementModal>
					<Table
						columns={ ipFilterTableColumns }
						data={ getIpFilterData() }
					/>
				</div>
				<div className="card">
					<div className="card-title">
						<h4>IEEE 802.1x Setup</h4>
					</div>
					<div className="form-row">
						<span>IEEE 802.1x</span>
						<Select
							allowClear={ false }
							className="security-select"
							name="ieeeEnabled"
							onSelect={ handleIeeeEnabledSelect }
							options={ enabledOptions }
							value={ localSecuritySettings.ieeeOptions.enabled ? "On" : "Off" }
						/>
					</div>
					<div className="form-row">
						<span>EAP Type</span>
						<Select
							allowClear={ false }
							className="security-select"
							disabled={ !localSecuritySettings.ieeeOptions.enabled }
							name="eapType"
							onSelect={ handleEapTypeSelect }
							options={ eapTypeOptions }
							value={ localSecuritySettings.ieeeOptions.eapType }
						/>
					</div>
					<div className="form-row">
						<span>EAPOL Version</span>
						<Select
							allowClear={ false }
							className="security-select"
							disabled={ !localSecuritySettings.ieeeOptions.enabled }
							name="eapolVersion"
							onSelect={ handleEapolVersionSelect }
							options={ eapolOptions }
							value={ localSecuritySettings.ieeeOptions.eapolVersion }
						/>
					</div>
					<div className="form-row">
						<span>ID</span>
						<Input
							autoComplete="new-password"
							disabled={ !localSecuritySettings.ieeeOptions.enabled }
							name="ieeeId"
							onUpdate={ handleIeeeIdUpdate }
							value={ localSecuritySettings.ieeeOptions.id }
							wrapClassName="security-wrapper"
						/>
					</div>
					<div className="form-row">
						<span>Password</span>
						<Input
							autoComplete="new-password"
							disabled={ !localSecuritySettings.ieeeOptions.enabled }
							name="ieeeId"
							onUpdate={ handleIeeePasswordUpdate }
							password
							value={ localSecuritySettings.ieeeOptions.password }
							wrapClassName="security-wrapper"
						/>
					</div>
				</div>
				<div className="card">
					<div className="card-title">
						<h4>Certificates</h4>
					</div>
					{ certificateFields }
					<div className="form-row">
						<span>Client Key Password</span>
						<Input
							autoComplete="new-password"
							disabled={ !localSecuritySettings.ieeeOptions.enabled }
							onUpdate={ handleIeeeClientKeyPasswordUpdate }
							password
							value={ localSecuritySettings.ieeeOptions.clientKeyPassword }
							wrapClassName="security-wrapper"
						/>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(securitySettings, localSecuritySettings) } />
		</form>
	);
}

export default memo(SecuritySettings);
