import { FormEvent, memo, useState } from "react";
import EmailServerSettings from "Components/ConfigLayout/Event/EmailFtpSettings/EmailServerSettings";
import { Tabs } from "@clintonelec/react-storybook";
import FtpServerSettings from "Components/ConfigLayout/Event/EmailFtpSettings/FtpServerSettings";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import EmailListSettings from "Components/ConfigLayout/Event/EmailFtpSettings/EmailListSettings";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectEmailFtpSettings, setEmailFtpSettingsAction } from "Data/Redux/Slices/Settings/Event/EmailFtp";
import { isEqual } from "lodash";
import { produce } from "immer";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

function EmailFtpSettings() {
	const dispatch = useAppDispatch();
	const emailFtpSettings = useAppSelector(selectEmailFtpSettings);
	const localEmailFtpState = useState(emailFtpSettings);
	const [ localEmailFtpSettings, setLocalEmailFtpSettings ] = localEmailFtpState;
	const setEmailFtpSettings = () => dispatch(setEmailFtpSettingsAction(localEmailFtpSettings));
	const disabled = isEqual(localEmailFtpSettings, emailFtpSettings)
		&& !emailFtpSettings.ftpServerSettings.password
		&& !emailFtpSettings.emailServerSettings.password;

	const panes = [
		{
			title: "Email Server",
			content: <EmailServerSettings localEmailFtpState={ localEmailFtpState }/>
		},
		{
			title: "Email List",
			content: <EmailListSettings localEmailFtpState={ localEmailFtpState }/>
		},
		{
			title: "FTP Server",
			content: <FtpServerSettings localEmailFtpState={ localEmailFtpState }/>
		}
	];

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLocalEmailFtpSettings(emailFtpSettings);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setEmailFtpSettings();
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, draft => {
			draft.ftpServerSettings.password = "";
		}));
	};

	return (
		<form
			className="settings-form email-ftp-settings"
			noValidate
			onReset={ handleReset }
			onSubmit={ handleSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="settings-content">
						<SettingsCard size="medium" title="Email / FTP">
							<Tabs panes={ panes } />
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(EmailFtpSettings);
