import { PayloadAction } from "@reduxjs/toolkit";
import { DateFormat, TimeFormat, TimezoneDst } from "Data/Objects/System";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IDateTimeSettingsState } from "Interfaces";

const initialState: IDateTimeSettingsState = {
	dateTimeSetup: {
		dateTime: null,
		dateFormat: DateFormat.MM_DD_YYYY,
		timeFormat: TimeFormat.TWENTYFOUR_HOUR
	},
	networkTimeServerSetup: {
		timeServer: "pool.ntp.org",
		autoTimeSync: false,
		syncTime: "00:00"
	},
	timezoneDstSetup: {
		timezoneDst: TimezoneDst.T6,
		dst: true
	}
};

export const setDateTimeReducer = (state: IDateTimeSettingsState, action: PayloadAction<IDateTimeSettingsState>) => {
	return action.payload;
};

export const getDateTime = (state: IDateTimeSettingsState) => {
	return state;
};

const dateTimeSlice = createAppSlice({
	name: "datetime",
	initialState,
	reducers: {
		setDateTimeAction: setDateTimeReducer
	},
	selectors: {
		selectDateTime: getDateTime
	}
});

export default dateTimeSlice;

export const { setDateTimeAction } = dateTimeSlice.actions;
export const { selectDateTime } = dateTimeSlice.getSelectors<AppState>(state => state.settings.system.datetime);
