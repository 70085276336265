import { Dispatch, memo, SetStateAction } from "react";
import "Components/ConfigLayout/Camera/AiAnalytics/AdvancedAiSettings/AdvancedAiSettings.less";
import {
	AspectMaintainer, Button, ButtonTypes, ISelectOption, Select
} from "@clintonelec/react-storybook";
import { produce } from "immer";
import { IAiAnalyticsState, IPoint } from "Interfaces";
import { StaticObjectFilter, TrackReferencePoint } from "Data/Objects/Camera";
import InteractiveRectangle from "Components/Global/InteractiveRectangle";

const staticObjectFilterOptions: ISelectOption[] = Object.values(StaticObjectFilter).map((filterDegree) => ({
	label: filterDegree,
	value: filterDegree
}));

const trackReferencePointOptions: ISelectOption[] = Object.values(TrackReferencePoint).map((referencePoint) => ({
	label: referencePoint,
	value: referencePoint
}));

interface IAdvancedAiSettingsProps {
	localAiAnalyticsState: [ IAiAnalyticsState, Dispatch<SetStateAction<IAiAnalyticsState>> ];
}

function AdvancedAiSettings(props: IAdvancedAiSettingsProps) {
	const { localAiAnalyticsState } = props;
	const [ localAiAnalyticsSettings, setLocalAiAnalyticsSettings ] = localAiAnalyticsState;
	const {
		enabled, staticObjectFilter, trackReferencePoint, maxSizeFilter, minSizeFilter, minSizeFilterEnabled,
		maxSizeFilterEnabled
	} = localAiAnalyticsSettings;

	const updateMinSizeFilterEnabled = () => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.minSizeFilterEnabled = !draft.minSizeFilterEnabled;
		}));
	};

	const updateMaxSizeFilterEnabled = () => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.maxSizeFilterEnabled = !draft.maxSizeFilterEnabled;
		}));
	};

	const updateStaticObjectFilter = (filterDegree: StaticObjectFilter) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.staticObjectFilter = filterDegree;
		}));
	};

	const updateTrackReferencePoint = (referencePoint: TrackReferencePoint) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.trackReferencePoint = referencePoint;
		}));
	};

	const updateMaxFilterPoints = (points: IPoint[]) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.maxSizeFilter = points;
		}));
	};

	const updateMinFilterPoints = (points: IPoint[]) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.minSizeFilter = points;
		}));
	};

	const renderAdvancedSettings = () => {
		if (!enabled) {
			return (
				<div className="enable-message">Enable AI Analytics to adjust rule settings.</div>
			);
		}

		return (
			<div className="settings">
				<div className="form-row">
					<span>Static Object Filter</span>
					<Select
						allowClear={ false }
						onSelect={ updateStaticObjectFilter }
						options={ staticObjectFilterOptions }
						value={ staticObjectFilter }
					/>
				</div>
				<div className="form-row">
					<span>Track Reference Point</span>
					<Select
						allowClear={ false }
						onSelect={ updateTrackReferencePoint }
						options={ trackReferencePointOptions }
						value={ trackReferencePoint }
					/>
				</div>
			</div>
		);
	};

	const renderInteractivePolygon = () => {
		if (!enabled) {
			return;
		}

		return (
			<InteractiveRectangle
				points={ minSizeFilterEnabled ? minSizeFilter : null }
				onUpdate={ updateMinFilterPoints }
				points2={ maxSizeFilterEnabled ? maxSizeFilter : null }
				onUpdate2={ updateMaxFilterPoints }
			/>
		);
	};

	return (
		<div className="advanced-settings">
			<div className="column">
				<div className="camera-container">
					<AspectMaintainer>
						<div className="camera-snapshot">
							{ renderInteractivePolygon() }
						</div>
					</AspectMaintainer>
				</div>
				<div className="filter-buttons">
					<Button
						className="filter-button"
						disabled={ !enabled }
						ghost={ !minSizeFilterEnabled }
						htmlType="button"
						onClick={ updateMinSizeFilterEnabled }
						type={ ButtonTypes.PRIMARY }
					>
						Min Size Filter
					</Button>
					<Button
						className="filter-button"
						disabled={ !enabled }
						ghost={ !maxSizeFilterEnabled }
						htmlType="button"
						onClick={ updateMaxSizeFilterEnabled }
						type={ ButtonTypes.WARNING }
					>
						Max Size Filter
					</Button>
				</div>
			</div>
			<div className="column">
				{ renderAdvancedSettings() }
			</div>
		</div>
	);
}

export default memo(AdvancedAiSettings);
