// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Images/Clinton_Logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-bar {
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
  color: #7e7e7e;
  display: flex;
  height: 56px;
  justify-content: center;
  position: relative;
  width: 100%;
}
.title-bar-left {
  height: 100%;
  left: 16px;
  position: absolute;
}
.title-bar-left .logo {
  background: no-repeat center url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 100%;
  width: 176px;
}
.title-bar-center {
  align-items: center;
  display: flex;
  gap: 32px;
}
.title-bar-right {
  align-items: center;
  display: flex;
  gap: 16px;
  position: absolute;
  right: 16px;
}
.title-bar-right .camera-model {
  font-weight: 500;
}
.title-bar-right .icon {
  cursor: pointer;
}
a:link,
a:visited,
.right-icon {
  color: #7e7e7e;
}
a:hover,
a.active,
.right-icon:hover {
  color: #0e6499;
}
.language-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.language-list li {
  cursor: pointer;
  line-height: 32px;
  text-align: center;
  width: 80px;
}
.language-list li:hover,
.language-list li.current-language {
  color: #127ec0;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TitleBar/TitleBar.less"],"names":[],"mappings":"AAEA;EACC,mBAAA;EACA,uBAAA;EACA,gCAAA;EACA,cAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;AADD;AAGC;EACC,YAAA;EACA,UAAA;EACA,kBAAA;AADF;AAFC;EAME,oEAAA;EACA,YAAA;EACA,YAAA;AADH;AAKC;EACC,mBAAA;EACA,aAAA;EACA,SAAA;AAHF;AAMC;EACC,mBAAA;EACA,aAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;AAJF;AADC;EAQE,gBAAA;AAJH;AAJC;EAYE,eAAA;AALH;AAUA;;;EACC,cAAA;AAND;AASA;;;EACC,cAAA;AALD;AAQA;EACC,gBAAA;EACA,SAAA;EACA,eAAA;AAND;AAGA;EAME,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AANF;AAQE;;EACC,cAAA;EACA,gBAAA;AALH","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.title-bar {\n\talign-items: center;\n\tbackground-color: white;\n\tborder-bottom: 1px solid @light-gray-0;\t\n\tcolor: @light-gray-2;\n\tdisplay: flex;\n\theight: 56px;\n\tjustify-content: center;\n\tposition: relative;\n\twidth: 100%;\n\n\t&-left {\n\t\theight: 100%;\n\t\tleft: 16px;\n\t\tposition: absolute;\n\n\t\t.logo {\n\t\t\tbackground: no-repeat center url(\"../../Images/Clinton_Logo.svg\");\n\t\t\theight: 100%;\n\t\t\twidth: 176px;\n\t\t}\n\t}\n\n\t&-center {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tgap: 32px;\n\t}\n\n\t&-right {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tgap: 16px;\n\t\tposition: absolute;\n\t\tright: 16px;\n\n\t\t.camera-model {\n\t\t\tfont-weight: 500;\n\t\t}\n\n\t\t.icon {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n}\n\na:link, a:visited, .right-icon {\n\tcolor: @light-gray-2;\n}\n\na:hover, a.active, .right-icon:hover {\n\tcolor: @blue-2;\n}\n\n.language-list {\n\tlist-style: none;\n\tmargin: 0;\n\tpadding-left: 0;\n\n\tli {\n\t\tcursor: pointer;\n\t\tline-height: 32px;\n\t\ttext-align: center;\n\t\twidth: 80px;\n\n\t\t&:hover, &.current-language {\n\t\t\tcolor: @blue-1;\n\t\t\tfont-weight: 600;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
