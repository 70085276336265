import { FormEvent, Fragment, memo, useState } from "react";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import "Components/ConfigLayout/Network/SnmpSettings/SnmpSettings.less";
import { Checkbox, Input, Select, Switch } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectSnmp, setSnmpAction } from "Data/Redux/Slices/Settings/Network/Snmp";
import { ISnmpSettingsState, SnmpType } from "Interfaces";
import { produce } from "immer";
import { isEqual } from "lodash";
import { snmpTypeOptions } from "Data/Objects/Network";
import SnmpV3Options from "Components/ConfigLayout/Network/SnmpSettings/SnmpV3Options";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

interface ISnmpSettingsForm extends HTMLFormElement {
	readCommunity: RadioNodeList;
	writeCommunity: HTMLInputElement;
	trapAddress: HTMLInputElement;
	trapCommunity: HTMLInputElement;
	authFailure: HTMLInputElement;
	netowrkConnection: HTMLInputElement;
	otherOption: HTMLInputElement;
	username: HTMLInputElement;
	authPassword: HTMLInputElement;
	privacyPassword: HTMLInputElement;
}

function SnmpSettings() {
	const dispatch = useAppDispatch();
	const snmpSettings = useAppSelector(selectSnmp);
	const [ submitted, setSubmitted ] = useState(false);
	const [ localSnmpSettings, setLocalSnmpSettings ] = useState<ISnmpSettingsState>(snmpSettings);
	const selectedSnmpOption = localSnmpSettings[ localSnmpSettings.snmpType ];
	const setSnmpSettings = () => dispatch(setSnmpAction(localSnmpSettings));

	const handleSnmpTypeSelect = (newSnmpType: SnmpType) => {
		const newLocalState = produce(localSnmpSettings, draft => {
			draft.snmpType = newSnmpType;
		});

		setLocalSnmpSettings(newLocalState);
	};

	const handleTrapsEnabledSwitch = (newChecked: boolean) => {
		const newLocalState = produce(localSnmpSettings, draft => {
			draft[ localSnmpSettings.snmpType ].trapEnabled = newChecked;
		});

		setLocalSnmpSettings(newLocalState);
	};

	const handleFormSubmit = (event: FormEvent<ISnmpSettingsForm>) => {
		event.preventDefault();
		setSubmitted(true);

		if (event.currentTarget.checkValidity()) {
			setSnmpSettings();
		}
	};

	const handleFormChanged = (event: FormEvent<ISnmpSettingsForm>) => {
		const {
			trapAddress: { value: newTrapAddress },
			trapCommunity: { value: newTrapCommunity },
			authFailure: { checked: newAuthFailure },
			networkConnection: { checked: newNetworkConnection },
			otherOption: { checked: newOtherOption }
		} = event.currentTarget;

		const newStateDiff = produce(localSnmpSettings, draft => {
			switch (localSnmpSettings.snmpType) {
				case SnmpType.V2:
					{
						const {
							readCommunity: { value: newReadCommunity },
							writeCommunity: { value: newWriteCommunity }
						} = event.currentTarget;

						draft.v2.readCommunity = newReadCommunity;
						draft.v2.writeCommunity = newWriteCommunity;
					}

					break;

				case SnmpType.V3:
					{
						const {
							username: { value: newUsername },
							authPassword: { value: newAuthPassword },
							privacyPassword: { value: newPrivacyPassword }
						} = event.currentTarget;

						draft.v3.authPassword = newAuthPassword;
						draft.v3.username = newUsername;
						draft.v3.privacyPassword = newPrivacyPassword;
					}

					break;

				default:
					return;
			}

			draft[ draft.snmpType ].trapAddress = newTrapAddress;
			draft[ draft.snmpType ].trapCommunity = newTrapCommunity;
			draft[ draft.snmpType ].trapOptions.authFailure = newAuthFailure;
			draft[ draft.snmpType ].trapOptions.networkConnection = newNetworkConnection;
			draft[ draft.snmpType ].trapOptions.otherOption = newOtherOption;
		});

		setLocalSnmpSettings(newStateDiff);
	};

	const handleFormReset = (event: FormEvent<ISnmpSettingsForm>) => {
		event.preventDefault();

		setLocalSnmpSettings(snmpSettings);
	};

	const renderSnmpFields = () => {
		if (localSnmpSettings.snmpType === SnmpType.V2) {
			return (
				<Fragment>
					<div className="form-row space">
						<span>Read Community</span>
						<Input
							name="readCommunity"
							value={ selectedSnmpOption?.readCommunity }
							wrapClassName="form-input"
						/>
					</div>
					<div className="form-row">
						<span>Write Community</span>
						<Input
							name="writeCommunity"
							value={ selectedSnmpOption?.writeCommunity }
							wrapClassName="form-input"
						/>
					</div>
				</Fragment>
			);
		}

		if (localSnmpSettings.snmpType === SnmpType.V3) {
			return (
				<SnmpV3Options
					formSubmitted={ submitted }
					localSnmpSettings={ localSnmpSettings }
					setSnmpSettings={ setLocalSnmpSettings }
				/>
			);
		}
	};

	const renderTraps = () => {
		if (localSnmpSettings.snmpType === SnmpType.NONE) {
			return;
		}

		return (
			<Fragment>
				<div className="form-row space">
					<span>Traps</span>
					<Switch
						checked={ selectedSnmpOption?.trapEnabled }
						className="snmp-switch"
						formName="trapEnabled"
						onChange={ handleTrapsEnabledSwitch }
					/>
				</div>
				<div className="form-row">
					<span>Trap Address</span>
					<Input
						disabled={ !selectedSnmpOption?.trapEnabled }
						name="trapAddress"
						value={ selectedSnmpOption?.trapAddress }
						wrapClassName="form-input"
					/>
				</div>
				<div className="form-row">
					<span>Trap Community</span>
					<Input
						disabled={ !selectedSnmpOption?.trapEnabled }
						name="trapCommunity"
						value={ selectedSnmpOption?.trapCommunity }
						wrapClassName="form-input"
					/>
				</div>
				<div className="trap-options">
					<span>Trap Options</span>
					<div className="checkbox-group">
						<Checkbox
							checked={ selectedSnmpOption?.trapOptions.authFailure }
							disabled={ !selectedSnmpOption?.trapEnabled }
							name="authFailure"
						>
							Authentication Failure
						</Checkbox>
						<Checkbox
							checked={ selectedSnmpOption?.trapOptions.networkConnection }
							disabled={ !selectedSnmpOption?.trapEnabled }
							name="networkConnection"
						>
							Network Connection
						</Checkbox>
						<Checkbox
							checked={ selectedSnmpOption?.trapOptions.otherOption }
							disabled={ !selectedSnmpOption?.trapEnabled }
							name="otherOption"
						>
							Other Option
						</Checkbox>
					</div>
				</div>
			</Fragment>
		);
	};

	return (
		<form
			className="settings-form"
			noValidate
			onChange={ handleFormChanged }
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="snmp-settings settings-content">
						<SettingsCard size="medium" title="SNMP Settings">
							<div className="form-row">
								<span>SNMP Type</span>
								<Select
									allowClear={ false }
									className="form-select"
									onSelect={ handleSnmpTypeSelect }
									options={ snmpTypeOptions }
									value={ localSnmpSettings.snmpType }
								/>
							</div>
							{ renderSnmpFields() }
							{ renderTraps() }
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localSnmpSettings, snmpSettings) } />
		</form>
	);
}

export default memo(SnmpSettings);
