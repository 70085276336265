import {
	CSSProperties, MouseEvent as ReactMouseEvent, WheelEvent, memo, useEffect, useMemo,
	useState
} from "react";
import "Components/PlaybackLayout/HistoricalTimeline/HistoricalTimeline.less";
import { DateUnits, ceilingDate, diffDates, diffDatesAbs, floorDate, formatDate } from "@clintonelec/typescriptutils";
import { ZoomLevel } from "Interfaces";
import { usePropsChanged } from "@clintonelec/react-storybook";
import { events } from "Data/Dummy/Playback";
import { useAppSelector } from "Data/Redux/Store";
import { selectDate, selectZoomLevel } from "Data/Redux/Slices/Playback";

interface ITimestampProps {
	timestamp: Date;
	className?: string;
	scrollTimelineEnd: Date;
	scrollTimelineStart: Date;
}

const Timestamp = memo(function Timestamp(props: ITimestampProps) {
	const { timestamp, className, scrollTimelineEnd, scrollTimelineStart } = props;
	const timestampText = formatDate(timestamp, "HH:mm");
	const leftPercent = Math.max(
		0, diffDatesAbs(timestamp, scrollTimelineStart) / diffDatesAbs(scrollTimelineEnd, scrollTimelineStart) * 100
	);

	const compositeClass = [ "timestamp", className ].join(" ");
	const customStyles = {
		left: `${ leftPercent }%`
	} as CSSProperties;

	return (
		<div className={ compositeClass } style={ customStyles }>
			<span>{ timestampText }</span>
			<div className="timestamp-marker" />
		</div>
	);
});

interface ITimelineEventProps {
	dayStart: Date;
	event: number;
	index: number;
	scrollTimelineEnd: Date;
	scrollTimelineStart: Date;
	zoomLevel: ZoomLevel;
}

const TimelineEvent = memo(function TimelineEvent(props: ITimelineEventProps) {
	const { dayStart, event, index, scrollTimelineEnd, scrollTimelineStart, zoomLevel } = props;
	const secondsInDay = 86400;
	const eventCount = 960;
	const eventDate = new Date(dayStart.getTime() + index / eventCount * secondsInDay * DateUnits.SECONDS );
	const eventLeftPercent = diffDates(eventDate, scrollTimelineStart) /
		diffDates(scrollTimelineEnd, scrollTimelineStart) * 100;

	const customStyles = {
		width: `${ 1/eventCount * 100 * (60 / zoomLevel) }%`,
		left: `${ eventLeftPercent }%`
	} as CSSProperties;

	const eventTypes = [ "continuous-event", "alarm-event", "motion-event", "network-loss-event", "ai-event" ];
	const compositeClass = [ "event", eventTypes[ event - 1 ] ].join(" ");

	return <div className={ compositeClass } style={ customStyles } />;
});

const dummyDate = floorDate(new Date());

dummyDate.setHours(3);

function HistoricalTimeline() {
	const zoomLevel = useAppSelector(selectZoomLevel);
	const radius = 12;
	const halfTimeline = zoomLevel * radius * DateUnits.MINUTES;
	const date = useAppSelector(selectDate);
	const [ playheadTime, setPlayheadTime ] = useState(date); // TODO: replace date with videoplayer time when it exists
	const [ timelineDate, setTimelineDate ] = useState(playheadTime);
	const [ scrollDistance, setScrollDistance ] = useState(0);

	usePropsChanged(date, () => {
		setScrollDistance(0);
		setTimelineDate(date);
		setPlayheadTime(date);
	});

	usePropsChanged(zoomLevel, () => {
		if (zoomLevel === ZoomLevel.SIXTY_MINUTES) {
			setScrollDistance(0);
		}
	});

	// simulating responding to some redux video player time update by updating the playhead, can be deleted
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (playheadTime) {
				setPlayheadTime(new Date(playheadTime.getTime() + 1000));
			}
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [ playheadTime ]);

	if (timelineDate?.getTime() - halfTimeline < floorDate(timelineDate)?.getTime()) {
		setTimelineDate(new Date(floorDate(timelineDate).getTime() + halfTimeline));
	} else if (timelineDate?.getTime() + halfTimeline > ceilingDate(timelineDate)?.getTime()) {
		setTimelineDate(new Date(ceilingDate(timelineDate)?.getTime() - halfTimeline));
	}

	const dayStart = floorDate(timelineDate);
	const dayEnd = ceilingDate(timelineDate);
	const [ dragging, setDragging ] = useState(false);
	const scrollTimelineStart = useMemo(() => {
		return new Date(timelineDate?.getTime() + scrollDistance - halfTimeline);
	}, [ timelineDate, scrollDistance, halfTimeline ]);

	const scrollTimelineEnd = useMemo(() => {
		return new Date(timelineDate?.getTime() + scrollDistance + halfTimeline);
	}, [ timelineDate, scrollDistance, halfTimeline ]);

	const handleTimelineClick = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
		if (dragging) {
			return;
		}

		const { left: timelineX, width: timelineWidth } = event.currentTarget.getBoundingClientRect();
		const { clientX } = event;
		const percent = (clientX - timelineX) / timelineWidth;
		const newTimestamp = new Date(
			scrollTimelineStart?.getTime() + (radius * 2 * zoomLevel * percent * DateUnits.MINUTES)
		);

		setPlayheadTime(newTimestamp);
	};

	const calculateScrollDistance = (scrollDelta: number) => {
		const timelineEndShift = scrollTimelineEnd?.getTime() + scrollDelta;
		const timelineStartShift = scrollTimelineStart?.getTime() + scrollDelta;

		if (timelineEndShift >= dayEnd?.getTime()) {
			const maxDelta = dayEnd?.getTime() - scrollTimelineEnd?.getTime();

			return scrollDistance + maxDelta;
		} else if (timelineStartShift <= dayStart?.getTime()) {
			const minDelta = dayStart?.getTime() - scrollTimelineStart?.getTime();

			return scrollDistance + minDelta;
		} else {
			return scrollDistance + scrollDelta;
		}
	};

	const handleTimelineScroll = (event: WheelEvent) => {
		if (event.buttons & 1 || zoomLevel === ZoomLevel.SIXTY_MINUTES) {
			return;
		}

		const scrollDelta = zoomLevel * Math.sign(event.deltaY) * DateUnits.MINUTES;

		setScrollDistance(calculateScrollDistance(scrollDelta));
	};

	const handleTimelineDrag = (event: ReactMouseEvent) => {
		if (!(event.buttons & 1) || zoomLevel === ZoomLevel.SIXTY_MINUTES) {
			return;
		}

		setDragging(true);

		const scrollDelta = zoomLevel * event.movementX / -100 * DateUnits.MINUTES;

		setScrollDistance(calculateScrollDistance(scrollDelta));
	};

	const handleDragEnd = () => {
		setDragging(false);
	};

	const getTimestamps = () => {
		const nearestTimestamp = new Date(timelineDate?.getTime() + scrollDistance);

		nearestTimestamp.setMinutes(Math.round(nearestTimestamp.getMinutes() / zoomLevel) * zoomLevel);
		nearestTimestamp.setSeconds(0);

		const timeStamps = [ nearestTimestamp ];

		for (let i = 1; i <= radius; i++) {
			timeStamps.push(
				new Date(nearestTimestamp?.getTime() + (zoomLevel * i * DateUnits.MINUTES)),
				new Date(nearestTimestamp?.getTime() - (zoomLevel * i * DateUnits.MINUTES))
			);
		}

		return timeStamps.filter((currentTimestamp: Date) =>
			currentTimestamp > scrollTimelineStart && currentTimestamp < scrollTimelineEnd
		);
	};

	const timestampContent = getTimestamps()
		.map((currentTimestamp: Date) => (
			<Timestamp
				timestamp={ currentTimestamp }
				scrollTimelineEnd={ scrollTimelineEnd }
				scrollTimelineStart={ scrollTimelineStart }
				key={ currentTimestamp?.getTime() }
			/>
		));

	const renderPlayhead = () => {
		const playheadLeftPercent = diffDates(playheadTime, scrollTimelineStart) /
			diffDates(scrollTimelineEnd, scrollTimelineStart) * 100;

		const customStyles = {
			left: `${ playheadLeftPercent }%`
		} as CSSProperties;

		return (
			<div className="playhead" style={ customStyles } />
		);
	};

	const renderEvents = () => {
		return events.map((event, index) => {
			return (
				<TimelineEvent
					// eslint-disable-next-line react/no-array-index-key
					key={ index }
					dayStart={ dayStart }
					event={ event }
					index={ index + 1 }
					scrollTimelineEnd={ scrollTimelineEnd }
					scrollTimelineStart={ scrollTimelineStart }
					zoomLevel={ zoomLevel }
				/>
			);
		});
	};

	if (!playheadTime) {
		return (
			<div className="timeline-container">
				<div className="timeline">
					<div className="seek-container" />
				</div>
			</div>
		);
	}

	return (
		<div className="timeline-container">
			<div className="timeline">
				<div
					className="seek-container"
					onWheel={ handleTimelineScroll }
					onMouseMove={ handleTimelineDrag }
					onClick={ handleDragEnd }
				>
					<div className="timestamps">
						{ timestampContent }
					</div>
					<div
						className={ `event-container $timeline-1` }
						onClick={ handleTimelineClick }
					>
						{ renderEvents() }
					</div>
					{ renderPlayhead() }
				</div>
			</div>
		</div>
	);
}

export default memo(HistoricalTimeline);
