import { PayloadAction } from "@reduxjs/toolkit";
import { DUMMY_SYSTEM_INFORMATION } from "Data/Dummy/System";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { ISystemInformation } from "Interfaces";

const setSystemInformationReducer = (state: ISystemInformation, action: PayloadAction<ISystemInformation>) => {
	Object.assign(state, action.payload);
};

const getSystemInformation = (state: ISystemInformation) => {
	return state;
};

const systemInformationSlice = createAppSlice({
	name: "information",
	initialState: DUMMY_SYSTEM_INFORMATION,
	reducers: {
		setSystemInformationAction: setSystemInformationReducer
	},
	selectors: {
		selectSystemInformation: getSystemInformation
	}
});

export default systemInformationSlice;

export const { setSystemInformationAction } = systemInformationSlice.actions;
export const { selectSystemInformation } = systemInformationSlice.getSelectors<AppState>(
	state => state.settings.system.information
);
