// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-layout {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.config-layout .config-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
}
.config-layout .config-main .config-content {
  background-color: #f0f0f0;
  display: flex;
  flex: 1;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/ConfigLayout.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,gBAAA;AADD;AAFA;EAME,aAAA;EACA,OAAA;EACA,sBAAA;EACA,gBAAA;AADF;AARA;EAYG,yBAAA;EACA,aAAA;EACA,OAAA;EACA,cAAA;AADH","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.config-layout {\n\tdisplay: flex;\n\tflex: 1;\n\toverflow: hidden;\n\n\t.config-main {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tflex-direction: column;\n\t\toverflow-x: auto;\n\n\t\t.config-content {\n\t\t\tbackground-color: @light-gray-0;\n\t\t\tdisplay: flex;\n\t\t\tflex: 1;\n\t\t\toverflow: auto;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
