import { memo } from "react";
import "Components/ConfigLayout/ConfigLayout.less";
import ConfigNav from "Components/ConfigLayout/ConfigNav";
import Breadcrumb from "Components/ConfigLayout/Breadcrumb";
import { Outlet } from "react-router-dom";

function ConfigLayout() {
	return (
		<div className="config-layout">
			<ConfigNav />
			<div className="config-main">
				<Breadcrumb />
				<div className="config-content">
					<Outlet />
				</div>
			</div>
		</div>
	);
}

export default memo(ConfigLayout);
