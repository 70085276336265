import { combineSlices } from "@reduxjs/toolkit";
import upnpSlice from "Data/Redux/Slices/Settings/Network/Upnp";
import networkSecuritySlice from "Data/Redux/Slices/Settings/Network/Security";
import ipSettingsSlice from "Data/Redux/Slices/Settings/Network/IpSettings";
import snmpSlice from "Data/Redux/Slices/Settings/Network/Snmp";
import multicastSettings from "Data/Redux/Slices/Settings/Network/MulticastSettings";

export default combineSlices(
	networkSecuritySlice, upnpSlice, ipSettingsSlice, snmpSlice, multicastSettings
);
