import { IPlaybackState } from "Interfaces";
import { createAppSlice } from "Data/Redux/Store";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState: IPlaybackState = {
	date: null
};

export const setDateReducer = (state: IPlaybackState, action: PayloadAction<number>) => {
	state.date = action.payload;
};

export const getDate = (state: IPlaybackState) => {
	return state.date ? new Date(state.date) : null;
};

const playbackSlice = createAppSlice({
	name: "playback",
	initialState,
	reducers: (create) => ({
		setDateAction: create.reducer(setDateReducer)
	}),
	selectors: {
		selectDate: getDate
	}
});

export default playbackSlice;

export const { setDateAction } = playbackSlice.actions;
export const { selectDate } = playbackSlice.selectors;
