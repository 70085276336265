import { FormEvent, memo, useState } from "react";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { Input, ITabPane, Tabs } from "@clintonelec/react-storybook";
import "Components/ConfigLayout/Network/IpSetupSettings/IpSetupSettings.less";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectIpSettings, setIpSettingsAction } from "Data/Redux/Slices/Settings/Network/IpSettings";
import Ipv4SetupSettings from "Components/ConfigLayout/Network/IpSetupSettings/Ipv4SetupSettings";
import Ipv6SetupSettings from "Components/ConfigLayout/Network/IpSetupSettings/Ipv6SetupSettings";
import { produce } from "immer";
import { isEqual, merge } from "lodash";
import { IIpv6Address, INetworkIpSettingsState } from "Interfaces";
import { IIpv6RadioSettings, invalidPortValueMessage } from "Data/Objects/Network";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

export interface IIpSettingsFormFields extends HTMLFormElement {
	dhcp: HTMLFormElement;
	ipv4Address: HTMLFormElement;
	subnetMask: HTMLFormElement;
	ipv4Gateway: HTMLFormElement;
	dnsIpv4: HTMLFormElement[];
	ipv6Radio: HTMLFormElement;
	linkLocalAddress: HTMLFormElement;
	ipv6Address: HTMLFormElement[];
	ipv6Cidr: HTMLFormElement[];
	ipv6Gateway: HTMLFormElement;
	dnsIpv6: HTMLFormElement[];
	httpPort: HTMLFormElement;
	rtspPort: HTMLFormElement;
	httpsPort: HTMLFormElement;
}

function IpSetupSettings() {
	const dispatch = useAppDispatch();
	const ipSetupSettings = useAppSelector(selectIpSettings);
	const [ dhcp, setDhcp ] = useState<boolean>();
	const [ ipv4Address, setIpv4Address ] = useState<string>();
	const [ subnetMask, setSubnetMask ] = useState<string>();
	const [ ipv4Gateway, setIpv4Gateway ] = useState<string>();
	const [ dnsIpv4, setDnsIpv4 ] = useState<string[]>([]);
	const [ ipv6Radio, setIpv6Radio ] = useState<IIpv6RadioSettings>();
	const [ linkLocalAddress, setLinkLocalAddress ] = useState<string>();
	const [ ipv6AddressInfo, setIpv6AddressInfo ] = useState<IIpv6Address[]>();
	const [ ipv6Gateway, setIpv6Gateway ] = useState<string>();
	const [ dnsIpv6, setDnsIpv6 ] = useState<string[]>([]);
	const [ httpPort, setHttpPort ] = useState<number>();
	const [ rtspPort, setRtspPort ] = useState<number>();
	const [ httpsPort, setHttpsPort ] = useState<number>();
	const ipSetupSettingsDiff: Partial<INetworkIpSettingsState> = {
		ipv4Settings: {
			dhcp,
			ipv4Address,
			subnetMask,
			ipv4Gateway,
			dnsIpv4
		},
		ipv6Settings: {
			ipv6Radio,
			linkLocalAddress,
			ipv6AddressInfo,
			ipv6Gateway,
			dnsIpv6
		},
		servicePortSettings: {
			httpPort,
			rtspPort,
			httpsPort
		}
	};

	const localIpSetupSettings = produce(ipSetupSettings, (draft) => {
		merge(draft, ipSetupSettingsDiff);
	});

	const { servicePortSettings: servicePort } = localIpSetupSettings;

	const setIpSetup = () => {
		dispatch(setIpSettingsAction(localIpSetupSettings));
	};

	const handleFormSubmit = (event: FormEvent<IIpSettingsFormFields>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setIpSetup();
			resetIpSetupSettingsDiff();
		}
	};

	const handleUpdateDnsIpv4 = (value: string[]) => {
		setDnsIpv4(value);
	};

	const handleUpdateIpv6AddressInfo = (updateInfo: [ number, string]) => {
		const [ index, value ] = updateInfo;

		setIpv6AddressInfo(produce(localIpSetupSettings.ipv6Settings.ipv6AddressInfo, draft => {
			draft[ index ].address = value;
		}));
	};

	const handleUpdateIpv6CidrInfo = (updateInfo: [ number, number ]) => {
		const [ index, value ] = updateInfo;

		setIpv6AddressInfo(produce(localIpSetupSettings.ipv6Settings.ipv6AddressInfo, draft => {
			draft[ index ].cidr = value;
		}));
	};

	const handleUpdateDnsIpv6 = (value: string[]) => {
		setDnsIpv6(value);
	};

	const handleUpdateHttpPort = (value: string) => {
		setHttpPort(+value);
	};

	const handleUpdateRtspPort = (value: string) => {
		setRtspPort(+value);
	};

	const handleUpdateHttpsPort = (value: string) => {
		setHttpsPort(+value);
	};

	const handleReset = (event: FormEvent) => {
		event.preventDefault();

		resetIpSetupSettingsDiff();
	};

	const resetIpSetupSettingsDiff = () => {
		setDhcp(undefined);
		setIpv4Address(undefined);
		setSubnetMask(undefined);
		setIpv4Gateway(undefined);
		setDnsIpv4([]);
		setIpv6Radio(undefined);
		setLinkLocalAddress(undefined);
		setIpv6AddressInfo([]);
		setIpv6Gateway(undefined);
		setDnsIpv6([]);
		setHttpPort(undefined);
		setRtspPort(undefined);
		setHttpsPort(undefined);
	};

	const IpAddressPanes: ITabPane[] = [
		{
			title: "IPv4",
			content: (
				<Ipv4SetupSettings
					ipSetupSettings={ localIpSetupSettings }
					updateDhcp={ setDhcp }
					updateIpv4Address={ setIpv4Address }
					updateSubnetMask={ setSubnetMask }
					updateIpv4Gateway={ setIpv4Gateway }
					updateDnsIpv4={ handleUpdateDnsIpv4 }
				/>
			)
		},
		{
			title: "IPv6",
			content: (
				<Ipv6SetupSettings
					ipSetupSettings={ localIpSetupSettings }
					updateIpv6Radio={ setIpv6Radio }
					updateLinkLocalAddress={ setLinkLocalAddress }
					updateIpv6AddressInfo={ handleUpdateIpv6AddressInfo }
					updateIpv6CidrInfo={ handleUpdateIpv6CidrInfo }
					updateIpv6Gateway={ setIpv6Gateway }
					updateDnsIpv6={ handleUpdateDnsIpv6 }
				/>
			)
		}
	];

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="ip-setup-settings settings-content">
						<SettingsCard size="medium" title="IP Address">
							<div className="ip-address-settings">
								<Tabs
									className="ip-address-tabs"
									panes={ IpAddressPanes }
								/>
							</div>
						</SettingsCard>
						<SettingsCard size="medium" title="Service Port">
							<div className="service-port-settings">
								<div className="service-port-inputs">
									<div className="form-row">
										<label>HTTP Port</label>
										<Input
											hideCharMessage
											max={ 65353 }
											min={ 0 }
											name="httpPort"
											onUpdate={ handleUpdateHttpPort }
											type="number"
											value={ servicePort.httpPort }
											validityMessage={ invalidPortValueMessage }
										/>
									</div>
									<div className="form-row">
										<label>RTSP Port</label>
										<Input
											hideCharMessage
											max={ 65353 }
											min={ 0 }
											name="rtspPort"
											onUpdate={ handleUpdateRtspPort }
											type="number"
											value={ servicePort.rtspPort }
											validityMessage={ invalidPortValueMessage }
										/>
									</div>
									<div className="form-row">
										<label>HTTPS Port</label>
										<Input
											hideCharMessage
											max={ 65353 }
											min={ 0 }
											name="httpsPort"
											onUpdate={ handleUpdateHttpsPort }
											type="number"
											value={ servicePort.httpsPort }
											validityMessage={ invalidPortValueMessage }
										/>
									</div>
								</div>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localIpSetupSettings, ipSetupSettings) } />
		</form>
	);
}

export default memo(IpSetupSettings);
