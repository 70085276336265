import { IIndexSignature } from "TsUtils/Interfaces";
import { get, isEqual } from "lodash";
import { produce } from "immer";

export function filterArray<T>(filters: IIndexSignature<string[]>, array: T[]) {
	const keys = Object.keys(filters);
	let filteredArray = array;

	keys.forEach(key => {
		filteredArray = filteredArray.filter((item) => {
			if (filters[key]?.length) {
				return filters[key].includes(get(item, key));
			}

			return true;
		});
	});

	return filteredArray;
}

export function addOrRemoveFromArray<T>(array: T[], item: T) {
	return produce(array, (draft: T[]) => {
		const index = draft.findIndex(currentItem => {
			return isEqual(currentItem, item);
		});

		if (index < 0) {
			draft.push(item);
		} else {
			draft.splice(index, 1);
		}
	});
}
