import { ChangeEvent, Fragment, memo } from "react";
import "Components/LiveLayout/LiveToolbar/LiveToolbar.less";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectAudioPlayback, selectAudioTransmission, selectBoundingBoxEnabled, selectDateTimeEnabled,
	selectEventIconsEnabled, selectFocus, selectIdEnabled, selectResolutionEnabled, selectRulesEnabled,
	selectStreamIndex,
	selectTrajectoriesEnabled, selectZoom, setAudioPlaybackAction, setAudioTransmissionAction,
	setBoundingBoxEnabledAction, setDateTimeEnabledAction, setEventIconsEnabledAction, setFocusAction,
	setIdEnabledAction, setResolutionEnabledAction, setRulesEnabledAction, setStreamIndexAction,
	setTrajectoriesEnabledAction, setZoomAction } from "Data/Redux/Slices/LiveView";
import { Button, ButtonTypes, Checkbox, Input, Modal, RangeSlider, Select } from "@clintonelec/react-storybook";
import { UnknownAction } from "redux";
import { selectStreams } from "Data/Redux/Slices/Settings/Camera/Codec";
import StreamStatusModal from "Components/LiveLayout/StreamStatusModal";

function LiveToolbar() {
	const dispatch = useAppDispatch();
	// region Redux State Selection
	const audioTransmissionEnabled = useAppSelector(selectAudioTransmission);
	const audioPlaybackEnabled = useAppSelector(selectAudioPlayback);
	const zoom = useAppSelector(selectZoom);
	const focus = useAppSelector(selectFocus);
	const dateTimeEnabled = useAppSelector(selectDateTimeEnabled);
	const resolutionEnabled = useAppSelector(selectResolutionEnabled);
	const eventIconsEnabled = useAppSelector(selectEventIconsEnabled);
	const rulesEnabled = useAppSelector(selectRulesEnabled);
	const boundingBoxEnabled = useAppSelector(selectBoundingBoxEnabled);
	const trajectoriesEnabled = useAppSelector(selectTrajectoriesEnabled);
	const idEnabled = useAppSelector(selectIdEnabled);
	const videoStreams = useAppSelector(selectStreams);
	const streamIndex = useAppSelector(selectStreamIndex);
	// endregion
	// region Redux Dispatch Bindings
	const toggleTransmissionEnabled = () => dispatch(setAudioTransmissionAction(!audioTransmissionEnabled));
	const togglePlaybackEnabled = () => dispatch(setAudioPlaybackAction(!audioPlaybackEnabled));
	const setZoom = (newZoom: number) => dispatch(setZoomAction(newZoom));
	const decrementZoom = () => dispatch(setZoomAction(zoom - 1));
	const incrementZoom = () => dispatch(setZoomAction(zoom + 1));
	const setFocus = (newFocus: number) => dispatch(setFocusAction(newFocus));
	const decrementFocus = () => dispatch(setFocusAction(focus - 1));
	const incrementFocus = () => dispatch(setFocusAction(focus + 1));
	const createCheckboxHandler = (actionCreator: (actionValue: boolean) => UnknownAction) =>
		(checkboxEvent: ChangeEvent<HTMLInputElement>) =>
			dispatch(actionCreator(!!checkboxEvent?.currentTarget?.checked));

	const setDateTimeEnabled = createCheckboxHandler(setDateTimeEnabledAction);
	const setResolutionEnabled = createCheckboxHandler(setResolutionEnabledAction);
	const setEventIconsEnabled = createCheckboxHandler(setEventIconsEnabledAction);
	const setRulesEnabled = createCheckboxHandler(setRulesEnabledAction);
	const setBoundingBoxEnabled = createCheckboxHandler(setBoundingBoxEnabledAction);
	const setTrajectoriesEnabled = createCheckboxHandler(setTrajectoriesEnabledAction);
	const setIdEnabled = createCheckboxHandler(setIdEnabledAction);
	const setStreamIndex = (newStreamIndex: string) => dispatch(setStreamIndexAction(+newStreamIndex));
	// endregion
	const audioPlaybackIcon = audioPlaybackEnabled ? "volume" : "volume-slash";
	const audioTransmissionIcon = audioTransmissionEnabled ? "microphone" : "microphone-slash";
	const videoStreamOptions = videoStreams
		.map((videoStream, index) => {
			return {
				label: `Stream ${ index + 1 }: ${ videoStream.resolution } @ ${ videoStream.frameRate }fps`,
				value: index
			};
		});

	const liveViewOptions = (
		<Fragment>
			<Checkbox onChange={ setDateTimeEnabled } checked={ dateTimeEnabled }>
				Date/Time
			</Checkbox>
			<Checkbox onChange={ setResolutionEnabled } checked={ resolutionEnabled }>
				Resolution
			</Checkbox>
			<Checkbox onChange={ setEventIconsEnabled } checked={ eventIconsEnabled }>
				Event Icons
			</Checkbox>
		</Fragment>
	);

	const aiDisplayOptions = (
		<Fragment>
			<Checkbox onChange={ setRulesEnabled } checked={ rulesEnabled }>Display Rules</Checkbox>
			<Checkbox onChange={ setBoundingBoxEnabled } checked={ boundingBoxEnabled }>
				Display Object Bounding Box
			</Checkbox>
			<Checkbox onChange={ setTrajectoriesEnabled } checked={ trajectoriesEnabled }>
				Display Object Trajectories
			</Checkbox>
			<Checkbox onChange={ setIdEnabled } checked={ idEnabled }>
				Display Object ID
			</Checkbox>
		</Fragment>
	);

	const lensAdjustmentOptions = (
		<Fragment>
			<div className="lens-adjustment-row">
				<span>Zoom</span>
				<Button
					type={ ButtonTypes.SECONDARY }
					onClick={ decrementZoom }
					icon={ { name: "minus" } }
				/>
				<RangeSlider
					onAfterChange={ setZoom }
					max={ 100 }
					min={ 0 }
					value={ zoom }
				/>
				<Button
					type={ ButtonTypes.SECONDARY }
					onClick={ incrementZoom }
					icon={ { name: "plus" } }
				/>
				<Input value={ zoom } />
			</div>
			<div className="lens-adjustment-row">
				<span>Focus</span>
				<Button
					type={ ButtonTypes.SECONDARY }
					onClick={ decrementFocus }
					icon={ { name: "minus" } }
				/>
				<RangeSlider
					onAfterChange={ setFocus }
					max={ 100 }
					min={ 0 }
					value={ focus }
				/>
				<Button
					type={ ButtonTypes.SECONDARY }
					onClick={ incrementFocus }
					icon={ { name: "plus" } }
				/>
				<Input value={ focus } />
			</div>
			<div className="lens-adjustment-buttons">
				<Button type={ ButtonTypes.SECONDARY } ghost>
					REFOCUS
				</Button>
				<Button type={ ButtonTypes.SECONDARY } ghost>
					LENS RESET
				</Button>
			</div>
		</Fragment>
	);

	return (
		<div className="live-toolbar">
			<div className="live-stream-select">
				<Select
					allowClear={ false }
					menuPlacement="top"
					options={ videoStreamOptions }
					value={ streamIndex }
					onSelect={ setStreamIndex }
				/>
			</div>
			<div className="live-toolbar-buttons">
				<div className="live-toolbar-button">
					<Icon
						fixedWidth
						iconWeight={ IconWeight.REGULAR }
						name="camera"
						size={ IconSize.SMALLER }
					/>
				</div>
				<div className="live-toolbar-button" onClick={ togglePlaybackEnabled }>
					<Icon
						fixedWidth
						iconWeight={ IconWeight.REGULAR }
						name={ audioPlaybackIcon }
						size={ IconSize.SMALLER }
					/>
				</div>
				<div className="live-toolbar-button" onClick={ toggleTransmissionEnabled }>
					<Icon
						fixedWidth
						iconWeight={ IconWeight.REGULAR }
						name={ audioTransmissionIcon }
						size={ IconSize.SMALLER }
					/>
				</div>
				<div className="live-toolbar-button">
					<Icon
						fixedWidth
						iconWeight={ IconWeight.REGULAR }
						name="siren-on"
						size={ IconSize.SMALLER }
					/>
				</div>
				<div className="live-toolbar-button">
					<Icon
						fixedWidth
						iconWeight={ IconWeight.REGULAR }
						name="bullhorn"
						size={ IconSize.SMALLER }
					/>
				</div>
				<div className="live-toolbar-button">
					<Icon
						iconWeight={ IconWeight.REGULAR }
						name="expand"
						size={ IconSize.SMALLER }
					/>
				</div>
				<Modal
					title="AI Display Options"
					modalContent={ aiDisplayOptions }>
					<div className="live-toolbar-button">
						<Icon
							iconWeight={ IconWeight.REGULAR }
							name="microchip-ai"
							size={ IconSize.SMALLER }
						/>
					</div>
				</Modal>
				<Modal
					title="Live View Display Options"
					modalContent={ liveViewOptions }>
					<div className="live-toolbar-button">
						<Icon
							iconWeight={ IconWeight.REGULAR }
							name="desktop"
							size={ IconSize.SMALLER }
						/>
					</div>
				</Modal>
				<Modal
					width={ 500 }
					title="Lens Adjustment"
					modalContent={ lensAdjustmentOptions }>
					<div className="live-toolbar-button">
						<Icon
							iconWeight={ IconWeight.REGULAR }
							name="aperture"
							size={ IconSize.SMALLER }
						/>
					</div>
				</Modal>
				<StreamStatusModal>
					<div className="live-toolbar-button">
						<Icon
							iconWeight={ IconWeight.REGULAR }
							name="square-info"
							size={ IconSize.SMALLER }
						/>
					</div>
				</StreamStatusModal>
			</div>
		</div>
	);
}

export default memo(LiveToolbar);
