import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Camera/CodecSettings/CodecSettings.less";
import { Select, Tabs } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectCodec, setCodecAction } from "Data/Redux/Slices/Settings/Camera/Codec";
import FourthTabContent from "Components/ConfigLayout/Camera/CodecSettings/TabContents/FourthTabContent";
import TabContent from "Components/ConfigLayout/Camera/CodecSettings/TabContents/TabContent";
import { videoFormatOptions } from "Data/Objects/CodecSettingsOptions";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";
import { produce } from "immer";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

function CodecSettings() {
	const dispatch = useAppDispatch();
	const codecSettings = useAppSelector(selectCodec);
	const codecSettingsState = useState(codecSettings);
	const [ localCodecSettings, setLocalCodecSettings ] = codecSettingsState;
	const setCodecSettings = () => dispatch(setCodecAction(localCodecSettings));
	const disabled = isEqual(localCodecSettings, codecSettings);

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLocalCodecSettings(codecSettings);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setCodecSettings();
	};

	const selectVideoFormat = (newVideoFormat: string) => {
		setLocalCodecSettings(produce(localCodecSettings, draft => {
			draft.videoFormat = +newVideoFormat;
		}));
	};

	const createContents = (index: number) => {
		if (index === 3) {
			return <FourthTabContent
				codecSettingsState={ codecSettingsState }
				streamIndex={ index }
			/>;
		}

		return <TabContent
			codecSettingsState={ codecSettingsState }
			streamIndex={ index }
		/>;
	};

	const panes = [
		{
			title: "Main Stream",
			content: createContents(0)
		},
		{
			title: "Second Stream",
			content: createContents(1)
		},
		{
			title: "Third Stream",
			content: createContents(2)
		},
		{
			title: "Fourth Stream",
			content: createContents(3)
		}
	];

	return (
		<form className="settings-form" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="codec-settings settings-content">
						<SettingsCard size="small" title="Codec">
							<Tabs panes={ panes } />
						</SettingsCard>
						<SettingsCard size="small" title="Camera Properties">
							<div className="form-row">
								<label>Video Format</label>
								<Select
									allowClear={ false }
									className="form-select"
									onSelect={ selectVideoFormat }
									options={ videoFormatOptions }
									value={ localCodecSettings.videoFormat }
								/>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(CodecSettings);
