// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-email-form {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.network-email-form .container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 30px;
}
.network-email-form .container .card {
  background-color: white;
  border-radius: 8px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px 20px;
  width: 500px;
}
.network-email-form .container .card .header {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}
.network-email-form .container .card .header h4 {
  margin-block-end: 5px;
}
.network-email-form .container .card .form-row {
  align-items: center;
  display: flex;
}
.network-email-form .container .card .form-row:not(:last-child) {
  margin-bottom: 8px;
}
.network-email-form .container .card .form-row > span {
  color: #7e7e7e;
}
.network-email-form .container .card .form-row .email-input,
.network-email-form .container .card .form-row .email-select,
.network-email-form .container .card .form-row .email-button {
  margin-left: auto;
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Network/EmailSettings/EmailSettings.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;AADD;AAFA;EAME,mBAAA;EACA,aAAA;EACA,OAAA;EACA,sBAAA;EACA,cAAA;EACA,aAAA;AADF;AAVA;EAcG,uBAAA;EACA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,oBAAA;EACA,YAAA;AADH;AAjBA;EAqBI,gCAAA;EACA,mBAAA;AADJ;AArBA;EAyBK,qBAAA;AADL;AAxBA;EA8BI,mBAAA;EACA,aAAA;AAHJ;AAMI;EACC,kBAAA;AAJL;AA/BA;EAuCK,cAAA;AALL;AAlCA;;;EA2CK,iBAAA;EACA,UAAA;AAJL","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.network-email-form {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\n\t.container {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tflex-direction: column;\n\t\toverflow: auto;\n\t\tpadding: 30px;\n\n\t\t.card {\n\t\t\tbackground-color: white;\n\t\t\tborder-radius: 8px;\n\t\t\theight: fit-content;\n\t\t\tpadding: 0 20px 20px;\n\t\t\twidth: 500px;\n\n\t\t\t.header {\n\t\t\t\tborder-bottom: 1px solid @light-gray-1;\n\t\t\t\tmargin-bottom: 20px;\n\n\t\t\t\th4 {\n\t\t\t\t\tmargin-block-end: 5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.form-row {\n\t\t\t\talign-items: center;\n\t\t\t\tdisplay: flex;\n\n\n\t\t\t\t&:not(:last-child) {\n\t\t\t\t\tmargin-bottom: 8px;\n\t\t\t\t}\n\n\t\t\t\t> span {\n\t\t\t\t\tcolor: @light-gray-2;\n\t\t\t\t}\n\n\t\t\t\t.email-input, .email-select, .email-button {\n\t\t\t\t\tmargin-left: auto;\n\t\t\t\t\twidth: 50%;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
