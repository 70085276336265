import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Camera/CodecSettings/CodecSettings.less";
import { Select, Tabs } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectCodec, setCodecAction } from "Data/Redux/Slices/Settings/Camera/Codec";
import FourthTabContent from "Components/ConfigLayout/Camera/CodecSettings/TabContents/FourthTabContent";
import TabContent from "Components/ConfigLayout/Camera/CodecSettings/TabContents/TabContent";
import { IStream } from "Interfaces";
import { videoFormatOptions } from "Data/Objects/CodecSettingsOptions";
import { VideoFormat } from "Data/Objects/Camera";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";

function CodecSettings() {
	const {
		streams: streamsFromSlice,
		videoFormat: videoFormatFromSlice
	} = useAppSelector(selectCodec);

	const [ userStreams, setUserStreams ] = useState(streamsFromSlice);
	const [ userVideoFormat, setUserVideoFormat ] = useState(videoFormatFromSlice);
	const dispatch = useAppDispatch();
	const setStream = (
		streams: IStream[],
		videoFormat: VideoFormat
	) => dispatch(setCodecAction({ streams, videoFormat }));

	const handleUpdate = (value: string) => setUserVideoFormat(+value);

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setStream(userStreams, userVideoFormat);
	};

	const handleCancel = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setUserStreams(streamsFromSlice);
		setUserVideoFormat(videoFormatFromSlice);
	};

	const createContents = (index: number) => {
		if (index === 3) {
			return <FourthTabContent
				setUserStreams={ setUserStreams }
				streamIndex={ index }
				userStreams={ userStreams }
			/>;
		}

		return <TabContent
			setUserStreams={ setUserStreams }
			streamIndex={ index }
			userStreams={ userStreams }
		/>;
	};

	const panes = [
		{
			title: "Main Stream",
			content: createContents(0)
		},
		{
			title: "Second Stream",
			content: createContents(1)
		},
		{
			title: "Third Stream",
			content: createContents(2)
		},
		{
			title: "Fourth Stream",
			content: createContents(3)
		}
	];

	return (
		<form className="codec-settings-form" onReset={ handleCancel } onSubmit={ handleSubmit }>
			<div className="container">
				<div className="card">
					<div className="header">
						<h4>Codec</h4>
					</div>
					<Tabs panes={ panes } />
				</div>
				<div className="card">
					<div className="header">
						<h4>Camera Properties</h4>
					</div>
					<div className="form-row">
						<label>Video Format</label>
						<Select
							allowClear={ false }
							className="codec-select"
							onSelect={ handleUpdate }
							options={ videoFormatOptions }
							value={ userVideoFormat }
						/>
					</div>
				</div>
			</div>
			<ActionButtons
				disabled={ isEqual(streamsFromSlice, userStreams) && isEqual(videoFormatFromSlice, userVideoFormat) }
			/>
		</form>
	);
}

export default memo(CodecSettings);
