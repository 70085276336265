// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certificate-info-modal-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.certificate-info-modal-content .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.certificate-info-modal-content .row > span {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Network/SecuritySettings/CertificateInfoModal/CertificateInfoModal.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AADD;AALA;EASE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAVA;EAcG,iBAAA;AADH","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.certificate-info-modal-content {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\twhite-space: nowrap;\n\n\t.row {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tmargin-bottom: 20px;\n\n\t\t> span {\n\t\t\tfont-weight: bold;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
