// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playback-video-container {
  background-color: #f0f0f0;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: hidden;
}
.no-video {
  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.no-video > p {
  font-size: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/PlaybackLayout/VideoContainer/VideoContainer.less"],"names":[],"mappings":"AAEA;EACC,yBAAA;EACA,aAAA;EACA,OAAA;EACA,uBAAA;EACA,gBAAA;AADD;AAIA;EACC,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;AAFD;AANA;EAWE,eAAA;AAFF","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.playback-video-container {\n\tbackground-color: @light-gray-0;\n\tdisplay: flex;\n\tflex: 1;\n\tjustify-content: center;\n\toverflow: hidden;\n}\n\n.no-video {\n\talign-items: center;\n\tbackground-color: black;\n\tcolor: white;\n\tdisplay: flex;\n\theight: 100%;\n\tjustify-content: center;\n\tposition: absolute;\n\twidth: 100%;\n\n\t> p {\n\t\tfont-size: 48px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
