import { PayloadAction } from "@reduxjs/toolkit";
import { RecordRadioSettings } from "Data/Objects/RecordSettings";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IRecordSettingsState } from "Interfaces";

const initialState: IRecordSettingsState = {
	recordingModeRadio: RecordRadioSettings.EVENT,
	storageOption: "SDCard",
	continuousRecordingOption: "secondStream",
	eventRecordingOption: "firstStream",
	recordingSchedule: {
		always: true ,
		weekday: false,
		weekend: false
	},
	preRecordingTime: 5,
	postRecordingTime: 30
};

// #region reducers
export const setRecordSettingsReducer = (state: IRecordSettingsState, action: PayloadAction<IRecordSettingsState>) => {
	if (!action.payload) {
		return;
	}

	return action.payload;
};

// #endregion

// #region selectors
export const getRecordSettings = (state: IRecordSettingsState) => {
	return state;
};

// #endregion

const recordSettingsSlice = createAppSlice({
	name: "recordSettings",
	initialState,
	reducers: {
		setRecordSettingsAction: setRecordSettingsReducer
	},
	selectors: {
		selectRecordSettings: getRecordSettings
	}

});

export default recordSettingsSlice;

export const { setRecordSettingsAction } = recordSettingsSlice.actions;
export const { selectRecordSettings } = recordSettingsSlice.getSelectors<AppState>(state =>
	state.settings.record.recordSettings
);
