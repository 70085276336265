import { Input, Select } from "@clintonelec/react-storybook";
import { ICodecSettingsState } from "Interfaces";
import { Fragment } from "react/jsx-runtime";
import {
	bitrateControlOptions, codecOptions, frameRateOptions, gopSizeOptions, imageQualityOptions, profileOptions,
	resolutionOptions
} from "Data/Objects/CodecSettingsOptions";
import { BitrateControl, Codec, ImageQuality, Profile, Resolution } from "Data/Objects/Camera";
import { Dispatch, memo, SetStateAction } from "react";
import { produce } from "immer";

interface ITabContentProps {
	codecSettingsState: [ ICodecSettingsState, Dispatch<SetStateAction<ICodecSettingsState>> ];
	streamIndex: number;
}

function TabContent(props: ITabContentProps) {
	const { codecSettingsState, streamIndex } = props;
	const [ localCodecSettings, setLocalCodecSettings ] = codecSettingsState;
	const {
		codec, profile, resolution, frameRate, gopSize, bitrateControl, bitrate, imageQuality, mjpegQuality
	} = localCodecSettings.streams[ streamIndex ];

	const isMJPEG = codec === Codec.MJPEG;

	const selectCodec = (newCodec: Codec) => {
		setLocalCodecSettings(produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].codec = newCodec;
		}));
	};

	const selectProfile = (newProfile: Profile) => {
		setLocalCodecSettings(produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].profile = newProfile;
		}));
	};

	const selectResolution = (newResolution: Resolution) => {
		setLocalCodecSettings(produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].resolution = newResolution;
		}));
	};

	const selectFrameRate = (newFrameRate: string) => {
		const newState = produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].frameRate = +newFrameRate;

			if (streamIndex !== 3) {
				draft.streams[ streamIndex ].gopSize = +newFrameRate;
			}

			const fourthStream = draft.streams[ 3 ];

			if((fourthStream.useStreamSettings === "mainStream" && streamIndex === 0)
				|| (fourthStream.useStreamSettings === "secondStream" && streamIndex === 1)) {
				draft.streams[ 3 ].frameRate = +newFrameRate * fourthStream.frameRateLabel;
			}
		});

		setLocalCodecSettings(newState);
	};

	const selectGopSize = (newGopSize: string) => {
		setLocalCodecSettings(produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].gopSize = +newGopSize;
		}));
	};

	const selectBitrateControl = (newBitrateControl: BitrateControl) => {
		setLocalCodecSettings(produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].bitrateControl = newBitrateControl;
		}));
	};

	const updateBitrate = (newBitrate: string) => {
		setLocalCodecSettings(produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].bitrate = +newBitrate;
		}));
	};

	const selectImageQuality = (newImageQuality: ImageQuality) => {
		setLocalCodecSettings(produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].imageQuality = newImageQuality;
		}));
	};

	const updateMjpegQuality = (newMjpegQuality: string) => {
		setLocalCodecSettings(produce(localCodecSettings, draft => {
			draft.streams[ streamIndex ].mjpegQuality = +newMjpegQuality;
		}));
	};

	return (
		<Fragment>
			<div className="form-row">
				<span>Codec</span>
				<Select
					allowClear={ false }
					className="form-select"
					onSelect={ selectCodec }
					options={ codecOptions }
					value={ codec }
				/>
			</div>
			<div className="form-row">
				<span>Profile</span>
				<Select
					allowClear={ false }
					className="form-select"
					onSelect={ selectProfile }
					options={ profileOptions }
					value={ profile }
				/>
			</div>
			<div className="form-row">
				<span>Resolution</span>
				<Select
					allowClear={ false }
					className="form-select"
					onSelect={ selectResolution }
					options={ resolutionOptions }
					value={ resolution }
				/>
			</div>
			<div className="form-row">
				<span>Frame Rate</span>
				<Select
					allowClear={ false }
					className="form-select"
					onSelect={ selectFrameRate }
					options={ frameRateOptions }
					value={ frameRate }
				/>
			</div>
			<div className="form-row">
				<span>GOP Size</span>
				<Select
					allowClear={ false }
					className="form-select"
					disabled={ isMJPEG && streamIndex === 2 }
					onSelect={ selectGopSize }
					options={ gopSizeOptions }
					value={ gopSize }
				/>
			</div>
			<div className="form-row">
				<span>Bitrate Control</span>
				<Select
					allowClear={ false }
					className="form-select"
					disabled={ isMJPEG && streamIndex === 2 }
					onSelect={ selectBitrateControl }
					options={ bitrateControlOptions }
					value={ bitrateControl }
				/>
			</div>
			<div className="form-row">
				<span>Bitrate</span>
				<div className="codec-input-box">
					<Input
						hideCharMessage={ true }
						max={ 8000 }
						min={ 512 }
						onSave={ updateBitrate }
						type="number"
						value={ bitrate }
					/>
					<span>kbps (512 ~ 8000)</span>
				</div>
			</div>
			<div className="form-row">
				<span>Image Quality</span>
				<Select
					allowClear={ false }
					className="form-select"
					disabled={ bitrateControl === BitrateControl.CBR }
					onSelect={ selectImageQuality }
					options={ imageQualityOptions }
					value={ imageQuality }
				/>
			</div>
			<div className="form-row">
				<span>MJPG Quality</span>
				<div className="codec-input-box">
					<Input
						disabled={ !isMJPEG }
						hideCharMessage={ true }
						max={ 100 }
						min={ 0 }
						onSave={ updateMjpegQuality }
						type="number"
						value={ mjpegQuality }
					/>
					<span>(0 ~ 100)</span>
				</div>
			</div>
		</Fragment>
	);
}

export default memo(TabContent);
