import { Checkbox, IColumn, Input, ISelectOption, Select, Table } from "@clintonelec/react-storybook";
import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/NetworkLossSettings/NetworkLossSettings.less";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectNetworkLossEvent, setNetworkLossEventAction } from "Data/Redux/Slices/Settings/Event/NetworkLoss";
import { produce } from "immer";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";

const networkLossColumns: IColumn[] = [
	{
		dataIndex: "detectedObject",
		title: "Detected object",
		key: "detectedObject",
		sortable: false
	},
	{
		dataIndex: "ignoringInterval",
		title: "Ignoring Interval",
		key: "ignoringInterval",
		sortable: false
	},
	{
		dataIndex: "hostIpAddress",
		title: "Destination Host IP",
		key: "hostIpAddress",
		sortable: false
	},
	{
		dataIndex: "pingInterval",
		title: "PING Interval",
		key: "pingInterval",
		sortable: false
	},
	{
		dataIndex: "alarmOut",
		title: "Alarm Out",
		key: "alarmOut",
		sortable: false
	},
	{
		dataIndex: "sendImage",
		title: "Send Image",
		key: "sendImage",
		sortable: false
	},
	{
		dataIndex: "recording",
		title: "Recording",
		key: "recording",
		sortable: false
	},
	{
		dataIndex: "email",
		title: "Email",
		key: "email",
		sortable: false
	}
];

interface INetworkLossSettingsForm extends HTMLFormElement {
	detectedObject: HTMLInputElement;
	ignoringInterval: HTMLInputElement;
	hostIpAddress: HTMLInputElement;
	pingInterval: HTMLInputElement;
	alarmOut: HTMLInputElement;
	sendImage: HTMLInputElement;
	recording: HTMLInputElement;
	email: HTMLInputElement;
}

function NetworkLossSettings() {
	const dispatch = useAppDispatch();
	const networkLossSettings = useAppSelector(selectNetworkLossEvent);
	const [ localNetworkLossEvent, setLocalNetworkLossEvent ] = useState(networkLossSettings);
	const setNetworkLossSettings = () => dispatch(setNetworkLossEventAction(localNetworkLossEvent));

	const handleFormSubmit = (event: FormEvent<INetworkLossSettingsForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setNetworkLossSettings();
		}
	};

	const handleFormReset = (event: FormEvent<INetworkLossSettingsForm>) => {
		event.preventDefault();

		setLocalNetworkLossEvent(networkLossSettings);
	};

	const handleDetectedObjectSelect = (detectedObject: string) => {
		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.detectedObject = detectedObject;

			if (detectedObject === "Ethernet") {
				draft.email = false;
			}
		}));
	};

	const handleFormChange = (event: FormEvent<INetworkLossSettingsForm>) => {
		const {
			ignoringInterval: { value: newIgnoringInterval },
			hostIpAddress: { value: newHostIpAddress },
			pingInterval: { value: newPingInterval },
			alarmOut: { checked: newAlarmOut },
			sendImage: { checked: newSendImage },
			recording: { checked: newRecording },
			email: { checked: newEmail }
		} = event.currentTarget;

		setLocalNetworkLossEvent(produce(localNetworkLossEvent, draft => {
			draft.ignoringInterval = +newIgnoringInterval;
			draft.hostIpAddress = newHostIpAddress;
			draft.pingInterval = +newPingInterval;
			draft.alarmOut = newAlarmOut;
			draft.sendImage = newSendImage;
			draft.recording = newRecording;
			draft.email = newEmail;

		}));
	};

	const detectedObjectSelectOptions: ISelectOption[] = [
		{
			label: "Ethernet",
			value: "Ethernet"
		},
		{
			label: "Ethernet and Destination Host IP",
			value: "Ethernet and Destination Host IP"
		}
	];

	const networkLossTableData = [
		{
			key: "networkLossData",
			detectedObject: (
				<Select
					allowClear={ false }
					className="detected-object-select"
					onSelect={ handleDetectedObjectSelect }
					options={ detectedObjectSelectOptions }
					value={ localNetworkLossEvent.detectedObject }
				/>
			),
			ignoringInterval: (
				<div className="ignoring-interval-table-data">
					<Input
						hideCharMessage
						max={ 60 }
						min={ 0 }
						name="ignoringInterval"
						type="number"
						value={ localNetworkLossEvent.ignoringInterval }
					/>
					<span>Sec</span>
				</div>
			),
			hostIpAddress: (
				<Input
					className="host-ip-address-table-data"
					disabled={ localNetworkLossEvent.detectedObject === "Ethernet" }
					name="hostIpAddress"
					value={ localNetworkLossEvent.hostIpAddress }
				/>
			),
			pingInterval: (
				<div className="ping-interval-table-data">
					<Input
						disabled={ localNetworkLossEvent.detectedObject === "Ethernet" }
						hideCharMessage
						max={ 60 }
						min={ 0 }
						name="pingInterval"
						type="number"
						value={ localNetworkLossEvent.pingInterval }
					/>
					<span>Sec</span>
				</div>
			),
			alarmOut: <Checkbox
				checked={ localNetworkLossEvent.alarmOut }
				name="alarmOut"
			/>,
			sendImage: <Checkbox
				checked={ localNetworkLossEvent.sendImage }
				name="sendImage"
			/>,
			recording: <Checkbox
				checked={ localNetworkLossEvent.recording }
				name="recording"
			/>,
			email: <Checkbox
				checked={ localNetworkLossEvent.email }
				disabled={ localNetworkLossEvent.detectedObject === "Ethernet" }
				name="email"
			/>
		}
	];

	return (
		<form
			className="network-loss-event-form"
			noValidate
			onChange={ handleFormChange }
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="event-note">
					<span>
						Notification - Actions and notifications require detailed settings on each setting page to work.
					</span>
					<span>
						FTP image transfer and email notification are possible only when there is a network connection
						to the server.
					</span>
				</div>
				<div className="card">
					<div className="page-title">
						<h4>Network Loss Settings</h4>
					</div>
					<div className="network-loss-event-settings">
						<Table
							columns={ networkLossColumns }
							data={ networkLossTableData }
						/>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localNetworkLossEvent, networkLossSettings) } />
		</form>
	);
}

export default memo(NetworkLossSettings);
