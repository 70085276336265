import { produce } from "immer";

export function createStateUpdater<T>(key: string) {
	return function (value: T) {
		this.setState((state) =>
			produce(state, (draft) => {
				draft[ key ] = value;
			})
		);
	};
}
