import { combineSlices } from "@reduxjs/toolkit";
import languageSlice from "Data/Redux/Slices/Settings/System/Language";
import securitySlice from "Data/Redux/Slices/Settings/System/Security";
import systemManagementSlice from "Data/Redux/Slices/Settings/System/Management";
import systemInformationSlice from "Data/Redux/Slices/Settings/System/Information";
import dateTimeSlice from "Data/Redux/Slices/Settings/System/DateTime";

export default combineSlices(
	languageSlice, securitySlice, systemInformationSlice, systemManagementSlice, dateTimeSlice
);
