import { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "Data/Redux/Store";
import { ILiveViewState } from "Interfaces";

const initialState: ILiveViewState = {
	audioPlaybackEnabled: false,
	audioTransmissionEnabled: false,
	alarmLightsEnabled: false,
	alarmAudioEnabled: false,
	fullscreen: false,
	liveViewOptions: {
		dateTimeEnabled: true,
		resolutionEnabled: true,
		eventIconsEnabled: true
	},
	aiDisplayOptions: {
		rulesEnabled: true,
		boundingBoxEnabled: true,
		trajectoriesEnabled: true,
		idEnabled: true
	},
	lensAdjustmentOptions: {
		zoom: 50,
		focus: 50
	},
	streamIndex: 0
};

// #region Reducers
export const setAudioPlaybackReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.audioPlaybackEnabled = action.payload;
};

export const setAudioTransmissionReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.audioTransmissionEnabled = action.payload;
};

export const setFullscreenReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.fullscreen = action.payload;
};

export const setDateTimeEnabledReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.liveViewOptions.dateTimeEnabled = action.payload;
};

export const setResolutionEnabledReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.liveViewOptions.resolutionEnabled = action.payload;
};

export const setEventIconsEnabledReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.liveViewOptions.eventIconsEnabled = action.payload;
};

export const setRulesEnabledReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.aiDisplayOptions.rulesEnabled = action.payload;
};

export const setBoundingBoxEnabledReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.aiDisplayOptions.boundingBoxEnabled = action.payload;
};

export const setTrajectoriesEnabledReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.aiDisplayOptions.trajectoriesEnabled = action.payload;
};

export const setIdEnabledReducer = (state: ILiveViewState, action: PayloadAction<boolean>) => {
	state.aiDisplayOptions.idEnabled = action.payload;
};

export const setZoomReducer = (state: ILiveViewState, action: PayloadAction<number>) => {
	state.lensAdjustmentOptions.zoom = action.payload;
};

export const setFocusReducer = (state: ILiveViewState, action: PayloadAction<number>) => {
	state.lensAdjustmentOptions.focus = action.payload;
};

export const setStreamIndexReducer = (state: ILiveViewState, action: PayloadAction<number>) => {
	state.streamIndex = action.payload;
};
// #endregion

// #region Selectors
export const getAudioPlayback = (state: ILiveViewState) => {
	return state.audioPlaybackEnabled;
};

export const getAudioTransmission = (state: ILiveViewState) => {
	return state.audioTransmissionEnabled;
};

export const getFullscreen = (state: ILiveViewState) => {
	return state.fullscreen;
};

export const getDateTimeEnabled = (state: ILiveViewState) => {
	return state.liveViewOptions.dateTimeEnabled;
};

export const getResolutionEnabled = (state: ILiveViewState) => {
	return state.liveViewOptions.resolutionEnabled;
};

export const getEventIconsEnabled = (state: ILiveViewState) => {
	return state.liveViewOptions.eventIconsEnabled;
};

export const getRulesEnabled = (state: ILiveViewState) => {
	return state.aiDisplayOptions.rulesEnabled;
};

export const getBoundingBoxEnabled = (state: ILiveViewState) => {
	return state.aiDisplayOptions.boundingBoxEnabled;
};

export const getTrajectoriesEnabled = (state: ILiveViewState) => {
	return state.aiDisplayOptions.trajectoriesEnabled;
};

export const getIdEnabled = (state: ILiveViewState) => {
	return state.aiDisplayOptions.idEnabled;
};

export const getZoom = (state: ILiveViewState) => {
	return state.lensAdjustmentOptions.zoom;
};

export const getFocus = (state: ILiveViewState) => {
	return state.lensAdjustmentOptions.focus;
};

export const getStreamIndex = (state: ILiveViewState) => {
	return state?.streamIndex;
};
// #endregion

const liveViewSlice = createAppSlice({
	name: "liveView",
	initialState,
	reducers: (create) => ({
		setAudioPlaybackAction: create.reducer(setAudioPlaybackReducer),
		setAudioTransmissionAction: create.reducer(setAudioTransmissionReducer),
		setFullscreenAction: create.reducer(setFullscreenReducer),
		setDateTimeEnabledAction: create.reducer(setDateTimeEnabledReducer),
		setResolutionEnabledAction: create.reducer(setResolutionEnabledReducer),
		setEventIconsEnabledAction: create.reducer(setEventIconsEnabledReducer),
		setRulesEnabledAction: create.reducer(setRulesEnabledReducer),
		setBoundingBoxEnabledAction: create.reducer(setBoundingBoxEnabledReducer),
		setTrajectoriesEnabledAction: create.reducer(setTrajectoriesEnabledReducer),
		setIdEnabledAction: create.reducer(setIdEnabledReducer),
		setZoomAction: create.reducer(setZoomReducer),
		setFocusAction: create.reducer(setFocusReducer),
		setStreamIndexAction: create.reducer(setStreamIndexReducer)
	}),
	selectors: {
		selectAudioPlayback: getAudioPlayback,
		selectAudioTransmission: getAudioTransmission,
		selectFullScreen: getFullscreen,
		selectDateTimeEnabled: getDateTimeEnabled,
		selectResolutionEnabled: getResolutionEnabled,
		selectEventIconsEnabled: getEventIconsEnabled,
		selectRulesEnabled: getRulesEnabled,
		selectBoundingBoxEnabled: getBoundingBoxEnabled,
		selectTrajectoriesEnabled: getTrajectoriesEnabled,
		selectIdEnabled: getIdEnabled,
		selectZoom: getZoom,
		selectFocus: getFocus,
		selectStreamIndex: getStreamIndex
	}
});

export default liveViewSlice;

export const {
	setAudioPlaybackAction, setAudioTransmissionAction, setFullscreenAction, setDateTimeEnabledAction,
	setResolutionEnabledAction, setEventIconsEnabledAction, setRulesEnabledAction, setBoundingBoxEnabledAction,
	setTrajectoriesEnabledAction, setIdEnabledAction, setZoomAction, setFocusAction, setStreamIndexAction
} = liveViewSlice.actions;
export const {
	selectAudioPlayback, selectAudioTransmission, selectFullScreen, selectDateTimeEnabled,
	selectResolutionEnabled, selectEventIconsEnabled, selectRulesEnabled, selectBoundingBoxEnabled,
	selectTrajectoriesEnabled, selectIdEnabled, selectZoom, selectFocus, selectStreamIndex
} = liveViewSlice.selectors;
