import { ISelectOption } from "@clintonelec/react-storybook";
import { BitrateControl, Codec, ImageQuality, Profile, Resolution, VideoFormat } from "Data/Objects/Camera";

export const codecOptions: ISelectOption[] = [
	{
		label: Codec.H264,
		value: Codec.H264
	},
	{
		label: Codec.H265,
		value: Codec.H265
	},
	{
		label: Codec.MJPEG,
		value: Codec.MJPEG
	}
];

export const profileOptions: ISelectOption[] = [
	{
		label: "Baseline",
		value: Profile.BASELINE
	},
	{
		label: "Main",
		value: Profile.MAIN
	},
	{
		label: "High",
		value: Profile.HIGH
	}
];

export const resolutionOptions: ISelectOption[] = [
	{
		label: Resolution.R1920X1080,
		value: Resolution.R1920X1080
	},
	{
		label: Resolution.R1280X1024,
		value: Resolution.R1280X1024
	},
	{
		label: Resolution.R1024X768,
		value: Resolution.R1024X768
	}
];

export const frameRateOptions: ISelectOption[] = new Array(30).fill(0).map((current, index) => {
	return {
		label: `${ index + 1 }`,
		value: index + 1
	};
});

export const gopSizeOptions: ISelectOption[] = new Array(60).fill(0).map((current, index) => {
	return {
		label: `${ index + 1 }`,
		value: index + 1
	};
});

export const bitrateControlOptions: ISelectOption[] = [
	{
		label: BitrateControl.CBR,
		value: BitrateControl.CBR
	},
	{
		label: BitrateControl.VBR,
		value: BitrateControl.VBR
	},
	{
		label: BitrateControl.VBRPLUS,
		value: BitrateControl.VBRPLUS
	}
];

export const imageQualityOptions: ISelectOption[] = [
	{
		label: "Low",
		value: ImageQuality.LOW
	},
	{
		label: "Mid",
		value: ImageQuality.MID
	},
	{
		label: "High",
		value: ImageQuality.HIGH
	}
];

export const useStreamSettingsOptions: ISelectOption[] = [
	{
		label: "Main Stream",
		value: "mainStream"
	},
	{
		label: "Second Stream",
		value: "secondStream"
	}
];

export const fourthStreamFrameRateOptions: ISelectOption[] = [
	{
		label: "1/2",
		value: 0.5
	},
	{
		label: "1/4",
		value: 0.25
	}
];

export const videoFormatOptions: ISelectOption[] = [
	{
		label: "NTSC - 60 Hz",
		value: VideoFormat.NTSC
	},
	{
		label: "PAL - 50 Hz",
		value: VideoFormat.PAL
	}
];
