import { PayloadAction } from "@reduxjs/toolkit";
import { DUMMY_ADMIN_USER, DUMMY_BASIC_USER, DUMMY_MANAGER_USER } from "Data/Dummy/User";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IUser, IUserManagmentState } from "Interfaces";

const initialState: IUserManagmentState = {
	users: [ DUMMY_ADMIN_USER, DUMMY_MANAGER_USER, DUMMY_BASIC_USER ]
};

// #region Reducers

export const createUserReducer = (state: IUserManagmentState, action: PayloadAction<IUser>) => {
	const { payload } = action;

	if (!payload) {
		return;
	}

	state.users.push(payload);
};

export const updateUserReducer = (state: IUserManagmentState, action: PayloadAction<IUser>) => {
	const { payload } = action;

	if (!payload) {
		return;
	}

	const foundUser = state.users.findIndex(user => user.id === payload.id);

	if (foundUser < 0) {
		return;
	}

	state.users[ foundUser ] = payload;
};

export const deleteUserReducer = (state: IUserManagmentState, action: PayloadAction<string>) => {
	const { payload: userId } = action;

	if (!userId) {
		return;
	}

	state.users = state.users.filter(currentUser => currentUser.id !== userId);
};

// #endregion

// #region Selectors

export const getAllUsers = (state: IUserManagmentState) => {
	return state.users;
};

// #endregion

const managementSlice = createAppSlice({
	name: "management",
	initialState,
	reducers: {
		createUserAction: createUserReducer,
		updateUserAction: updateUserReducer,
		deleteUserAction: deleteUserReducer
	},
	selectors: {
		selectAllUsers: getAllUsers
	}
});

export default managementSlice;

export const {
	createUserAction, deleteUserAction, updateUserAction
} = managementSlice.actions;

export const {
	selectAllUsers
} = managementSlice.getSelectors<AppState>(state => state.settings.user.management);
