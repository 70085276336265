// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-management {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.user-management .scrollable-content {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: auto;
  padding: 30px;
}
.user-management .scrollable-content .container {
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 0 20px 20px;
}
.user-management .scrollable-content .container .page-title {
  border-bottom: 1px solid #7e7e7e;
  font-weight: bold;
  margin-bottom: 20px;
  width: 100%;
}
.user-management .scrollable-content .container .page-title h4 {
  margin-block-end: 5px;
}
.user-management .scrollable-content .container .add-user-button {
  margin-bottom: 20px;
  width: 125px;
}
.user-management .scrollable-content .container .user-actions {
  display: flex;
}
.user-management .scrollable-content .container .user-actions > .cec-button:not(:last-child) {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/User/UserManagement/UserManagement.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;EACA,uBAAA;AADD;AAHA;EAOE,aAAA;EACA,OAAA;EACA,uBAAA;EACA,cAAA;EACA,aAAA;AADF;AAVA;EAcG,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,2BAAA;EAAA,sBAAA;EACA,oBAAA;AADH;AAnBA;EAuBI,gCAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AADJ;AAzBA;EA6BK,qBAAA;AADL;AA5BA;EAkCI,mBAAA;EACA,YAAA;AAHJ;AAhCA;EAuCI,aAAA;AAJJ;AAnCA;EA0CK,iBAAA;AAJL","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.user-management {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\tjustify-content: center;\n\n\t.scrollable-content {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tjustify-content: center;\n\t\toverflow: auto;\n\t\tpadding: 30px;\n\n\t\t.container {\n\t\t\tbackground-color: white;\n\t\t\tborder-radius: 8px;\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\theight: fit-content;\n\t\t\tmin-width: fit-content;\n\t\t\tpadding: 0 20px 20px;\n\n\t\t\t.page-title {\n\t\t\t\tborder-bottom: 1px solid @light-gray-2;\n\t\t\t\tfont-weight: bold;\n\t\t\t\tmargin-bottom: 20px;\n\t\t\t\twidth: 100%;\n\n\t\t\t\th4 {\n\t\t\t\t\tmargin-block-end: 5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.add-user-button {\n\t\t\t\tmargin-bottom: 20px;\n\t\t\t\twidth: 125px;\n\t\t\t}\n\n\t\t\t.user-actions {\n\t\t\t\tdisplay: flex;\n\n\t\t\t\t> .cec-button:not(:last-child) {\n\t\t\t\t\tmargin-right: 8px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
