import { memo } from "react";
import Router from "Components/Routing/Router";
import { Slide, ThemeContext, Themes, ToastContainer } from "@clintonelec/react-storybook";

const App = () => {
	return (
		<ThemeContext.Provider value={ Themes.LIGHT }>
			<Router />
			<ToastContainer
				position="top-right"
				autoClose={ 4500 }
				hideProgressBar
				newestOnTop={ false }
				closeOnClick
				rtl={ false }
				pauseOnFocusLoss
				draggable
				pauseOnHover
				transition={ Slide }
				className="cec-toast"
				limit={ 10 }
				style={ { width: 350 } }
				theme={ Themes.LIGHT }
			/>
		</ThemeContext.Provider>
	);
};

export default memo(App);
