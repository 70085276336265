const Colors = {
	Blue: {
		subtitle: "Primary",
		colors: [
			"#3fabed",
			"#127ec0",
			"#0e6499",
		]
	},
	Green: {
		subtitle: "Success",
		colors: [
			"#68d5ae",
			"#33B889",
			"#28936d",
		]
	},
	Yellow: {
		subtitle: "Warning",
		colors: [
			"#f7dc70",
			"#f4ce34",
			"#f2c717",
		]
	},
	Red: {
		subtitle: "Error",
		colors: [
			"#fd7c7c",
			"#fd4445",
			"#fc0405",
		]
	},
	Grayscale: {
		subtitle: "Backgrounds",
		colors: [
			"#b6b8bc",
			"#8e8e8e",
			"#2e333f",
			"#272a35",
			"#25292d",
			"#232428",
			"#202325"
		]
	},
	GrayscaleLight: {
		subtitle: "Fonts and backgrounds",
		colors: [
			"#f0f0f0",
			"#eaeaea",
			"#7e7e7e",
		]
	},
};

module.exports = {
	Colors,
	install: (less, pluginManager, functions) => {
		functions.add("getTemplateColor", (title, index) => {
			return Colors[title.value].colors[index.value];
		});
	}
};
