import { FormEvent, Fragment, memo, useState } from "react";
import { Button, ButtonTypes, Checkbox, Input, Modal, Select, SelectComponent } from "@clintonelec/react-storybook";
import Icon from "Components/Global/Icon";
import { IUser, IWithChildren } from "Interfaces";
import "Components/ConfigLayout/User/UserManagement/UserManagementModal/UserManagementModal.less";
import { groupSelectOptions } from "Data/Objects/User";
import { createUserAction, updateUserAction } from "Data/Redux/Slices/Settings/User/Management";
import { useAppDispatch } from "Data/Redux/Store";
import UserValidation from "Components/ConfigLayout/User/UserValidation";
import { idLengthRegex, passwordRegex } from "Data/Utils/User";
import { v4 as uuidv4 } from "uuid";

interface IUserManagementForm extends HTMLFormElement {
	confirmPassword: HTMLInputElement;
	email: HTMLInputElement;
	group: SelectComponent;
	newPassword: HTMLInputElement;
	notifications: HTMLInputElement;
	userId: HTMLInputElement;
}

interface IUserManagementModalProps extends IWithChildren {
	user?: IUser;
}

function UserManagementModal(props: IUserManagementModalProps) {
	const { children, user } = props;
	const dispatch = useAppDispatch();
	const createUser = (newUser: IUser) => dispatch(createUserAction(newUser));
	const updateUser = (updatedUser: IUser) => dispatch(updateUserAction(updatedUser));
	const [ userId, setUserId ] = useState(user?.userId ?? "");
	const [ password, setPassword ] = useState("");
	const [ oldPassword, setOldPassword ] = useState("");
	const [ submitted, setSubmitted ] = useState(false);
	const [ visible, setVisible ] = useState(false);

	const handleVisibilityChanged = (newVisibilty: boolean) => {
		setSubmitted(false);
		setPassword("");
		setOldPassword("");
		setVisible(newVisibilty);
	};

	const handleFormSubmit = (event: FormEvent<IUserManagementForm>) => {
		setSubmitted(true);
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			const { group, email, notifications } = event.currentTarget;

			if (user) {
				const updatedUser: IUser = {
					...user,
					userId,
					email: email.value,
					group: group.value,
					notifications: notifications.checked
				};

				updateUser(updatedUser);
			} else {
				const newUser: IUser = {
					id: uuidv4(),
					userId,
					email: email.value,
					group: group.value,
					notifications: notifications.checked
				};

				createUser(newUser);
			}

			setVisible(!visible);
		}
	};

	const validateUserId = (value: string) => {
		return idLengthRegex.test(value);
	};

	const validatePassword = (value: string) => {
		return passwordRegex.test(value);
	};

	const validateConfirmPassword = (inputValue: string) => {
		return inputValue === password;
	};

	const renderTitle = () => {
		const titleText = user ? "Edit User" : "Create User";

		return (
			<Fragment>
				<Icon name="user" />
				{ titleText }
			</Fragment>
		);
	};

	const renderPasswordFields = () => {
		const passwordFields = (
			<Fragment>
				<Input
					disabled={ user && oldPassword.length === 0 }
					name="newPassword"
					noValidate={ !submitted }
					onUpdate={ setPassword }
					password
					placeholder="Password"
					required={ !user }
					validator={ validatePassword }
					autoComplete="new-password"
				/>
				<Input
					disabled={ user && oldPassword.length === 0 }
					name="confirmPassword"
					noValidate={ !submitted }
					password
					placeholder="Confirm Password"
					required={ password.length > 0 }
					validator={ validateConfirmPassword }
				/>
			</Fragment>
		);

		if (!user) {
			return passwordFields;
		}

		return (
			<Fragment>
				<Input
					name="oldPassword"
					password
					placeholder="Old Password"
					onUpdate={ setOldPassword }
				/>
				{ passwordFields }
			</Fragment>
		);
	};

	const modalContent = (
		<form
			className="user-management-modal-content"
			noValidate
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable">
				<label>User ID</label>
				<Input
					defaultValue={ user?.userId }
					name="userId"
					noValidate={ !submitted }
					onUpdate={ setUserId }
					required
					validator={ validateUserId }
					autoComplete="new-password"
				/>
				<label>Email</label>
				<Input
					defaultValue={ user?.email }
					name="email"
					noValidate={ !submitted }
				/>
				<label>Group</label>
				<Select
					allowClear= { false }
					defaultValue={ user?.group ?? "USER" }
					name="group"
					options={ groupSelectOptions }
				/>
				<label>Password</label>
				{ renderPasswordFields() }
				<Checkbox name="notifications" defaultChecked={ user?.notifications }>
					Enable Notifications
				</Checkbox>
				<UserValidation userId={ userId } password={ password } />
			</div>
			<div className="footer">
				<Button icon={ { name: "pencil" } } ghost type={ ButtonTypes.SECONDARY }>
					Submit
				</Button>
			</div>
		</form>
	);

	return (
		<Modal
			className="user-management-modal"
			modalContent={ modalContent }
			onVisibilityChange={ handleVisibilityChanged }
			title={ renderTitle() }
			visible={ visible }
			width={ 700 }
		>
			{ children }
		</Modal>
	);
}

export default memo(UserManagementModal);
