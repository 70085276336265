import { PayloadAction } from "@reduxjs/toolkit";
import { RoiQuality } from "Data/Objects/Camera";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IRoiSettingsState } from "Interfaces";

const initialState: IRoiSettingsState = {
	roiEnabled: false,
	quality: RoiQuality.LOW,
	zones: [ [], [], [], [], [], [], [], [] ]
};

export const setRoiSettingsReducer = (state: IRoiSettingsState, action: PayloadAction<IRoiSettingsState>) => {
	return action.payload;
};

export const getRoiSettings = (state: IRoiSettingsState) => {
	return state;
};

const roiSlice = createAppSlice({
	name: "roi",
	initialState,
	reducers: {
		setRoiSettingsAction: setRoiSettingsReducer
	},
	selectors: {
		selectRoiSettings: getRoiSettings
	}
});

export default roiSlice;

export const { setRoiSettingsAction } = roiSlice.actions;
export const { selectRoiSettings } = roiSlice.getSelectors<AppState>(state => state.settings.camera.roi);
