import { memo } from "react";
import "Components/LiveLayout/VideoContainer/VideoContainer.less";

function VideoContainer() {
	return (
		<div className="video-container">
			Video Container
		</div>
	);
}

export default memo(VideoContainer);
