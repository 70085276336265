import { memo, useEffect } from "react";
import "Components/ConfigLayout/ActionButtons/ActionButtons.less";
import { Button, ButtonTypes, toast } from "@clintonelec/react-storybook";
import { NotificationType, notify } from "src/Notifications";
import { useBlocker } from "react-router-dom";

interface IActionButtonsProps {
	disabled?: boolean;
}

function ActionButtons(props: IActionButtonsProps) {
	const { disabled } = props;
	const { reset, proceed, state: blockerState } = useBlocker(!disabled);

	useEffect(() => {
		const toastContent = (
			<div className="blocker-toast-container">
				<div className="blocker-toast-content">
					Are you sure you want to leave the page? There may be unsaved changes.
				</div>
				<Button
					className="blocker-cancel-button"
					ghost
					onClick={ reset }
					type={ ButtonTypes.DANGER }
				>
					Cancel
				</Button>
				<Button
					onClick={ proceed }
					type={ ButtonTypes.TERTIARY }
				>
					Proceed
				</ Button>
			</div>
		);

		if (blockerState === "blocked") {
			if (!disabled) {
				notify(
					NotificationType.WARNING,
					"Unsaved changes",
					toastContent,
					false,
					{ toastId: "blockerToast" });
			} else {
				toast.dismiss("blockerToast");
				reset();
			}
		}
	}, [ blockerState, reset, proceed, disabled ]);

	return (
		<div className="action-buttons">
			<Button
				disabled={ disabled }
				fill
				htmlType="reset"
				type={ ButtonTypes.SECONDARY }
			>
				Cancel
			</Button>
			<Button
				disabled={ disabled }
				type={ ButtonTypes.TERTIARY }
			>
				Accept
			</Button>
		</div>
	);
}

export default memo(ActionButtons);
