import { memo } from "react";
import { useAppSelector } from "Data/Redux/Store";
import "Components/ConfigLayout/System/SystemInformation/SystemInformation.less";
import { selectSystemInformation } from "Data/Redux/Slices/Settings/System/Information";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

function SystemInformation() {
	const systemInformation = useAppSelector(selectSystemInformation);
	const cardContent = Object.entries(systemInformation)
		.map(([ key, value ]) => {
			const words = key.split(/(?=[A-Z0-9])/);

			return (
				<div className="form-row info-row" key={ key }>
					<span>{ words.join(" ") }:&nbsp;</span>
					<p>{ value }</p>
				</div>
			);
		});

	return (
		<div className="settings-form">
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="system-information-settings settings-content">
						<SettingsCard size="small" title="System Information">
							{ cardContent }
						</SettingsCard>
					</div>
				</div>
			</div>
		</div>
	);
}

export default memo(SystemInformation);
