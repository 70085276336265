import { IIpv6RadioSettings } from "Data/Objects/Network";

export enum RtspAuthenticationType {
	OFF = "OFF",
	BASIC = "BASIC",
	DIGEST = "DIGEST"
}

export enum HttpAuthenticationType {
	BASIC = "BASIC",
	DIGEST = "DIGEST"
}

export enum IpFilterRule {
	ALLOW = "ALLOW",
	BLOCK = "BLOCK"
}

export enum IpFilterType {
	A_NETWORK = "A_NETWORK",
	B_NETWORK = "B_NETWORK",
	C_NETWORK = "C_NETWORK",
	IP_ADDRESS = "IP_ADDRESS"
}

export enum EapType {
	TLS = "EAP-TLS",
	MD5 = "EAP-MD5"
}

export enum CertificateType {
	CA = "CA Certificate",
	CLIENT = "Client Certificate",
	KEY = "Client Key"
}

export interface IIpFilter {
	type: IpFilterType;
	address: string;
}

export interface ISecurityCertificate {
	type: CertificateType;
	name: string;
	issuer?: string;
	subject?: string;
	validStart?: Date;
	validEnd?: Date;
}

export interface INetworkSecuritySettings {
	rtspAuthType: RtspAuthenticationType;
	httpsEnabled: boolean;
	httpAuthType: HttpAuthenticationType;
}

export interface IIpFilteringOptions {
	ipFilterEnabled: boolean;
	ipFilterRule: IpFilterRule;
}

export interface ISetupIeee802Options {
	enabled: boolean;
	eapType: EapType;
	eapolVersion: number;
	id: string;
	password: string;
	clientKeyPassword: string;
}

export interface IIpFilteringSettings extends IIpFilteringOptions {
	filters: IIpFilter[];
}

export interface INetworkIpv4Settings {
	dhcp: boolean;
	ipv4Address: string;
	subnetMask: string;
	ipv4Gateway: string;
	dnsIpv4: string[];
}

export interface IIpv6Address {
	address: string;
	cidr: number;
}

export interface INetworkIpv6Settings {
	ipv6Radio: IIpv6RadioSettings;
	linkLocalAddress: string;
	ipv6AddressInfo: IIpv6Address[];
	ipv6Gateway: string;
	dnsIpv6: string[];
}

export interface IServicePortSettings {
	httpPort: number;
	rtspPort: number;
	httpsPort: number;
}

export interface INetworkIpSettings {
	ipv4Settings: INetworkIpv4Settings;
	ipv6Settings: INetworkIpv6Settings;
	servicePortSettings: IServicePortSettings;
}
