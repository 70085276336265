import { createDraftSafeSelector, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { produce } from "immer";
import { IEmailFtpSettingsState } from "Interfaces";

const initialState: IEmailFtpSettingsState = {
	emailServerSettings: {
		server: "smtp-mail.outlook.com",
		port: 25,
		security: false,
		user: "username842@outlook.com",
		testEmailAddress: "testname@clintonelectronics.com"
	},
	emailListSettings: {
		emailList: [
			"test@email.com",
			"test2@email.com",
			"test3@email.com"
		],
		minimumEmailFrequency: 1,
		includeSnapshotOption: true,
		presetSchedule: {
			always: true,
			weekday: false,
			weekend: false
		}
	},
	ftpServerSettings: {
		receiver: "",
		host: "",
		port: 21,
		basePath: "",
		idPassRequired: false,
		id: "",
		frequency: 0,
		log: false,
		filePrefix: "",
		schedule: {
			always: false,
			weekday: false,
			weekend: false
		}
	}
};

const setEmailFtpSettingsReducer = (state: IEmailFtpSettingsState, action: PayloadAction<IEmailFtpSettingsState>) => {
	return produce(action.payload, draft => {
		delete draft.emailServerSettings.password;
		delete draft.ftpServerSettings.password;
	});
};

const getEmailFtpSettings = (state: IEmailFtpSettingsState) => {
	return produce(state, draft => {
		draft.emailServerSettings.password = "";
		draft.ftpServerSettings.password = "";
	});
};

const emailRtpSlice = createAppSlice({
	initialState,
	name: "emailRtp",
	reducers: {
		setEmailFtpSettingsAction: setEmailFtpSettingsReducer
	},
	selectors: {
		selectEmailFtpSettings: createDraftSafeSelector(
			(state) => state,
			getEmailFtpSettings
		)
	}
});

export default emailRtpSlice;

export const { setEmailFtpSettingsAction } = emailRtpSlice.actions;
export const { selectEmailFtpSettings } = emailRtpSlice.getSelectors<AppState>(state => state.settings.event.emailRtp);
