import { memo } from "react";
import "Components/LiveLayout/LiveSider/LiveSider.less";

function LiveSider() {
	return (
		<div className="live-sider">
			Sider
		</div>
	);
}

export default memo(LiveSider);
