import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { ITamperEventSettingsState } from "Interfaces";

const initialState: ITamperEventSettingsState = {
	email: false,
	alarmOut: false,
	audioAlarm: false,
	interval: 5,
	recording: false,
	redBlueLight: false,
	snapshot: false,
	whiteLight: false,
	ftp: false
};

const setTamperEventReducer = (state: ITamperEventSettingsState, action: PayloadAction<ITamperEventSettingsState>) => {
	return action.payload;
};

const getTamperEvent = (state: ITamperEventSettingsState) => {
	return state;
};

const tamperEventSlice = createAppSlice({
	initialState,
	name: "tamperEvent",
	reducers: {
		setTamperEventAction: setTamperEventReducer
	},
	selectors: {
		selectTamperEvent: getTamperEvent
	}
});

export default tamperEventSlice;

export const { setTamperEventAction } = tamperEventSlice.actions;
export const { selectTamperEvent } = tamperEventSlice.getSelectors<AppState>(state => state.settings.event.tamperEvent);
