import { ScheduleType } from "Data/Objects/System";
import { IScheduleSettingsState, ISystemInformation } from "Interfaces";

export const DUMMY_SYSTEM_INFORMATION: ISystemInformation = {
	dns1: "10.1.3.23",
	dns2: "8.8.8.8",
	firmwareVersion: "89100.1.0033.100",
	gateway: "10.1.6.1",
	httpPort: 80,
	ipAddress: "10.1.6.154",
	macAddress: "C4:A1:0E:B3:01:27",
	model: "CE-ID30AR5C",
	rtspPort: 554,
	serialNumber: "041224C203Uxxxx",
	subnetMask: "255.255.255.0"
};

export const DUMMY_SCHEDULE_SETTINGS: IScheduleSettingsState = {
	schedules: [
		{
			name: "always",
			scheduleType: ScheduleType.DAILY,
			scheduledDay: {
				MON: true,
				TUE: true,
				WED: true,
				THU: true,
				FRI: true,
				SAT: true,
				SUN: true
			},
			startTime: "00:00",
			endTime: "00:00"
		},
		{
			name: "weekday",
			scheduleType: ScheduleType.WEEKLY,
			scheduledDay: {
				MON: true,
				TUE: true,
				WED: true,
				THU: true,
				FRI: true,
				SAT: true,
				SUN: true
			},
			startTime: "MON. 00:00",
			endTime: "SAT. 00:00"
		},
		{
			name: "weekend",
			scheduleType: ScheduleType.WEEKLY,
			scheduledDay: {
				MON: true,
				TUE: true,
				WED: true,
				THU: true,
				FRI: true,
				SAT: true,
				SUN: true
			},
			startTime: "SAT. 00:00",
			endTime: "MON. 00:00"
		}
	]
};
