import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Camera/AiAnalytics/AiAnalytics.less";
import { Switch, Tabs } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";
import { selectAiAnalytics, setAiAnalyticsAction } from "Data/Redux/Slices/Settings/Camera/AiAnalytics";
import { produce } from "immer";
import AiRuleSettings from "Components/ConfigLayout/Camera/AiAnalytics/AiRuleSettings";
import AdvancedAiSettings from "Components/ConfigLayout/Camera/AiAnalytics/AdvancedAiSettings";
import { AiRuleType } from "Data/Objects/Camera";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

function AiAnalytics() {
	const dispatch = useAppDispatch();
	const aiAnalyticsState = useAppSelector(selectAiAnalytics);
	const localAiAnalyticsState = useState(aiAnalyticsState);
	const [ localAiAnalyticsSettings, setLocalAiAnalyticsSettings ] = localAiAnalyticsState;
	const { rules } = localAiAnalyticsSettings;
	const setAiAnalytics = () => dispatch(setAiAnalyticsAction(localAiAnalyticsSettings));
	const firstExistingRuleIndex = aiAnalyticsState.rules.findIndex((rule) => !!rule);
	const [ selectedRuleIndex, setSelectedRuleIndex ] = useState<number>(firstExistingRuleIndex);
	let incorrectConfiguration = false;

	if (rules?.[ selectedRuleIndex ]?.type === AiRuleType.ZONE) {
		const { loiter, enter, exit, intrusion } = rules[ selectedRuleIndex ];

		if (!loiter && !enter && !exit && !intrusion) {
			incorrectConfiguration = true;
		}
	}

	if (selectedRuleIndex >= 0) {
		const { human, vehicle, bike } = rules[ selectedRuleIndex ].objectTypes;

		if (!human && !vehicle && !bike) {
			incorrectConfiguration = true;
		}
	}

	const handleFormSubmit = (event: FormEvent) => {
		event.preventDefault();

		setAiAnalytics();
	};

	const handleFormReset = (event: FormEvent) => {
		event.preventDefault();

		const currentlySelectedRuleExists = !!aiAnalyticsState.rules[ selectedRuleIndex ];

		if (!currentlySelectedRuleExists) {
			setSelectedRuleIndex(firstExistingRuleIndex);
		}

		setLocalAiAnalyticsSettings(aiAnalyticsState);
	};

	const updateEnabled = (checked: boolean) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.enabled = checked;
		}));
	};

	const panes = [
		{
			title: "AI Rule Setup",
			content: <AiRuleSettings
				localAiAnalyticsState={ localAiAnalyticsState }
				selectedRuleIndex={ selectedRuleIndex }
				setSelectedRuleIndex={ setSelectedRuleIndex }
			/>
		},
		{
			title: "Advanced",
			content: <AdvancedAiSettings
				localAiAnalyticsState={ localAiAnalyticsState }
			/>
		}
	];

	return (
		<form
			className="settings-form"
			noValidate
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="ai-analytics settings-content">
						<SettingsCard size="large" title="AI Analytics">
							<div className="enabled-switch">
								<Switch
									checked={ localAiAnalyticsSettings.enabled }
									onChange={ updateEnabled }
								/>
								<span>Enable AI Analytics</span>
							</div>
							<Tabs panes={ panes } />
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localAiAnalyticsSettings, aiAnalyticsState) || incorrectConfiguration } />
		</form>
	);
}

export default memo(AiAnalytics);
