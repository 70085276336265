import { PayloadAction } from "@reduxjs/toolkit";
import { DiskWriteMode, FormatType } from "Data/Objects/Storage";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { ISdCardSettingsState } from "Interfaces";

const initialState: ISdCardSettingsState = {
	enableSdCardRecording: true,
	totalSize: 256,
	usedSize: 35.9,
	status: true,
	diskWriteMode: DiskWriteMode.OVERWRITE,
	storageName: "SD Card",
	formatType: FormatType.EXFAT
};

const setSdCardReducer = (state: ISdCardSettingsState, action: PayloadAction<ISdCardSettingsState>) => {
	return action.payload;
};

const getSdCard = (state: ISdCardSettingsState) => {
	return state;
};

const sdCardSlice = createAppSlice({
	name: "sdCard",
	initialState,
	reducers: {
		setSdCardAction: setSdCardReducer
	},
	selectors: {
		selectSdCard: getSdCard
	}
});

export default sdCardSlice;

export const { setSdCardAction } = sdCardSlice.actions;
export const { selectSdCard } = sdCardSlice.getSelectors<AppState>(state => state.settings.storage.sdCard);
