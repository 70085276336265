import { PayloadAction } from "@reduxjs/toolkit";
import { AudioCodec } from "Data/Objects/Audio";
import { IAudioSettingsState } from "Interfaces";
import { createAppSlice } from "Data/Redux/Store";
import { AppState } from "Data/Redux/Reducers";

const initialState: IAudioSettingsState = {
	enableAudio: false,
	audioCodec: AudioCodec.G711,
	micVolume: 10,
	speakerVolume: 10
};

export const setAudioReducer = (state: IAudioSettingsState, action: PayloadAction<IAudioSettingsState>) => {
	return action.payload;
};

export const getAudio = (state: IAudioSettingsState) => {
	return state;
};

const audioSlice = createAppSlice({
	name: "audio",
	initialState,
	reducers: {
		setAudioAction: setAudioReducer
	},
	selectors: {
		selectAudio: getAudio
	}
});

export default audioSlice;

export const { setAudioAction } = audioSlice.actions;
export const { selectAudio } = audioSlice.getSelectors<AppState>(state => state.settings.audio.audio);
