import { ReactElement, ReactNode, memo } from "react";
import "Components/PlaybackLayout/HistoricalTimelineToolbar/HistoricalTimelineToolbar.less";
import {
	Button, IconWeight, IIcon, ISelectOption, Popover, PopoverPlacement, PopoverTrigger, Select
} from "@clintonelec/react-storybook";
import { ZoomLevel } from "Interfaces";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectZoomLevel, setZoomLevelAction } from "Data/Redux/Slices/Playback";

const zoomOptions: ISelectOption[] = [
	{
		label: "24 Hour",
		value: ZoomLevel.SIXTY_MINUTES
	},
	{
		label: "12 Hour",
		value: ZoomLevel.THIRTY_MINUTES
	},
	{
		label: "6 Hour",
		value: ZoomLevel.FIFTEEN_MINUTES
	},
	{
		label: "2 Hour",
		value: ZoomLevel.FIVE_MINUTES
	}
];

const timelineInfo: ReactElement = (
	<div className="timeline-info">
		<div>Recording Type:</div>
		<div className="recording-types">
			<span><span className="continuous" />Continuous</span>
			<span><span className="alarm" />Alarm</span>
			<span><span className="motion" />Motion</span>
			<span><span className="network-loss" />Network Loss</span>
			<span><span className="ai" />AI</span>
		</div>
	</div>
);

function HistoricalTimelineToolbar() {
	const dispatch = useAppDispatch();
	const zoomLevel = useAppSelector(selectZoomLevel);
	const changeZoomLevel = (value: ZoomLevel) => dispatch(setZoomLevelAction(value));

	const handleZoomLevelChange = (value: string) => {
		changeZoomLevel(parseInt(value));
	};

	const renderButton = (
		icon: string, popoverContent?: ReactNode
	) => {
		const buttonIcon: IIcon = {
			name: icon,
			weight: IconWeight.REGULAR
		};

		const content = <Button className="toolbar-button" icon={ buttonIcon } />;

		if (!popoverContent) {
			return content;
		}

		return (
			<Popover
				content={ popoverContent }
				interactive={ false }
				placement={ PopoverPlacement.TOP }
				trigger={ PopoverTrigger.CLICK }
			>
				{ content }
			</Popover>
		);
	};

	return (
		<div className="historical-timeline-toolbar">
			<div className="zoom-level-picker">
				<span>Timeline</span>
				<Select
					options={ zoomOptions }
					value={ zoomLevel }
					onSelect={ handleZoomLevelChange }
					allowClear={ false }
					menuPlacement="top"
				/>
			</div>
			<div className="toolbar-controls">
				<div className="center-content">
					{ renderButton("play") }
					{ renderButton("camera") }
					{ renderButton("expand") }
					{ renderButton("download") }
					{ renderButton("square-info", timelineInfo) }
				</div>
			</div>
		</div>
	);
}

export default memo(HistoricalTimelineToolbar);
