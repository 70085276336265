import { Select } from "@clintonelec/react-storybook";
import { FormEvent, memo, useState } from "react";
import { onOffOptions, deviceNameOptions, colorOptions } from "Data/Objects/EmbeddedOsdOptions";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectEmbeddedOsd, setEmbeddedOsdAction } from "Data/Redux/Slices/Settings/Camera/EmbeddedOsd";
import { IEmbeddedOsdSettingsState } from "Interfaces";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { Link } from "react-router-dom";
import { Colors, DeviceNamePosition } from "Data/Objects/Camera";
import { produce } from "immer";
import { isEqual, merge } from "lodash";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

function EmbeddedOsdSettings() {
	const dispatch = useAppDispatch();
	const embeddedOsdSettings = useAppSelector(selectEmbeddedOsd);
	const [ embeddedOsdSettingsDiff, setEmbeddedOsdSettingsDiff ] = useState<Partial<IEmbeddedOsdSettingsState>>({});

	const setEmbeddedOsdSettings = (newSettings: IEmbeddedOsdSettingsState) => {
		dispatch(setEmbeddedOsdAction(newSettings));
	};

	const localEmbeddedOsdSettings = produce(embeddedOsdSettings, draft => {
		merge(draft, embeddedOsdSettingsDiff);
	});

	const { resolution, dateTime, deviceName } = localEmbeddedOsdSettings.cameraInfo;
	const { displayMode, objectColor, ruleColor, eventColor } = localEmbeddedOsdSettings.ai;
	const disabled = isEqual(localEmbeddedOsdSettings, embeddedOsdSettings);

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setEmbeddedOsdSettingsDiff({});
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setEmbeddedOsdSettings(localEmbeddedOsdSettings);
		setEmbeddedOsdSettingsDiff({});
	};

	const selectResolution = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.cameraInfo.resolution = value === "ON";
		}));
	};

	const selectDateTime = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.cameraInfo.dateTime = value === "ON";
		}));
	};

	const selectDeviceName = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.cameraInfo.deviceName = value as DeviceNamePosition;
		}));
	};

	const selectDisplayMode = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.ai.displayMode = value === "ON";
		}));
	};

	const selectObjectColor = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.ai.objectColor = value as Colors;
		}));
	};

	const selectRuleColor = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.ai.ruleColor = value as Colors;
		}));
	};

	const selectEventColor = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.ai.eventColor = value as Colors;
		}));
	};

	return (
		<form className="settings-form" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="settings-content">
						<SettingsCard size="small" title="Camera Information">
							<div className="form-row">
								<span>Resolution</span>
								<Select
									allowClear={ false }
									className="form-select"
									onSelect={ selectResolution }
									options={ onOffOptions }
									value={ resolution ? "ON" : "OFF" }
								/>
							</div>
							<div className="form-row">
								<span>Date / Time</span>
								<Select
									allowClear={ false }
									className="form-select"
									onSelect={ selectDateTime }
									options={ onOffOptions }
									value={ dateTime ? "ON" : "OFF" }
								/>
							</div>
							<div className="form-row">
								<span>Device Name</span>
								<Select
									allowClear={ false }
									className="form-select"
									onSelect={ selectDeviceName }
									options={ deviceNameOptions }
									value={ deviceName }
								/>
							</div>
							<div className="note-banner alert">
								<span>Note</span>
								<span>&nbsp;-&nbsp;</span>
								<span>
									<Link to="/setup/system/management">
										Go to System Management to set the device name.
									</Link>
								</span>
							</div>
						</SettingsCard>
						<SettingsCard size="small" title="AI OSD">
							<div className="form-row">
								<span>Display Mode</span>
								<Select
									allowClear={ false }
									className="form-select"
									onSelect={ selectDisplayMode }
									options={ onOffOptions }
									value={ displayMode ? "ON" : "OFF" }
								/>
							</div>
							<div className="form-row">
								<span>Object Color</span>
								<Select
									allowClear={ false }
									className="form-select"
									onSelect={ selectObjectColor }
									options={ colorOptions }
									value={ objectColor }
								/>
							</div>
							<div className="form-row">
								<span>Rule Color</span>
								<Select
									allowClear={ false }
									className="form-select"
									onSelect={ selectRuleColor }
									options={ colorOptions }
									value={ ruleColor }
								/>
							</div>
							<div className="form-row">
								<span>Event Color</span>
								<Select
									allowClear={ false }
									className="form-select"
									onSelect={ selectEventColor }
									options={ colorOptions }
									value={ eventColor }
								/>
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(EmbeddedOsdSettings);
