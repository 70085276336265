import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IMotionEventState } from "Interfaces";

const initialState: IMotionEventState = {
	email: false,
	alarmOut: false,
	audioAlarm: false,
	interval: 0,
	recording: false,
	redBlueLight: false,
	snapshot: false,
	whiteLight: false,
	ftp: false
};

const setMotionEventReducer = (state: IMotionEventState, action: PayloadAction<IMotionEventState>) => {
	return action.payload;
};

const getMotionEvent = (state: IMotionEventState) => {
	return state;
};

const motionEventSlice = createAppSlice({
	initialState,
	name: "motionEvent",
	reducers: {
		setMotionEventAction: setMotionEventReducer
	},
	selectors: {
		selectMotionEvent: getMotionEvent
	}
});

export default motionEventSlice;

export const { setMotionEventAction } = motionEventSlice.actions;
export const { selectMotionEvent } = motionEventSlice.getSelectors<AppState>(state => state.settings.event.motionEvent);
