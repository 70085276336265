import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IFtpSettingsState } from "Interfaces";

const initialState: IFtpSettingsState = {
	ftp: false
};

export const setFtpReducer = (state: IFtpSettingsState, action: PayloadAction<IFtpSettingsState>) => {
	return action.payload;
};

export const getFtp = (state: IFtpSettingsState) => {
	return state;
};

const ftpSlice = createAppSlice({
	name: "ftp",
	initialState,
	reducers: {
		setFtpAction: setFtpReducer
	},
	selectors: {
		selectFtp: getFtp
	}
});

export default ftpSlice;

export const { setFtpAction } = ftpSlice.actions;
export const { selectFtp } = ftpSlice.getSelectors<AppState>(state => state.settings.network.ftp);
