// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aspect-wrapper-1 {
  align-items: center;
  display: flex;
  height: 100%;
  max-width: 100%;
  position: relative;
}
.aspect-wrapper-1 .aspect-wrapper-2 {
  max-height: 100%;
  position: absolute;
  width: 100%;
}
.aspect-wrapper-1 .aspect-wrapper-2 .responsive-container {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
}
.aspect-wrapper-1 .aspect-maintainer {
  display: block;
  height: 100%;
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Global/AspectMaintainer/AspectMaintainer.less"],"names":[],"mappings":"AAEA;EACC,mBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AADD;AAJA;EAQE,gBAAA;EACA,kBAAA;EACA,WAAA;AADF;AATA;EAaG,YAAA;EACA,QAAA;EACA,kBAAA;EACA,WAAA;AADH;AAfA;EAqBE,cAAA;EACA,YAAA;EACA,eAAA;AAHF","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.aspect-wrapper-1 {\n\talign-items: center;\n\tdisplay: flex;\n\theight: 100%;\n\tmax-width: 100%;\n\tposition: relative;\n\n\t.aspect-wrapper-2 {\n\t\tmax-height: 100%;\n\t\tposition: absolute;\n\t\twidth: 100%;\n\n\t\t.responsive-container {\n\t\t\theight: 100%;\n\t\t\tinset: 0;\n\t\t\tposition: absolute;\n\t\t\twidth: 100%;\n\t\t}\n\t}\n\n\t.aspect-maintainer {\n\t\tdisplay: block;\n\t\theight: 100%;\n\t\tmax-width: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
