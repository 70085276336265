import { PayloadAction } from "@reduxjs/toolkit";
import { DUMMY_SCHEDULE_SETTINGS } from "Data/Dummy/System";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IScheduleSettingsState } from "Interfaces";

export const setScheduleReducer = (state: IScheduleSettingsState, action: PayloadAction<IScheduleSettingsState>) => {
	return action.payload;
};

export const getSchedule = (state: IScheduleSettingsState) => {
	return state;
};

const scheduleSlice = createAppSlice({
	name: "schedule",
	initialState: DUMMY_SCHEDULE_SETTINGS,
	reducers: {
		setScheduleAction: setScheduleReducer
	},
	selectors: {
		selectSchedule: getSchedule
	}
});

export default scheduleSlice;

export const { setScheduleAction } = scheduleSlice.actions;
export const { selectSchedule } = scheduleSlice.getSelectors<AppState>(state => state.settings.system.schedule);
