// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cert-button:not(:first-child) {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Network/SecuritySettings/CertificateField/CertificateField.less"],"names":[],"mappings":"AAEA;EACC,gBAAA;AADD","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.cert-button:not(:first-child) {\n\tmargin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
