import { memo, ReactElement, useMemo } from "react";
import {
	Button, ButtonTypes, Checkbox, ColumnSortDirection, ConfirmModal, IColumn, Table
} from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectCurrentUser } from "Data/Redux/Slices/User";
import "Components/ConfigLayout/User/UserManagement/UserManagement.less";
import UserManagementModal from "Components/ConfigLayout/User/UserManagement/UserManagementModal";
import { IUser } from "Interfaces";
import { deleteUserAction, selectAllUsers } from "Data/Redux/Slices/Settings/User/Management";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const userManagementColumns: IColumn[] = [
	{
		dataIndex: "userId",
		title: "User ID",
		key: "userId",
		sortable: true,
		defaultSortOrder: ColumnSortDirection.SORT_ASC
	},
	{
		dataIndex: "group",
		title: "Group",
		key: "group"
	},
	{
		dataIndex: "email",
		title: "Email",
		key: "email"
	},
	{
		dataIndex: "notifications",
		title: "Notifications",
		key: "notifications",
		sortable: false
	},
	{
		dataIndex: "actions",
		title: "Actions",
		key: "actions",
		sortable: false
	}
];

interface IUserActionsProps {
	user: IUser;
}

function UserActions(props: IUserActionsProps) {
	const { user } = props;
	const dispatch = useAppDispatch();
	const deleteUser = () => dispatch(deleteUserAction(user.id));
	const loggedInUser = useAppSelector(selectCurrentUser);
	let deleteContent: ReactElement;

	if (user.id !== loggedInUser.id) {
		deleteContent = (
			<ConfirmModal
				okButton={ { text: "Confirm", onClick: deleteUser, type: ButtonTypes.TERTIARY } }
				cancelButton={ { text: "Cancel", type: ButtonTypes.DANGER } }
				title="Delete User"
				modalContent="Are you sure you want to do this? It cannot be undone."
			>
				<Button
					type={ ButtonTypes.DANGER }
					ghost
					icon={ { name: "trash" } }
					htmlType="button"
				/>
			</ConfirmModal>
		);
	}

	return (
		<div className="user-actions">
			<UserManagementModal user={ user }>
				<Button
					icon={ { name: "pencil" } }
					type={ ButtonTypes.SECONDARY }
					ghost
					htmlType="button"
				/>
			</UserManagementModal>
			{ deleteContent }
		</div>
	);
}

function UserManagement() {
	const allUsers = useAppSelector(selectAllUsers);
	const tableData = useMemo(() => {
		return allUsers?.map(user => {
			const { id, userId, email, group, notifications } = user;

			return {
				key: id,
				userId,
				group,
				email,
				notifications: <Checkbox checked={ notifications } disabled />,
				actions: <UserActions user={ user } />
			};
		});
	}, [ allUsers ]);

	return (
		<form className="settings-form" noValidate>
			<div className="scrollable-content scrollable-container">
				<div className="settings-content-container">
					<div className="user-management-settings settings-content">
						<SettingsCard size="medium" title="User Management">
							<UserManagementModal>
								<Button
									className="add-user-button"
									icon={ { name: "plus" } }
									htmlType="button"
									type={ ButtonTypes.TERTIARY }
								>
									Add User
								</Button>
							</UserManagementModal>
							<Table
								columns={ userManagementColumns }
								data={ tableData }
							/>
						</SettingsCard>
					</div>
				</div>
			</div>
		</form>
	);
}

export default memo(UserManagement);
