import { Dispatch, FormEvent, SetStateAction, memo, useMemo, useState } from "react";
import { Checkbox, IColumn, Table } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import "Components/ConfigLayout/User/GroupAuthority/GroupAuthority.less";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { UserPermissions } from "Data/Objects/User";
import { selectGroupAuthority, setGroupAuthorityAction } from "Data/Redux/Slices/Settings/User/Authority";
import { addOrRemoveFromArray } from "@clintonelec/typescriptutils";
import { isEqual } from "lodash";
import { produce } from "immer";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const groupAuthorityColumns: IColumn[] = [
	{
		dataIndex: "permission",
		title: "",
		key: "permission",
		sortable: false
	},
	{
		dataIndex: "admin",
		title: "Admin",
		key: "admin",
		sortable: false,
		align: "center"
	},
	{
		dataIndex: "manager",
		title: "Manager",
		key: "manager",
		sortable: false,
		align: "center"
	},
	{
		dataIndex: "user",
		title: "User",
		key: "user",
		sortable: false,
		align: "center"
	}
];

function GroupAuthority() {
	const groupAuthority = useAppSelector(selectGroupAuthority);
	const [ userAuthorityDiff, setUserAuthorityDiff ] = useState<UserPermissions[]>([]);
	const [ managerAuthorityDiff, setManagerAuthorityDiff ] = useState<UserPermissions[]>([]);
	const dispatch = useAppDispatch();
	const setGroupAuthority = (user: UserPermissions[], manager: UserPermissions[]) => dispatch(
		setGroupAuthorityAction({ user, manager })
	);

	const createLocalPermissions = (remotePermissions: UserPermissions[], localPermissions: UserPermissions[]) => {
		return produce(remotePermissions, (draft) => {
			localPermissions.forEach((permission) => {
				const index = draft.findIndex(currentItem => {
					return isEqual(currentItem, permission);
				});

				if (index < 0) {
					draft.push(permission);
				} else {
					draft.splice(index, 1);
				}
			});
		});
	};

	const localUserPermissions = createLocalPermissions(groupAuthority.user, userAuthorityDiff);
	const localManagerPermissions = createLocalPermissions(groupAuthority.manager, managerAuthorityDiff);

	const handleCheckboxChanged = (
		permission: UserPermissions,
		permissionList: UserPermissions[],
		callback: Dispatch<SetStateAction<UserPermissions[]>>
	) => {
		const newList = addOrRemoveFromArray(permissionList, permission);

		callback(newList);
	};

	const tableData = useMemo(() => {
		return Object.values(UserPermissions).map((permission) => {
			const managerChecked = localManagerPermissions.includes(permission);
			const userChecked = localUserPermissions.includes(permission);
			const handleManagerChecked = () => handleCheckboxChanged(
				permission, managerAuthorityDiff, setManagerAuthorityDiff
			);

			const handleUserChecked = () => handleCheckboxChanged(
				permission, userAuthorityDiff, setUserAuthorityDiff
			);

			return {
				key: permission,
				permission: permission,
				admin: <Checkbox checked disabled />,
				manager: <Checkbox checked={ managerChecked } onChange={ handleManagerChecked } />,
				user: <Checkbox checked={ userChecked } onChange={ handleUserChecked } />
			};
		});
	}, [ localUserPermissions, localManagerPermissions, userAuthorityDiff, managerAuthorityDiff ]);

	const handleReset = (event: FormEvent) => {
		event.preventDefault();

		setUserAuthorityDiff([]);
		setManagerAuthorityDiff([]);
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		setGroupAuthority(localUserPermissions, localManagerPermissions);
		setUserAuthorityDiff([]);
		setManagerAuthorityDiff([]);
	};

	return (
		<form className="settings-form" noValidate onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="group-authority-settings settings-content">
						<SettingsCard size="medium" title="Group Authority">
							<Table
								columns={ groupAuthorityColumns }
								data={ tableData }
							/>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons
				disabled={ isEqual({ user: localUserPermissions, manager: localManagerPermissions }, groupAuthority) }
			/>
		</form>
	);
}

export default memo(GroupAuthority);
