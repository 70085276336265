import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { ISystemEventState } from "Interfaces";

const initialState: ISystemEventState = {
	alarmOut: false,
	email: false,
	snapshot: false,
	ftp: false
};

const setSystemEventReducer = (state: ISystemEventState, action: PayloadAction<ISystemEventState>) => {
	return action.payload;
};

const getSystemEvent = (state: ISystemEventState) => {
	return state;
};

const systemEventSlice = createAppSlice({
	initialState,
	name: "systemEvent",
	reducers: {
		setSystemEventAction: setSystemEventReducer
	},
	selectors: {
		selectSystemEvent: getSystemEvent
	}
});

export default systemEventSlice;

export const { setSystemEventAction } = systemEventSlice.actions;
export const { selectSystemEvent } = systemEventSlice.getSelectors<AppState>(state => state.settings.event.systemEvent);
