import { dom, library } from "@fortawesome/fontawesome-svg-core";
import * as light from "@fortawesome/pro-light-svg-icons";
import * as regular from "@fortawesome/pro-regular-svg-icons";
import * as solid from "@fortawesome/pro-solid-svg-icons";

// Add our solid, regular, and light icons to the library
export const icons = [ "faEyeSlash", "faEye", "faLanguage", "faCircleQuestion", "faTv", "faArrowRightFromBracket",
	"faClapperboardPlay", "faSliders", "faAngleDown", "faAngleRight", "faCameraCctv", "faDisplay", "faVolume",
	"faUser", "faGlobe", "faGear", "faHardDrive" , "faSirenOn", "faFilm", "faXmark", "faPencil", "faCaretUp",
	"faCaretDown", "faTrash", "faPlus"
];

export default function initFontAwesome() {
	icons.map((icon: string) => {
		library.add(solid[icon]);
		library.add(regular[icon]);
		library.add(light[icon]);
	});

	dom.watch();
}
