import "Components/ConfigLayout/System/ScheduleSettings/ScheduleSettings.less";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { ChangeEvent, FormEvent, memo, useState } from "react";
import {
	Button, ButtonTypes, ConfirmModal, IColumn, IModalButtonProps, Input, ISelectOption, IWithKey, Select, Table
} from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectSchedule, setScheduleAction } from "Data/Redux/Slices/Settings/System/Schedule";
import { produce } from "immer";
import { isEqual } from "lodash";
import { IScheduledDay } from "Interfaces";
import { ScheduleType } from "Data/Objects/System";
import SchedulePopover from "Components/ConfigLayout/System/ScheduleSettings/SchedulePopover";
import SettingsCard from "Components/ConfigLayout/SettingsCard";

const typeOptions: ISelectOption[] = [
	{
		label: "Daily",
		value: ScheduleType.DAILY
	},
	{
		label: "Weekly",
		value: ScheduleType.WEEKLY
	}
];

const days = [ "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN" ];

const createTimeOptions = (day?: string) => {
	return new Array(24).fill(0).map((current, index) => {
		return {
			label: `${ day ? day + ". " : "" }${ index.toString().padStart(2, "0") }:00`,
			value: `${ day ? day + ". " : "" }${ index.toString().padStart(2, "0") }:00`
		};
	});
};

const dailyTimeOptions: ISelectOption[] = createTimeOptions();
const weeklyTimeOptions: ISelectOption[] = days.flatMap(createTimeOptions);
const columns: IColumn[] = [
	{
		align: "center",
		dataIndex: "no",
		key: "no",
		sortable: false,
		title: "No."
	},
	{
		dataIndex: "name",
		key: "name",
		sortable: false,
		title: "Name",
		width: 190
	},
	{
		dataIndex: "type",
		key: "type",
		sortable: false,
		title: "Type",
		width: 190
	},
	{
		dataIndex: "startTime",
		key: "startTime",
		sortable: false,
		title: "Start Time",
		width: 190
	},
	{
		dataIndex: "endTime",
		key: "endTime",
		sortable: false,
		title: "End Time",
		width: 190
	},
	{
		dataIndex: "delete",
		key: "delete",
		sortable: false,
		title: "Delete"
	}
];

function ScheduleSettings() {
	const dispatch = useAppDispatch();
	const scheduleSettings = useAppSelector(selectSchedule);
	const [ localScheduleSettings, setLocalScheduleSettings ] = useState(scheduleSettings);
	const setScheduleSettings = () => dispatch(setScheduleAction(localScheduleSettings));
	const disabled = isEqual(localScheduleSettings, scheduleSettings);

	const handleFormReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLocalScheduleSettings(scheduleSettings);
	};

	const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setScheduleSettings();
		}
	};

	const updateName = (id: number) => (value: string) => {
		setLocalScheduleSettings(produce(localScheduleSettings, draft => {
			draft.schedules[ id ].name = value;
		}));
	};

	const selectScheduleType = (id: number) => (value: ScheduleType) => {
		setLocalScheduleSettings(produce(localScheduleSettings, draft => {
			draft.schedules[ id ].scheduleType = value;

			switch (value) {
				case ScheduleType.DAILY: {
					draft.schedules[ id ].startTime = "00:00";
					draft.schedules[ id ].endTime = "00:00";

					break;
				}

				case ScheduleType.WEEKLY: {
					draft.schedules[ id ].startTime = "MON. 00:00";
					draft.schedules[ id ].endTime = "MON. 00:00";

					break;
				}

				default: {
					break;
				}
			}
		}));
	};

	const checkScheduledDay = (id: number) => (value: IScheduledDay) => {
		setLocalScheduleSettings(produce(localScheduleSettings, draft => {
			draft.schedules[ id ].scheduledDay = value;
		}));
	};

	const changeCheckbox = (day: string, id: number) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalScheduleSettings(produce(localScheduleSettings, draft => {
			draft.schedules[ id ].scheduledDay[ day ] = event.target.checked;
		}));
	};

	const selectStartTime = (id: number) => (value: string) => {
		setLocalScheduleSettings(produce(localScheduleSettings, draft => {
			draft.schedules[ id ].startTime = value;
		}));
	};

	const selectEndTime = (id: number) => (value: string) => {
		setLocalScheduleSettings(produce(localScheduleSettings, draft => {
			draft.schedules[ id ].endTime = value;
		}));
	};

	const deleteRow = (id: number) => () => {
		setLocalScheduleSettings(produce(localScheduleSettings, draft => {
			draft.schedules = localScheduleSettings.schedules.filter((current, index) => index !== id);
		}));
	};

	const tableData: IWithKey[] = localScheduleSettings.schedules.map((schedule, index) => {
		const { name, scheduleType, scheduledDay, startTime, endTime } = schedule;
		const nameField = (
			<Input
				onUpdate={ updateName(index) }
				value={ name }
			/>
		);

		const scheduleTypeField = (
			<div className="schedule-type">
				<Select
					allowClear={ false }
					onSelect={ selectScheduleType(index) }
					options={ typeOptions }
					value={ scheduleType }
				/>
				<SchedulePopover
					checkScheduledDay={ checkScheduledDay }
					days={ days }
					index={ index }
					scheduledDay={ scheduledDay }
					scheduleType={ scheduleType }
					changeCheckbox={ changeCheckbox }
				/>
			</div>
		);

		const timeField = (key: string, time: string) => {
			return (
				<Select
					allowClear={ false }
					onSelect={ key === "startTime" ? selectStartTime(index) : selectEndTime(index) }
					options={ scheduleType === ScheduleType.WEEKLY ? weeklyTimeOptions : dailyTimeOptions }
					value={ time }
				/>
			);
		};

		const cancelButton: IModalButtonProps = {
			text: "Cancel",
			type: ButtonTypes.DANGER
		};

		const confirmButton: IModalButtonProps = {
			text: "Confirm",
			type: ButtonTypes.TERTIARY,
			onClick: deleteRow(index)
		};

		const deleteField = (
			<ConfirmModal
				cancelButton={ cancelButton }
				okButton={ confirmButton }
				title="Delete Preset"
				modalContent="Are you sure you want to remove this preset?"
			>
				<Button
					ghost
					htmlType="button"
					icon={ { name: "trash" } }
					type={ ButtonTypes.DANGER }
				/>
			</ConfirmModal>
		);

		return {
			no: index + 1,
			name: nameField,
			type: scheduleTypeField,
			startTime: timeField("startTime", startTime),
			endTime: timeField("endTime", endTime),
			delete: deleteField,
			key: index + ""
		};
	});

	const createNewSchedule = () => {
		const newSchedule = {
			name: "NEW SCHEDULE",
			scheduleType: ScheduleType.DAILY,
			scheduledDay: {
				MON: true,
				TUE: true,
				WED: true,
				THU: true,
				FRI: true,
				SAT: true,
				SUN: true
			},
			startTime: "00:00",
			endTime: "00:00"
		};

		setLocalScheduleSettings(produce(localScheduleSettings, draft => {
			draft.schedules.push(newSchedule);
		}));
	};

	return (
		<form
			className="schedule-settings-form settings-form"
			noValidate
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="settings-content-container">
					<div className="schedule-settings settings-content">
						<SettingsCard size="large" title="Schedule Preset">
							<div className="form-row">
								<Button
									htmlType="button"
									icon={ { name: "plus" } }
									onClick={ createNewSchedule }
									type={ ButtonTypes.TERTIARY }
								>
									Add Preset
								</Button>
							</div>
							<Table
								columns={ columns }
								data={ tableData }
							/>
						</SettingsCard>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(ScheduleSettings);
