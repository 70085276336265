import {
	Button, ButtonTypes, DatePicker, Input, ISelectOption, Modal, Select, usePropsChanged
} from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import "Components/ConfigLayout/System/DateTimeSettings/DateTimeSettings.less";
import { DateFormat, TimeFormat, TimezoneDst } from "Data/Objects/System";
import { selectDateTime, setDateTimeAction } from "Data/Redux/Slices/Settings/System/DateTime";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { produce } from "immer";
import { IDateTimeSettingsState } from "Interfaces";
import { isEqual, merge } from "lodash";
import { Dispatch, FormEvent, memo, SetStateAction, useEffect, useState } from "react";

const dateFormatOptions: ISelectOption[] = [
	{
		label: DateFormat.YYYY_MM_DD,
		value: DateFormat.YYYY_MM_DD
	},
	{
		label: DateFormat.MM_DD_YYYY,
		value: DateFormat.MM_DD_YYYY
	},
	{
		label: DateFormat.DD_MM_YYYY,
		value: DateFormat.DD_MM_YYYY
	}
];

const timeFormatOptions: ISelectOption[] = [
	{
		label: TimeFormat.TWENTYFOUR_HOUR,
		value: TimeFormat.TWENTYFOUR_HOUR
	},
	{
		label: TimeFormat.TWELVE_HOUR,
		value: TimeFormat.TWELVE_HOUR
	}
];

const onOffOptions: ISelectOption[] = [
	{
		label: "On",
		value: "ON"
	},
	{
		label: "Off",
		value: "OFF"
	}
];

const syncTimeOptions: ISelectOption[] = new Array(24).fill(0).map((current, index) => {
	return {
		label: `${ index.toString().padStart(2, "0") }:00`,
		value: `${ index.toString().padStart(2, "0") }:00`
	};
});

const timezoneDstOptions: ISelectOption[] = Object.values(TimezoneDst).map(value => {
	return {
		label: value,
		value: value
	};
});

const datePickerDateFormat = {
	[ DateFormat.YYYY_MM_DD ]: "yyyy/MM/dd",
	[ DateFormat.MM_DD_YYYY ]: "MM/dd/yyyy",
	[ DateFormat.DD_MM_YYYY ]: "dd/MM/yyyy"
};

const datePickerTimeFormat = {
	[ TimeFormat.TWENTYFOUR_HOUR ]: "HH:mm:ss",
	[ TimeFormat.TWELVE_HOUR ]: "hh:mm:ss aa"
};

interface IDateTimePickerProps {
	format: string;
	dateTimeSettings: IDateTimeSettingsState;
	setDiff: Dispatch<SetStateAction<IDateTimeSettingsState>>;
	timeFormat: TimeFormat;
}

function DateTimePicker(props: IDateTimePickerProps) {
	const { format, dateTimeSettings, setDiff, timeFormat } = props;
	const newDateTime = dateTimeSettings.dateTimeSetup.dateTime;
	const [ dateTime, setDateTime ] = useState(new Date(newDateTime));
	const [ isCalendarOpen, setIsCalendarOpen ] = useState(false);
	const closeCalendar = () => setIsCalendarOpen(false);
	const openCalendar = () => setIsCalendarOpen(true);

	useEffect(() => {
		if (!isCalendarOpen) {
			const timeout = setTimeout(() => {
				setDateTime(new Date(dateTime.getTime() + 1000));
			}, 1000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [ dateTime, isCalendarOpen ]);

	usePropsChanged(newDateTime, () => {
		setDateTime(new Date(newDateTime));
	});

	const handleOk = (date: Date) => {
		setDiff(produce(dateTimeSettings, draft => {
			draft.dateTimeSetup.dateTime = date.getTime();
		}));
	};

	return (
		<div className="date-time-setup-picker">
			<DatePicker
				cleanable={ false }
				format={ format }
				onClose={ closeCalendar }
				onOk={ handleOk }
				onOpen={ openCalendar }
				showMeridian={ timeFormat === TimeFormat.TWELVE_HOUR }
				size="sm"
				value={ dateTime }
			/>
		</div>
	);
}

function DateTimeSettings() {
	const dispatch = useAppDispatch();
	const dateTimeSettings = useAppSelector(selectDateTime);
	const [ dateTimeSettingsDiff, setDateTimeSettingsDiff ] = useState<Partial<IDateTimeSettingsState>>({});

	const setDateTimeSettings = (newSettings: IDateTimeSettingsState) => {
		dispatch(setDateTimeAction(newSettings));
	};

	const localDateTimeSettings = produce(dateTimeSettings, draft => {
		merge(draft, dateTimeSettingsDiff);
	});

	const { dateFormat, timeFormat } = localDateTimeSettings.dateTimeSetup;
	const { timeServer, autoTimeSync, syncTime } = localDateTimeSettings.networkTimeServerSetup;
	const { timezoneDst, dst } = localDateTimeSettings.timezoneDstSetup;
	const disabled = isEqual(localDateTimeSettings, dateTimeSettings);
	const format = datePickerDateFormat[ dateFormat ] + " " + datePickerTimeFormat[ timeFormat ];
	const [ syncPcModalVisible, setSyncPcModalVisible ] = useState(false);
	const [ syncServerModalVisible, setSyncServerModalVisible ] = useState(false);
	const clickSyncPcButton = () => setSyncPcModalVisible(visible => !visible);
	const clickSyncServerButton = () => setSyncServerModalVisible(visible => !visible);

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setDateTimeSettingsDiff({});
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setDateTimeSettings(localDateTimeSettings);
		setDateTimeSettingsDiff({});
	};

	const selectDateFormat = (value: DateFormat) => {
		setDateTimeSettingsDiff(produce(localDateTimeSettings, draft => {
			draft.dateTimeSetup.dateFormat = value;
		}));
	};

	const selectTimeFormat = (value: TimeFormat) => {
		setDateTimeSettingsDiff(produce(localDateTimeSettings, draft => {
			draft.dateTimeSetup.timeFormat = value;
		}));
	};

	const updateTimeServer = (value: string) => {
		setDateTimeSettingsDiff(produce(localDateTimeSettings, draft => {
			draft.networkTimeServerSetup.timeServer = value;
		}));
	};

	const selectAutoTimeSync = (value: string) => {
		setDateTimeSettingsDiff(produce(localDateTimeSettings, draft => {
			draft.networkTimeServerSetup.autoTimeSync = value === "ON";
		}));
	};

	const selectSyncTime = (value: string) => {
		setDateTimeSettingsDiff(produce(localDateTimeSettings, draft => {
			draft.networkTimeServerSetup.syncTime = value;
		}));
	};

	const selectTimezoneDst = (value: TimezoneDst) => {
		setDateTimeSettingsDiff(produce(localDateTimeSettings, draft => {
			draft.timezoneDstSetup.timezoneDst = value;
		}));
	};

	const selectDst = (value: string) => {
		setDateTimeSettingsDiff(produce(localDateTimeSettings, draft => {
			draft.timezoneDstSetup.dst = value === "ON";
		}));
	};

	return (
		<form className="date-time-form" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="container">
				<div className="card">
					<div className="header">
						<h4>Date Time Setup</h4>
					</div>
					<div className="form-row">
						<span>Date Time</span>
						<DateTimePicker
							format={ format }
							dateTimeSettings={ localDateTimeSettings }
							setDiff={ setDateTimeSettingsDiff }
							timeFormat={ timeFormat }
						/>
					</div>
					<div className="form-row">
						<span>Date Format</span>
						<Select
							allowClear={ false }
							className="date-time-select"
							onSelect={ selectDateFormat }
							options={ dateFormatOptions }
							value={ dateFormat }
						/>
					</div>
					<div className="form-row">
						<span>Time Format</span>
						<Select
							allowClear={ false }
							className="date-time-select"
							onSelect={ selectTimeFormat }
							options={ timeFormatOptions }
							value={ timeFormat }
						/>
					</div>
					<div className="form-row">
						<Modal
							className="date-time-sync-modal"
							modalContent="The system time is changing"
							onVisibilityChange={ clickSyncPcButton }
							title="Date Time Setup"
							visible={ syncPcModalVisible }
							width={ 400 }
						>
							<Button
								className="date-time-button"
								htmlType="button"
								onClick={ clickSyncPcButton }
								type={ ButtonTypes.TERTIARY }
							>
								Sync with PC
							</Button>
						</Modal>
					</div>
				</div>
				<div className="card">
					<div className="header">
						<h4>Network Time Server Setup</h4>
					</div>
					<div className="form-row">
						<span>Time Server</span>
						<Input
							onUpdate={ updateTimeServer }
							value={ timeServer }
							wrapClassName="date-time-input"
						/>
					</div>
					<div className="form-row">
						<span>Auto Time Sync</span>
						<Select
							allowClear={ false }
							className="date-time-select"
							onSelect={ selectAutoTimeSync }
							options={ onOffOptions }
							value={ autoTimeSync ? "ON" : "OFF" }
						/>
					</div>
					<div className="form-row">
						<Modal
							className="date-time-sync-modal"
							modalContent="System time is synchronized with NTP server"
							onVisibilityChange={ clickSyncServerButton }
							title="Date Time Setup"
							visible={ syncServerModalVisible }
							width={ 400 }
						>
							<Button
								className="date-time-button"
								htmlType="button"
								onClick={ clickSyncServerButton }
								type={ ButtonTypes.TERTIARY }
							>
								Sync.
							</Button>
						</Modal>
					</div>
					<div className="form-row">
						<span>Sync Time</span>
						<Select
							allowClear={ false }
							className="date-time-select"
							disabled={ !autoTimeSync }
							onSelect={ selectSyncTime }
							options={ syncTimeOptions }
							value={ syncTime }
						/>
					</div>
				</div>
				<div className="card">
					<div className="header">
						<h4>Timezone / DST</h4>
					</div>
					<div className="form-row">
						<span>Timezone / DST</span>
						<Select
							allowClear={ false }
							className="date-time-select"
							onSelect={ selectTimezoneDst }
							options={ timezoneDstOptions }
							value={ timezoneDst }
						/>
					</div>
					<div className="form-row">
						<span>DST</span>
						<Select
							allowClear={ false }
							className="date-time-select"
							onSelect={ selectDst }
							options={ onOffOptions }
							value={ dst ? "ON" : "OFF" }
						/>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(DateTimeSettings);
