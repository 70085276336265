import { memo } from "react";
import { useAppSelector } from "Data/Redux/Store";
import { selectCurrentUser, selectToken } from "Data/Redux/Slices/User";
import { Navigate, Outlet } from "react-router-dom";
import { IWithChildren } from "Interfaces";

interface IPrivateRouteProps extends IWithChildren {
	hasPermission?: boolean;
	redirectTo?: string;
}

function PrivateRoute({ hasPermission = true, children, redirectTo = "/login" }: IPrivateRouteProps) {
	const currentUser = useAppSelector(selectCurrentUser);
	const token = useAppSelector(selectToken);

	if (!currentUser || !token || !hasPermission) {
		return <Navigate to={ redirectTo } />;
	}

	return children ?? <Outlet />;
}

export default memo(PrivateRoute);
