import { AspectMaintainer, DatePicker, Input, RangeSlider, Select } from "@clintonelec/react-storybook";
import {
	DayNightMode, dayNightOptions, dayToNightSensitivityOptions, DwellTime, dwellTimeOptions, IlluminationMode,
	illuminationModeOptions, IrLed, irLedOptions, nightToDaySensitivityOptions, onOffOptions, WhiteLedControl,
	whiteLedControlOptions
} from "Data/Objects/Camera";
import { produce } from "immer";
import { IImageSettingsState } from "Interfaces";
import { Dispatch, Fragment, memo, SetStateAction } from "react";

interface IDayNightProps {
	localSettings: IImageSettingsState;
	setLocalSettings: Dispatch<SetStateAction<IImageSettingsState>>;
}

function DayNight(props: IDayNightProps) {
	const { localSettings, setLocalSettings } = props;
	const {
		dayNightMode, dwellTime, dayTimeStart, dayTimeEnd, dayToNightSensitivity, nightToDaySensitivity,
		illuminationMode, irLed, adaptiveIr, irLedIllumination, antiOverexposure, whiteLedControl, whiteLedIllumination
	} = localSettings.dayNight;

	const isDayMode = dayNightMode === DayNightMode.DAY;
	const isAutoMode = dayNightMode === DayNightMode.AUTO;

	const selectDayNightMode = (value: DayNightMode) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.dayNightMode = value;
		}));
	};

	const changeDayTimeStart = (value: Date) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.dayTimeStart = value.getTime();
		}));
	};

	const changeDayTimeEnd = (value: Date) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.dayTimeEnd = value.getTime();
		}));
	};

	const selectDwellTime = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.dwellTime = +value as DwellTime;
		}));
	};

	const selectDayToNightSensitivity = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.dayToNightSensitivity = +value;
		}));
	};

	const selectNightToDaySensitivity = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.nightToDaySensitivity = +value;
		}));
	};

	const selectIlluminationMode = (value: IlluminationMode) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.illuminationMode = value;
		}));
	};

	const selectIrLed = (value: IrLed) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.irLed = value;
		}));
	};

	const selectAdaptiveIr = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.adaptiveIr = value === "ON";
		}));
	};

	const changeIrLedIllumination = (value: number) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.irLedIllumination = value;
		}));
	};

	const updateIrLedIllumination = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.irLedIllumination = +value;
		}));
	};

	const selectAntiOverexposure = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.antiOverexposure = value === "ON";
		}));
	};

	const selectWhiteLedControl = (value: WhiteLedControl) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.whiteLedControl = value;
		}));
	};

	const changeWhiteLedIllumination = (value: number) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.whiteLedIllumination = value;
		}));
	};

	const updateWhiteLedIllumination = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.dayNight.whiteLedIllumination = +value;
		}));
	};

	const renderDayTime = () => {
		if (dayNightMode === DayNightMode.SCHEDULE) {
			return (
				<div className="form-row">
					<span>Day Time</span>
					<div className="form-input">
						<DatePicker
							cleanable={ false }
							format="HH:mm"
							defaultValue={ new Date(dayTimeStart) }
							onChange={ changeDayTimeStart }
						/>
						<span>~</span>
						<DatePicker
							cleanable={ false }
							format="HH:mm"
							defaultValue={ new Date(dayTimeEnd) }
							onChange={ changeDayTimeEnd }
						/>
					</div>
				</div>
			);
		}
	};

	const renderIlluminationSetup = () => {
		switch (illuminationMode) {
			case IlluminationMode.IR: {
				return (
					<Fragment>
						<div className="form-row">
							<span>IR LED</span>
							<Select
								allowClear={ false }
								className="form-select"
								disabled={ isDayMode }
								onSelect={ selectIrLed }
								options={ irLedOptions }
								value={ irLed }
							/>
						</div>
						<div className="form-row">
							<span>Adaptive IR</span>
							<Select
								allowClear={ false }
								className="form-select"
								disabled={ isDayMode }
								onSelect={ selectAdaptiveIr }
								options={ onOffOptions }
								value={ adaptiveIr ? "ON" : "OFF" }
							/>
						</div>
						<div className="form-row">
							<span>IR LED Illumination</span>
							<div className="form-slider-wrapper">
								<div className="image-slider">
									<RangeSlider
										disabled={ isDayMode }
										max={ 255 }
										min={ 25 }
										onAfterChange={ changeIrLedIllumination }
										value={ irLedIllumination }
									/>
								</div>
								<Input
									disabled={ isDayMode }
									hideCharMessage={ true }
									max={ 255 }
									min={ 25 }
									onUpdate={ updateIrLedIllumination }
									type="number"
									value={ irLedIllumination }
								/>
							</div>
						</div>
					</Fragment>
				);
			}

			case IlluminationMode.WHITE: {
				return (
					<Fragment>
						<div className="form-row">
							<span>Anti-Overexposure</span>
							<Select
								allowClear={ false }
								className="form-select"
								disabled={ isDayMode }
								onSelect={ selectAntiOverexposure }
								options={ onOffOptions }
								value={ antiOverexposure ? "ON" : "OFF" }
							/>
						</div>
						<div className="form-row">
							<span>Control</span>
							<Select
								allowClear={ false }
								className="form-select"
								disabled={ isDayMode }
								onSelect={ selectWhiteLedControl }
								options={ whiteLedControlOptions }
								value={ whiteLedControl }
							/>
						</div>
						<div className="form-row">
							<span>White LED Illumination</span>
							<div className="form-slider-wrapper">
								<div className="image-slider">
									<RangeSlider
										disabled={ isDayMode }
										max={ 200 }
										min={ 1 }
										onAfterChange={ changeWhiteLedIllumination }
										value={ whiteLedIllumination }
									/>
								</div>
								<Input
									disabled={ isDayMode }
									hideCharMessage={ true }
									max={ 200 }
									min={ 1 }
									onUpdate={ updateWhiteLedIllumination }
									type="number"
									value={ whiteLedIllumination }
								/>
							</div>
						</div>
					</Fragment>
				);
			}

			default:
				break;
		}
	};

	return (
		<Fragment>
			<div className="content-left">
				<AspectMaintainer>
					<div className="camera-snapshot"></div>
				</AspectMaintainer>
			</div>
			<div className="content-right">
				<div className="form-row">
					<span>Day / Night Mode</span>
					<Select
						allowClear={ false }
						className="form-select"
						onSelect={ selectDayNightMode }
						options={ dayNightOptions }
						value={ dayNightMode }
					/>
				</div>
				{ renderDayTime() }
				<div className="form-row">
					<span>Dwell Time</span>
					<Select
						allowClear={ false }
						className="form-select"
						disabled={ !isAutoMode }
						onSelect={ selectDwellTime }
						options={ dwellTimeOptions }
						value={ dwellTime.toString() }
					/>
				</div>
				<div className="form-row">
					<span>Day to Night Sensitivity</span>
					<Select
						allowClear={ false }
						className="form-select"
						disabled={ !isAutoMode }
						onSelect={ selectDayToNightSensitivity }
						options={ dayToNightSensitivityOptions }
						value={ dayToNightSensitivity.toString() }
					/>
				</div>
				<div className="form-row">
					<span>Night to Day Sensitivity</span>
					<Select
						allowClear={ false }
						className="form-select"
						disabled={ !isAutoMode }
						onSelect={ selectNightToDaySensitivity }
						options={ nightToDaySensitivityOptions }
						value={ nightToDaySensitivity.toString() }
					/>
				</div>
				<div className="form-row">&nbsp;</div>
				<div className="form-row">
					<span>Illumination Mode</span>
					<Select
						allowClear={ false }
						className="form-select"
						onSelect={ selectIlluminationMode }
						options={ illuminationModeOptions }
						value={ illuminationMode }
					/>
				</div>
				{ renderIlluminationSetup() }
			</div>
		</Fragment>
	);
}

export default memo(DayNight);
