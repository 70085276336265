import { ChangeEvent, Dispatch, Fragment, memo, SetStateAction } from "react";
import "Components/ConfigLayout/Camera/AiAnalytics/AiRuleSettings/AiRuleSettings.less";
import {
	AspectMaintainer, Button, ButtonTypes, Checkbox, Input, ISelectOption, Popover, RangeSlider, Select,
	Switch
} from "@clintonelec/react-storybook";
import { produce } from "immer";
import { AiRule, IAiAnalyticsState, IPoint } from "Interfaces";
import { Link } from "react-router-dom";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";
import { AiRuleDirection, AiRuleType } from "Data/Objects/Camera";
import InteractivePolygon from "Components/Global/InteractivePolygon";
import InteractiveLine from "Components/Global/InteractiveLine";

const ruleTypeOptions: ISelectOption[] = Object.values(AiRuleType).map((type) => ({
	label: type,
	value: type
}));

const directionOptions: ISelectOption[] = Object.values(AiRuleDirection).map((direction) => ({
	label: direction,
	value: direction
}));

interface IAiRuleSettingsProps {
	localAiAnalyticsState: [ IAiAnalyticsState, Dispatch<SetStateAction<IAiAnalyticsState>> ];
	selectedRuleIndex: number;
	setSelectedRuleIndex: Dispatch<SetStateAction<number>>;
}

function AiRuleSettings(props: IAiRuleSettingsProps) {
	const { localAiAnalyticsState, selectedRuleIndex, setSelectedRuleIndex } = props;
	const [ localAiAnalyticsSettings, setLocalAiAnalyticsSettings ] = localAiAnalyticsState;
	const { rules, enabled } = localAiAnalyticsSettings;
	const disabledOption: ISelectOption = { label: "Disabled", value: -1 };
	const createdRules = rules.filter(rule => !!rule);
	const counterOptions: ISelectOption[] = [ disabledOption ].concat(createdRules.map((rule, index) => ({
		label: `Rule ${ index + 1 } - ${ rule?.type }`,
		value: index
	})));

	const link = (
		<div>
			Go to <Link to="/setup/system/schedule">Schedule Preset</Link> to create a new preset.
		</div>
	);

	const updateScheduleChecked = (scheduleName: string) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].schedule[ scheduleName ] = event.currentTarget.checked;
		}));
	};

	const updateRuleType = (type: AiRuleType) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].type = type;
			draft.rules[ selectedRuleIndex ].points = [];

			if (draft.rules[ selectedRuleIndex ].type === AiRuleType.LINE) {
				draft.rules[ selectedRuleIndex ].direction = AiRuleDirection.BOTH;
			}
		}));
	};

	const updatePoints = (points: IPoint[]) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].points = points;
		}));
	};

	const updateCounterPoints = (points: IPoint[]) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].counter.points = points;
		}));
	};

	const updateHumanChecked = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].objectTypes.human = event.currentTarget.checked;
		}));
	};

	const updateVehicleChecked = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].objectTypes.vehicle = event.currentTarget.checked;
		}));
	};

	const updateBikeChecked = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].objectTypes.bike = event.currentTarget.checked;
		}));
	};

	const updateAudioFile = (filename: string) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].audioFile = filename;
		}));
	};

	const updateSliderConfidenceThreshold = (confidenceThreshold: number) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].confidenceThreshold = confidenceThreshold;
		}));
	};

	const updateInputConfidenceThreshold = (confidenceThreshold: string) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].confidenceThreshold = +confidenceThreshold;
		}));
	};

	const updateDirection = (direction: AiRuleDirection) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			if (draft.rules[ selectedRuleIndex ].type !== AiRuleType.LINE) {
				return;
			}

			draft.rules[ selectedRuleIndex ].direction = direction;
		}));
	};

	const handleClearActiveRule = () => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ] = null;
		}));

		setSelectedRuleIndex(-1);
	};

	const handleClearAllRules = () => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules = Array.from(Array(8));
		}));

		setSelectedRuleIndex(-1);
	};

	const updateIntrusion = (intrusion: boolean) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			if (draft.rules[ selectedRuleIndex ].type !== AiRuleType.ZONE) {
				return;
			}

			draft.rules[ selectedRuleIndex ].intrusion = intrusion;
		}));
	};

	const updateEnter = (enter: boolean) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			if (draft.rules[ selectedRuleIndex ].type !== AiRuleType.ZONE) {
				return;
			}

			draft.rules[ selectedRuleIndex ].enter = enter;
		}));
	};

	const updateExit = (exit: boolean) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			if (draft.rules[ selectedRuleIndex ].type !== AiRuleType.ZONE) {
				return;
			}

			draft.rules[ selectedRuleIndex ].exit = exit;
		}));
	};

	const updateLoiter = (loiter: boolean) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			if (draft.rules[ selectedRuleIndex ].type !== AiRuleType.ZONE) {
				return;
			}

			draft.rules[ selectedRuleIndex ].loiter = loiter;
		}));
	};

	const updateSliderLoiterDuration = (loiterDuration: number) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			if (draft.rules[ selectedRuleIndex ].type !== AiRuleType.ZONE) {
				return;
			}

			draft.rules[ selectedRuleIndex ].loiterDuration = loiterDuration;
		}));
	};

	const updateInputLoiterDuration = (loiterDuration: string) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			if (draft.rules[ selectedRuleIndex ].type !== AiRuleType.ZONE) {
				return;
			}

			draft.rules[ selectedRuleIndex ].loiterDuration = +loiterDuration;
		}));
	};

	const updateCounterEnabled = (counterEnabled: boolean) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].counter.enabled = counterEnabled;
		}));
	};

	const updateCounterNotificationEnabled = (notificationEnabled: boolean) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].counter.notificationEnabled = notificationEnabled;
		}));
	};

	const updateCounterNotificationCount = (notificationCount: string) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].counter.notificationCounter = +notificationCount;
		}));
	};

	const updateCounterReset = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].counter.resetCounterAfterNotification = event.currentTarget.checked;
		}));
	};

	const updateCounterIncreaseRule = (increaseRule: string) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].counter.increaseRule = +increaseRule;
		}));
	};

	const updateCounterDecreaseRule = (decreaseRule: string) => {
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ selectedRuleIndex ].counter.decreaseRule = +decreaseRule;
		}));
	};

	const createRule = (index: number) => () => {
		const rule: AiRule = {
			type: AiRuleType.ZONE,
			intrusion: true,
			enter: false,
			exit: false,
			loiter: false,
			loiterDuration: 5,
			objectTypes: {
				human: true,
				vehicle: true,
				bike: true
			},
			confidenceThreshold: 25,
			audioFile: "", // TODO: replace with actual list
			schedule: { always: true, day: true, night: true }, // TODO: replace with actual list
			points: [],
			counter: {
				enabled: false,
				notificationEnabled: false,
				notificationCounter: 1,
				resetCounterAfterNotification: false,
				increaseRule: -1,
				decreaseRule: -1,
				points: [],
				count: 0
			}
		};

		setSelectedRuleIndex(index);
		setLocalAiAnalyticsSettings(produce(localAiAnalyticsSettings, (draft) => {
			draft.rules[ index ] = rule;
		}));
	};

	const renderSchedule = (schedule: [ string, boolean ]) => {
		const [ scheduleName, checked ] = schedule;

		return (
			<div className="checkbox-wrapper" key={ scheduleName }>
				<Checkbox
					checked={ checked }
					onChange={ updateScheduleChecked(scheduleName) }
				/>
				<label>{ scheduleName }</label>
			</div>
		);
	};

	const renderLineSettings = () => {
		if (rules[ selectedRuleIndex ].type !== AiRuleType.LINE) {
			return;
		}

		return (
			<div className="form-row">
				<span>Direction</span>
				<Select
					allowClear={ false }
					onSelect={ updateDirection }
					options={ directionOptions }
					value={ rules[ selectedRuleIndex ].direction }
				/>
			</div>
		);
	};

	const renderZoneSettings = () => {
		if (rules[ selectedRuleIndex ].type !== AiRuleType.ZONE) {
			return;
		}

		const { loiter, enter, exit, intrusion } = rules[ selectedRuleIndex ];
		let warningMessage = null;

		if (!loiter && !enter && !exit && !intrusion) {
			warningMessage = (
				<div className="warning-message">You must enable at least one of the above toggles.</div>
			);
		}

		return (
			<Fragment>
				<div className="form-row">
					<span>Intrusion</span>
					<div>
						<Switch
							checked={ rules[ selectedRuleIndex ].intrusion }
							onChange={ updateIntrusion }
						/>
					</div>
				</div>
				<div className="form-row">
					<span>Enter</span>
					<div>
						<Switch
							checked={ rules[ selectedRuleIndex ].enter }
							onChange={ updateEnter }
						/>
					</div>
				</div>
				<div className="form-row">
					<span>Exit</span>
					<div>
						<Switch
							checked={ rules[ selectedRuleIndex ].exit }
							onChange={ updateExit }
						/>
					</div>
				</div>
				<div className="form-row">
					<span>Loiter</span>
					<div>
						<Switch
							checked={ rules[ selectedRuleIndex ].loiter }
							onChange={ updateLoiter }
						/>
					</div>
				</div>
				{ warningMessage }
				<div className="form-row">
					<span>Loiter Duration (sec)</span>
					<div className="slider-container">
						<RangeSlider
							min={ 5 }
							max={ 600 }
							onAfterChange={ updateSliderLoiterDuration }
							value={ rules[ selectedRuleIndex ].loiterDuration }
							disabled={ !rules[ selectedRuleIndex ].loiter }
						/>
						<Input
							type="number"
							min={ 5 }
							max={ 600 }
							value={ rules[ selectedRuleIndex ].loiterDuration }
							onSave={ updateInputLoiterDuration }
							disabled={ !rules[ selectedRuleIndex ].loiter }
							hideCharMessage
						/>
					</div>
				</div>
			</Fragment>
		);
	};

	const renderRuleSettings = () => {
		if (!enabled) {
			return (
				<div className="add-rule-message">Enable AI Analytics to adjust rule settings.</div>
			);
		}

		if (selectedRuleIndex < 0 || !rules[selectedRuleIndex]) {
			return (
				<div className="add-rule-message">Create a rule to adjust rule settings.</div>
			);
		}

		const { human, vehicle, bike } = rules[ selectedRuleIndex ].objectTypes;
		let warningMessage = null;

		if (!human && !vehicle && !bike) {
			warningMessage = (
				<div className="warning-message">You must enable at least one object.</div>
			);
		}

		const { enabled: counterEnabled, notificationEnabled } = rules[ selectedRuleIndex ].counter;
		const counterOptionsDisabledClass = counterEnabled ? "" : " counter-options-disabled";
		const counterNotificationDisabledClass = notificationEnabled ? "" : " counter-options-disabled";
		const notificationPopoverContent = `Set the number of detected objects to trigger an event notification.
			Select Reset Counter to clear the count after an event notification.`;

		return (
			<div className="rule-settings">
				<div className="form-row">
					<span>AI Rule Type</span>
					<Select
						allowClear={ false }
						onSelect={ updateRuleType }
						options={ ruleTypeOptions }
						value={ rules[ selectedRuleIndex ].type }
					/>
				</div>
				{ renderLineSettings() }
				{ renderZoneSettings() }
				<div className="form-row">
					<span>Object</span>
					<div className="schedules">
						<div className="checkbox-wrapper">
							<Checkbox
								checked={ human }
								onChange={ updateHumanChecked }
							/>
							<label>Human</label>
						</div>
						<div className="checkbox-wrapper">
							<Checkbox
								checked={ vehicle }
								onChange={ updateVehicleChecked }
							/>
							<label>Vehicle</label>
						</div>
						<div className="checkbox-wrapper">
							<Checkbox
								checked={ bike }
								onChange={ updateBikeChecked }
							/>
							<label>Bike</label>
						</div>
					</div>
				</div>
				{ warningMessage }
				<div className="form-row">
					<span>Confidence Threshold (%)</span>
					<div className="slider-container">
						<RangeSlider
							min={ 0 }
							max={ 100 }
							onAfterChange={ updateSliderConfidenceThreshold }
							value={ rules[ selectedRuleIndex ].confidenceThreshold }
						/>
						<Input
							type="number"
							min={ 0 }
							max={ 100 }
							value={ rules[ selectedRuleIndex ].confidenceThreshold }
							onUpdate={ updateInputConfidenceThreshold }
							hideCharMessage
						/>
					</div>
				</div>
				<div className="form-row">
					<span>Play Audio File</span>
					<Select
						onSelect={ updateAudioFile }
						value={ rules[ selectedRuleIndex ].audioFile }
					/>
				</div>
				<div className="form-row">
					<div className="schedule-title">
						<span>Schedule</span>
						<Popover content={ link }>
							<Icon name="circle-info" iconWeight={ IconWeight.LIGHT } size={ IconSize.SMALLEST } />
						</Popover>
					</div>
					<div className="schedules">
						{ Object.entries(rules[ selectedRuleIndex ].schedule).map(renderSchedule) }
					</div>
				</div>
				<div className="form-row">
					<span>Add Counter</span>
					<div>
						<Switch
							checked={ counterEnabled }
							onChange={ updateCounterEnabled }
						/>
					</div>
				</div>
				<div className={ "counter-options" + counterOptionsDisabledClass }>
					<div className="form-row">
						<span>
							<span>Event Notification Count</span>
							<Popover content={ notificationPopoverContent }>
								<Icon name="circle-info" iconWeight={ IconWeight.LIGHT } size={ IconSize.SMALLEST } />
							</Popover>
						</span>
						<div className="counter-notification-settings">
							<Switch
								checked={ rules[ selectedRuleIndex ].counter.notificationEnabled }
								onChange={ updateCounterNotificationEnabled }
								disabled={ !counterEnabled }
							/>
							<Input
								type="number"
								min={ 1 }
								max={ 32767 }
								value={ rules[ selectedRuleIndex ].counter.notificationCounter }
								onSave={ updateCounterNotificationCount }
								disabled={ !counterEnabled || !notificationEnabled }
								className={ counterNotificationDisabledClass }
								hideCharMessage
							/>
							<div className={ "checkbox-wrapper" + counterNotificationDisabledClass }>
								<Checkbox
									checked={ rules[ selectedRuleIndex ].counter.resetCounterAfterNotification }
									onChange={ updateCounterReset }
									disabled={ !counterEnabled || !notificationEnabled }
								/>
								<label>Reset Counter</label>
							</div>
						</div>
					</div>
					<div className="form-row">
						<span>Counter Increase</span>
						<Select
							allowClear={ false }
							onSelect={ updateCounterIncreaseRule }
							options={ counterOptions }
							value={ rules[ selectedRuleIndex ].counter.increaseRule }
						/>
					</div>
					<div className="form-row">
						<span>Counter Decrease</span>
						<Select
							allowClear={ false }
							onSelect={ updateCounterDecreaseRule }
							options={ counterOptions }
							value={ rules[ selectedRuleIndex ].counter.decreaseRule }
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderRuleButton = (_, index: number) => {
		if (!rules[ index ]) {
			return (
				<Button
					key={ index }
					type={ ButtonTypes.SECONDARY }
					ghost={ index !== selectedRuleIndex }
					onClick={ createRule(index) }
					htmlType="button"
					disabled={ !enabled }
				>
					+
				</Button>
			);
		}

		const updateSelectedRuleIndex = () => setSelectedRuleIndex(index);

		return (
			<Button
				key={ index }
				type={ index !== selectedRuleIndex ? ButtonTypes.SECONDARY : ButtonTypes.TERTIARY }
				ghost={ index !== selectedRuleIndex }
				onClick={ updateSelectedRuleIndex }
				htmlType="button"
				disabled={ !enabled }
			>
				{ `Rule ${ index + 1 }` }
			</Button>
		);
	};

	const renderInteractivePolygon = () => {
		if (selectedRuleIndex < 0 || !enabled) {
			return;
		}

		const { type, points, counter } = rules[ selectedRuleIndex ];
		const { points: counterPoints, count, enabled: counterEnabled } = counter;

		if (type === AiRuleType.ZONE) {
			return (
				<InteractivePolygon
					points={ points }
					onUpdate={ updatePoints }
					count={ count }
					counterPoints={ counterPoints }
					counterEnabled={ counterEnabled }
					updateCounterPoints={ updateCounterPoints }
				/>
			);
		}

		const { direction } = rules[ selectedRuleIndex ];

		return (
			<InteractiveLine
				points={ points }
				onUpdate={ updatePoints }
				arrowA={ direction === AiRuleDirection.A || direction === AiRuleDirection.BOTH }
				arrowB={ direction === AiRuleDirection.B || direction === AiRuleDirection.BOTH }
				count={ count }
				counterPoints={ counterPoints }
				counterEnabled={ counterEnabled }
				updateCounterPoints={ updateCounterPoints }
			/>
		);
	};

	return (
		<div className="ai-rule-settings">
			<div className="column">
				<div className="camera-container">
					<AspectMaintainer>
						<div className="camera-snapshot">
							{ renderInteractivePolygon() }
						</div>
					</AspectMaintainer>
				</div>
				<div className="rule-buttons">
					{ Array.from(Array(8)).map(renderRuleButton) }
				</div>
				<div className="controls-container">
					<Button
						className="rule-action-button"
						disabled={ !enabled || selectedRuleIndex === -1 }
						ghost
						htmlType="button"
						onClick={ handleClearActiveRule }
						type={ ButtonTypes.SECONDARY }
					>
						Clear Active Rule
					</Button>
					<Button
						className="rule-action-button"
						disabled={ !enabled || !rules.some((rule) => !!rule) }
						type={ ButtonTypes.SECONDARY }
						ghost
						htmlType="button"
						onClick={ handleClearAllRules }
					>
						Clear All Rules
					</Button>
				</div>
			</div>
			<div className="column">
				{ renderRuleSettings() }
			</div>
		</div>
	);
}

export default memo(AiRuleSettings);
