import { memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LiveLayout from "Components/LiveLayout";
import LoginPage from "Components/LoginPage";
import PrivateRoute from "Components/Routing/PrivateRoute";
import PlaybackLayout from "Components/PlaybackLayout";
import ConfigLayout from "Components/ConfigLayout";
import LanguageSettings from "Components/ConfigLayout/System/LanguageSettings";
import CodecSettings from "Components/ConfigLayout/Camera/CodecSettings";
import TamperSettings from "Components/ConfigLayout/Camera/TamperSettings";
import SecuritySettings from "Components/ConfigLayout/System/SecuritySettings";
import ManagementSettings from "Components/ConfigLayout/System/ManagementSettings";
import UserManagement from "Components/ConfigLayout/User/UserManagement";
import UpnpSettings from "Components/ConfigLayout/Network/UpnpSettings";
import EmbeddedOsdSettings from "Components/ConfigLayout/Camera/EmbeddedOsdSettings";
import AudioSettings from "Components/ConfigLayout/Audio/AudioSettings";
import FtpSettings from "Components/ConfigLayout/Network/FtpSettings";
import SystemInformation from "Components/ConfigLayout/System/SystemInformation";
import EmailSettings from "Components/ConfigLayout/Network/EmailSettings";
import DateTimeSettings from "Components/ConfigLayout/System/DateTimeSettings";
import NetworkSecuritySettings from "Components/ConfigLayout/Network/SecuritySettings";
import IpSetupSettings from "Components/ConfigLayout/Network/IpSetupSettings";
import RecordSetupSettings from "Components/ConfigLayout/Record/SetupSettings";
import SdCardSettings from "Components/ConfigLayout/Storage/SdCardSettings";
import MotionEventSettings from "Components/ConfigLayout/Event/MotionEventSettings";
import TamperEventSettings from "Components/ConfigLayout/Event/TamperEventSettings";
import NetworkLossSettings from "Components/ConfigLayout/Event/NetworkLossSettings";

const Router = () => {
	return (
		<Routes>
			<Route path="login" element={ <LoginPage /> } />
			<Route path="/" element={ <PrivateRoute /> }>
				<Route index element={ <LiveLayout /> } />
				<Route path="/search" element={ <PlaybackLayout /> } />
				<Route path="/setup" element={ <ConfigLayout /> }>
					<Route index element={ <Navigate to="/setup/camera/codec" /> } />
					<Route path="/setup/camera">
						<Route index element={ <Navigate to="codec" /> } />
						<Route path="codec" element={ <CodecSettings /> } />
						<Route path="image" element={ <h1>image</h1> } />
						<Route path="motion" element={ <h1>motion</h1> } />
						<Route path="mask" element={ <h1>mask</h1> } />
						<Route path="embedded_osd" element={ <EmbeddedOsdSettings /> } />
						<Route path="tamper" element={ <TamperSettings /> } />
						<Route path="analytics" element={ <h1>analytics</h1> } />
					</Route>
					<Route path="/setup/audio">
						<Route index element={ <Navigate to="audio" /> } />
						<Route path="audio" element={ <AudioSettings /> } />
					</Route>
					<Route path="/setup/user">
						<Route index element={ <Navigate to="management" /> } />
						<Route path="management" element={ <UserManagement /> } />
						<Route path="authority" element={ <h1>authority</h1> } />
					</Route>
					<Route path="/setup/network">
						<Route index element={ <Navigate to="ip_setup" /> } />
						<Route path="ip_setup" element={ <IpSetupSettings /> } />
						<Route path="email" element={ <EmailSettings /> } />
						<Route path="upnp" element={ <UpnpSettings /> } />
						<Route path="rtp" element={ <h1>rtp</h1> } />
						<Route path="ftp" element={ <FtpSettings /> } />
						<Route path="security" element={ <NetworkSecuritySettings /> } />
					</Route>
					<Route path="/setup/system">
						<Route index element={ <Navigate to="datetime" /> } />
						<Route path="datetime" element={ <DateTimeSettings /> } />
						<Route path="management" element={ <ManagementSettings /> } />
						<Route path="information" element={ <SystemInformation /> } />
						<Route path="language" element={ <LanguageSettings /> } />
						<Route path="security" element={ <SecuritySettings /> } />
						<Route path="schedule" element={ <h1>schedule</h1> } />
					</Route>
					<Route path="/setup/storage">
						<Route index element={ <Navigate to="sd_card" /> } />
						<Route path="sd_card" element={ <SdCardSettings /> } />
					</Route>
					<Route path="/setup/event">
						<Route index element={ <Navigate to="action" /> } />
						<Route path="action" element={ <h1>action</h1> } />
						<Route path="notification" element={ <h1>notification</h1> } />
						<Route path="alarm" element={ <h1>alarm</h1> } />
						<Route path="motion" element={ <MotionEventSettings /> } />
						<Route path="tamper" element={ <TamperEventSettings /> } />
						<Route path="network_loss" element={ <NetworkLossSettings /> } />
						<Route path="system" element={ <h1>system</h1> } />
						<Route path="analytics" element={ <h1>analytics</h1> } />
					</Route>
					<Route path="/setup/record">
						<Route index element={ <Navigate to="setup" /> } />
						<Route path="setup" element={ <RecordSetupSettings /> } />
					</Route>
				</Route>
			</Route>
		</Routes>
	);
};

export default memo(Router);
