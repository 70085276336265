import { IIndexSignature } from "TsUtils/Interfaces";
import { produce } from "immer";

export const checkKeyExists = <T>(keyToFind: string, object: IIndexSignature<T>) => {
	return Object.keys(object).some((key) => key === keyToFind);
};

export const isEmptyString = (value: string) => {
	return typeof value === "string" && value.trim().length === 0;
};

export const sanitizeEmptyStrings = <T extends object>(object: T): T => {
	return produce(object, (draft) => {
		Object.entries(draft).forEach(([ key, value ]) => {
			if (value === null || value === undefined || isEmptyString(value)) {
				delete draft[ key ];
			} else if (typeof value === "object") {
				draft[ key ] = sanitizeEmptyStrings(value);
			}
		});
	});
};
