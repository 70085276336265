// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-management-modal-content {
  display: flex;
  flex-direction: column;
  max-height: 75vh;
}
.user-management-modal-content .cec-input-wrapper,
.user-management-modal-content .cec-select {
  margin-bottom: 15px;
}
.user-management-modal-content .scrollable {
  flex: 1;
  overflow: auto;
}
.user-management-modal-content .footer {
  padding-top: 15px;
}
.cec-modal-title > span {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/User/UserManagement/UserManagementModal/UserManagementModal.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,sBAAA;EACA,gBAAA;AADD;AAFA;;EAOE,mBAAA;AADF;AANA;EAWE,OAAA;EACA,cAAA;AAFF;AAVA;EAgBE,iBAAA;AAHF;AAOA;EACC,iBAAA;AALD","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.user-management-modal-content {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmax-height: 75vh;\n\n\t.cec-input-wrapper,\n\t.cec-select {\n\t\tmargin-bottom: 15px;\n\t}\n\n\t.scrollable {\n\t\tflex: 1;\n\t\toverflow: auto;\n\t}\n\n\t.footer {\n\t\tpadding-top: 15px;\n\t}\n}\n\n.cec-modal-title > span {\n\tmargin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
