// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-validation {
  margin: 25px 0;
}
.user-validation .valid {
  color: #33B889;
}
.user-validation .invalid {
  color: #fd4445;
}
.user-validation .disabled {
  color: #eaeaea;
}
.user-validation p {
  line-height: 1.15;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/User/UserValidation/UserValidation.less"],"names":[],"mappings":"AAEA;EACC,cAAA;AADD;AAAA;EAIE,cAAA;AADF;AAHA;EAQE,cAAA;AAFF;AANA;EAYE,cAAA;AAHF;AATA;EAgBE,iBAAA;AAJF","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.user-validation {\n\tmargin: 25px 0;\n\n\t.valid {\n\t\tcolor: @green-1;\n\t}\n\n\t.invalid {\n\t\tcolor: @red-1;\n\t}\n\n\t.disabled {\n\t\tcolor: @light-gray-1;\n\t}\n\n\tp {\n\t\tline-height: 1.15;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
