// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ipsetup-form .scrollable-container .card .ip-address-settings .ipv6-form-row.form-row {
  margin-right: 0;
}
.ipsetup-form .scrollable-container .card .ip-address-settings .ip-address-tabs .cec-tabs-content-container .ipv6-setup-content label {
  flex: none;
}
.ipsetup-form .scrollable-container .card .ip-address-settings .ip-address-tabs .cec-tabs-content-container .ipv6-setup-content .ip-address-inputs .ipv6-address-wrapper {
  display: flex;
  flex: 2;
}
.ipsetup-form .scrollable-container .card .ip-address-settings .ip-address-tabs .cec-tabs-content-container .ipv6-setup-content .ip-address-inputs .ipv6-address-wrapper .ipv6-address {
  padding-right: 5px;
}
.ipsetup-form .scrollable-container .card .ip-address-settings .ip-address-tabs .cec-tabs-content-container .ipv6-setup-content .ip-address-inputs .ipv6-address-wrapper .ipv6-cidr {
  flex: 1;
  padding-left: 5px;
}
.ipsetup-form .scrollable-container .card .ip-address-settings .ip-address-tabs .cec-tabs-content-container .ipv6-setup-content .ip-address-inputs .ipv6-address-wrapper .ipv6-cidr > div {
  width: 50px;
}
.ipsetup-form .scrollable-container .card .ip-address-settings .ip-address-tabs .cec-tabs-content-container .ipv6-setup-content .ip-address-inputs .ipv6-address-wrapper .ipv6-cidr .cec-input-validity-message {
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Network/IpSetupSettings/Ipv6SetupSettings/Ipv6SetupSettings.less"],"names":[],"mappings":"AAEA;EAEE,eAAA;AAFF;AAAA;EAQI,UAAA;AALJ;AAHA;EAaK,aAAA;EACA,OAAA;AAPL;AAPA;EAiBM,kBAAA;AAPN;AAVA;EAqBM,OAAA;EACA,iBAAA;AARN;AAdA;EAyBO,WAAA;AARP;AAjBA;EA6BO,mBAAA;AATP","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.ipsetup-form .scrollable-container .card .ip-address-settings {\n\t.ipv6-form-row.form-row {\n\t\tmargin-right: 0;\n\t}\n\n\t.ip-address-tabs .cec-tabs-content-container {\n\t\t.ipv6-setup-content {\n\t\t\tlabel {\n\t\t\t\tflex: none;\n\t\t\t}\n\n\t\t\t.ip-address-inputs {\n\t\t\t\t.ipv6-address-wrapper {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tflex: 2;\n\n\t\t\t\t\t.ipv6-address {\n\t\t\t\t\t\tpadding-right: 5px;\n\t\t\t\t\t}\n\n\t\t\t\t\t.ipv6-cidr {\n\t\t\t\t\t\tflex: 1;\n\t\t\t\t\t\tpadding-left: 5px;\n\n\t\t\t\t\t\t> div {\n\t\t\t\t\t\t\twidth: 50px;\n\t\t\t\t\t\t}\n\n\t\t\t\t\t\t.cec-input-validity-message {\n\t\t\t\t\t\t\twhite-space: normal;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
