export enum Codec {
	H264 = "H264",
	H265 = "H265",
	MJPEG = "MJPEG"
}

export enum Profile {
	BASELINE = "BASELINE",
	MAIN = "MAIN",
	HIGH = "HIGH"
}

export enum Resolution {
	R1920X1080 = "1920x1080",
	R1280X1024 = "1280x1024",
	R1024X768 = "1024x768"
}

export enum BitrateControl {
	CBR = "CBR",
	VBR = "VBR",
	VBRPLUS = "VBR+"
}

export enum ImageQuality {
	LOW = "LOW",
	MID = "MID",
	HIGH = "HIGH"
}

export enum VideoFormat {
	NTSC = 60,
	PAL = 50
}

export type UseStreamSettings = "mainStream" | "secondStream";

export enum TamperDetection {
	OFF = "OFF",
	LOW = "LOW",
	MID = "MID",
	HIGH = "HIGH"
}

export enum Colors {
	RED = "RED",
	ORANGE = "ORANGE",
	YELLOW = "YELLOW",
	GREEN = "GREEN",
	CYAN = "CYAN",
	BLUE = "BLUE",
	MAGENTA = "MAGENTA",
	BLACK = "BLACK",
	WHITE = "WHITE"
}

export enum DeviceNamePosition {
	OFF = "OFF",
	UPPER_LEFT = "UPPER_LEFT",
	UPPER_RIGHT = "UPPER_RIGHT",
	LOWER_LEFT = "LOWER_LEFT",
	LOWER_RIGHT = "LOWER_RIGHT"
}
