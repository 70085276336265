import { AsyncThunkAction } from "@reduxjs/toolkit";

export const rejectOnUndefined = <T>(payload: T) => {
	return payload ? Promise.resolve(payload) : Promise.reject();
};

export const testAsyncAction = <AppState, Returned, ThunkArg>(
	actionCreator: AsyncThunkAction<Returned, ThunkArg, unknown>, getState: () => AppState
) => {
	actionCreator(jest.fn(), getState, undefined)
		.then(payload => {
			delete payload.meta.requestId;

			expect(payload).toMatchSnapshot();
		});
};
