import { ChangeEvent, Dispatch, memo, SetStateAction } from "react";
import "Components/ConfigLayout/Event/ActionSettings/Snapshot/Snapshot.less";
import { Checkbox, Input, ISelectOption, Popover, Select } from "@clintonelec/react-storybook";
import { produce } from "immer";
import { IActionState, ScheduleUpdater } from "Interfaces";
import { Link } from "react-router-dom";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";

const frequencyOptions: ISelectOption[] = [ 0, 5, 10, 15, 30, 60, 120, 180, 300 ].map(value => ({
	label: value === 0 ? "Immediately" : `${ value } Sec`,
	value
}));

interface ISnapshotProps {
	localActionState: [ IActionState, Dispatch<SetStateAction<IActionState>> ];
}

function Snapshot(props: ISnapshotProps) {
	const { localActionState: [ localActionSettings, setLocalActionSettings ] } = props;
	const link = (
		<div>
			Go to <Link to="/setup/system/schedule">Schedule Preset</Link> to create a new preset.
		</div>
	);

	const updateSdReceiver = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.snapshot.receiver = value;
		}));
	};

	const updateSdFilePrefix = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.snapshot.filePrefix = value;
		}));
	};

	const updateSdFrequency = (duration: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.snapshot.frequency = +duration;
		}));
	};

	const updateSdLog = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.snapshot.log = event.currentTarget.checked;
		}));
	};

	const updateSdScheduleChecked = (scheduleName: string) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.snapshot.schedule[ scheduleName ] = event.currentTarget.checked;
		}));
	};

	const renderSchedule = (updater: ScheduleUpdater) => {
		return function Schedule(schedule: [ string, boolean ]) {
			const [ scheduleName, checked ] = schedule;

			return (
				<div className="checkbox-wrapper" key={ scheduleName }>
					<Checkbox
						checked={ checked }
						onChange={ updater(scheduleName) }
					/>
					<label>{ scheduleName }</label>
				</div>
			);
		};
	};

	const sdSchedule = Object
		.entries(localActionSettings.snapshot.schedule)
		.map(renderSchedule(updateSdScheduleChecked));

	return (
		<div className="snap-shot">
			<div className="column">
				<div className="page-title">
					<h4>SD Card</h4>
				</div>
				<div className="form-row">
					<span>Receiver</span>
					<Input
						value={ localActionSettings.snapshot.receiver }
						onUpdate={ updateSdReceiver }
					/>
				</div>
				<div className="form-row">
					<span>File Prefix</span>
					<Input
						value={ localActionSettings.snapshot.filePrefix }
						onUpdate={ updateSdFilePrefix }
					/>
				</div>
				<div className="form-row">
					<span>Min. Frequency</span>
					<Select
						allowClear={ false }
						onSelect={ updateSdFrequency }
						options={ frequencyOptions }
						value={ localActionSettings.snapshot.frequency }
					/>
				</div>
				<div className="form-row">
					<span>Log</span>
					<Checkbox
						checked={ localActionSettings.snapshot.log }
						className="form-input"
						onChange={ updateSdLog }
					/>
				</div>
			</div>
			<div className="column">
				<div className="schedule-title">
					<h4>Schedule</h4>
					<Popover content={ link }>
						<Icon name="circle-info" iconWeight={ IconWeight.LIGHT } size={ IconSize.SMALLEST } />
					</Popover>
				</div>
				<div className="schedules">
					{ sdSchedule }
				</div>
			</div>
		</div>
	);
}

export default memo(Snapshot);
