import { ISystemInformation } from "Interfaces";

export const DUMMY_SYSTEM_INFORMATION: ISystemInformation = {
	dns1: "10.1.3.23",
	dns2: "8.8.8.8",
	firmwareVersion: "89100.1.0033.100",
	gateway: "10.1.6.1",
	httpPort: 80,
	ipAddress: "10.1.6.154",
	macAddress: "C4:A1:0E:B3:01:27",
	model: "CE-ID30AR5C",
	rtspPort: 554,
	serialNumber: "041224C203Uxxxx",
	subnetMask: "255.255.255.0"
};
