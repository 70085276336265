import { PayloadAction } from "@reduxjs/toolkit";
import { TamperDetection } from "Data/Objects/Camera";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { ITamperSettingsState } from "Interfaces";

const initialState: ITamperSettingsState = {
	tamperDetection: TamperDetection.OFF
};

// #region reducers

export const setTamperDetectionReducer = (state: ITamperSettingsState, action: PayloadAction<TamperDetection>) => {
	if (!action.payload) {
		return;
	}

	state.tamperDetection = action.payload;
};

// #endregion

// #region Selectors

const getTamperDetection = (state: ITamperSettingsState) => {
	return state.tamperDetection;
};

// #endregion

const tamperSlice = createAppSlice({
	name: "tamper",
	initialState,
	reducers: {
		setTamperDetectionAction: setTamperDetectionReducer
	},
	selectors: {
		selectTamperDetection: getTamperDetection
	}
});

export default tamperSlice;

export const { setTamperDetectionAction } = tamperSlice.actions;
export const { selectTamperDetection } = tamperSlice.getSelectors<AppState>(state => state.settings.camera.tamper);
