import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { ISystemSecuritySettingsState, ISetSystemSecurityPayload } from "Interfaces";

const initialState: ISystemSecuritySettingsState = {
	autoLogout: false,
	autoLogoutDuration: 1
};

const setSystemSecuritySettingsReducer = (
	state: ISystemSecuritySettingsState,
	action: PayloadAction<ISetSystemSecurityPayload>
) => {
	if (!action.payload) {
		return;
	}

	return action.payload;
};

const getSystemSecuritySettings = (state: ISystemSecuritySettingsState) => {
	return state;
};

const systemSecuritySlice = createAppSlice({
	name: "security",
	initialState,
	reducers: {
		setSystemSecuritySettingsAction: setSystemSecuritySettingsReducer
	},
	selectors: {
		selectSystemSecuritySettings: getSystemSecuritySettings
	}
});

export default systemSecuritySlice;

export const { setSystemSecuritySettingsAction } = systemSecuritySlice.actions;
export const {
	selectSystemSecuritySettings
} = systemSecuritySlice.getSelectors<AppState>(state => state.settings.system.security);
