// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-sider {
  width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/LiveLayout/LiveSider/LiveSider.less"],"names":[],"mappings":"AAEA;EACC,YAAA;AADD","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.live-sider {\n\twidth: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
