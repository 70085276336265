import { Checkbox, Input, ISelectOption, Popover, Select } from "@clintonelec/react-storybook";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";
import { produce } from "immer";
import { IEmailFtpSettingsState, ScheduleUpdater } from "Interfaces";
import { ChangeEvent, Dispatch, memo, SetStateAction } from "react";
import { Link } from "react-router-dom";
import "Components/ConfigLayout/Event/EmailFtpSettings/FtpServerSettings/FtpServerSettings.less";

const frequencyOptions: ISelectOption[] = [ 0, 5, 10, 15, 30, 60, 120, 180, 300 ].map(value => ({
	label: value === 0 ? "Immediately" : `${ value } Sec`,
	value
}));

interface IFtpServerSettingsProps {
	localEmailFtpState: [ IEmailFtpSettingsState, Dispatch<SetStateAction<IEmailFtpSettingsState>> ];
}

function FtpServerSettings(props: IFtpServerSettingsProps) {
	const { localEmailFtpState: [ localEmailFtpSettings, setLocalEmailFtpSettings ] } = props;
	const link = (
		<div>
			Go to <Link to="/setup/system/schedule">Schedule Preset</Link> to create a new preset.
		</div>
	);

	const {
		receiver, host, port, basePath, idPassRequired, id, password, filePrefix, frequency, log
	} = localEmailFtpSettings.ftpServerSettings;

	const updateFtpReceiver = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.receiver = value;
		}));
	};

	const updateHost = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.host = value;
		}));
	};

	const updatePort = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.port = +value;
		}));
	};

	const updateBasePath = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.basePath = value;
		}));
	};

	const updateIdPassRequired = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.idPassRequired = event.currentTarget.checked;
		}));
	};

	const updateId = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.id = value;
		}));
	};

	const updatePassword = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.password = value;
		}));
	};

	const updateFtpFilePrefix = (value: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.filePrefix = value;
		}));
	};

	const updateFtpLog = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.log = event.currentTarget.checked;
		}));
	};

	const updateFtpFrequency = (duration: string) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.frequency = +duration;
		}));
	};

	const updateFtpScheduleChecked = (scheduleName: string) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalEmailFtpSettings(produce(localEmailFtpSettings, (draft) => {
			draft.ftpServerSettings.schedule[ scheduleName ] = event.currentTarget.checked;
		}));
	};

	const renderSchedule = (updater: ScheduleUpdater) => {
		return function Schedule(schedule: [ string, boolean ]) {
			const [ scheduleName, checked ] = schedule;

			return (
				<div className="checkbox-wrapper" key={ scheduleName }>
					<Checkbox
						checked={ checked }
						onChange={ updater(scheduleName) }
					/>
					<label>{ scheduleName }</label>
				</div>
			);
		};
	};

	const ftpSchedule = Object
		.entries(localEmailFtpSettings.ftpServerSettings.schedule)
		.map(renderSchedule(updateFtpScheduleChecked));

	return (
		<div className="ftp-server-settings-container">
			<div className="ftp-server-settings">
				<div className="column">
					<div className="header">
						<h4>FTP</h4>
					</div>
					<div className="form-row">
						<span>Receiver</span>
						<Input
							value={ receiver }
							onUpdate={ updateFtpReceiver }
							wrapClassName="form-input"
						/>
					</div>
					<div className="form-row">
						<span>Host / IP</span>
						<Input
							value={ host }
							onUpdate={ updateHost }
							wrapClassName="form-input"
						/>
					</div>
					<div className="form-row">
						<span>Port</span>
						<Input
							hideCharMessage={ true }
							max={ 65535 }
							min={ 1 }
							onUpdate={ updatePort }
							type="number"
							value={ port }
							wrapClassName="form-input"
						/>
					</div>
					<div className="form-row">
						<span>Base Path</span>
						<Input
							value={ basePath }
							onUpdate={ updateBasePath }
							wrapClassName="form-input"
						/>
					</div>
					<div className="form-row">
						<span>ID/Password Required</span>
						<Checkbox
							checked={ idPassRequired }
							className="form-input"
							onChange={ updateIdPassRequired }
						/>
					</div>
					<div className="form-row">
						<span>ID</span>
						<Input
							value={ id }
							onUpdate={ updateId }
							disabled={ !idPassRequired }
							wrapClassName="form-input"
						/>
					</div>
					<div className="form-row">
						<span>Password</span>
						<Input
							value={ password }
							onUpdate={ updatePassword }
							disabled={ !idPassRequired }
							password
							placeholder="****"
							wrapClassName="form-input"
						/>
					</div>
					<div className="form-row">
						<span>File Prefix</span>
						<Input
							value={ filePrefix }
							onUpdate={ updateFtpFilePrefix }
							wrapClassName="form-input"
						/>
					</div>
					<div className="form-row">
						<span>Min. Frequency</span>
						<Select
							allowClear={ false }
							className="form-input"
							onSelect={ updateFtpFrequency }
							options={ frequencyOptions }
							value={ frequency }
						/>
					</div>
					<div className="form-row">
						<span>Log</span>
						<Checkbox
							checked={ log }
							className="form-input"
							onChange={ updateFtpLog }
						/>
					</div>
				</div>
				<div className="column">
					<div className="schedule-title header">
						<h4>Schedule</h4>
						<Popover content={ link }>
							<Icon name="circle-info" iconWeight={ IconWeight.LIGHT } size={ IconSize.SMALLEST } />
						</Popover>
					</div>
					<div className="schedules">
						{ ftpSchedule }
					</div>
				</div>
			</div>
		</div>
	);
}

export default memo(FtpServerSettings);
