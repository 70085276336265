import { IUser } from "Interfaces";

export const DUMMY_ADMIN_USER: IUser = {
	id: "ADMIN_ID",
	userId: "TESTUSER",
	group: "ADMIN",
	notifications: true
};

export const DUMMY_MANAGER_USER: IUser = {
	id: "MANAGER_ID",
	userId: "MANAGER",
	group: "MANAGER",
	notifications: false
};

export const DUMMY_BASIC_USER: IUser = {
	id: "USER_ID",
	userId: "USER",
	email: "test@example.com",
	group: "USER",
	notifications: true
};
