// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-nav {
  border-right: 1px solid #f0f0f0;
  min-width: 240px;
  overflow: auto;
}
.config-nav .panel-wrapper {
  position: relative;
}
.config-nav .panel-wrapper:has(a.active) .category::before {
  background-color: #127ec0;
  content: "";
  display: block;
  height: 40px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 4px;
}
.config-nav .category {
  font-weight: 500;
}
.config-nav .category-icon {
  left: 24px;
  position: absolute;
}
.config-nav ul {
  list-style: none;
  padding-left: 56px;
}
.config-nav ul li {
  line-height: 2.5;
  position: relative;
}
a:link,
a:visited {
  color: #7e7e7e;
  text-decoration: none;
}
a:hover,
a.active {
  color: #127ec0;
  font-weight: 600;
}
a.active::before {
  content: "▶";
  display: block;
  left: -24px;
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/ConfigNav/ConfigNav.less"],"names":[],"mappings":"AAEA;EACC,+BAAA;EACA,gBAAA;EACA,cAAA;AADD;AAFA;EAME,kBAAA;AADF;AAGE;EACC,yBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,SAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;AADH;AAfA;EAqBE,gBAAA;AAHF;AAKE;EACC,UAAA;EACA,kBAAA;AAHH;AAtBA;EA8BE,gBAAA;EACA,kBAAA;AALF;AA1BA;EAkCG,gBAAA;EACA,kBAAA;AALH;AAUA;;EACC,cAAA;EACA,qBAAA;AAPD;AAUA;;EACC,cAAA;EACA,gBAAA;AAPD;AAUA;EACC,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;AARD","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.config-nav {\n\tborder-right: 1px solid @light-gray-0;\n\tmin-width: 240px;\n\toverflow: auto;\n\n\t.panel-wrapper {\n\t\tposition: relative;\n\n\t\t&:has(a.active) .category::before {\n\t\t\tbackground-color: @blue-1;\n\t\t\tcontent: \"\";\n\t\t\tdisplay: block;\n\t\t\theight: 40px;\n\t\t\tleft: 4px;\n\t\t\tposition: absolute;\n\t\t\ttop: 3px;\n\t\t\twidth: 4px;\n\t\t}\n\t}\n\n\t.category {\n\t\tfont-weight: 500;\n\n\t\t&-icon {\n\t\t\tleft: 24px;\n\t\t\tposition: absolute;\n\t\t}\n\t}\n\n\tul {\n\t\tlist-style: none;\n\t\tpadding-left: 56px;\n\n\t\tli {\n\t\t\tline-height: 2.5;\n\t\t\tposition: relative;\n\t\t}\n\t}\n}\n\na:link, a:visited {\n\tcolor: @light-gray-2;\n\ttext-decoration: none;\n}\n\na:hover, a.active {\n\tcolor: @blue-1;\n\tfont-weight: 600;\n}\n\na.active::before {\n\tcontent: \"▶\";\n\tdisplay: block;\n\tleft: -24px;\n\tposition: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
