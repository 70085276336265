import {
	createAsyncThunk, Action, AnyAction, configureStore, DevToolsEnhancerOptions, EnhancedStore,
	MiddlewareArray, Reducer, ThunkMiddleware
} from "@reduxjs/toolkit";

export function createIdentityThunk<T>(actionType: string) {
	return createAsyncThunk(
		actionType,
		async (actionArg: T) => {
			return actionArg;
		}
	);
}

export function createIdentitySelector<S, T>() {
	return (state: S, value: T) => (value);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export let store: EnhancedStore<any, Action<any>, MiddlewareArray<[(store: any) => (next: any) => (action: any) => any,
	ThunkMiddleware<any, AnyAction, undefined>]>>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export const buildStore = (reducer: Reducer, devTools: DevToolsEnhancerOptions, middleware) => {
	store = configureStore({
		reducer,
		devTools,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware({
			immutableCheck: true,
			serializableCheck: false
		})
			.prepend(middleware)
	});
};
