import { ChangeEvent, FormEvent, memo, useRef, useState } from "react";
import { Button, ButtonTypes, Input, Modal } from "@clintonelec/react-storybook";
import "Components/ConfigLayout/Network/SecuritySettings/CertificateSelectModal/CertificateSelectModal.less";
import { ISecurityCertificate } from "Interfaces";

interface ICertificateSelectModalProps {
	ieeeEnabled?: boolean;
	certificate: ISecurityCertificate;
	handleCertUpdate?: (cert: ISecurityCertificate) => void;
}

function CertificateSelectModal(props: ICertificateSelectModalProps) {
	const { ieeeEnabled, certificate, handleCertUpdate } = props;
	const [ visible, setVisible ] = useState(false);
	const [ selectedFile, setSelectedFile ] = useState<File>(null);
	const fileSelectRef = useRef<HTMLInputElement>(null);

	const handleVisibilityChanged = (newVisibility: boolean) => {
		setSelectedFile(null);
		setVisible(newVisibility);
	};

	const handleSelectedFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target?.files?.length === 0) {
			return;
		}

		setSelectedFile(event.target.files[0]);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setVisible(false);
		handleCertUpdate({
			...certificate,
			name: selectedFile?.name
		});
	};

	const modalContent = (
		<form className="certificate-select-modal-content" onSubmit={ handleSubmit } noValidate>
			<div className="file-selector">
				<input
					hidden
					onChange={ handleSelectedFileChange }
					ref={ fileSelectRef }
					type="file"
				/>
				<Input
					className="file-label"
					disabled
					value={ selectedFile?.name }
				/>
				<Button
					onClick={ () => fileSelectRef.current?.click() }
					htmlType="button"
					type={ ButtonTypes.TERTIARY }
				>
					Choose File
				</Button>
			</div>
			<Button
				disabled={ !selectedFile }
				fill
				type={ ButtonTypes.TERTIARY }
			>
				Upload
			</Button>
		</form>
	);

	return (
		<Modal
			title="Open File"
			modalContent={ modalContent }
			onVisibilityChange={ handleVisibilityChanged }
			visible={ visible }
			width={ 400 }
		>
			<Button
				className="cert-button"
				disabled={ !ieeeEnabled }
				htmlType="button"
				type={ ButtonTypes.TERTIARY }
			>
				Find
			</Button>
		</Modal>
	);
}

export default memo(CertificateSelectModal);
