import { Fragment, memo } from "react";
import { Button, ButtonTypes, ConfirmModal, Input } from "@clintonelec/react-storybook";
import CertificateSelectModal from "Components/ConfigLayout/Network/SecuritySettings/CertificateSelectModal";
import "Components/ConfigLayout/Network/SecuritySettings/CertificateField/CertificateField.less";
import CertificateInfoModal from "../CertificateInfoModal";
import { CertificateType, ISecurityCertificate } from "Interfaces";

interface ICertificateProps {
	ieeeEnabled?: boolean;
	certificate: ISecurityCertificate;
	handleCertUpdate?: (value: ISecurityCertificate) => void;
}

const CertificateField = (props: ICertificateProps) => {
	const { ieeeEnabled, certificate, handleCertUpdate } = props;

	const handleDeleteClick = () => {
		handleCertUpdate({
			type: certificate.type,
			name: "",
			issuer: null,
			subject: null,
			validEnd: null,
			validStart: null
		});
	};

	let buttonContent = (
		<Fragment>
			<CertificateSelectModal
				ieeeEnabled={ ieeeEnabled }
				certificate={ certificate }
				handleCertUpdate={ handleCertUpdate }
			/>
			<ConfirmModal
				title={ `Delete ${ certificate.type }?` }
				modalContent="Deleting a certificate may result in loss of network connectivity."
				okButton={ { text: "Ok", type: ButtonTypes.TERTIARY, onClick: handleDeleteClick } }
				cancelButton={ { text: "Cancel" } }
			>
				<Button
					className="cert-button"
					disabled={ !certificate.name || !ieeeEnabled }
					htmlType="button"
					type={ ButtonTypes.TERTIARY }
				>
					Delete
				</Button>
			</ConfirmModal>
		</Fragment>
	);

	if (certificate.type !== CertificateType.KEY) {
		buttonContent = (
			<Fragment>
				{ buttonContent }
				<CertificateInfoModal certificate={ certificate } ieeeEnabled={ ieeeEnabled } />
			</Fragment>
		);
	}

	return (
		<div className="form-row">
			<span>{ certificate.type }</span>
			<Input
				disabled
				name="ieeeId"
				placeholder={ certificate.name || "No Certificate." }
				value={ certificate.name }
				wrapClassName="cert-wrapper"
			/>
			{ buttonContent }
		</div>
	);
};

export default memo(CertificateField);
